import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { Card } from 'react-bootstrap';

class UserTypeChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {

            },
            series: [

            ],
            labels: []
        };
    }

    componentWillReceiveProps(props) {
        if (!!props.chartData)
            this.setState({
                options: props.chartData.options,
                series: props.chartData.series,
                labels: props.chartData.labels,
            })
    }

    render() {
        return (
            <Card style={{ width: '100%' }}>
                <Card.Header as="h5">Biểu đồ</Card.Header>
                <Card.Body>
                    <Card.Title>Tỷ trọng loại Users</Card.Title>
                    <Card.Text>
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="donut"
                            width='100%'
                        />
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}

export default UserTypeChart;