import { observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-bootstrap';
import { SPECIFIC_TYPE } from 'src/pages/PushNotification/stores/PushNotificationStores';
import { sendEmailStores } from '../stores/SendEmailStores';
import BootstrapTable from 'react-bootstrap-table-next';
import { toJS } from 'mobx';
import { COLOR } from 'src/common/constant';

const columns = [{
    dataField: 'id',
    text: 'Id'
}, {
    dataField: 'name',
    text: 'Full name'
}, {
    dataField: 'phoneNumber',
    text: 'Phone number'
}, {
    dataField: 'email',
    text: 'Email'
}
];

const SendEmailStep3 = ({
}) => {
    const {
        countUserMatcheSegmented,
        emailTitle,
        targetType,
        arrUserToSend,
        sendEmailToAllUser
    } = sendEmailStores;
    return (
        <div>
            <Form.Label>Tiêu đề Email</Form.Label>
            <p>{emailTitle}</p>
            <Form.Label>Nội dung Email</Form.Label>
            <div dangerouslySetInnerHTML={{ __html: sendEmailStores.emailContent }} />
            {
                targetType === SPECIFIC_TYPE
                    ?
                    (sendEmailToAllUser ? <Form.Label style={{ fontWeight: 'bold', color: COLOR.primary, fontSize: 16 }}>Email sẽ được gửi cho tất cả user</Form.Label>
                        :
                        <div>
                            <Form.Label>Danh sách users</Form.Label>
                            <BootstrapTable keyField='id' data={toJS(arrUserToSend)} columns={columns} />
                        </div>)
                    :
                    <div>
                        <Form.Label>Có <b> {countUserMatcheSegmented} Users</b>  phù hợp điều kiện</Form.Label>

                    </div>

            }
        </div>
    )
}

export default observer(SendEmailStep3);
