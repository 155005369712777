import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import moment from 'moment';

const ReportHistoryItem = ({
    reportItem
}) => (
        <ListGroup.Item className='list-group-reportItem'>
            <blockquote className="blockquote">
                {/* <small className='mb-0'>
        <a>{reportItem.fromFullname}</a>
    </small> */}

                <div className="card-avatar" style={{ flexDirection: 'row' }}>
                    <span className="card-description">
                        <small>Đã báo cáo</small>
                    </span>

                    <img className='img' style={{ width: 30, height: 30, objectFit: 'cover', borderRadius: 30, marginLeft: 8, marginRight: 8 }}
                        src={reportItem.toUserAvatar || ''} />

                    <small className='mb-1'>
                        <a href='#'>{reportItem.toUserFullname}</a>
                    </small>

                    <small className='mb-1'>
                        <i> {reportItem.reportTypeName} </i>
                    </small>
                </div>

                <span className="card-description">
                    <small>Nội dung: </small>
                </span>
                <small className='mb-1'>
                    <i>{reportItem.message}</i>
                </small>

                <footer className="blockquote-footer">
                    <cite title="Source Title">
                        <small>Lúc {moment(reportItem.createdAt).format('HH:mm A DD-MM-YYYY')}</small>
                    </cite>
                </footer>
            </blockquote>
        </ListGroup.Item>
    );

export default ReportHistoryItem;
