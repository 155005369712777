import React, { Component } from 'react';
import ProductReviewTable from './ProductReviewTable';
import ProductReviewModal from './ProductReviewModal';
import { Global } from '../../../../GlobalClass';
import { reviewProductStores, radioFilterProductReviewData } from '../../stores/ReviewProductStores';
import { observer } from 'mobx-react';
import { ROUTER } from '../../../../common/route';
import { getParamURL } from '../../../../helpers/CF';
import { history } from '../../../../history';
import RadioGroup from 'src/pages/components/RadioGroup/RadioGroup';

class ReviewProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewProductDetailModal: false,
            productId: null
        }

    };

    onSelectedFilter = async ({ item }) => {
        const filter = item.id;
        const { getReviewProductsCountAction, setFilterSelected } = reviewProductStores;
        setFilterSelected(filter);
        reviewProductStores.setReviewProductPage(1);
        await getReviewProductsCountAction();
        this.getReviewProductList();
    }


    onHideReviewProductModal = () => {
        this.setState({ reviewProductDetailModal: false, productId: null });
        Global.push(ROUTER.PRODUCT.REVIEW);
    }

    onItemClicked = (item) => {
        this.setState({ productId: item.id, reviewProductDetailModal: true });
        Global.push(`${ROUTER.PRODUCT.REVIEW}?id=${item.id}`);
    }

    render() {
        const { reviewProductDetailModal } = this.state;
        const { filterSelected } = reviewProductStores;
        return (
            <div className="card">
                {/* <div id='review-product' className='v-radio'>
                    <RadioGroup
                        inputKey='review-product'
                        containerStyle={{ marginRight: 32 }}
                        checkedId={filterSelected}
                        onItemClicked={this.onSelectedFilter}
                        datas={radioFilterProductReviewData} />
                </div> */}
                <div className="card-body">
                    <ProductReviewTable
                        onItemClicked={this.onItemClicked}
                    />
                </div>

                <ProductReviewModal isShow={reviewProductDetailModal} onHide={this.onHideReviewProductModal} />
            </div>
        );
    }

    getReviewProductList = async () => {
        await reviewProductStores.getReviewProductsAction();
    }

    componentDidMount = async () => {
        const { getReviewProductsCountAction, getRejectTypeListAction, productRejectTypeList } = reviewProductStores;
        await getReviewProductsCountAction();
        if (!productRejectTypeList.length) await getRejectTypeListAction();
        this.getReviewProductList();
        if (history.location.pathname == ROUTER.PRODUCT.REVIEW) {
            const productId = getParamURL(this.props, 'id');
            if (!!productId)
                this.setState({ productId, reviewProductDetailModal: true });
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { reviewProductDetailModal } = this.state;
        if (prevState.reviewProductDetailModal !== reviewProductDetailModal) {
            const {
                getReviewProductDetailAction,
                getProductHistoryInReviewProductAction,
                setSupportConversationsPage,
                setSupportConversationList
            } = reviewProductStores;
            if (reviewProductDetailModal) {
                getReviewProductDetailAction(this.state.productId);
                getProductHistoryInReviewProductAction(this.state.productId);
            } else {
                setSupportConversationsPage(1);
                setSupportConversationList([]);
            }
        }
    };


}

export default observer(ReviewProductPage);