import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormTypes } from '../../../../common/constant';
import InputTextADSForm from './InputTextADSForm'
import ComboboxADSForm from './ComboboxADSForm';

class AdvertisingForm extends Component {
    constructor(props) {
        super(props);
    };

    render() {
        const { objectTree } = this.props;
        return (
            <div>
                {
                    Object.keys(objectTree).map((key, index) => (
                        <div key={index}>
                            {this.switchToRenderView(key, objectTree[key])}
                        </div>
                    ))
                }
            </div>
        );
    }

    switchToRenderView = (key, item) => {
        switch (item.type) {
            case FormTypes.COMBOBOX:
                return this.renderCombobox(key, item);
            case FormTypes.TEXT_INPUT:
                return this.renderTextInput(key, item);
            default:
                return;
        }
    }

    renderCombobox = (key, item) => {
        return (
            <ComboboxADSForm
                itemKey={key}
                item={item} />
        )
    }
    renderTextInput = (key, item) => {
        return (
            <InputTextADSForm
                itemKey={key}
                item={item} />
        )
    }
}


export default observer(AdvertisingForm);