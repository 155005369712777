import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { AcceptButton, CancelButton } from 'src/pages/components/Button';
import {convertToBiddingApi} from 'src/api/product/bidding';
import { Global } from 'src/GlobalClass';

const ConvertToBiddingModal = ({
  isShow,
  onHide,
  convertedBiddingProduct
}) => {
  const [floorPrice, setFloorPrice] = useState('');
  const [startPrice, setStartPrice] = useState('');
  const [stepPrice, setStepPrice] = useState('');
  const [endDate, setEndDate] = useState(null);

  const onConfirm = async () => {
    try {
      if (!floorPrice || !startPrice || !stepPrice || !endDate) {
        Global.error({message: 'Cần điền đầy đủ thông tin'});
        return;
      }
      const result = await convertToBiddingApi({
        productId: convertedBiddingProduct.id,
        floorPrice: floorPrice,
        startPrice: startPrice,
        stepPrice: stepPrice,
        endDate
      })
      if (result.responseCode.toString().startsWith('2')) Global.success({message: 'Chuyển đổi thành công'});
      else Global.error({message: result.responseText || 'Chuyển đổi thất bại'})
    }
    catch (err) {
      Global.error({message: err.message || ''})
    }
  }

  return <Modal
    className='review-product-modal'
    show={isShow}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={onHide}
  >
    <Modal.Header closeButton>
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        Chuyển đổi {convertedBiddingProduct ? convertedBiddingProduct.productName : ''} sang sản phẩm đấu giá
      </Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ maxHeight: '65vh', overflow: 'scroll' }}>
      <div className="form-group bmd-form-group" style={{margin: 32}} >
        <label className="bmd-label-floating">Giá sàn</label>
          <input 
            required
            value={floorPrice} 
            type="number" 
            className="form-control" 
            onChange={(e) => {
              setFloorPrice(e.target.value);
            }}
          />
      </div>
      <div className="form-group bmd-form-group" style={{margin: 32}} >
        <label className="bmd-label-floating">Giá khởi điểm</label>
          <input 
            required
            value={startPrice} 
            type="number" 
            className="form-control" 
            onChange={(e) => {
              setStartPrice(e.target.value);
            }}
          />
      </div>
      <div className="form-group bmd-form-group" style={{margin: 32}} >
        <label className="bmd-label-floating">Bước giá</label>
          <input
            required
            value={stepPrice} 
            type="number" 
            className="form-control" 
            onChange={(e) => {
              setStepPrice(e.target.value);
            }}
          />
      </div>
      <div className="form-group bmd-form-group" style={{margin: 32}} >
        <label style={{marginRight: 32}}>Ngày kết thúc</label>
        <DatePicker
            value={endDate ? moment(endDate).format("DD/MM/YYYY hh:mm A") : ''}
            adjustDateOnChange={false}
            showTimeSelect
            className="my-date-picker"
            selected={endDate || new Date()}
            dateFormat='dd/MM/yyyy hh:mm'
            minDate={Date.now()}
            onChange={(date) => {
              if (!!date)
                setEndDate(date)
            }} 
          />
      </div>
      
    </Modal.Body>
    <Modal.Footer>           
      <div>
          <CancelButton onClick={onHide} title='Huỷ' />
          <AcceptButton onClick={onConfirm} title='Gửi' />
      </div>
    </Modal.Footer>
  </Modal>
}

export default ConvertToBiddingModal;