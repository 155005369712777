import React, { Component, useCallback, useEffect, useRef } from 'react';
import ReportListTable from './components/ReportListTable';
import { reportStore, REPORT_MODAL_TYPE } from './stores/ReportStore';
import { userDetailStores } from '../UserManagement/stores/UserDetailStores';
import UserDetailModal from '../UserManagement/components/UserDetail/UserDetailModal';
import ReportContentModal from './components/ReportContentModal';

const ReportList = () => {
    const reportContentModalRef = useRef(null);
    useEffect(() => {
        loadData();
    }, []);

    const loadData = useCallback(async () => {
        await reportStore.getTotalCount();
        reportStore.getData();
    }, []);

    const onShowUserInfo = (userId) => {
        if (!userId) return;
        userDetailStores.setUserIdForLoadDetail(userId);
        userDetailStores.onChangeUserDetailModal(true);
    }

    const onChangeNotePress = (content, reportId) => {
        reportStore.changeModalReportTypeWorker(REPORT_MODAL_TYPE.REPORT_NOTE);
        if (reportContentModalRef.current) {
            reportContentModalRef.current.setContent(content);
            reportContentModalRef.current.setReportId(reportId);
        }
    }

    const onFeedbackIconPress = (userIdToSend) => {
        reportStore.changeModalReportTypeWorker(REPORT_MODAL_TYPE.REPORT_FEEDBACK);
        if (reportContentModalRef.current) {
            reportContentModalRef.current.settUserIdToSendFeedback(userIdToSend);
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <ReportListTable
                    onChangeNotePress={onChangeNotePress}
                    onFeedbackIconPress={onFeedbackIconPress}
                    onShowUserInfo={onShowUserInfo}
                />
            </div>
            <ReportContentModal ref={reportContentModalRef} />
            <UserDetailModal />
        </div>
    );
}

export default ReportList;