import { extendObservable } from 'mobx';
import { pageableOffset } from '../../../helpers/CF';
import { getReportList, getReportListTotalCount, updateReportStatusApi, updateNoteApi, sendReportFeedbackApi } from '../../../api/report/report';
import { Global } from 'src/GlobalClass';

export const REPORT_MODAL_TYPE = {
    REPORT_NOTE: 'REPORT_NOTE',
    REPORT_FEEDBACK: 'REPORT_FEEDBACK'
}
class ReportStore {
    constructor() {
        extendObservable(this, {
            reportlist: [],
            loadingFeedbackList: false,
            reportCurrentPage: 1,
            totalReportCount: 0,
            isShowReportContentModal: false,
            reportModalPostContentLoading: false,
            reportModalType: REPORT_MODAL_TYPE.REPORT_NOTE
        });
    }


    getData = async () => {
        this.loadingFeedbackList = true;
        try {
            const offset = pageableOffset(this.reportCurrentPage);
            const result = await getReportList(offset);
            if (result.responseCode.toString().startsWith('2')) {
                this.reportlist = result.dataArray || [];
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.loadingFeedbackList = false;
    }

    getTotalCount = async () => {
        try {
            this.loadingFeedbackList = true;
            const result = await getReportListTotalCount();
            if (result.responseCode.toString().startsWith('2')) {
                this.totalReportCount = parseInt(result.data.total);
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
    }

    updateStatusWorker = async (id, status) => {
        this.loadingFeedbackList = true;
        try {
            const result = await updateReportStatusApi(id, status);
            if (result.responseCode.toString().startsWith('2')) {
                const index = this.reportlist.findIndex(o => o.id == id);
                if (index > -1) {
                    this.reportlist[index].status = status;
                }
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.loadingFeedbackList = false;
    }

    updateNoteWorker = async (id, note) => {
        try {
            this.changeReportModalLoadingWorker(true);
            const result = await updateNoteApi(id, note);
            if (result.responseCode.toString().startsWith('2')) {
                const index = this.reportlist.findIndex(o => o.id == id);
                if (index > -1) {
                    this.reportlist[index].note = note;
                }
                return true;
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.changeReportModalLoadingWorker(false);
        return false;
    }

    sendReportFeedbackWorker = async (content, userIdToSend, metadata) => {
        try {
            this.changeReportModalLoadingWorker(true);
            const result = await sendReportFeedbackApi(content, userIdToSend, metadata);
            if (result.responseCode.toString().startsWith('2')) return true;
            else Global.error({ message: result.responseText || '' });
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.changeReportModalLoadingWorker(false);
        return false;
    }

    changeReportModalStatusWorker = (status) => this.isShowReportContentModal = status;

    changeReportModalLoadingWorker = (status) => this.reportModalPostContentLoading = status;

    changeModalReportTypeWorker = (type) => this.reportModalType = type;

    setPage = (page) => this.reportCurrentPage = page;

}
export const reportStore = new ReportStore();