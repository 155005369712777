import React from 'react';
// add icon .svg path
// change from fill="..." to fill="currentColor" to can be change color
import { ReactComponent as call } from './call.svg';
import { ReactComponent as email } from './email.svg';
import { ReactComponent as facebook_text } from './facebook.svg';
import { ReactComponent as id_card } from './id-card.svg';
import { ReactComponent as user } from './user.svg';
import { ReactComponent as camera } from './camera.svg';

export const IconsSVG = {
  call,
  email,
  facebook_text,
  id_card,
  user,
  camera
}


const Icon = (props) => {

  const { name, height, width, color } = props;
  const IconSVG = IconsSVG[name];

  return <IconSVG height={height} width={width} style={{ color }} />
}

Icon.defaultProps = {
  height: 24,
  width: 24,
  color: '#8E8E8E'
}

export default Icon;