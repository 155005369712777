import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { sendEmailStores } from '../stores/SendEmailStores';
import { observer } from 'mobx-react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { toJS } from 'mobx';
import _ from 'lodash';
import { SEGMENTED_TYPE, SPECIFIC_TYPE } from 'src/pages/PushNotification/stores/PushNotificationStores';

const conditionSegments = [
    {
        id: 0,
        title: 'Từ khoá ẩn',
    },
    {
        id: 1,
        title: 'Đã đăng bán X sản phẩm',
    },
    {
        id: 2,
        title: 'Đã completed Y giao dịch mua'
    },
    {
        id: 3,
        title: 'Đã completed Z giao dịch bán'
    },
    {
        id: 4,
        title: 'Đã bán sản phẩm trong các Category A/B/C'
    },
    {
        id: 5,
        title: 'Vị trí địa lý: Quận - Thành phố'
    },
    {
        id: 6,
        title: 'User đã hoặc chưa verify cmnd'
    },
    {
        id: 7,
        title: 'User đã hoặc chưa verify sdt'
    },
    // {
    //     id: 8,
    //     title: 'User đã có cmnd'
    // },
    {
        id: 9,
        title: 'Đã like category'
    },
];

const SepcificView = observer(() => {
    const {
        sendEmailToAllUser,
        setSendAllStatus,
        setArrUserToSend,
        arrUserToSend,
        searchUserResults,
        searchUserByKeywordService,
        setSearchedUser,
    } = sendEmailStores;
    const [loadingUser, setLoadingUser] = useState(false);
    const [userKeyword, setUserKeyword] = useState('');
    const handleChangeDebounce = useCallback(_.debounce(async (strSearch) => {
        if (!strSearch) return setSearchedUser([]);
        setLoadingUser(true);
        await searchUserByKeywordService(strSearch);
        setLoadingUser(false);
    }, 500), []);

    const handleChange = (e) => {
        handleChangeDebounce(e.target.value);
        setUserKeyword(e.target.value);
    }

    const onSelectUser = (e, arr) => {
        setArrUserToSend(arr);
        setUserKeyword('');
    }
    return <div>
        <b>Gửi tới toàn bộ Users trên hệ thống</b>
        <Switch
            checked={sendEmailToAllUser}
            onChange={(e, checked) => {
                setSendAllStatus(checked);
            }}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        {
            !sendEmailToAllUser
            &&
            <Autocomplete
                multiple
                id="size-small-standard-multi"
                size="small"
                noOptionsText='Danh sách trống'
                options={toJS(searchUserResults)}
                loading={loadingUser}
                loadingText='Đang tải sanh sách user'
                getOptionLabel={option => option.fullname}
                style={{ minWidth: '400px', maxWidth: '1400px' }}
                onChange={onSelectUser}
                value={toJS(arrUserToSend)}
                inputValue={userKeyword}
                renderInput={params => (
                    <TextField
                        {...params}
                        label='Tìm (Tên, email, số điện thoại)'
                        variant='outlined'
                        onChange={handleChange}
                        style={{ minWidth: '400px', maxWidth: '1400px' }}
                    />
                )}
            />

        }
    </div>;
})

const SegmentedTextInputType = observer(({ segment, index }) => {
    const {
        selectedSegments,
        setSelectedSegments
    } = sendEmailStores;
    return (
        <div key={segment.id} style={{ marginTop: '20px' }} className="row">
            <div className="col-md-4">
                <Autocomplete
                    id='ac-type'
                    options={conditionSegments}
                    getOptionLabel={option => option.title}
                    style={{ width: '100%' }}
                    onChange={(e, arr) => {
                        if (arr) {
                            var clone = toJS(selectedSegments);
                            clone[index].segmentId = arr.id;
                            clone[index].selectedValue = {
                                id: arr.id,
                                title: arr.title
                            }
                            setSelectedSegments(clone);
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        var clone = toJS(selectedSegments);
                        clone[index].selectedValue.title = newInputValue;
                        setSelectedSegments(clone);
                    }}
                    inputValue={segment.selectedValue.title}
                    renderInput={params => <TextField style={{ width: '100%' }}
                        {...params} label="Chọn..." variant="outlined" />
                    }
                /></div>
            <div className="col-md-4">
                <TextField style={{ width: '100%' }}
                    label="Điều kiện" variant="outlined"
                    onChange={(e) => {
                        const val = e.target.value;
                        var clone = toJS(selectedSegments);
                        clone[index].value = val;
                        setSelectedSegments(clone);
                    }}
                    value={segment.value || ''}
                />
            </div>
            {
                (selectedSegments.length == 1 || index == selectedSegments.length - 1)
                    ?
                    <div className="col-md-4"><button type="button" onClick={
                        () => {
                            var clone = toJS(selectedSegments);
                            var maxNumber = clone[clone.length - 1].id + 1;
                            clone.push({
                                id: maxNumber,
                                segmentId: 1,
                                value: '',
                                selectedValue: {
                                    id: 1,
                                    title: 'Đã đăng bán X sản phẩm',
                                }
                            })
                            setSelectedSegments(clone);

                        }

                    } className="btn btn-info">AND</button>
                    </div>
                    :
                    <div className="col-md-4"><button type="button" onClick={
                        () => {
                            var clone = toJS(selectedSegments);
                            clone.splice(index, 1)
                            setSelectedSegments(clone);
                        }

                    } className="btn btn-danger">DELETE</button>
                    </div>
            }

        </div>
    )
})

const SegmentedComboBoxType = observer(({ segment, index, comboboxOptions, onChange, onInputChange, inputValue }) => {
    const {
        selectedSegments,
        setSelectedSegments
    } = sendEmailStores;
    return (
        <div id={segment.id} style={{ marginTop: '20px' }} className="row">
            <div className="col-md-4">
                <Autocomplete
                    id='ac-type1'
                    options={conditionSegments}
                    getOptionLabel={option => option.title}
                    style={{ width: '100%' }}
                    onChange={(e, arr) => {
                        if (!e) return;
                        if (arr) {
                            var clone = toJS(selectedSegments);
                            clone[index].segmentId = arr.id;
                            clone[index].selectedValue = {
                                id: arr.id,
                                title: arr.title
                            }
                            setSelectedSegments(clone);
                        }
                    }}
                    onInputChange={(e, newInputValue) => {
                        if (!e) return;
                        var clone = toJS(selectedSegments);
                        clone[index].selectedValue.title = newInputValue;
                        setSelectedSegments(clone);
                    }}
                    inputValue={toJS(selectedSegments)[index].selectedValue.title}
                    renderInput={params => {
                        return (
                            <TextField style={{ width: '100%' }}
                                {...params} label="Chọn..." variant="outlined" />
                        )
                    }}
                /></div>
            <div className="col-md-4">
                <Autocomplete
                    id='ac-type2'
                    options={comboboxOptions}
                    getOptionLabel={option => option.title}
                    style={{ width: '100%' }}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    inputValue={inputValue}
                    renderOption={params => {
                        if (comboboxOptions[index].image && comboboxOptions[index].image.length > 0) {
                            return (
                                <div className="row">
                                    <div className="col-md-3">
                                        {
                                            <img src={params.image} style={{ width: '40px', height: '40px' }} />
                                        }
                                    </div>
                                    <div className="col-md-8 offset-1">
                                        <div>{params.title}</div>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div>{params.title}</div>
                                    </div>
                                </div>
                            )
                        }

                    }}
                    renderInput={params => {
                        return (
                            <TextField style={{ width: '100%' }}
                                {...params} label="Chọn..." variant="outlined" />
                        )
                    }}
                />
            </div>
            {
                (selectedSegments.length == 1 || index == selectedSegments.length - 1)
                    ?
                    <div className="col-md-4"><button type="button" onClick={
                        () => {
                            var clone = toJS(selectedSegments);
                            var maxNumber = clone[clone.length - 1].id + 1;
                            clone.push({
                                id: maxNumber,
                                segmentId: 1,
                                value: '',
                                selectedValue: {
                                    id: 1,
                                    title: 'Đã đăng bán X sản phẩm',
                                }
                            })
                            // setSelectedSegments(clone);
                        }

                    } className="btn btn-info">AND</button>
                    </div>
                    :
                    <div className="col-md-4"><button type="button" onClick={
                        () => {
                            var clone = toJS(selectedSegments);
                            clone.splice(index, 1)
                            // setSelectedSegments(clone);
                        }

                    } className="btn btn-danger">DELETE</button>
                    </div>
            }
        </div>
    )
})

const SegmentView = observer(({ categories, areaTexts, idVerifies, phoneNumberVerifies }) => {
    const {
        selectedSegments,
        setSelectedSegments
    } = sendEmailStores;
    return (
        <div>
            <b>Target user if...</b>
            <br />
            {
                toJS(selectedSegments).map((segment, index) => {
                    if (segment.segmentId == 0 ||
                        segment.segmentId == 1 ||
                        segment.segmentId == 2 ||
                        segment.segmentId == 3 ||
                        segment.segmentId == 8
                    )
                        return <SegmentedTextInputType
                            key={index}
                            segment={segment}
                            index={index} />;
                    else if (segment.segmentId == 4 || segment.segmentId == 9) {
                        return <SegmentedComboBoxType
                            key={index}
                            segment={segment}
                            index={index}
                            comboboxOptions={toJS(categories)}
                            onChange={(e, arr) => {
                                if (!e) return;
                                var clone = toJS(selectedSegments);
                                clone[index].value = arr ? arr.id : '';
                                clone[index].displayValue = arr ? arr.title : '';
                                setSelectedSegments(clone);
                            }}
                            onInputChange={(e, newInputValue) => {
                                if (!e) return;
                                //On input change
                                var clone = toJS(selectedSegments);
                                clone[index].displayValue = newInputValue;
                                setSelectedSegments(clone);
                            }}
                            inputValue={segment.displayValue}
                        />
                    } else if (segment.segmentId == 5) {
                        return <SegmentedComboBoxType
                            key={index}
                            segment={segment}
                            index={index}
                            comboboxOptions={toJS(areaTexts)}
                            onChange={(e, arr) => {
                                if (!e) return;
                                var clone = toJS(selectedSegments);
                                clone[index].value = arr ? arr.title : '';
                                setSelectedSegments(clone);
                            }}
                            onInputChange={(event, newInputValue) => { }}
                            inputValue={segment.value}
                        />
                    } else if (segment.segmentId == 6) {
                        return <SegmentedComboBoxType
                            key={index}
                            segment={segment}
                            index={index}
                            comboboxOptions={toJS(idVerifies)}
                            onChange={(e, arr) => {
                                if (!e) return;
                                var clone = toJS(selectedSegments);
                                clone[index].value = arr ? arr.id : '';
                                clone[index].displayValue = arr ? arr.title : '';
                                setSelectedSegments(clone);
                            }}
                            onInputChange={(e, newInputValue) => {
                                if (!e) return;
                                //On input change
                                var clone = toJS(selectedSegments);
                                clone[index].displayValue = newInputValue;
                                setSelectedSegments(clone);
                            }}
                            onInputChange={(event, newInputValue) => { }}
                            inputValue={segment.displayValue}
                        />
                    } else if (segment.segmentId == 7) {
                        return <SegmentedComboBoxType
                            key={index}
                            segment={segment}
                            index={index}
                            comboboxOptions={toJS(phoneNumberVerifies)}
                            onChange={(e, arr) => {
                                if (!e) return;
                                var clone = toJS(selectedSegments);
                                clone[index].value = arr ? arr.id : '';
                                clone[index].displayValue = arr ? arr.title : '';
                                setSelectedSegments(clone);
                            }}
                            onInputChange={(e, newInputValue) => {
                                if (!e) return;
                                //On input change
                                var clone = toJS(selectedSegments);
                                clone[index].displayValue = newInputValue;
                                setSelectedSegments(clone);
                            }}
                            onInputChange={(event, newInputValue) => { }}
                            inputValue={segment.displayValue}
                        />
                    }
                })
            }
        </div>
    );
})

const SendEmailStep2 = ({
}) => {
    const {
        setTargetType,
        targetType,
        areaTexts,
        categories,
        idVerifies,
        phoneNumberVerifies,
        loadProductAreaText,
        loadProductCategories
    } = sendEmailStores;

    const specificClassName = useMemo(() => {
        return targetType == SPECIFIC_TYPE ? 'btn btn-primary' : 'btn btn-secondary';
    }, [targetType]);

    const segmentClassName = useMemo(() => {
        return targetType == SEGMENTED_TYPE ? 'btn btn-primary' : 'btn btn-secondary';
    }, [targetType]);

    const ViewContent = useMemo(() => {
        let view = <SepcificView />;
        if (targetType === SEGMENTED_TYPE)
            view = <SegmentView
                areaTexts={areaTexts}
                categories={categories}
                idVerifies={idVerifies}
                phoneNumberVerifies={phoneNumberVerifies}
            />;
        return view;
    }, [targetType]);

    useEffect(() => {
        loadProductAreaText();
        loadProductCategories();
    }, []);

    return (
        <div>
            <button type='button' onClick={() => setTargetType(SPECIFIC_TYPE)} className={specificClassName}>Specific</button>
            <button type='button' onClick={() => setTargetType(SEGMENTED_TYPE)}
                className={segmentClassName}>Segment</button>
            <hr />
            {ViewContent}
        </div>
    )
}

export default observer(SendEmailStep2);
