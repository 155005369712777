import { extendObservable } from 'mobx';
import { getOrderListApi, getTotalOrderCountApi } from '../../../api/order/order';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';


class RefundListStores {
    constructor() {
        extendObservable(this, {
            refundList: [],
            getRefundListLoading: false,
            totalRefundListCount: 0,
            refundTableCurrentPage: 1,
            isShowRefundDetailModal: false,
            txtSearchRefund: '', 
        });
    }
    onSearchRefundChange = (txt) => this.txtSearchRefund = txt || '';

    setRefundFilterSelected = (id) => this.RefundFilterSelected = id;

    getCurrentState = order => order.progress.find(state => state.name === order.progressStep) || {name: 'N/a', status: 'N/a', result: 'N/a'};

    getRefundListStore = async () => {
        try {
            this.getRefundListLoading = true;
            const offset = pageableOffset(this.refundTableCurrentPage);
            const result = await getOrderListApi(offset, this.txtSearchProduct || '', 'CHECKING', 'REFUND');
            if (result.responseCode.toString().startsWith('2')) {
                this.refundList = (result.dataArray || []).map(order => ({...order, currentState: this.getCurrentState(order)}));
            } else {
                Global.error({ message: result.responseText || '' });
            }
            this.getRefundListLoading = false;
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.getRefundListLoading = false;
        }
    }
    setRefundTablePage = (page) => this.refundTableCurrentPage = page;

    getTotalRefundCount = async (filter) => {
        const result = await getTotalOrderCountApi(this.txtSearchRefund || '', 'CHECKING', 'REFUND');
        if (result.responseCode.toString().startsWith('2')) {
            this.totalRefundListCount = parseInt(result.data.count);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    onRefundDetailModalStatusChange = (status) => this.isShowRefundDetailModal = status;

}
export const refundListStores = new RefundListStores();