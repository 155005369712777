import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import './insertmodal.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { observer } from 'mobx-react';
import { introImagesStores } from '../stores/IntroImagesStores';

class InsertModal extends Component {

    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
    };
    onDrop(acceptedFiles, rejectedFiles) {
        introImagesStores.onDrop(acceptedFiles, rejectedFiles);
    }

    render() {
        const { ...rest } = this.props;

        return (
            <Modal {...rest} bsSize="large" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">
                        Intro images
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <Dropzone
                                    accept=".jpg,.png"
                                    multiple={false}
                                    onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <div id="box-shadow">
                                                    {
                                                        introImagesStores.uploadedUrl != '' ?
                                                            <img src={introImagesStores.uploadedUrl} id="upload-image" />
                                                            :
                                                            <div>
                                                                {!introImagesStores.uploadingImage ? "Click here or Drag & Drop to upload image" : ""}
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone> 
                                <div style={{ position: 'absolute', top: 90, left: 100 }}>
                                    {
                                        introImagesStores.uploadingImage
                                        &&
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={30}
                                            width={30}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{ marginTop: '30px' }}>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="bmd-label-floating">Order index</label>
                                <input value={introImagesStores.orderIndex} onChange={(e) => {
                                    introImagesStores.orderIndex = e.target.value;
                                }} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="success" onClick={introImagesStores.onBtnSavePressed}>Save</Button>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default observer(InsertModal);
