import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SendEmailStep1 from './SendEmailStep1';
import SendEmailStep2 from './SendEmailStep2';
import SendEmailStep3 from './SendEmailStep3';
import { sendEmailStores } from '../stores/SendEmailStores';
import { Global } from '../../../GlobalClass';
import { COLOR } from '../../../common/constant';
import { observer } from 'mobx-react';
import { AcceptButton, CancelButton, InformationButton } from 'src/pages/components/Button/Button';
import { SPECIFIC_TYPE } from 'src/pages/PushNotification/stores/PushNotificationStores';

const muiTheme = createMuiTheme({
    overrides: {
        MuiStepIcon: {
            root: {
                '&$active': {
                    fill: COLOR.primary,
                    '& $text': {
                        fill: 'white',
                    },
                },
            },
            text: {
                fill: 'white',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return ['Nhập nội dung email', 'Chọn đối tượng cần gửi', 'Review'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return (
                <SendEmailStep1 />
            )
        case 1:
            return (
                <SendEmailStep2 />
            )

        case 2:
            return (
                <SendEmailStep3 />
            )
        default:
            return 'Unknown step';
    }
}

const SendEmailVerticalLinearStepper = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const {
        emailContent,
        arrUserToSend,
        sendEmailToAllUser,
        emailTitle,
        loadSegmentsUser,
        targetType,
        sendEmailStore
    } = sendEmailStores;

    const handleNext = () => {
        if (!emailContent || !emailTitle)
            return Global.warning({ message: 'Vui lòng nhập đủ thông tin.' });
        else if (activeStep === 1 && targetType === SPECIFIC_TYPE && !arrUserToSend.length && !sendEmailToAllUser)
            return Global.warning({ message: 'Vui lòng chọn người dùng cần gửi.' });
        else if (activeStep === steps.length - 1) {
            sendEmailStore();
        }
        setActiveStep(prevActiveStep => {
            if (prevActiveStep == 1) loadSegmentsUser();
            return prevActiveStep + 1;
        });
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={muiTheme}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography component={'span'}>{getStepContent(index)}</Typography>
                                <div className={classes.actionsContainer}>
                                    <InformationButton
                                        title='Quay lại'
                                        disabled={activeStep === 0}
                                        onClick={handleBack} />

                                    <AcceptButton
                                        disabled={sendEmailStores.uploadingImageInEmail}
                                        containerStyle={{ marginLeft: 8 }}
                                        title={activeStep === steps.length - 1 ? 'Gửi email' : 'Tiếp theo'}
                                        onClick={handleNext}
                                    />
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </MuiThemeProvider>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography component={'span'}>Gửi thành công!</Typography>
                    <CancelButton containerStyle={{ marginLeft: 8 }} onClick={() => { setActiveStep(0); sendEmailStores.reset(); }} title='Reset' />
                </Paper>
            )}
        </div>
    );
}

export default observer(SendEmailVerticalLinearStepper);