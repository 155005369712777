import { requestApi } from "../axios";
const prefix = 'user-profile';

export function getCurrentMods(offset) {
  return requestApi(`/forum/get-moderators?limit=10&offset=${offset}`, 'GET');
}

export function removeMod(moderatorId) {
  return requestApi(`/forum/delete-moderator`, 'DELETE', {moderatorId});
}

export function addMod(moderatorId) {
  return requestApi(`/forum/add-moderator`, 'POST', {moderatorId})
}
