import { requestApi } from "../axios";

export function loadBuyingRequest(offset, limit) {
  return requestApi(`/buyingRequest/loadBuyingRequest?limit=${limit}&offset=${offset}`, 'GET');
}

export function fetchCategoriesWithImages() {
  return requestApi(`/buyingRequest/getDefaultImages`, 'GET');
}

export function createNewPushSaleProduct(data) {
  return requestApi(`/buyingRequest/createRequest`, 'POST', data);
}

export function editPushSaleProduct(data) {
  return requestApi(`/buyingRequest/updateRequest`, 'POST', data);
}

export function createNewTeccoSaleProduct(data) {
  return requestApi(`/cross-post/cloneTeccoProduct`, 'POST', data);
}

export function getTotalProductsNumber() {
  return requestApi(`/buyingRequest/getBuyingRequestNumber`, 'GET')
}

export function turnOnRequest(data) {
  return requestApi(`/buyingRequest/turnOnRequest`, 'POST', data)
}

export function turnOffRequest(data) {
  return requestApi(`/buyingRequest/turnOffRequest`, 'POST', data)
}
