import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ProductListOfUserTable from './ProductListOfUserTable';

class ProductListOfUser extends Component {
    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <ProductListOfUserTable />
                </div>
            </div>
        );
    }

    componentDidMount = async () => {
    };

    componentDidUpdate = (prevProps, prevState) => {
    };
}

export default observer(ProductListOfUser);