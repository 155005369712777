import React, { useEffect } from 'react';
import SendEmailVerticalLinearStepper from './components/SendEmailVerticalLinearStepper'

const SendEmail = () => {
    useEffect(() => {
    }, []);
    return (
        <div className="card">
            <div className="card-header card-header-primary">
                <h4 className="card-title">Gửi Email tới người dùng Aladin</h4>
                {/* <p class="card-category">Gửi thông báo tới User Aladin </p> */}
            </div>
            <div className="card-body">
                <SendEmailVerticalLinearStepper />
            </div>
        </div>
    );

}
export default SendEmail;
