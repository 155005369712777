import { extendObservable } from 'mobx';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';
import { getAddedProductListApi, getProductListApi, getTotalProductCountApi } from 'src/api/collection/collection';
import { toJS } from 'mobx';
import { uploadImageNoResize, AWS_IMAGE_BASE_URL, AWS_IMAGE_FOLDERS } from 'src/api/axios';
import _ from 'lodash';
import { createCollectionApi, updateCollectionApi, deleteProductFromCollectionApi, addProductsToCollectionApi } from 'src/api/collection/collection';
import { collectionListStores } from './CollectionStore';
import { updateAdvertisingStatusApi } from 'src/api/advertising/manage-advertising';

class CollectionStore {
  constructor() {
    extendObservable(this, {
      collection: undefined,
      // Products da co trong collection
      addedProducts: [],
      getAddedProductListLoading: true,
      totalAddedProductListCount: 0,
      productsTableCurrentPage: 1,

      // Products de them vao collection
      products: [],
      getProductListLoading: true,
      totalProductListCount: 0,
      txtSearchProduct: undefined,
      productListTableCurrentPage: 1,

      // Products vua moi them vao collection, chua submit len server
      newAddedProducts: [],
      totalNewAddedProductsCount: 0,
      newAddedProductsCurrentPage: 1,      
    })
  }

  // Product da co san trong collection

  getAddedProductList = async () => {
    try {
      const { collection } = toJS(this);
      this.getAddedProductListLoading = true;
      this.totalAddedProductListCount = collection.productNumber;
      const offset = pageableOffset(this.productsTableCurrentPage);
      const result = await getAddedProductListApi(this.collection.id, offset);
      if (result.responseCode.toString().startsWith('2')) {
        this.getAddedProductListLoading = false;
        this.addedProducts = result.dataArray;
      } else {
        this.getAddedProductListLoading = false;
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (err) {
      this.getAddedProductListLoading = false;
      Global.error({ message: err.message || '' });
    }
  }

  setAddedProductsTablePage = (page) => this.productsTableCurrentPage = page;

  // Tao collection moi

  newCollection = () => this.collection = undefined;

  uploadImage = async (form) => {
    const result = await uploadImageNoResize(form);
    if (result.data) {
      if (!this.collection) this.collection = { thumbImage: result.data.imageNames ? AWS_IMAGE_BASE_URL + AWS_IMAGE_FOLDERS.lg + '/' + result.data.imageNames : result.data.uploadedUrl }
      else this.collection = {...this.collection, thumbImage: result.data.imageNames ? AWS_IMAGE_BASE_URL + AWS_IMAGE_FOLDERS.lg + '/' + result.data.imageNames : result.data.uploadedUrl}
    }
  }

  createNewCollection = async () => {
    try {
      const productIds = this.newAddedProducts.map(p => {
        return {id: p.id}
      });
      const payload = {
        adsName: this.collection.advertisingName,
        imageUrl: this.collection.thumbImage,
        endDate: new Date(this.collection.endDate),
        description: this.collection.description,
        products: productIds,
        layoutFormat: this.collection.layoutFormat
      }
      const result = await createCollectionApi(payload);
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({ message: 'Tạo thành công' });
        this.resetStore();
        collectionListStores.getTotalSize();
        collectionListStores.getCollectionList();
        return Promise.resolve(true);
      } else {
        Global.error({ message: result.responseText || '' });
        return Promise.resolve(false);
      }
    }
    catch(error) {
      Global.error({ message: error.message || '' });
      return Promise.resolve(false);
    }
  }

  resetStore = () => {
    this.collection = undefined;
    this.newAddedProducts = [];
    this.addedProducts = [];
    this.productsTableCurrentPage = 1;
    this.productListTableCurrentPage = 1;
  }

  // Chinh sua collection

  deleteProductFromCollection = async (product) => {
    try {
      const payload = {
        adId: this.collection.id,
        productId: product.id
      }
      const result = await deleteProductFromCollectionApi(payload);
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({ message: `Xoá thành công sản phẩm ${product.productName} trong collection ${this.collection.advertisingName}` });
        this.getAddedProductList();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (error) {
      Global.error({ message: error.message || '' });
    }
  }

  addProductsToCollection = async () => {
    const newAddedProducts = this.newAddedProducts.map(p => {return {id: p.id}})
    try {
      const payload = {
        adId: this.collection.id,
        products: newAddedProducts
      }
      const result = await addProductsToCollectionApi(payload)
      if (!result.responseCode.toString().startsWith('2')) {
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (error) {
      Global.error({ message: error.message || '' });
    }
  }

  updateCollection = async () => {
    try {
      if (this.newAddedProducts.length > 0) {
        this.addProductsToCollection()
      }
      const payload = {
        adId: this.collection.id,
        adsName: this.collection.advertisingName,
        imageUrl: this.collection.thumbImage,
        endDate: new Date(this.collection.endDate),
        description: this.collection.description,
        layoutFormat: this.collection.layoutFormat
      }
      const result = await updateCollectionApi(payload);
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({ message: 'Cập nhật thành công' });
        this.resetStore();
        collectionListStores.getTotalSize();
        collectionListStores.getCollectionList();
        return Promise.resolve(true);
      } else {
        Global.error({ message: result.responseText || '' });
        return Promise.resolve(false);
      }
    }
    catch(error) {
      Global.error({ message: error.message || '' });
      return Promise.resolve(false);
    }
  }

  changeAdvertisingName = (text) => {
    if (!this.collection) this.collection = { advertisingName: text }
    else this.collection = {...this.collection, advertisingName: text}
  }

  changeDescription = (text) => {
    if (!this.collection) this.collection = { description: text }
    else this.collection = {...this.collection, description: text}
  }

  changeLayoutFormat = (text) => {
    if (!this.collection) this.collection = { layoutFormat: text }
    else this.collection = {...this.collection, layoutFormat: text}
  }

  changeEndDate = (text) => {
    if (!this.collection) this.collection = { endDate: text }
    else this.collection = {...this.collection, endDate: text}
  }

  updateCollectionStatus = async (checked) => {
    if (!this.collection.id) return;
    this.collection.deletedAt = checked ? new Date() : null;
    const result = await updateAdvertisingStatusApi(this.collection.id, checked);
    if (!result.responseCode.toString().startsWith('2')) {
      this.collection.deletedAt = !checked ? new Date() : null;
      Global.error({ message: result.responseText || '' });
    }
    else {
      collectionListStores.getCollectionList();
    }
  }

  // Product List de chon them vao collection

  getProductListStore = async () => {
    try {
      this.getProductListLoading = true;
      const offset = pageableOffset(this.productListTableCurrentPage);
      const result = await getProductListApi(offset, this.txtSearchProduct || '', null, {
        id: 'all',
        name: 'Tất cả'
      }, this.collection && this.collection.id ? this.collection.id : undefined);
      if (result.responseCode.toString().startsWith('2')) {
        this.products = result.dataArray || [];
      } else {
        Global.error({ message: result.responseText || '' });
      }
      this.getProductListLoading = false;
    } catch (error) {
      Global.error({ message: error.message || '' });
      this.getProductListLoading = false;
    }
  }

  getTotalProductListCount = async () => {
    const result = await getTotalProductCountApi(this.txtSearchProduct || '', null, {
      id: 'all',
      name: 'Tất cả'
    }, this.collection && this.collection.id ? this.collection.id : undefined);
    if (result.responseCode.toString().startsWith('2')) {
      this.totalProductListCount = parseInt(result.data.total);
    } else {
      Global.error({ message: result.responseText || '' });
    }
  }

  setProductListPage = (pageNumber) => this.productListTableCurrentPage = pageNumber;

  setSearchText = (text) => this.txtSearchProduct = text;

  // Products vua moi them vao collection, chua submit len server

  addProductToCollection = (product) => {
    this.newAddedProducts.push(product);
  }

  removeProductFromNewAddedProducts = (productId) => {
    const index = _.findIndex(this.newAddedProducts, {id: productId});
    this.newAddedProducts.splice(index, 1);
  }
}

export const specificCollectionStore = new CollectionStore()

