import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import { AcceptButton, CancelButton } from '../../../components/Button/Button';
import Loader from 'react-loader-spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import { COLOR, pageableDefault, PRODUCT_AUTO_APPROVE_STATUS } from '../../../../common/constant'
import { reviewProductStores } from '../../stores/ReviewProductStores';
import { observer } from 'mobx-react';
import { InputGroup, FormControl, ButtonGroup, ToggleButton } from 'react-bootstrap';
import RadioGroup from '../../../components/RadioGroup';
import { formatCurrency } from '../../../../helpers/CF';
import { Global } from '../../../../GlobalClass';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { toJS } from 'mobx';
import ReactMarkDown from 'react-markdown';
import moment from 'moment';
import Icon from 'src/resources/svg';
import ProductSupportConversation from '../ProductSupportConversation';
import ProductReject from './ProductReject';

class ProductReviewModal extends Component {
    initialState = {
        imgSelected: 0,
        reason: '',
        rejectTypeSelected: 0,
        showReasonInput: false,
        message: ''
    }
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.confirmAlert = false;
    };

    rowData = (displayName, k, formater, text) => {
        const val = text || reviewProductStores.productDetailInfo[k] || '';
        return (
            <div className="row" style={{ marginTop: 12, marginBottom: 12 }}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text style={{ minWidth: 150, justifyContent: 'left' }}>{displayName}: </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="col">
                        <InputGroup.Prepend>
                            <span>{formater ? formater(val) : (val || <i>Trống</i>)}</span>
                        </InputGroup.Prepend>
                    </div>
                </InputGroup>
            </div>
        )
    }

    rowAudioData = (displayName, k, formater, text) => {
        const val = text || reviewProductStores.productDetailInfo[k] || '';
        return (
            <div className="row" style={{ marginTop: 12, marginBottom: 12 }}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text style={{ minWidth: 150, justifyContent: 'left' }}>{displayName}: </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="col">
                        <InputGroup.Prepend>
                            {val == '' ? 
                            (<span><i>Trống</i></span>) :
                            (<audio controls>
                                <source src={val} />
                            </audio>)}
                        </InputGroup.Prepend>
                    </div>
                </InputGroup>
            </div>
        )
    }

    onReasonTextChange = (e) => {
        const txt = e.target.value;
        if (txt.length > 254) return;
        this.setState({ reason: txt })
    }

    onImageChange = (imgSelected) => () => {
        this.setState({ imgSelected })
    }

    onHide = () => {
        this.props.onHide();
        this.setState(this.initialState);
        reviewProductStores.setProductHistoryPageable(pageableDefault);
        reviewProductStores.setProductHistoryList([]);
        reviewProductStores.setProductDetailInfo({});
    }

    onReportProduct = async () => {
        const { reason, rejectTypeSelected } = this.state;
        const { productDetailInfo, onReportProduct } = reviewProductStores;
        if (!productDetailInfo.id) return;
        if (!reason) return Global.error({ message: 'Vui lòng nhập lý do.' })
        this.confirmAlert = window.confirm('Xác nhận lại hành động.');
        if (this.confirmAlert) {
            onReportProduct(productDetailInfo.id, rejectTypeSelected, reason);
            this.onHide();
        }
    }

    onApproveProduct = () => {
        const { productDetailInfo, onApproveProduct } = reviewProductStores;
        if (!productDetailInfo.id) return;
        onApproveProduct(productDetailInfo.id);
        this.onHide();
    }

    onReasonItemChange = ({ item, index }) => {
        const data = toJS(item);
        setTimeout(() => {
            if (index === reviewProductStores.productRejectTypeList.length - 1) {
                this.setState({ reason: '', showReasonInput: true, rejectTypeSelected: data.id });
            } else {
                this.setState({ rejectTypeSelected: data.id, reason: data.name, showReasonInput: false });
            }
        }, 0);
    }

    onChangeText = (e) => this.setState({ message: e.target.value });

    renderProductDetail = () => {
        const { productDetailInfo, firstLoadingReviewProductDetail, productRejectTypeList } = reviewProductStores;
        const { imgSelected, reason, rejectTypeSelected, showReasonInput, message } = this.state;
        const imgSelectedUrl = (productDetailInfo.imageInfo && productDetailInfo.imageInfo[imgSelected]) ? productDetailInfo.imageInfo[imgSelected].imageUrl : '';
        const categoryName = `${productDetailInfo.categoryName}${(!!productDetailInfo.appendInfo && !!productDetailInfo.appendInfo.authentic) ? ` - ${productDetailInfo.appendInfo.authentic.name || ''}` : ''}`
        return (
            <div style={{ paddingTop: 12 }}>
                {
                    firstLoadingReviewProductDetail ?
                        <Loader
                            type="TailSpin"
                            color={COLOR.primary}
                            height={30}
                            width={30}
                        />
                        :
                        <div>
                            {productDetailInfo.statusId == 1 && <FormControl isInvalid={true} isValid={false}
                                value={message || ''}
                                onChange={this.onChangeText}
                                style={{ borderBottom: 'solid 1px #fe805c', minHeight: 40 }}
                                placeholder={'Gửi tin nhắn hỗ trợ'}
                                plaintext
                                maxLength={1024}
                                as="textarea" />}
                            <img alt='' style={{ maxHeight: 400, width: '100%', objectFit: 'contain', marginBottom: 8, marginTop: 8, border: `solid 0.5px ${COLOR.tertiary_light}` }}
                                src={imgSelectedUrl} />
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', flexWrap: 'wrap' }}>
                                    {
                                        (productDetailInfo.imageInfo || []).map((item, index) => {
                                            return <div
                                                onClick={this.onImageChange(index)}
                                                key={index}
                                                style={{ display: 'flex', position: 'relative' }}>
                                                {
                                                    !!item.takenByAladin &&
                                                    <span style={{ width: 24, height: 24, borderRadius: 12, backgroundColor: 'rgba(0, 0, 0, 0.5)', justifyContent: 'center', alignItems: 'center', left: 6, bottom: 12, display: 'flex', position: 'absolute' }}>
                                                        <Icon name='camera' width={14} height={14} color='white' />
                                                    </span>
                                                }
                                                <img
                                                    style={{
                                                        width: 100, height: 60, marginRight: 6, marginBottom: 6, objectFit: 'cover',
                                                        borderWidth: 3,
                                                        border: imgSelected == index ? 'solid' : 'none',
                                                        borderColor: imgSelected == index ? COLOR.primary : 'white',
                                                    }}
                                                    src={item.imageUrl} />
                                            </div>
                                        })
                                    }
                                </div>
                                {this.rowAudioData('Audio', 'audioUrl')}
                                {this.rowData('Người bán', 'fullname')}
                                {this.rowData('Yêu cầu', '', null, this.getPostTypeText())}
                                {this.rowData('Mô tả', 'description')}
                                {this.rowData('Giá (VNĐ)', 'price', formatCurrency)}
                                {this.rowData('Tình trạng', 'conditionDesc')}
                                {this.rowData('Loại mặt hàng', '', null, categoryName)}
                                {this.rowData('Địa chỉ', 'areaText')}

                                {
                                    (productDetailInfo.statusId == 1 || productDetailInfo.statusId == 3) &&
                                    <div className="row" style={{ marginTop: 20 }}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text style={{ alignItems: 'flex-start', justifyContent: 'left', minWidth: 150 }}>Lý do báo cáo: </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <div className="col">
                                                <RadioGroup
                                                    inputKey='review-product-modal'
                                                    checkedId={rejectTypeSelected}
                                                    onItemClicked={this.onReasonItemChange}
                                                    datas={productRejectTypeList} />
                                                {
                                                    showReasonInput &&
                                                    <FormControl isInvalid={true} isValid={false} value={reason} onChange={this.onReasonTextChange} style={{ minHeight: 80, borderBottom: 'solid 1px #fe805c' }} placeholder="Nhập lý do" plaintext as="textarea" />
                                                }
                                            </div>
                                        </InputGroup>
                                    </div>
                                }


                            </div>
                        </div>
                }
            </div>
        )
    }

    renderItemProductHistory = (item) => {
        return (
            <div className='markdown-class'>
                <ListGroup.Item className='list-group-item'>
                    <ReactMarkDown
                        source={item.title}
                        escapeHtml={true}
                    />
                    <div style={{ paddingLeft: 32 }}>
                        <ReactMarkDown
                            source={item.description}
                            escapeHtml={true}
                        />
                    </div>
                    {
                        !!item.createdAt &&
                        <span style={{ fontSize: 14, fontStyle: 'italic' }}>─ {moment(item.createdAt).format('DD/MM/YYYY hh:mm A')} </span>
                    }
                </ListGroup.Item>
            </div>
        )
    }

    onLoadMoreProductHistory = () => reviewProductStores.getProductHistoryInReviewProductAction(reviewProductStores.productDetailInfo.id);

    renderProductHistory = () => {
        const { productHistoryList, loadingProductHistory, pageableProductHistory } = toJS(reviewProductStores);
        if (!productHistoryList.length)
            return (
                <div style={{ padding: 16 }}>
                    <cite title="Source Title">
                        Không có dữ liệu
                    </cite>
                </div>
            )
        return (
            <div className='product-review-modal'>
                {
                    productHistoryList.map((item, index) => {
                        return (
                            <ListGroup key={index} variant='flush'>
                                {this.renderItemProductHistory(item)}
                            </ListGroup>
                        )
                    })
                }
                {
                    loadingProductHistory &&
                    <Loader
                        style={{ marginTop: '12px' }}
                        type="TailSpin"
                        color={COLOR.primary}
                        height={30}
                        width={30}
                    />
                }

                {
                    !pageableProductHistory.last &&
                    <div style={{ marginTop: 12 }}>
                        <a onClick={this.onLoadMoreProductHistory} href='#'>Load More...</a>
                    </div>
                }
            </div>
        )
    }

    renderFooter = () => {
        const { productDetailInfo } = reviewProductStores;

        if (!productDetailInfo || !productDetailInfo.id) return null;
        switch (productDetailInfo.statusId) {
            case 3:
                return (
                    <Modal.Footer>
                        <div>
                            <ButtonGroup toggle>
                                <ToggleButton type="radio" name="radio" value="1" onChange={(e) => {
                                    reviewProductStores.productDetailInfo.isMarked = true;
                                    reviewProductStores.productDetailInfo.isShop = false;
                                    reviewProductStores.isShop = false;

                                }} variant={
                                    productDetailInfo.isMarked ? productDetailInfo.isShop ? 'default' : 'success' : 'default'
                                }>
                                    Seller
                            </ToggleButton>
                                <ToggleButton type="radio" name="radio" value="2" onChange={(e) => {
                                    reviewProductStores.productDetailInfo.isMarked = true;
                                    reviewProductStores.productDetailInfo.isShop = true;
                                    reviewProductStores.isShop = true;

                                }}

                                    variant={
                                        productDetailInfo.isMarked ? productDetailInfo.isShop ? 'success' : 'default' : 'default'
                                    }>
                                    Shop
                            </ToggleButton>
                            </ButtonGroup>
                        </div>
                        <div>
                            <CancelButton onClick={this.onReportProduct} title='Báo cáo' />
                            <AcceptButton onClick={this.onApproveProduct} title='Đã xem' />
                        </div>
                    </Modal.Footer>
                )
            // case 3:
            //     return (
            //         <Modal.Footer>
            //             <CancelButton onClick={this.onReportProduct} title='Báo cáo' />
            //             {
            //                 (productDetailInfo.autoApprove == PRODUCT_AUTO_APPROVE_STATUS.AUTO_APPROVE_WAITING_FOR_REVIEW ||
            //                     productDetailInfo.autoApprove == PRODUCT_AUTO_APPROVE_STATUS.AUTO_APPROVE_WAITING_FOR_REVIEW2)
            //                 &&
            //                 <AcceptButton onClick={this.onApproveProduct} title='Đã xem' />
            //             }
            //         </Modal.Footer>
            //     )
            default:
                return (
                    <div style={{ display: 'flex', marginTop: 12, justifyContent: 'flex-end', paddingLeft: 16, paddingRight: 16 }}>
                        <span className="card-title" style={{ color: COLOR.primary }}>
                            Trạng thái sản phẩm: <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>{productDetailInfo.statusName}</span>
                        </span>
                    </div>
                )
        }
    }

    getPostTypeText = () => {
        const { historyCount } = reviewProductStores.productDetailInfo;
        if (historyCount == 1) {
            return 'Phê duyệt sản phẩm';
        } else if (historyCount > 1) return 'Cập nhật thông tin sản phẩm';
        return '';
    }

    render() {
        const { isShow } = this.props;
        const { productDetailInfo, isShowReviewProduct } = reviewProductStores;
        return (
            <Modal
                className='review-product-modal'
                show={isShow || isShowReviewProduct}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                animation
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                        {productDetailInfo.productName}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: '65vh', overflow: 'scroll' }}>
                    <Tabs
                        mountOnEnter
                        defaultActiveKey={'product-detail'}
                        transition={false}
                        id="product-review-modal">
                        <Tab eventKey='product-detail' title='Chi tiết sản phẩm'>
                            {this.renderProductDetail()}
                        </Tab>
                        <Tab eventKey='product-history' title='Tiến trình'>
                            {this.renderProductHistory()}
                        </Tab>
                        <Tab eventKey='product-support-conversation' title='Hỗ trợ'>
                            <ProductSupportConversation />
                        </Tab>
                        <Tab eventKey='product-reject' title='Từ chối'>
                            <ProductReject />
                        </Tab>
                    </Tabs>
                </Modal.Body>
                {this.renderFooter()}
            </Modal>
        );
    }
}

export default observer(ProductReviewModal)