import { extendObservable } from 'mobx';
import { getAllNotificationTypesApi, toggleOnOffPushNotificationApi, toggleOnOffInAppPushNotificationApi, toggleOnOffFireBasePushNotificationApi } from '../../../api/pushNotification/pushNotification';

class ConfigPushNotificationStores {
    constructor() {
        extendObservable(this, {
            loading: false,
            notificatyonTypes: [],
            notificatyonTypeCount: 0,
            currentPage: 0
        });
    }

    loadConfigPushNotifications = async () => {

        this.loading = true;
        const result = await getAllNotificationTypesApi();
        this.notificatyonTypes = result.dataArray;
        this.notificatyonTypeCount = result.dataArray.count;
        this.loading = false;
    }

    toggleOnOffPushNotification = async (id, status) => {
        await toggleOnOffPushNotificationApi(id, status);
        await toggleOnOffFireBasePushNotificationApi(id, false);
        await toggleOnOffInAppPushNotificationApi(id, false);
        this.loadConfigPushNotifications();
    }

    toggleOnOffFireBasePushNotification = async (id, status) => {
        await toggleOnOffFireBasePushNotificationApi(id, status);
        this.loadConfigPushNotifications();
    }

    toggleOnOffInAppPushNotification = async (id, status) => {
        await toggleOnOffInAppPushNotificationApi(id, status);
        this.loadConfigPushNotifications();
    }

    setPage(page) {
        this.currentPage = page;
    }
}
export const configPushNotificationStores = new ConfigPushNotificationStores();