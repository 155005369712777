import React, { Component } from 'react';
import { userListStores } from '../../stores/UserListStores';
import UserListTable from './UserListTable';
import { observer } from 'mobx-react';
import ChangePasswordModal from './ChangePasswordModal';
import UserDetailModal from '../UserDetail';
import '../../css/styles.css';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <UserListTable
                        getTotalSize={this.getTotalSize}
                        getUserList={this.getUserList} />
                </div>

                <ChangePasswordModal />
                <UserDetailModal />

            </div>
        );
    }


    getUserList = (search) => {
        userListStores.getUserListStore(search);
    }


    getTotalSize = async (search) => {
        await userListStores.getTotalUserCount(search);
    }

    componentDidMount = async () => {
        await this.getTotalSize();
        this.getUserList();
    };

}


export default observer(UserList);