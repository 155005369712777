import { requestApi } from "../axios";
const prefix2 = 'product-review';
const prefix = 'product-cs-report';

export function getReviewProductsCountApi(filter) {
    const url = `${prefix}/count?filter=${filter}`;
    return requestApi(url, 'GET');
}
export function getRejectTypeListApi() {
    return requestApi(`${prefix}/reject-type-list`, 'GET');
}
export function getReviewProductsApi(offset, filter) {
    const url = `${prefix}/list-products?limit=${10}&offset=${offset}&&filter=${filter}`;
    return requestApi(url, 'GET');
}
export function getReviewProductDetailApi(productId) {
    const url = `${prefix}/detail`
    return requestApi(url, 'POST', { productId });
}
export function approveProductApi(productId, isShop) {
    const url = `${prefix}/approve`
    return requestApi(url, 'POST', { productId, isShop: isShop });
}
export function reportProductApi(productId, rejectType, reason) {
    const url = `${prefix}/report`
    return requestApi(url, 'POST', { productId, rejectType, reason });
}

export function rejectProductApi(productId, rejectType, reason) {
    const url = `${prefix2}/reject`
    return requestApi(url, 'POST', { productId, rejectType, reason });
}

export function getSupportConversationCount({ productId }) {
    return requestApi(`${prefix}/admin-get-count-chat-message-by-product-id?productId=${productId}`, 'GET');
}

export function getSupportConversationList({ productId, offset }) {
    return requestApi(`${prefix}/admin-get-chat-message-by-product-id?productId=${productId}&limit=10&offset=${offset}`, 'GET');
}

export function sendSupportMessageApi({ productId, message }) {
    return requestApi(`${prefix}/admin-create-chat-conversation`, 'POST', { productId, message });
}