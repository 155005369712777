import React, { useState, useCallback,useMemo, useEffect } from 'react';
import { AcceptButton, InformationButton } from '../../../components/Button/Button';
import PushSaleEditorModal from './components/PushSaleEditorModal';
import TeccoSaleEditorModal from './components/TeccoSaleEditorModal';
import { loadBuyingRequest, fetchCategoriesWithImages, getTotalProductsNumber, turnOnRequest, turnOffRequest } from '@api/pushSale/pushSale';
import { getProductCategories } from '@api/pushNotification/pushNotification';
import moment from 'moment';
import { Global } from 'src/GlobalClass';
import PaginationTable from '@pages/components/PaginationTable';
import _ from 'lodash';
import { getFullImagePath } from '@helpers/getFullImagePath';
import { pageableOffsetWithLimit } from '@helpers/CF';
import './styles.css';

const ROWS_PER_PAGE = 10;

const PushSale = () => {
  const [showModal, setShowModal] = useState(false);
  const [showTeccoModal, setShowTeccoModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryImages, setCategoryImages] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProductsCount, setTotalProductsCount] = useState(0);

  useEffect(() => {
    fetchBuyingRequest();
    fetchTotalProductsNumber();
  }, [currentPage])

  const fetchBuyingRequest = async () => {
    try {
      const offset = pageableOffsetWithLimit(currentPage, ROWS_PER_PAGE);
      const result = await loadBuyingRequest(offset, ROWS_PER_PAGE);
      if (result.responseCode.toString().startsWith('2')) {
        setProducts(result.dataArray);
      }
      else Global.error({message: 'Có lỗi xảy ra. Vui lòng thử lại.'})
    }
    catch (err) {
      Global.error({message: 'Có lỗi xảy ra. Vui lòng thử lại.'})
    }
  }

  const fecthCategories = async () => {
    try {
      const result = await getProductCategories();
      if (result.responseCode.toString().startsWith('2')) {
        const categories = result.dataArray.filter(c => c.categoryName !== 'Khác');
        setCategories(categories);
      }
      else Global.error({message: 'Lấy thông tin category bị lỗi'})
    }
    catch (err) {
      Global.error({message: 'Lấy thông tin category bị lỗi'})
    }
  }

  const fetchCategoriesImages = async () => {
    try {
      const result = await fetchCategoriesWithImages();
      if (result.responseCode.toString().startsWith('2')) {
        setCategoryImages(result.data);
      }
      else Global.error({message: 'Lấy thông tin hình ảnh category bị lỗi'})
    }
    catch (err) {
      Global.error({message: 'Lấy thông tin hình ảnh category bị lỗi'})
    }
  }

  const fetchTotalProductsNumber = async () => {
    try {
      const result = await getTotalProductsNumber();
      if (result.responseCode.toString().startsWith('2')) {
        setTotalProductsCount(result.data);
      }
      else Global.error({message: 'Lấy tổng số lương sản phẩm lỗi'})
    }
    catch (err) {
      Global.error({message: 'Lấy tổng số lương sản phẩm lỗi'})
    }
  }

  useEffect(() => {
    fecthCategories();
    fetchCategoriesImages();
    fetchTotalProductsNumber();
  }, []);

  const showingModalHandler = () => setShowModal(true);

  const closingModalHandler = () => setShowModal(false);

  const showingTeccoModalHandler = () => setShowTeccoModal(true);

  const closingTeccoModalHandler = () => setShowTeccoModal(false);

  const paginationOption = () => {
    const paginationOption = {
        custom: true,
        hideSizePerPage: true,
        totalSize: totalProductsCount,
        page: currentPage,
    };
    return paginationOption;
  }

  const setProductTablePage = (page) => this.productTableCurrentPage = page

  const headerStyleDefault = useCallback(() => ({
    width: 100,
  }), []);

  const headerFormatter = useCallback((column, colIndex) => {
    return <h5><strong>{column.text}</strong></h5>
  }, []);

  const actionButtons = useCallback((cell, row) => {
    return <div className="td-actions text-center">
      {/* <button onClick={() => console.log('')} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-danger">
        <i className="material-icons">delete</i>
      </button> */}
      <button onClick={() => {
        setSelectedProduct(row);
        setShowModal(true);        
      }} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
        <i className="material-icons">edit</i>
      </button>
    </div>
  }, []);

  const thumbFormatter = useCallback((cell, row) => {
    return <div key={row.id} className="card-avatar">
      <img className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 20 }} src={getFullImagePath(row.imageName)} />
    </div>
  }, []);

  const descFormatter = useCallback((cell, row) => {
    return <div>
      
    </div>
  }, [])

  const dateFormatter = useCallback((cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span>{moment(cell).format('DD/MM/YYYY')}</span>
      </div>
    );
  }, []);

  const setLabelForCategory = (cateId) => {
    const cat = _.find(categories, {id: cateId});
    if (cat) return cat.categoryName;
    else return 'Newsfeed';
  }

  const cateFormarter = (cell, row) => {
    return <div key={row.id} >
      <span>{setLabelForCategory(cell)}</span>
    </div>
  }

  const requestStatusChangeHandler = async (isEnabled, id) => {
    try {
      let result;
      if (isEnabled) result = await turnOnRequest({id});
      else result = await turnOffRequest({id});
      if (result.responseCode.toString().startsWith('2')) {
        const newListProduct = products.map(p => p.id == id ? {...p, deletedAt: !isEnabled} : p);
        setProducts(newListProduct);
        Global.success({message: 'Thay đổi thành công'})
      }
      else Global.error({message: 'Có lỗi xảy ra. Vui lòng thử lại'})
    }
    catch (err) {
      Global.error({message: 'Có lỗi xảy ra. Vui lòng thử lại'})
    }
  }

  const switchFormatter = (cell, row) => {
    return <label class="switch">
      <input type="checkbox" checked={!!!cell} onChange={() => requestStatusChangeHandler(!!cell, row.id)} />
      <span class="slider round"></span>
    </label>
  }

  const  onTableChange = (type, { page, sizePerPage }) => {
    window.scrollTo(0, 0);
    setCurrentPage(page);
  }

  const columnsOption = () => {
    return [
      {
        dataField: 'id',
        text: '#',
        headerFormatter: headerFormatter,
        headerStyle: {
          width: 60
        }
      },
      {
        dataField: 'imageName',
        text: 'Image',
        formatter: thumbFormatter,
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'defaultTitle',
        text: 'Tên sản phẩm',
        headerStyle: headerStyleDefault,
        headerFormatter: headerFormatter,
      },
      {
        dataField: 'defaultCateId',
        text: 'Hiển thị ở',
        headerStyle: headerStyleDefault,
        formatter: cateFormarter,
        headerFormatter: headerFormatter,
      },
      {
        dataField: 'imageText',
        text: 'Chữ trên hình',
        headerStyle: headerStyleDefault,
        headerFormatter: headerFormatter
      },
      {
        dataField: 'price',
        text: 'Giá',
        headerStyle: headerStyleDefault,
        headerFormatter: headerFormatter
      },
      {
        dataField: 'deletedAt',
        text: 'Trạng thái',
        headerStyle: headerStyleDefault,
        headerFormatter: headerFormatter,
        formatter: switchFormatter
      },
      {
        dataField: 'action',
        text: 'Hành động',
        formatter: actionButtons,
        headerFormatter: headerFormatter,
        headerStyle: { width: 80 }
      }
    ];
  }

  return <>
    <div style={{height: '50px'}} />
    <AcceptButton 
      containerStyle={{ height: 'fit-content' }} 
      onClick={showingModalHandler} 
      title='Tạo sản phẩm'
    />
    <AcceptButton 
      containerStyle={{ height: 'fit-content' }} 
      onClick={showingTeccoModalHandler} 
      title='Tạo mặt hàng Tecco'
    />
    <PaginationTable
      onTableChange={onTableChange}
      paginationOptions={paginationOption()}
      datas={products}
      columns={columnsOption()}
      loading={false}
    />
    <PushSaleEditorModal 
      isShowing={showModal}
      closeModal={closingModalHandler}
      categories={categories}
      selectedProduct={selectedProduct}
      resetSelectedProduct={() => setSelectedProduct(undefined)}
      categoryImages={categoryImages}
      reloadData={fetchBuyingRequest}
    />
    <TeccoSaleEditorModal 
      isShowing={showTeccoModal}
      closeModal={closingTeccoModalHandler}
      categories={categories}
      selectedProduct={selectedProduct}
      resetSelectedProduct={() => setSelectedProduct(undefined)}
      categoryImages={categoryImages}
      reloadData={fetchBuyingRequest}
    />
  </>
}

export default PushSale;