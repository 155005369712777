import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { AcceptButton, CancelButton } from '../../../components/Button/Button';
import { COLOR } from 'src/common/constant';
import { reviewVerifyPhoneStores } from '../../stores/ReviewVerifyPhoneStores';
import { FormControl } from 'react-bootstrap';
import RadioGroup from '../../../components/RadioGroup';
import { observer } from 'mobx-react';

class ReviewVerifyPhoneChooseReasonModal extends Component {
    initialState = {
        showReasonInput: false,
        txtReason: '',
        rejectTypeSelected: null
    }
    constructor(props) {
        super(props);
        this.state = this.initialState;
    };
    onRejectVerifyPhone = () => {
        const { rejectTypeSelected, txtReason } = this.state;
        if (!rejectTypeSelected) return;
        this.props.onRejectRequest({ reason: txtReason, rejectedId: rejectTypeSelected });
        this.onHide();
    }
    // onApproveVerifyPhone = () => {
    //     this.props.onApproveRequest();
    //     this.onHide();
    // }
    onReasonItemChange = ({ item, index }) => {
        if (index === reviewVerifyPhoneStores.verifyPhoneRejectTypeList.length - 1) {
            this.setState({ txtReason: '', showReasonInput: true, rejectTypeSelected: item.id });
        } else {
            this.setState({ rejectTypeSelected: item.id, txtReason: item.name, showReasonInput: false });
        }
    }
    onReasonTextChange = (e) => {
        const txt = e.target.value;
        if (txt.length > 254) return;
        this.setState({ txtReason: txt })
    }
    onHide = () => {
        this.props.onHide();
        this.setState(this.initialState)
    }
    render() {
        const { verifyPhoneRejectTypeList, verifyPhoneRejectTypeListLoading } = reviewVerifyPhoneStores;
        const { showReasonInput, txtReason, rejectTypeSelected } = this.state;
        const { isShow } = this.props;
        return (
            <Modal
                show={isShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                animation
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                        Chọn lý do từ chối
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                    {
                        verifyPhoneRejectTypeListLoading ?
                            <Loader
                                type="TailSpin"
                                color={COLOR.primary}
                                height={30}
                                width={30}
                            />
                            :
                            <div className="col">
                                <RadioGroup
                                    inputKey='review-verify-phone-modal'
                                    checkedId={rejectTypeSelected}
                                    onItemClicked={this.onReasonItemChange}
                                    datas={verifyPhoneRejectTypeList} />
                                {
                                    showReasonInput &&
                                    <FormControl isInvalid={true} isValid={false} value={txtReason} onChange={this.onReasonTextChange} style={{ minHeight: 80, borderBottom: 'solid 1px #fe805c' }} placeholder="Nhập lý do" plaintext as="textarea" />
                                }
                            </div>
                    }
                </Modal.Body>
                {
                    <Modal.Footer>
                        <CancelButton onClick={this.onRejectVerifyPhone} title='Từ chối' />
                        {/* <AcceptButton onClick={this.onApproveVerifyPhone} title='Phê duyệt' /> */}
                    </Modal.Footer>
                }
            </Modal>
        );
    }
}
export default observer(ReviewVerifyPhoneChooseReasonModal);