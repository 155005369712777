import { requestApi } from "../axios";
const prefix = 'push-notification';

export function searchUser(keyword) {
    return requestApi(`${prefix}/searchUser`, 'POST', { keyword: keyword });
}

export function sendPushNotification(data) {
    return requestApi(`${prefix}/send-push-notification`, 'POST', data);
}
export function updateScheduledNotificationApi(data) {
    return requestApi(`${prefix}/update-scheduled-notification`, 'PUT', data);
}
export function sendEmailService({ title, content, list, sendAll, targetType, selectedSegments }) {
    return requestApi(`${prefix}/send-email`, 'POST', { title, content, list, sendAll, targetType, selectedSegments });
}

export function getNotificationTypesApi() {
    return requestApi(`${prefix}/get-notification-types`, 'GET');
}

export function getAllNotificationTypesApi() {
    return requestApi(`${prefix}/get-all-notification-types`, 'GET');
}

export function getProductCategories() {
    return requestApi(`${prefix}/get-product-categories`, 'GET');
}

export function getProductAreaText() {
    return requestApi(`${prefix}/get-product-area-text`, 'GET');
}

export function getScheduledNotfications(offset) {
    return requestApi(`${prefix}/get-scheduled-notfications?limit=${10}&offset=${offset}`, 'GET');
}

export function deleteScheduledNotification(id) {
    return requestApi(`${prefix}/delete-scheduled-notification`, 'DELETE', { id: id });
}

export function toggleOnOffPushNotificationApi(id, status) {
    return requestApi(`${prefix}/toggle-on-off-push-notification`, 'PUT', { id: id, status: status });
}

export function toggleOnOffInAppPushNotificationApi(id, status) {
    return requestApi(`${prefix}/toggle-on-off-in-app-notification`, 'PUT', { id: id, status: status });
}

export function toggleOnOffFireBasePushNotificationApi(id, status) {
    return requestApi(`${prefix}/toggle-on-off-fire-base-notification`, 'PUT', { id: id, status: status });
}

export function getSegmentUser(selectedSegments) {
    return requestApi(`${prefix}/calc-number-users-match-segment`, 'POST', { selectedSegments: selectedSegments });
}
