import React, { Component } from 'react';
import { loginStore } from './stores/LoginStores';
import { observer } from 'mobx-react'
import { LOCAL_STORAGE_TYPE, delLocalStorage } from '../../common/localStorage';
import { Global } from '../../GlobalClass';


class LoginPage extends Component {
    constructor(props) {
        super(props);
    };

    onLoginClicked = async (e) => {
        loginStore.doLoginAction(this.props.history);
    }

    onChangeUsername = (e) => loginStore.onChangeUsername(e.target.value);

    onChangePassword = (e) => loginStore.onChangePassword(e.target.value);

    render() {
        const { username, password } = loginStore;
        return (
            <div className="wrapper wrapper-full-page">
                <div className="page-header login-page header-filter" filter-color="black" style={{
                    backgroundImage: "url('/./assets/img/login.jpg')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center'
                }}>
                    <div className="container">
                        <div className="container" style={{ paddingTop: '85px' }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                                    {/* <form className="form" method="" action=""> */}
                                    <div className="card card-login">
                                        <div className="card-header card-header-rose text-center">
                                            <h4 className="card-title">Login</h4>
                                        </div>
                                        <div className="card-body ">
                                            <p className="card-description text-center">Aladin admin portal</p>

                                            <span className="bmd-form-group">
                                                <div
                                                    style={{ marginBottom: 12 }}
                                                    className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="material-icons">account_circle</i>
                                                        </span>
                                                    </div>
                                                    <input
                                                        onChange={this.onChangeUsername}
                                                        value={username}
                                                        type="email" className="form-control" placeholder="Tên đăng nhập" />
                                                </div>
                                            </span>
                                            <span className="bmd-form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="material-icons">lock_outline</i>
                                                        </span>
                                                    </div>
                                                    <input
                                                        onChange={this.onChangePassword}
                                                        value={password}
                                                        type="password" className="form-control" placeholder="Mật khẩu" />
                                                </div>
                                            </span>
                                        </div>
                                        <div className="card-footer justify-content-center">
                                            <button onClick={this.onLoginClicked} className="btn btn-success">Đăng nhập</button>
                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        delLocalStorage(LOCAL_STORAGE_TYPE.USER_TOKEN);
    };

}

export default observer(LoginPage);