import React, {useState, useEffect} from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { AcceptButton, CancelButton } from 'src/pages/components/Button';
import {updateTypeBidding} from 'src/api/product/bidding';
import { Global } from 'src/GlobalClass';

const TYPES = [
  {
    key: 'CLASSIC',
    value: 'classic',
  },
  {
    key: 'INCREMENTAL_BIDS_AMOUNT',
    value: 'incremental bids amount',

  }
]


const UpdateBiddingTypeModal = ({
  isShow,
  onHide,
  bidding,
  onSuccess,
}) => {
  const [type, setType] = useState(bidding.type);

  useEffect(() => {
    if (bidding) {
      setType(bidding.type);
    }
  }, [bidding]);

  const onConfirm = async () => {
    try {
      if (!type) {
        Global.error({message: 'Loại đấu giá không hợp lệ'});
        return;
      }
      const result = await updateTypeBidding({
        type,
        productId: bidding.productId,
      })
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({message: 'Cập nhật loại đấu giá thành công'});
        onSuccess();
        resetDataModal();
      }
      else Global.error({message: result.responseText || 'Cập nhật loại đấu giá thất bại'})
    }
    catch (err) {
      Global.error({message: err.message || ''})
    }
  }

  const resetDataModal = () => {
    setType('');
  }

  const onCloseModal = () =>{
    resetDataModal();
    onHide();
  }

  return <Modal
    className='review-product-modal'
    show={isShow}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={onCloseModal}
  >
    <Modal.Header closeButton>
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        Cập nhật loại đấu giá {bidding ? bidding.productName : ''}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ maxHeight: '65vh', overflow: 'scroll' }}>
      <div className="" style={{margin: 32}} >
        <label className="bmd-label-floating" style={{marginRight: 16, width: '15%'}}>Loại bước giá</label>
        <select name="event" value={type}
          onChange={e => {
            setType(e.target.value)
          }}
          style={{ padding: 5, width: '40%' }}
        >
            {TYPES.map(c => <option value={c.key} key={c.key} >{c.value}</option>)}
        </select>
      </div>
      
    </Modal.Body>
    <Modal.Footer>           
      <div>
          <CancelButton onClick={onCloseModal} title='Huỷ' />
          <AcceptButton onClick={onConfirm} title='Gửi' />
      </div>
    </Modal.Footer>
  </Modal>
}

export default UpdateBiddingTypeModal;