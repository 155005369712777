export const LOCAL_STORAGE_TYPE = {
    USER_TOKEN: 'USER_TOKEN',
    PATH_TO_REDIRECT: 'PATH_TO_REDIRECT', // using for redirect to exact url after login (ex: go to product review detail after login if not login befores)
}


export const setLocalStorage = (type, data) => localStorage.setItem(type, data);

export const delLocalStorage = (type) => localStorage.removeItem(type);

export const getLocalStorage = (type) => localStorage.getItem(type);

export const clearLocalStorage = () => localStorage.clear();