import { extendObservable } from 'mobx';
import { pageableOffset } from '../../../helpers/CF';
import { getData, updateRow, insert, update } from '../../../api/introImage/introImage';
import { Global } from '../../../GlobalClass';
import { uploadImageNoResize } from '../../../api/axios';

class IntroImagesStores {
    constructor() {
        extendObservable(this, {
            data: [],
            isEdit: false,
            loading: false,
            currentPage: 1, 
            id: 0,
            showModal: false,

            //Modal fields
            uploadingImage: false,
            uploadedUrl: '',
            orderIndex: 1,
            updateId: 0,
        });
    }

    onDrop = async (acceptedFiles, rejectedFiles) => {
        this.uploadingImage = true;
        this.uploadedUrl = '';
        if (acceptedFiles.length < 0) {
            return
        }
        let data = new FormData();
        let file = acceptedFiles[0];
        data.append('file', file, file.name);

        const result = await uploadImageNoResize(data);
        try {
            this.uploadedUrl = result.data.uploadedUrl;
            this.uploadingImage = false;
        } catch (error) {
            this.uploadingImage = false;
        }
    }

    onBtnSavePressed = async () => {
        if (this.uploadedUrl == '') {
            alert('Bạn chưa upload ảnh');
        } else {
            if (this.isEdit) {
                await updateRow(this.updateId, this.uploadedUrl, this.orderIndex);
                this.getData();
                this.uploadingImage = false;
                this.uploadedUrl = '';
                this.orderIndex = 1;
                this.showModal = false;
                this.isEdit = false;
            } else {
                await insert(this.uploadedUrl, this.orderIndex);
                this.getData();
                this.uploadingImage = false;
                this.uploadedUrl = '';
                this.orderIndex = 1;
                this.showModal = false;
                this.isEdit = false;
            }

        }
    }

    showModalInsert = () => {
        this.uploadingImage = false;
        this.uploadedUrl = '';
        this.orderIndex = 1;
        this.showModal = true;
        this.isEdit = false;
        this.updateId = 0;
    }

    onItemClicked = (row) => {

        this.uploadingImage = false;
        this.uploadedUrl = row.url;
        this.orderIndex = row.orderIndex;
        this.showModal = true;
        this.isEdit = true;
        this.updateId = row.id;
    }

    getData = async () => {
        this.loading = true;
        const offset = pageableOffset(this.currentPage);
        const result = await getData(offset);
        if (result.responseCode.toString().startsWith('2')) {
            this.data = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.loading = false;
    }

    updateActive = async (cell, id) => {
        await update('active', cell, id);
        this.getData();
    }

    setPage = (page) => this.currentPage = page;

}
export const introImagesStores = new IntroImagesStores();

