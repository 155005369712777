import React from 'react';

export const InformationButton = ({ round, title, icon, onClick, disabled, containerStyle }) => {
    let classDefault = 'btn btn-info';
    if (round) classDefault += ' btn-round';
    return (
        <button style={containerStyle} disabled={disabled} onClick={onClick} className={classDefault}>
            {icon && <i className="material-icons">{icon}</i>} {title}
            <div className="ripple-container"></div>
        </button>
    )
};
export const AcceptButton = ({ round, title, icon, onClick, disabled, containerStyle }) => {
    let buttonClass = 'btn btn-success';
    if (disabled) buttonClass = 'btn btn-success.disabled'
    if (round) buttonClass += ' btn-round';
    return (
        <button style={containerStyle} disabled={disabled} onClick={onClick} className={buttonClass}>
            {icon && <i className="material-icons">{icon}</i>} {title}
            <div className="ripple-container"></div>
        </button>
    )
};
export const CancelButton = ({ round, title, icon, onClick, disabled, containerStyle }) => {
    let classDefault = 'btn btn-danger';
    if (round) classDefault += ' btn-round';
    return (
        <button style={containerStyle} disabled={disabled} onClick={onClick} className={classDefault}>
            {icon && <i className="material-icons">{icon}</i>} {title}
            <div className="ripple-container"></div>
        </button>
    )
};