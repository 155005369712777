import { colors } from '@material-ui/core';
import React from 'react';

const RadioGroup = ({ datas, onItemClicked, checkedId, disable, containerStyle, inputKey }) => {
    const onClick = (item) => () => {
        if (onItemClicked) onItemClicked(item);
    }
    return (
        (datas || []).map((item, index) => {
            const itemId = `${inputKey}-${index}`;
            const inputId = `${inputKey}-${index}`;
            const isChecked = item.id === checkedId;
            return (
                <div key={itemId} className="form-check form-check-radio" style={containerStyle}>
                    <label className="form-check-label">
                        <input
                            id={inputId}
                            disabled={disable}
                            onChange={onClick({ item, index })}
                            checked={isChecked}
                            className="form-check-input"
                            type="radio"
                            name={`${inputId || item.name}-radioButton`}
                            value={inputId} />
                        <p style={{ color: isChecked ? 'black' : 'grey' }}>{item.name}</p>
                        <span className="circle">
                            <span className="check"></span>
                        </span>
                    </label>
                </div>
            )
        })
    )
};

export default RadioGroup;
