import React, { Component, createRef } from 'react';
import ProductReportListTable from './components/ProductReportListTable';
import { productReportStore } from './stores/ProductReportStore';
import { userDetailStores } from '../UserManagement/stores/UserDetailStores';
import UserDetailModal from '../UserManagement/components/UserDetail/UserDetailModal';
import ProductReportFeedbackModal from './components/ProductReportFeedbackModal';

class ProductReportList extends Component {
    constructor(props) {
        super(props);
        this.productReportFeedbackModalRef = createRef();
    };

    onShowUserInfo = (userId) => {
        if (!userId) return;
        userDetailStores.setUserIdForLoadDetail(userId);
        userDetailStores.onChangeUserDetailModal(true);
    }

    onFeedbackIconPress = (userIdToSend) => {
        if (this.productReportFeedbackModalRef.current) {
            this.productReportFeedbackModalRef.current.settUserIdToSendFeedback(userIdToSend);
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <ProductReportListTable
                        onFeedbackIconPress={this.onFeedbackIconPress}
                        onShowUserInfo={this.onShowUserInfo}
                    />
                </div>
                <UserDetailModal />
                <ProductReportFeedbackModal
                    ref={this.productReportFeedbackModalRef} />
            </div>
        );
    }

    componentDidMount = async () => {
        productReportStore.getData();
    };

}

export default ProductReportList;