import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AdvertisingList from './components/AdvertisingList';
import { manageAdvertisingStores } from './stores/ManageAdvertisingStores';
import { Button } from 'react-bootstrap';
import InsertModal from './components/InsertModal';

class ManageAdvertising extends Component {
    constructor(props) {
        super(props);
    };


    render() {
        return (
            <div className="card">

                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3 offset-md-9" style={{
                            textAlign: 'end'
                        }}>
                            <Button onClick={manageAdvertisingStores.showModalInsert} variant="primary">Thêm mới</Button>
                        </div>
                    </div>
                    <AdvertisingList {...this.props} />
                </div>
                <InsertModal
                    show={manageAdvertisingStores.showModal} />
            </div>
        );
    }

    componentDidMount = async () => {
    };

}

export default observer(ManageAdvertising);