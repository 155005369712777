import React, { Component } from 'react';
import { observer } from 'mobx-react';
import BiddingListTable from './BiddingListTable';
import { radioFilterBiddingListData, biddingListStores } from '../../stores/BiddingListStores';
import RadioGroup from 'src/pages/components/RadioGroup/RadioGroup';
import ResetBiddingModal from '../ResetBiddingModal/ResetBiddingModal';
import UpdateBiddingTypeModal from '../UpdateBiddingTypeModal';
import UpdateBiddingAuctionTypeModal from '../UpdateBiddingAuctionTypeModal';
import UpdateBiddingPriceBuyNowModal from '../UpdateBiddingPriceBuyNowModal';

class BiddingList extends Component {
    hideResetBiddingModal = () => {
        biddingListStores.setShowModalResetBidding(false);
    }

    onResetBiddingSuccess = async () => {
        const { getBiddingCount, getListBidding, setShowModalResetBidding } = biddingListStores;
        await getBiddingCount();
        getListBidding();
        setShowModalResetBidding(false);
    }

    hideUpdateTypeBiddingModal = () => {
        biddingListStores.setShowModalUpdateTypeBidding(false);
    }

    onUpdateTypeBiddingSuccess = async () => {
        const { getBiddingCount, getListBidding, setShowModalUpdateTypeBidding } = biddingListStores;
        await getBiddingCount();
        getListBidding();
        setShowModalUpdateTypeBidding(false);
    }

    hideUpdateAuctionTypeBiddingModal = () => {
        biddingListStores.setShowModalUpdateAuctionTypeBidding(false);
    }

    onUpdateAuctionTypeBiddingSuccess = async () => {
        const { getBiddingCount, getListBidding, setShowModalUpdateAuctionTypeBidding } = biddingListStores;
        await getBiddingCount();
        getListBidding();
        setShowModalUpdateAuctionTypeBidding(false);
    }

    hideUpdatePriceBuyNowBiddingModal = () => {
        biddingListStores.setShowModalUpdatePriceBuyNowBidding(false);
    }

    onUpdatePriceBuyNowBiddingSuccess = async () => {
        const { getBiddingCount, getListBidding, setShowModalUpdatePriceBuyNowBidding } = biddingListStores;
        await getBiddingCount();
        getListBidding();
        setShowModalUpdatePriceBuyNowBidding(false);
    }

    onSelectedFilter = async ({ item }) => {
        const filter = item.id;
        const { setBiddingTableCurrentPage, setBiddingFilterSelected, getBiddingCount, getListBidding } = biddingListStores;
        setBiddingFilterSelected(filter);
        setBiddingTableCurrentPage(1);
        await getBiddingCount();
        getListBidding();
    }

    render() {
        const { 
            biddingFilterSelected,
            isShowModalResetBidding,
            biddingSelected,
            isShowModalUpdateTypeBidding,
            isShowModalUpdateAuctionTypeBidding,
            isShowModalUpdatePriceBuyNowBidding,
        } = biddingListStores;
        return (
            <div className="card">
                <div id='product-list' className='v-radio'>
                    <RadioGroup
                        inputKey='product-list'
                        containerStyle={{ marginRight: 32 }}
                        checkedId={biddingFilterSelected}
                        onItemClicked={this.onSelectedFilter}
                        datas={radioFilterBiddingListData}
                    />
                </div>
                <div className="card-body">
                    <ResetBiddingModal
                        isShow={isShowModalResetBidding}
                        onHide={this.hideResetBiddingModal}
                        onSuccess={this.onResetBiddingSuccess}
                        bidding={biddingSelected}
                    />
                    <UpdateBiddingAuctionTypeModal
                        isShow={isShowModalUpdateAuctionTypeBidding}
                        onHide={this.hideUpdateAuctionTypeBiddingModal}
                        onSuccess={this.onUpdateAuctionTypeBiddingSuccess}
                        bidding={biddingSelected}
                    />
                    <UpdateBiddingTypeModal
                        isShow={isShowModalUpdateTypeBidding}
                        onHide={this.hideUpdateTypeBiddingModal}
                        onSuccess={this.onUpdateTypeBiddingSuccess}
                        bidding={biddingSelected}
                    />
                    <UpdateBiddingPriceBuyNowModal
                        isShow={isShowModalUpdatePriceBuyNowBidding}
                        onHide={this.hideUpdatePriceBuyNowBiddingModal}
                        onSuccess={this.onUpdatePriceBuyNowBiddingSuccess}
                        bidding={biddingSelected}
                    />
                    <BiddingListTable
                    // onShowUserInfo={this.onShowUserInfo}
                    // onProductItemClicked={this.onProductItemClicked}
                    // openConvertToBiddingModal={this.openConvertToBiddingModal}
                    />
                </div>
            </div>
        );
    }

    componentDidMount = () => {

    };

    componentDidUpdate = (prevProps, prevState) => {
    };
}

export default observer(BiddingList);