import { extendObservable, toJS } from 'mobx';
import { getSegmentUser, searchUser, sendPushNotification, getNotificationTypesApi, getScheduledNotfications, deleteScheduledNotification, getProductCategories, getProductAreaText, updateScheduledNotificationApi } from '../../../api/pushNotification/pushNotification';
import { Global } from '../../../GlobalClass';
import moment from 'moment';
import { pageableOffset } from '../../../helpers/CF';
import { uploadImageNoResize } from '../../../api/axios';

export const SPECIFIC_TYPE = 'SPECIFIC_TYPE';
export const SEGMENTED_TYPE = 'SEGMENTED_TYPE';

export const NOTIFICATION_TAB_KEY = {
    PUSH_NOTIFICATION: 'push-notification',
    SCHEDULED_PUSH_NOTIFICATION: 'scheduled-push-notification',
    CONFIG_PUSH_NOTIFICATION: 'on-off-push-notification'
}

const defaultSelectedSegments = [{
    id: 1,
    segmentId: 1,
    value: null,
    displayValue: '',
    selectedValue: {
        id: 1,
        title: '',
    }
}];
class PushNotificationStores {
    constructor() {
        extendObservable(this, {
            sendAll: 2,
            setSchedule: 2,
            scheduleType: 1, // 1:ONE_TIME, 2:DAILY
            pushNotificationTitle: '',
            pushNotificationContent: '',
            notificationTypeSelected: {},
            notificationTypes: [],
            payloadData: {},

            searchKeywordUser: '',
            searchResults: [],
            selectedArray: [],
            scheduleDate: new Date(),

            categories: [],
            areaTexts: [],
            idVerifies: [
                {
                    id: 1,
                    title: 'Đã verify CMND'
                },
                {
                    id: 2,
                    title: 'Chưa verify CMND'
                },
            ],
            phoneNumberVerifies: [
                {
                    id: 1,
                    title: 'Đã verify Số điện thoại'
                },
                {
                    id: 2,
                    title: 'Chưa verify Số điện thoại'
                },
            ],

            //table 
            total: 0,
            currentPage: 1,
            notificationList: [],
            loading: false,

            //step 2 segment
            type: SPECIFIC_TYPE,
            selectedSegments: defaultSelectedSegments,
            countUserMatcheSegmented: 0,
            //Upload push notification image
            uploadedUrl: '',
            uploadingImage: false,
            notificationIdToEdit: null,
            scheduledSwitchStatus: false,
            processStep: 0
        });
    }

    loadSegmentsUser = async () => {
        if (this.type == SEGMENTED_TYPE) {
            const result = await getSegmentUser(this.selectedSegments);
            this.countUserMatcheSegmented = result.data.count;
        }
    }

    deleteNotificationSchedule = async (id) => {
        this.loading = true;
        const result = await deleteScheduledNotification(id);
        this.getNotifications();
    }

    getNotifications = async () => {
        this.loading = true;
        const offset = pageableOffset(this.currentPage);
        const result = await getScheduledNotfications(offset);
        if (result.responseCode.toString().startsWith('2')) {
            this.notificationList = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.loading = false;
    }

    setPage(page) {
        this.currentPage = page;
    }

    reset = async () => {
        this.setSchedule = 2;
        this.scheduleType = 1; // 1:ONE_TIME, 2:DAILY
        this.sendAll = 2;
        this.pushNotificationTitle = '';
        this.pushNotificationContent = '';
        this.searchKeywordUser = '';
        this.searchResults = [];
        this.selectedArray = [];
        this.payloadData = {};
        this.notificationTypeSelected = {};
        this.uploadingImage = false;
        this.uploadedUrl = '';
        this.notificationIdToEdit = null;
        this.scheduledSwitchStatus = false;
        this.setProcessStep(0);
        this.selectedSegments = defaultSelectedSegments;
        this.scheduleDate = new Date();
    }
    searchUser = async () => {
        const result = await searchUser(this.searchKeywordUser);
        this.searchResults = result.dataArray;
    }
    pushNotification = async () => {
        try {
            if (this.type == SEGMENTED_TYPE) {
                let dataSend = {
                    targetType: this.type,
                    title: this.pushNotificationTitle,
                    content: this.pushNotificationContent,
                    selectedSegments: toJS(this.selectedSegments),
                    scheduler: this.setSchedule,
                    scheduleType: this.scheduleType,
                    schedulerAt: moment(this.scheduleDate).format('YYYY-MM-DD HH:mm'),
                    pushNotificationImageUrl: this.uploadedUrl
                }
                if (!!this.notificationTypeSelected.id) {
                    dataSend = {
                        ...dataSend,
                        notificationTypeId: this.notificationTypeSelected.id,
                        notificationTypeName: this.notificationTypeSelected.name,
                        payload: toJS(this.payloadData)
                    }
                }
                const result = await sendPushNotification(dataSend);
                if (result.responseCode.toString().startsWith('2')) {
                    this.getNotifications();
                    return true;
                }
                else return false;
            } else if (this.type == SPECIFIC_TYPE) {
                let dataSend = {
                    targetType: this.type,
                    title: this.pushNotificationTitle,
                    content: this.pushNotificationContent,
                    list: toJS(this.selectedArray),
                    sendAll: this.sendAll == 1 ? true : false,
                    scheduler: this.setSchedule,
                    scheduleType: this.scheduleType,
                    schedulerAt: moment(this.scheduleDate).format('YYYY-MM-DD HH:mm'),
                    pushNotificationImageUrl: this.uploadedUrl
                }
                if (!!this.notificationTypeSelected.id) {
                    dataSend = {
                        ...dataSend,
                        notificationTypeId: this.notificationTypeSelected.id,
                        notificationTypeName: this.notificationTypeSelected.name,
                        payload: toJS(this.payloadData)
                    }
                }
                const result = await sendPushNotification(dataSend);
                if (result.responseCode.toString().startsWith('2')) {
                    this.getNotifications();
                    return true;
                }
                else return false;
            }
        }
        catch (err) {
            Global.error({ message: err.message || '' });
            return false;
        }
    }

    updateScheduledNotificationWorker = async () => {
        try {
            if (this.type == SEGMENTED_TYPE) {
                let dataSend = {
                    id: this.notificationIdToEdit,
                    targetType: this.type,
                    title: this.pushNotificationTitle,
                    content: this.pushNotificationContent,
                    selectedSegments: toJS(this.selectedSegments),
                    scheduler: this.setSchedule,
                    scheduleType: this.scheduleType,
                    schedulerAt: moment(this.scheduleDate).format('YYYY-MM-DD HH:mm'),
                    pushNotificationImageUrl: this.uploadedUrl
                }
                if (!!this.notificationTypeSelected.id) {
                    dataSend = {
                        ...dataSend,
                        notificationTypeId: this.notificationTypeSelected.id,
                        notificationTypeName: this.notificationTypeSelected.name,
                        payload: toJS(this.payloadData)
                    }
                }
                const result = await updateScheduledNotificationApi(dataSend);
                if (result.responseCode.toString().startsWith('2')) {
                    this.cancelEditScheduledNotificationWorker();
                    this.getNotifications();
                    return true;
                } else return false;
            } else if (this.type == SPECIFIC_TYPE) {
                let dataSend = {
                    id: this.notificationIdToEdit,
                    targetType: this.type,
                    title: this.pushNotificationTitle,
                    content: this.pushNotificationContent,
                    list: toJS(this.selectedArray),
                    sendAll: this.sendAll == 1 ? true : false,
                    scheduler: this.setSchedule,
                    scheduleType: this.scheduleType,
                    schedulerAt: moment(this.scheduleDate).format('YYYY-MM-DD HH:mm'),
                    pushNotificationImageUrl: this.uploadedUrl
                }
                if (!!this.notificationTypeSelected.id) {
                    dataSend = {
                        ...dataSend,
                        notificationTypeId: this.notificationTypeSelected.id,
                        notificationTypeName: this.notificationTypeSelected.name,
                        payload: toJS(this.payloadData)
                    }
                }
                const result = await updateScheduledNotificationApi(dataSend);
                if (result.responseCode.toString().startsWith('2')) {
                    this.cancelEditScheduledNotificationWorker();
                    this.getNotifications();
                    return true;
                } else return false;
            }
        }
        catch (err) {
            Global.error({ message: err.message || '' });
            return false;
        }
    }

    getNotificationTypesStore = async () => {
        const result = await getNotificationTypesApi();
        if (result.responseCode.toString().startsWith('2')) {
            this.notificationTypes = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    loadProductCategories = async () => {
        const result = await getProductCategories();
        if (result.responseCode.toString().startsWith('2')) {
            const dataArray = result.dataArray;
            var tempArray = [];

            for (let i = 0; i < dataArray.length; i++) {
                const id = dataArray[i].id;
                const categoryName = dataArray[i].categoryName;
                const imageActive = dataArray[i].imageActive;
                tempArray.push({
                    id: id,
                    title: categoryName,
                    image: imageActive
                })
            }
            this.categories = tempArray;
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    loadProductAreaText = async () => {
        const result = await getProductAreaText();
        if (result.responseCode.toString().startsWith('2')) {
            const dataArray = result.dataArray;
            var tempArray = [];

            for (let i = 0; i < dataArray.length; i++) {
                const id = i;
                const areaText = dataArray[i].areaText;
                tempArray.push({
                    id: id,
                    title: areaText,
                })
            }
            this.areaTexts = tempArray;
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    onDrop = async (acceptedFiles, rejectedFiles) => {
        this.uploadingImage = true;
        this.uploadedUrl = '';
        if (acceptedFiles.length < 0) {
            return
        }
        let data = new FormData();
        let file = acceptedFiles[0];
        data.append('file', file, file.name);

        const result = await uploadImageNoResize(data);
        try {
            this.uploadedUrl = result.data.uploadedUrl;
            this.uploadingImage = false;
        } catch (error) {
            this.uploadingImage = false;
        }
    }

    setSelectedSegmentsWorker = (data) => {
        this.selectedSegments = data || [];
    }

    onNotificationTypeSelected = (notiTypeId) => {
        const itemNotiType = toJS(this.notificationTypes).find(o => o.id == notiTypeId);
        this.notificationTypeSelected = itemNotiType || { id: 0 };
    }

    cancelEditScheduledNotificationWorker = () => {
        this.notificationIdToEdit = null;
        this.scheduledSwitchStatus = false;
    }

    setProcessStep = (step) => this.processStep = step || 0;

    onSetDataToEditScheduledNotiWorker = (item) => {
        const {
            id,
            title,
            content,
            selectedSegments,
            payload,
            list,
            notificationTypeId,
            pushNotificationImageUrl,
            sendAll,
            targetType, // SPECIFIC_TYPE/SEGMENTED_TYPE
            scheduleType,
            schedulerAt
        } = item;
        this.notificationIdToEdit = id;
        this.pushNotificationTitle = title;
        this.pushNotificationContent = content;
        this.onNotificationTypeSelected(notificationTypeId);
        this.payloadData = payload || {};
        this.selectedArray = list || [];
        this.selectedSegments = selectedSegments || [];
        this.uploadedUrl = pushNotificationImageUrl;
        this.sendAll = !!sendAll ? 1 : 2;
        this.setSchedule = 1;
        this.scheduleType = scheduleType;
        this.type = targetType;
        if (!!schedulerAt && moment(schedulerAt).isValid()) this.scheduleDate = moment(schedulerAt).toDate();
        this.scheduledSwitchStatus = true;
    }
}
export const pushNotificationStores = new PushNotificationStores();