import { requestApi } from "../axios";
const prefix = 'dashboard';

export function getCommonStatisticApi() {
    return requestApi(`${prefix}/common-statistic`, 'GET');
}

export function getDailyUserChart() {
    return requestApi(`${prefix}/get-daily-user-chart`, 'GET');
}

export function getDailyAskChart() {
    return requestApi(`${prefix}/get-daily-ask-chart`, 'GET');
}

export function getWeeklyUserChart() {
    return requestApi(`${prefix}/get-weekly-user-chart`, 'GET');
}

export function getMonthlyUserChart() {
    return requestApi(`${prefix}/get-monthly-user-chart`, 'GET');
}

export function getYearlyUserChart() {
    return requestApi(`${prefix}/get-yearly-user-chart`, 'GET');
}

export function getUserTypeChart() {
    return requestApi(`${prefix}/get-user-type-chart`, 'GET');
}

export function getTotalProductByCategoryChart() {
    return requestApi(`${prefix}/get-total-product-by-category-chart`, 'GET');
}

export function generateChartData() {
    return requestApi(`${prefix}/generate-chart-data`, 'POST');
}

export function getDailyMakeAnOfferChart() {
    return requestApi(`${prefix}/get-daily-make-an-offer-chart`, 'GET');
}

export function getDailyChatMessages() {
    return requestApi(`${prefix}/get-daily-chat-message-chart`, 'GET');
}

export function getDailyTotalMoneySold() {
    return requestApi(`${prefix}/get-daily-total-money-sold-chart`, 'GET');
}

export function getDailyTotalSale() {
    return requestApi(`${prefix}/get-daily-total-sale-chart`, 'GET');
}
export function getDailyTotalSaleThreeTypes() {
    return requestApi(`${prefix}/get-daily-total-sale-three-types-chart`, 'GET');
}
export function getDailyTotalSold() {
    return requestApi(`${prefix}/get-daily-total-sold-chart`, 'GET');
}