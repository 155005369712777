import { requestApi } from "../axios";
import {stringify} from 'querystring';
const prefix = 'promotion-popup';

export function getPromotionPopupList(search, limit, offset) {
    return requestApi(`${prefix}?${stringify({search, limit, offset})}`, 'GET');
}

export function getPromotionPopupCount(search) {
    return requestApi(`${prefix}/count?${stringify({search})}`, 'GET');
}

export function getPromotionPopupDetails(promotionPopupId) {
  return requestApi(`${prefix}/${promotionPopupId}`, 'GET');
}

export function createPromotionPopup(payload) {
  return requestApi(`${prefix}`, 'POST', payload);
}


export function updatePromotionPopup(promotionPopupId, payload) {
  return requestApi(`${prefix}/${promotionPopupId}`, 'POST', payload);
}