import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { feedbackStores } from '../stores/FeedbackStores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { user_avatar_default } from '../../../common/images';
import moment from 'moment';
import Dropdown from '../../components/Dropdown';

const FEEDBACK_STATUS = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    DONE: 'DONE'
}

const FEEDBACK_STATUS_LIST = [
    {
        id: FEEDBACK_STATUS.NEW,
        name: 'Chưa xử lý'
    },
    {
        id: FEEDBACK_STATUS.PROCESSING,
        name: 'Đang xử lý'
    },
    {
        id: FEEDBACK_STATUS.DONE,
        name: 'Đã xử lý'
    }
]

class FeedbackListTable extends Component {
    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: feedbackStores.totalFeedbackCount,
            page: feedbackStores.feedbackCurrentPage,
        };
        return paginationOption;
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img alt='' className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar || user_avatar_default} />
        </div>
    }

    onImageClick = (url) => () => {
        this.props.onOpenImageView([{ src: url }]);
    }

    imageFormatter = (cell, row) => {
        return (<div onClick={this.onImageClick(cell)} key={row.id} style={{ cursor: 'pointer' }} >
            <img alt='' className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.image} />
        </div>)
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('hh:mm A')}</p>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                    </div>
                }

            </div>
        );
    }

    statusFormater = (cell, row) => {
        const buttonClassName = row.status == FEEDBACK_STATUS.DONE ? 'btn-success' : row.status == FEEDBACK_STATUS.PROCESSING ? 'btn-info' : 'btn-danger'
        return <div className="td-actions text-center">
            <Dropdown
                buttonClassName={buttonClassName}
                onItemClicked={this.onChangeStatus(row)}
                idSelected={row.status}
                datas={FEEDBACK_STATUS_LIST}
            />
        </div>
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'avatar',
                text: 'Avatar',
                headerFormatter: this.headerFormatter,
                formatter: this.thumbFormatter,
                headerStyle: {
                    width: 100,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'fullname',
                text: 'Họ tên',
                headerFormatter: this.headerFormatter,
                formatter: this.fullnameFormarter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'feedbackContent',
                text: 'Nội dung',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'image',
                text: 'Hình ảnh',
                formatter: this.imageFormatter,
                headerStyle: {
                    width: 100
                }
            },
            {
                dataField: 'createdAt',
                text: 'Thời gian gửi',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'adminFullname',
                text: 'Quản trị viên',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'responseFeedbackAt',
                text: 'Phản hồi lúc',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'responseFeedbackContent',
                text: 'Nội dung phản hồi',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 100
                }
            },
            {
                dataField: 'status',
                text: 'Trạng thái',
                formatter: this.statusFormater,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 70,
                    textAlign: 'center'
                }
            }
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
        textAlign: 'center'
    })

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => this.props.onItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">send</i>
            </button>
        </div>
    }

    fullnameFormarter = (cell, row) => {
        return <a href={`#${row.userId}`} onClick={() => this.props.onShowUserInfo(row.userId)}>{row.fullname}</a>
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        feedbackStores.setFeedbackPage(page);
        feedbackStores.getFeedbackListAction();
    }

    onChangeStatus = (row) => (item) => {
        if (row.status == item.id) return;
        feedbackStores.updateStatusWorker(row.id, item.id);
    }

    render() {
        const feedbacks = toJS(feedbackStores).feedbackList;
        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={feedbacks}
                columns={this.columnsOption()}
                loading={feedbackStores.loadingFeedbackList}
            />
        );
    }
}

export default observer(FeedbackListTable);