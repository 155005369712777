import React, { useCallback, useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { AcceptButton } from 'src/pages/components/Button/Button';
import ProductSupportConversationTable from './ProductSupportConversationTable'
import { reviewProductStores } from '../../stores/ReviewProductStores';
import { usePrevious } from 'src/helpers/hooksApi';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

const ProductSupportConversation = () => {
    const [text, setText] = useState('');
    const {
        productDetailInfo,
        supportConversations,
        getSupportConversationTotalCountWorker,
        getSupportConversationListWorker,
        sendSupportMessageLoading,
        setSupportConversationsPage,
        sendSupportMessageWorker
    } = toJS(reviewProductStores);
    const prevProductDetailInfo = usePrevious(productDetailInfo);
    const prevSendSupportMessageLoading = usePrevious(sendSupportMessageLoading);

    useEffect(() => {
        if (!prevProductDetailInfo & productDetailInfo || (prevProductDetailInfo && productDetailInfo && prevProductDetailInfo.id != productDetailInfo.id && productDetailInfo.id)) {
            // load support conversation
            getSupportConversationTotalCountWorker();
            getSupportConversationListWorker();
        }
    }, [productDetailInfo, prevProductDetailInfo]);

    useEffect(() => {
        if (prevSendSupportMessageLoading != sendSupportMessageLoading && prevSendSupportMessageLoading && !sendSupportMessageLoading) {
            setText('');
            setSupportConversationsPage(1);
            getSupportConversationTotalCountWorker();
            getSupportConversationListWorker();
        }
    }, [sendSupportMessageLoading, prevSendSupportMessageLoading]);

    const onSendMessage = useCallback(() => sendSupportMessageWorker(text), [text, sendSupportMessageWorker]);

    const onChangeText = useCallback((e) => setText(e.target.value), []);
    return (
        <div className='product-support-conversation'>
            <div className='input-view'>
                <FormControl isInvalid={true} isValid={false}
                    value={text || ''}
                    onChange={onChangeText}
                    style={{ borderBottom: 'solid 1px #fe805c', minHeight: 80 }}
                    placeholder={'Nhập nội dung cần gửi'}
                    plaintext
                    maxLength={1024}
                    as="textarea" />
                <AcceptButton onClick={onSendMessage} disabled={sendSupportMessageLoading} containerStyle={{ height: 'fit-content' }} title='Gửi' />
            </div>
            {
                !!supportConversations.length &&
                <ProductSupportConversationTable />
            }
        </div>
    )
}

export default observer(ProductSupportConversation);
