import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { updatePriceBuyNowBidding } from 'src/api/product/bidding';
import { Global } from 'src/GlobalClass';
import { AcceptButton, CancelButton } from 'src/pages/components/Button';

const UpdateBiddingPriceBuyNowModal = ({
  isShow,
  onHide,
  bidding,
  onSuccess,
}) => {
  const [priceBuyNow, setPriceBuyNow] = useState(bidding.priceBuyNow);
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    if (bidding) {
      if (bidding.buynowPrice === null) {
        setEnable(false)
      } else {
        setEnable(true)
      }
      setPriceBuyNow(bidding.buynowPrice);
    }
  }, [bidding]);

  useEffect(()=> {
    if (!enable) {
      setPriceBuyNow(null)
    }
  }, [enable])

  const onConfirm = async () => {
    try {
      debugger
      if (priceBuyNow!==null && priceBuyNow <= 0) {
        Global.error({ message: 'Giá mua ngay không hợp lệ' });
        return;
      }
      const result = await updatePriceBuyNowBidding({
        productId: bidding.productId,
        buynowPrice: priceBuyNow,
      })
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({ message: 'Làm mới thành công' });
        onSuccess();
        resetDataModal();
      }
      else Global.error({ message: result.responseText || 'Làm mới thất bại' })
    }
    catch (err) {
      Global.error({ message: err.message || '' })
    }
  }

  const resetDataModal = () => {
    setPriceBuyNow(null);
    setEnable(false);
  }

  const onCloseModal = () => {
    resetDataModal();
    onHide();
  }

  return <Modal
    className='review-product-modal'
    show={isShow}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={onCloseModal}
  >
    <Modal.Header closeButton>
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        Cập nhật loại đấu giá {bidding ? bidding.productName : ''}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ maxHeight: '65vh', overflow: 'scroll' }}>
      <div className="" style={{ margin: 32, display: 'inline-flex', width: '100%' }} >
        <label className="bmd-label-floating" style={{ marginRight: 16, width: '15%' }}>Đặt giá mua ngay</label>
        <label class="switch">
          <input type="checkbox" onChange={(e) => {setEnable(e.target.checked)}} checked={enable}/>
          <span class="slider round"></span>
        </label>
      </div>
      <div className="" style={{ margin: 32 }} >

        <label className="bmd-label-floating" style={{ marginRight: 16, width: '15%' }}>Giá mua ngay</label>
        <input
          required
          value={priceBuyNow === null ? 'N/a' : priceBuyNow}
          type="number"
          className="form-control"
          disabled={!enable}
          onChange={(e) => {
            setPriceBuyNow(e.target.value);
          }}
        />
      </div>

    </Modal.Body>
    <Modal.Footer>
      <div>
        <CancelButton onClick={onCloseModal} title='Huỷ' />
        <AcceptButton onClick={onConfirm} title='Gửi' />
      </div>
    </Modal.Footer>
  </Modal>
}

export default UpdateBiddingPriceBuyNowModal;