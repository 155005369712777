import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { get, toJS } from 'mobx';
import moment from 'moment';
import _ from 'lodash'
import '../../css/styles.css';
import { refundListStores } from '../../stores/RefundListStores';
import PaginationTable from '../../../components/PaginationTable';
import { user_avatar_default } from '../../../../common/images';
import { formatCurrency } from 'src/helpers/CF';
import { formatter } from '../../../../helpers/formatter';

class RefundListTable extends Component {
    constructor(props) {
        super(props);
        this.onChangeTextDebounce = _.debounce(this.onChangeTextDebounce.bind(this), 600);
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={cell || user_avatar_default} />
        </div>
    }

    priceFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span>{formatCurrency(cell)}</span>
        );
    }

    onShowChangePassModal = (userId) => () => {
        if (!userId) return;
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'invoice.productId',
                text: 'Mã sản phẩm',
                formatter: formatter.productIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'invoice.productThumb',
                text: 'Hình ảnh',
                formatter: this.thumbFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.productName',
                text: 'Tên sản phẩm',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault,
            },
            {
                dataField: 'invoice.price',
                text: 'Giá (VNĐ)',
                headerFormatter: this.headerFormatter,
                formatter: this.priceFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.sellerId',
                text: 'Id người bán',
                formatter: formatter.userIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.buyerId',
                text: 'Id người mua',
                formatter: formatter.userIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentState.name',
                text: 'Tiến trình',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentState.status',
                text: 'Trạng thái',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentState.result',
                text: 'Kết quả',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
        ];
    }

    fullnameFormarter = (cell, row) => {
        return <a href={`#${row.userId}`} onClick={() => this.props.onShowUserInfo(row.userId)}>{row.fullname}</a>
    }


    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        refundListStores.setRefundTablePage(page);
        this.getRefundList(toJS(refundListStores).txtSearchProduct);
    }

    getTotalSize = async () => {
        await refundListStores.getTotalRefundCount(refundListStores.productFilterSelected);
    }

    getRefundList = () => {
        refundListStores.getRefundListStore();
    }

    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: toJS(refundListStores).totalRefundListCount,
            page: refundListStores.refundTableCurrentPage,
        };
        return paginationOption;
    }

    async onChangeTextDebounce() {
        await this.getTotalSize();
        refundListStores.setRefundTablePage(1);
        this.getRefundList();
    }

    onChangeText = async (e) => {
        refundListStores.onSearchRefundChange(e.target.value);
        this.onChangeTextDebounce();
    }


    render() {
        const { refundList, getRefundListLoading, txtSearchRefund } = toJS(refundListStores);
        return (
            <div className='product-list'>
                {/* TODO: Phuc handle it after getting feedback from admin
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className='material-icons'>search</i>
                        </span>
                    </div>
                    <input
                        style={{ marginBottom: 12 }}
                        onChange={this.onChangeText}
                        type="text"
                        value={txtSearchRefund}
                        className="form-control"
                        placeholder="Tìm kiếm theo tên sản phẩm..." />
                </div> */}
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    expandRow = {{
                        renderer: row=> {'a'}
                    }}
                    onTableChange={this.onTableChange}
                    datas={refundList}
                    columns={this.columnsOption()}
                    loading={getRefundListLoading}
                    // tableRowEvents={
                    //     {
                    //         onClick: (e, row, rowIndex) => {
                    //             this.props.onProductItemClicked(row)
                    //         }
                    //     }
                    // }
                />
            </div>
        )
    }

    componentDidMount = async () => {
        await this.getTotalSize();
        this.getRefundList();
    };


}

export default observer(RefundListTable);