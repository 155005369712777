import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import moment from 'moment';
import { pushNotificationStores, NOTIFICATION_TAB_KEY } from '../stores/PushNotificationStores';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';
const { REACT_APP_ENVIRONTMENT } = process.env;
class TableScheduledNotification extends Component {
    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: pushNotificationStores.total,
            page: pushNotificationStores.currentPage,
        };
        return paginationOption;
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('hh:mm A')}</p>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                    </div>
                }

            </div>
        );
    }

    listFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {/* {cell} */}
            </div>
        );
    }
    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {!!cell ? moment(cell).format('DD/MM/YYYY HH:mm') : 'Chưa được gửi'}
            </div>
        );
    }
    // vì timezone của db prod là GMT(0)
    date2Formatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {!!cell ? moment(cell).zone(0).format('DD/MM/YYYY HH:mm') : 'Chưa được gửi'}
            </div>
        );
    }

    sendAllFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell == true
                        ?
                        <span className="badge badge-warning">Gửi toàn bộ</span>
                        :
                        <span className="badge badge-light">Gửi danh sách user</span>
                }
            </div>
        );
    }
    imageFormatter = (cell, row) => {
        return <div className="td-actions text-center">
            {!!cell && <img src={cell} width={50} height={50} style={{ objectFit: 'contain' }} />}
        </div >
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'pushNotificationImageUrl',
                text: 'Ảnh',
                headerFormatter: this.headerFormatter,
                formatter: this.imageFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'title',
                text: 'Tiêu đè',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'content',
                text: 'Nội dung',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            // {
            //     dataField: 'list',
            //     text: 'Danh sách gửi',
            //     headerFormatter: this.headerFormatter,
            //     formatter: this.listFormatter,
            //     headerStyle: {
            //         width: 150
            //     }
            // },
            {
                dataField: 'sendAll',
                text: 'Gửi tất cả mọi người',
                headerFormatter: this.headerFormatter,
                formatter: this.sendAllFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'notificationTypeName',
                text: 'Loại',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'createdAt',
                text: 'Tạo lúc',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'schedulerAt',
                text: 'Sẽ gửi lúc',
                headerFormatter: this.headerFormatter,
                formatter: REACT_APP_ENVIRONTMENT == 'PROD' ? this.date2Formatter : this.dateFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'sentAt',
                text: 'Đã gửi lúc',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: {
                    width: 100
                }
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60,
                    textAlign: 'center'
                }
            }
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
        textAlign: 'center'
    })

    onDeleteIconPress = (row) => () => {
        this.confirmAlert = window.confirm('Xóa dòng này ?');
        if (this.confirmAlert) {
            pushNotificationStores.deleteNotificationSchedule(row.id);
        }
    }

    onEditIconPress = (row) => () => {
        pushNotificationStores.onSetDataToEditScheduledNotiWorker(row);
        pushNotificationStores.setProcessStep(0);
        this.props.onSelectedTab(NOTIFICATION_TAB_KEY.PUSH_NOTIFICATION)
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={this.onDeleteIconPress(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-danger btn-simple">
                <i className="material-icons">delete</i>
            </button>
            <button onClick={this.onEditIconPress(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-warning btn-simple">
                <i className="material-icons">edit</i>
            </button>
        </div >
    }

    headerFormatter = (column, colIndex) => {
        return <div className="td-actions text-center"><h5><strong>{column.text}</strong></h5></div>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        pushNotificationStores.setPage(page);
    }

    componentDidMount() {
        pushNotificationStores.getNotifications();
    }

    render() {

        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={mobx.toJS(pushNotificationStores.notificationList)}
                columns={this.columnsOption()}
                loading={pushNotificationStores.loading}
            />
        );
    }
}

export default observer(TableScheduledNotification);