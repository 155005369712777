import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { toJS } from 'mobx';
import moment from 'moment';
import _ from 'lodash'
import { biddingListStores } from '../../stores/BiddingListStores';
import PaginationTable from '../../../components/PaginationTable';
import { user_avatar_default } from '../../../../common/images';
import { formatCurrency } from '../../../../helpers/CF';
import { formatter } from '../../../../helpers/formatter';
import { Button } from 'react-bootstrap';

class BiddingListTable extends Component {
    constructor(props) {
        super(props);
        this.onChangeTextDebounce = _.debounce(this.onChangeTextDebounce.bind(this), 600);
    };

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.thumbImage || user_avatar_default} />
        </div>
    }

    onFailedClicked = (bidding) => {
        const { setShowModalResetBidding, setBiddingSelected } = biddingListStores;
        setBiddingSelected(bidding);
        setShowModalResetBidding(true);
    }

    onTypeClicked = (bidding) => {
        const { setShowModalUpdateTypeBidding, setBiddingSelected } = biddingListStores;
        setBiddingSelected(bidding);
        setShowModalUpdateTypeBidding(true);
    }

    typeFormatter = (cell, row) => {
        return <span className="linkId" onClick={e => this.onTypeClicked(row)}>{cell}</span>
    }

    onAuctionTypeClicked = (bidding) => {
        const { setShowModalUpdateAuctionTypeBidding, setBiddingSelected } = biddingListStores;
        setBiddingSelected(bidding);
        setShowModalUpdateAuctionTypeBidding(true);
    }

    auctionTypeFormatter = (cell, row) => {
        return <span className="linkId" onClick={e => this.onAuctionTypeClicked(row)}>{cell}</span>
    }

    onPriceBuyNowClicked = (bidding) => {
        const { setShowModalUpdatePriceBuyNowBidding, setBiddingSelected } = biddingListStores;
        setBiddingSelected(bidding);
        setShowModalUpdatePriceBuyNowBidding(true);
    }

    priceBuyNowFormatter = (cell, row) => {
        return <span className="linkId" onClick={e => this.onPriceBuyNowClicked(row)}>{cell === null ? 'N/a' : cell }</span>
    }


    statusFormatter = (cell, row) => {
        switch (cell) {
            case null:
                return (<div className="bidding-running" ><span>Đang đấu giá</span></div>)
            case true:
                return (<div className="bidding-succeeded" ><span>Thành công</span></div>)
            case false:
                return (<div className="bidding-failed" onClick={e => this.onFailedClicked(row)} >
                    <span>Thất bại</span>
                    <span className="bidding-failed-tooltip">Đấu giá lại</span>
                </div>)
            default:
                return 'N/a'
        }
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    expiredDateFormatter = (cell) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                        <p>{moment(cell).format('hh:mm A')}</p>
                    </div>
                }

            </div>
        );
    }

    priceFormatter = (cell) => {
        return (
            <span>{formatCurrency(cell)}</span>
        );
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    columnsOption = () => {
        return [
            {
                dataField: 'productId',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'productName',
                text: 'Tên sản phẩm',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault,
            },
            {
                dataField: 'startPrice',
                text: 'Giá khởi điểm (VNĐ)',
                headerFormatter: this.headerFormatter,
                formatter: this.priceFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'floorPrice',
                text: 'Giá sàn (VNĐ)',
                headerFormatter: this.headerFormatter,
                formatter: this.priceFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'stepPrice',
                text: 'Bước giá (VNĐ)',
                headerFormatter: this.headerFormatter,
                formatter: this.priceFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentPrice',
                text: 'Giá hiện tại (VNĐ)',
                headerFormatter: this.headerFormatter,
                formatter: this.priceFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'buynowPrice',
                text: 'Giá mua ngay (VNĐ)',
                headerFormatter: this.headerFormatter,
                formatter: this.priceBuyNowFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'endAt',
                text: 'Ngày kết thúc',
                headerFormatter: this.headerFormatter,
                formatter: this.expiredDateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'winnerId',
                text: 'Id người chiến thắng',
                formatter: formatter.userIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'auctionType',
                text: 'Loại đấu giá',
                headerFormatter: this.headerFormatter,
                formatter: this.auctionTypeFormatter,
                headerStyle: this.headerStyleDefault,
            },
            {
                dataField: 'type',
                text: 'Loại bước giá',
                headerFormatter: this.headerFormatter,
                formatter: this.typeFormatter,
                headerStyle: this.headerStyleDefault,
            },
            {
                dataField: 'result',
                text: 'Kết quả',
                formatter: this.statusFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            }
        ];
    }

    fullnameFormarter = (cell, row) => {
        return <a href={`#${row.userId}`} onClick={() => this.props.onShowUserInfo(row.userId)}>{row.fullname}</a>
    }


    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        biddingListStores.setBiddingTableCurrentPage(page);
        this.getBiddingList(toJS(biddingListStores).txtSearchProduct);
    }

    getTotalSize = async () => {
        await biddingListStores.getBiddingCount();
    }

    getBiddingList = () => {
        biddingListStores.getListBidding();
    }

    paginationOption = () => (
        {
            custom: true,
            hideSizePerPage: true,
            totalSize: toJS(biddingListStores).biddingCount,
            page: biddingListStores.biddingTableCurrentPage,
        }
    )

    async onChangeTextDebounce() {
        await this.getTotalSize();
        biddingListStores.setBiddingTableCurrentPage(1);
        this.getBiddingList();
    }

    onChangeText = async (e) => {
        biddingListStores.onSearchProductChange(e.target.value);
        this.onChangeTextDebounce();
    }


    render() {
        const { biddingList, isLoadingGetList, txtSearchProduct } = toJS(biddingListStores);
        return (
            <div className='product-list'>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className='material-icons'>search</i>
                        </span>
                    </div>
                    <input
                        style={{ marginBottom: 12 }}
                        onChange={this.onChangeText}
                        type="text"
                        value={txtSearchProduct}
                        className="form-control"
                        placeholder="Tìm kiếm theo tên sản phẩm hoặc mã sản phẩm..." />
                </div>
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    onTableChange={this.onTableChange}
                    datas={biddingList}
                    columns={this.columnsOption()}
                    loading={isLoadingGetList}
                    keyField="productId"
                />
            </div>
        )
    }

    componentDidMount = async () => {
        await this.getTotalSize();
        this.getBiddingList();
    };


}

export default observer(BiddingListTable);