import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AcceptButton } from '../../../components/Button/Button';
import { Global } from '../../../../GlobalClass';
import { confirmPayment } from '../../../../api/order/order';

const UpdateOrderModal = ({
  closeModal,
  isShowing
}) => {
  const [transactionId, setTransactionId] = useState('')

  const updateOrderPurchaseStatus = async () => {
    try {
      const result = await confirmPayment(transactionId);
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({messgae: 'Cập nhật thành công'});
        setTransactionId('');
        closeModal();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (err) {
      Global.error({message: 'Cập nhật thất bại'})
    }
  }

  return <Modal 
    show={isShowing}
    size='xl'
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={() => {}}
  >
    <Modal.Header closeButton onHide={closeModal} >
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        {'Cập nhật hoá đơn'}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body >
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}} >
        <input style={{
          fontSize: '18px', padding: '5px', width: '50%', marginRight: '16px'}} 
          placeholder='Nhập mã hoá đơn' 
          value={transactionId}
          onChange={e => setTransactionId(e.target.value)}
        />
        <AcceptButton 
          containerStyle={{ height: 'fit-content' }} 
          onClick={updateOrderPurchaseStatus} 
          title='Xác nhận đã thanh toán'
        />
      </div>
    </Modal.Body>

  </Modal>
}

export default UpdateOrderModal;