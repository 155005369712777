import { extendObservable, toJS } from 'mobx';
import { uploadImageNoResize } from 'src/api/axios';
import { getProductAreaText, getProductCategories, getSegmentUser, searchUser } from 'src/api/pushNotification/pushNotification';
import { Global } from 'src/GlobalClass';
import { SEGMENTED_TYPE, SPECIFIC_TYPE } from 'src/pages/PushNotification/stores/PushNotificationStores';
import { sendEmailService } from 'src/api/pushNotification/pushNotification'

class SendEmailStores {
    constructor() {
        extendObservable(this, {
            emailTitle: '',
            emailContent: '',
            uploadingImageInEmail: false,
            sendEmailToAllUser: false,
            arrUserToSend: [],
            searchUserResults: [],

            targetType: SPECIFIC_TYPE,
            selectedSegments: [{
                id: 1,
                segmentId: 1,
                value: '',
                displayValue: '',
                selectedValue: {
                    id: 1,
                    title: 'Đã đăng bán X sản phẩm',
                }
            }],
            countUserMatcheSegmented: 0,
            categories: [],
            areaTexts: [],
            idVerifies: [
                {
                    id: 1,
                    title: 'Đã verify CMND'
                },
                {
                    id: 2,
                    title: 'Chưa verify CMND'
                },
            ],
            phoneNumberVerifies: [
                {
                    id: 1,
                    title: 'Đã verify Số điện thoại'
                },
                {
                    id: 2,
                    title: 'Chưa verify Số điện thoại'
                },
            ],
        });
    }

    emailContentChange = (text) => this.emailContent = text;
    emailTitleChange = (text) => this.emailTitle = text;

    uploadImageInEmailService = async (file) => {
        if (!file) return;
        try {
            this.uploadingImageInEmail = true;
            const data = new FormData();
            data.append('file', file, file.name);
            const result = await uploadImageNoResize(data);
            this.uploadingImageInEmail = false;
            if (result.responseCode.toString().startsWith('2')) {
                return result.data.uploadedUrl;
            } else {
                Global.error({ message: result.responseText || 'Lỗi upload ảnh.' });
            }
            return;
        } catch (error) {
            console.log(error)
            Global.error({ message: error.message || 'Lỗi upload ảnh.' });
            return;
        }
    }

    searchUserByKeywordService = async (keyword) => {
        try {
            const result = await searchUser(keyword);
            if (result.responseCode.toString().startsWith('2')) {
                this.setSearchedUser(result.dataArray);
            } else {
                Global.error({ message: result.responseText || 'Lỗi khi search.' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Lỗi khi search.' });
        }
        return;
    }

    loadProductCategories = async () => {
        try {
            const result = await getProductCategories();
            if (result.responseCode.toString().startsWith('2')) {
                const dataArray = result.dataArray;
                let tempArray = [];

                for (let i = 0; i < dataArray.length; i++) {
                    const id = dataArray[i].id;
                    const categoryName = dataArray[i].categoryName;
                    const imageActive = dataArray[i].imageActive;
                    tempArray.push({
                        id: id,
                        title: categoryName,
                        image: imageActive
                    })
                }
                this.categories = tempArray;
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
    }

    loadProductAreaText = async () => {
        try {
            const result = await getProductAreaText();
            if (result.responseCode.toString().startsWith('2')) {
                const dataArray = result.dataArray;
                let tempArray = [];

                for (let i = 0; i < dataArray.length; i++) {
                    const id = i;
                    const areaText = dataArray[i].areaText;
                    tempArray.push({
                        id: id,
                        title: areaText,
                    })
                }
                this.areaTexts = tempArray;
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
    }

    loadSegmentsUser = async () => {
        if (this.targetType == SEGMENTED_TYPE) {
            try {
                const result = await getSegmentUser(toJS(this.selectedSegments));
                this.countUserMatcheSegmented = result.data.count;
            } catch (error) {
                Global.error({ message: error.message || 'Có lỗi xảy ra.' });
            }
        }
    }

    sendEmailStore = async () => {
        try {
            const dto = {
                title: this.emailTitle,
                content: this.emailContent,
                list: this.targetType == SPECIFIC_TYPE ? toJS(this.arrUserToSend) : undefined,
                targetType: this.targetType,
                selectedSegments: this.targetType == SEGMENTED_TYPE ? toJS(this.selectedSegments) : undefined,
                sendAll: this.sendEmailToAllUser && this.targetType == SPECIFIC_TYPE
            }
            const result = await sendEmailService(dto);
            if (result.responseCode.toString().startsWith('2')) {
                this.setSearchedUser(result.dataArray);
            } else {
                Global.error({ message: result.responseText || 'Lỗi khi gửi email.' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
    }

    setSendAllStatus = (status) => this.sendEmailToAllUser = status;

    setArrUserToSend = (list) => this.arrUserToSend = list || [];

    setSearchedUser = (list) => this.searchUserResults = list || [];

    setSelectedSegments = (segment) => this.selectedSegments = segment;

    reset = () => {
        this.emailContent = '';
        this.emailTitle = '';
        this.uploadingImageInEmail = false;
        this.sendEmailToAllUser = false;
        this.arrUserToSend = [];
        this.searchUserResults = [];
        this.targetType = SPECIFIC_TYPE;
        this.countUserMatcheSegmented = 0;
    }

    setTargetType = (type) => this.targetType = type;


}
export const sendEmailStores = new SendEmailStores();