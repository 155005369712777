import React, { useState } from 'react';
import CollectionTable from './components/CollectionTable';
import { AcceptButton } from 'src/pages/components/Button/Button';
import './css/styles.css';
import CollectionModal from './components/CollectionModal';
import { specificCollectionStore } from './store/SpecificCollectionStore'

const CollectionPage = () => {
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const [createNew, setCreateNew] = useState(false);

  const openNewCollectionHandler = () => {
    specificCollectionStore.newCollection();
    setShowCollectionModal(true);
    setCreateNew(true);
  }

  const openEditCollectionHandler = () => {
    setShowCollectionModal(true);
    setCreateNew(false);
  }

  const closeNewCollectionHandler = () => setShowCollectionModal(false);

  return <div className="card">
    <div className="card-body">
      <div className='row justify-content-end' style={{ margin: 0 }}>
        <AcceptButton
          containerStyle={{ marginLeft: 8 }}
          title='Tạo collection mới'
          onClick={openNewCollectionHandler}
        />
      </div>
      <CollectionTable editCollectionHandler={openEditCollectionHandler} />
    </div>
    <CollectionModal 
      show={showCollectionModal}
      close={closeNewCollectionHandler}
      createNew={createNew}
    />
  </div>
}

export default CollectionPage;