import { extendObservable } from 'mobx';
import { pageableOffset } from '../../../helpers/CF';
import { getCoalitionReview, getCoalitionTotalCount, rejectCoalition, approveCoalition, getCoalitionReviewById } from '../../../api/coalition/review-coalition';
import { Global } from '../../../GlobalClass';
class ReviewCoalitionStores {
    constructor() {
        extendObservable(this, {
            data: [],
            loading: false,
            totalCount: 0,
            currentPage: 1,
            isShowReviewCoaltionModal: false,
            coalitionModalItem: {}
        });
    }

    getTotalCount = async () => {
        const result = await getCoalitionTotalCount();
        if (result.responseCode.toString().startsWith('2')) {
            this.totalCount = parseInt(result.data.total);
            console.log(this.totalCount);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }


    loadData = async () => {
        this.loading = true;
        const offset = pageableOffset(this.currentPage);
        const result = await getCoalitionReview(offset);
        console.log('zo11');
        if (result.responseCode.toString().startsWith('2')) {
            this.data = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.loading = false;
    }

    setPage = (page) => this.currentPage = page;

    showModal(row) {
        this.isShowReviewCoaltionModal = true;
        this.coalitionModalItem = row;
    }

    showModalById = async (id) => {
        var data = await getCoalitionReviewById(id);
        if (data && data.dataArray.length > 0) {
            this.coalitionModalItem = data.dataArray[0];
            this.isShowReviewCoaltionModal = true;
        }
    }

    reject = async () => {
        await rejectCoalition(this.coalitionModalItem.id);
        this.isShowReviewCoaltionModal = false;
        this.loadData();
    }

    approve = async () => {
        await approveCoalition(this.coalitionModalItem.id);
        this.isShowReviewCoaltionModal = false;
        this.loadData();
    }

}
export const reviewCoalitionStores = new ReviewCoalitionStores(); 