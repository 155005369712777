import React, {useState, useEffect} from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { AcceptButton, CancelButton } from 'src/pages/components/Button';
import {resetBidding} from 'src/api/product/bidding';
import { Global } from 'src/GlobalClass';


const ResetBiddingModal = ({
  isShow,
  onHide,
  bidding,
  onSuccess,
}) => {
  const [floorPrice, setFloorPrice] = useState(bidding.floorPrice || '');
  const [startPrice, setStartPrice] = useState(bidding.startPrice || '');
  const [stepPrice, setStepPrice] = useState(bidding.stepPrice || '');
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (bidding) {
      setFloorPrice(bidding.floorPrice + '');
      setStartPrice(bidding.startPrice + '');
      setStepPrice(bidding.stepPrice + '');
    }
  }, [bidding]);

  const onConfirm = async () => {
    try {
      if (!floorPrice || !startPrice || !stepPrice || !endDate) {
        Global.error({message: 'Cần điền đầy đủ thông tin'});
        return;
      }
      const result = await resetBidding({
        productId: bidding.productId,
        floorPrice: floorPrice,
        startPrice: startPrice,
        stepPrice: stepPrice,
        endDate
      })
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({message: 'Làm mới thành công'});
        onSuccess();
        resetDataModal();
      }
      else Global.error({message: result.responseText || 'Làm mới thất bại'})
    }
    catch (err) {
      Global.error({message: err.message || ''})
    }
  }

  const resetDataModal = () => {
    setFloorPrice('');
    setStartPrice('');
    setStepPrice('');
    setEndDate(null);
  }

  const onCloseModal = () =>{
    resetDataModal();
    onHide();
  }

  return <Modal
    className='review-product-modal'
    show={isShow}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={onCloseModal}
  >
    <Modal.Header closeButton>
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        Làm mới đấu giá {bidding ? bidding.productName : ''}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ maxHeight: '65vh', overflow: 'scroll' }}>
      <div className="form-group bmd-form-group" style={{margin: 32}} >
        <label className="bmd-label-floating">Giá sàn</label>
          <input 
            required
            value={floorPrice} 
            type="number" 
            className="form-control" 
            onChange={(e) => {
              setFloorPrice(e.target.value);
            }}
          />
      </div>
      <div className="form-group bmd-form-group" style={{margin: 32}} >
        <label className="bmd-label-floating">Giá khởi điểm</label>
          <input 
            required
            value={startPrice} 
            type="number" 
            className="form-control" 
            onChange={(e) => {
              setStartPrice(e.target.value);
            }}
          />
      </div>
      <div className="form-group bmd-form-group" style={{margin: 32}} >
        <label className="bmd-label-floating">Bước giá</label>
          <input
            required
            value={stepPrice} 
            type="number" 
            className="form-control" 
            onChange={(e) => {
              setStepPrice(e.target.value);
            }}
          />
      </div>
      <div className="form-group bmd-form-group" style={{margin: 32}} >
        <label style={{marginRight: 32}}>Ngày kết thúc</label>
        <DatePicker
            value={endDate ? moment(endDate).format("DD/MM/YYYY hh:mm A") : ''}
            adjustDateOnChange={false}
            showTimeSelect
            className="my-date-picker"
            selected={endDate || new Date()}
            dateFormat='dd/MM/yyyy hh:mm'
            minDate={Date.now()}
            onChange={(date) => {
              if (!!date)
                setEndDate(date)
            }} 
          />
      </div>
      
    </Modal.Body>
    <Modal.Footer>           
      <div>
          <CancelButton onClick={onCloseModal} title='Huỷ' />
          <AcceptButton onClick={onConfirm} title='Gửi' />
      </div>
    </Modal.Footer>
  </Modal>
}

export default ResetBiddingModal;