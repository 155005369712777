import React from 'react';
import ProductReviewModal from 'src/pages/ProductManagement/components/ReviewProduct/ProductReviewModal';
import { reviewProductStores } from 'src/pages/ProductManagement/stores/ReviewProductStores';
import { userDetailStores } from 'src/pages/UserManagement/stores/UserDetailStores';
import UserDetailModal from '../pages/UserManagement/components/UserDetail/UserDetailModal';

export const formatter = {
    productIdFormatter: (cell) => {
        return (<div>
            <ProductReviewModal onHide={onHideProduct} />
            <span className="linkId" onClick={e => onProductIdClicked(cell)}>{cell}</span>
        </div>)
    },
    userIdFormatter: cell => {
        return (
            <div>
                <UserDetailModal />
                <span className="linkId" onClick={e => onUserIdClicked(cell)}>{cell}</span>
            </div>
        )
    }
}

const onProductIdClicked = async (productId) => {   
    const { getReviewProductDetailAction, setIsShowReviewProduct } = reviewProductStores
    await getReviewProductDetailAction(productId);
    setIsShowReviewProduct(true);
}

const onHideProduct = () => {
    reviewProductStores.setIsShowReviewProduct(false);
}

const onUserIdClicked = (userId) => {
    const { setUserIdForLoadDetail, onChangeUserDetailModal } = userDetailStores;
    setUserIdForLoadDetail(userId);
    onChangeUserDetailModal(true);
}