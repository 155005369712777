import { extendObservable } from 'mobx';
import { getOrderListApi, getTotalOrderCountApi, confirmPickUpProductFromSellerApi, confirmDeliverProductToBuyerApi, confirmDeliveryFailureApi } from '../../../api/order/order';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';

// export const radioFilterProductListData = [
//     {
//         id: 'all',
//         name: 'Tất cả'
//     },
//     {
//         id: 'no_proof',
//         name: 'Sản phẩm không bằng chứng sở hữu'
//     }
// ]
class DeliveryListStores {
    constructor() {
        extendObservable(this, {
            deliveryList: [],
            getDeliveryListLoading: false,
            totalDeliveryListCount: 0,
            deliveryTableCurrentPage: 1,
            isShowDeliveryDetailModal: false,
            txtSearchDelivery: '',
            isLoadingConfirmPickup: false,
            isLoadingConfirmDeliver: false,
            isLoadingConfirmCancel: false,
            // deliveryFilterSelected: radioFilterDeliveryListData[0].id  
        });
    }
    onSearchDeliveryChange = (txt) => this.txtSearchDelivery = txt || '';

    setDeliveryFilterSelected = (id) => this.DeliveryFilterSelected = id;

    getCurrentState = order => order.progress.find(state => state.name === order.progressStep) || {name: 'N/a', status: 'N/a', result: 'N/a'};
    
    getDeliveryListStore = async () => {
        try {
            this.getDeliveryListLoading = true;
            const offset = pageableOffset(this.deliveryTableCurrentPage);
            const result = await getOrderListApi(offset, this.txtSearchProduct || '', 'DELIVERY');
            if (result.responseCode.toString().startsWith('2')) {
                this.deliveryList = (result.dataArray || []).map(order => ({...order, currentState: this.getCurrentState(order)}));
            } else {
                Global.error({ message: result.responseText || '' });
            }
            this.getDeliveryListLoading = false;
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.getDeliveryListLoading = false;
        }
    }
    setDeliveryTablePage = (page) => this.DeliveryTableCurrentPage = page;

    getTotalDeliveryCount = async (filter) => {
        const result = await getTotalOrderCountApi(this.txtSearchDelivery || '', 'DELIVERY');
        if (result.responseCode.toString().startsWith('2')) {
            this.totalDeliveryListCount = parseInt(result.data.count);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    onDeliveryDetailModalStatusChange = (status) => this.isShowDeliveryDetailModal = status;

    confirmPickUpProduct = async (orderId) => {
        this.isLoadingConfirmPickup = true;
        try {
            const result = await confirmPickUpProductFromSellerApi(orderId);
            if (result.responseCode.toString().startsWith('2')) {
                Global.success({ message: `Đơn hàng ${orderId} đã được cập nhật` });
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: 'Có lỗi xảy ra. Vui lòng thử lại.' });
        }
        this.isLoadingConfirmPickup = false;
    }

    confirmDeliverProduct = async (orderId) => {
        this.isLoadingConfirmDeliver = true;
        try {
            const result = await confirmDeliverProductToBuyerApi(orderId);
            if (result.responseCode.toString().startsWith('2')) {
                Global.success({ message: `Đơn hàng ${orderId} đã được cập nhật` });
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: 'Có lỗi xảy ra. Vui lòng thử lại.' });
        }
        this.isLoadingConfirmDeliver = false;
    }

    confirmCancelOrder = async (orderId, note) => {
        this.isLoadingConfirmCancel = true;
        try {
            const result = await confirmDeliveryFailureApi(orderId, note);
            if (result.responseCode.toString().startsWith('2')) {
                Global.success({ message: `Đơn hàng ${orderId} đã được cập nhật` });
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: 'Có lỗi xảy ra. Vui lòng thử lại.' });
        }
        this.isLoadingConfirmCancel = true;
    }

}
export const deliveryListStores = new DeliveryListStores();