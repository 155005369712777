
import React, { Component } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pushNotificationStores, SEGMENTED_TYPE, SPECIFIC_TYPE } from '../stores/PushNotificationStores';
import _ from 'lodash';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';
import Switch from '@material-ui/core/Switch';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

class Step2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            segments: [
                {
                    id: 0,
                    title: 'Từ khoá ẩn',
                },
                {
                    id: 1,
                    title: 'Đã đăng bán X sản phẩm',
                },
                {
                    id: 2,
                    title: 'Đã completed Y giao dịch mua'
                },
                {
                    id: 3,
                    title: 'Đã completed Z giao dịch bán'
                },
                {
                    id: 4,
                    title: 'Đã bán sản phẩm trong các Category A/B/C'
                },
                {
                    id: 5,
                    title: 'Vị trí địa lý: Quận - Thành phố'
                },
                {
                    id: 6,
                    title: 'User đã hoặc chưa verify cmnd'
                },
                {
                    id: 7,
                    title: 'User đã hoặc chưa verify sdt'
                },
                // {
                //     id: 8,
                //     title: 'User đã có cmnd'
                // },
                {
                    id: 9,
                    title: 'Đã like category'
                },
            ]
        }
        this.handleChange = this.handleChange.bind(this);
    };

    onChangeDate = date => {
        pushNotificationStores.scheduleDate = date;
    }

    componentDidMount() {
        pushNotificationStores.loadProductCategories();
        pushNotificationStores.loadProductAreaText();
    }

    search = _.debounce(function (searchKeywordUser) {
        pushNotificationStores.searchKeywordUser = searchKeywordUser;
        pushNotificationStores.searchUser();
    }, 400)


    handleChange(e) {
        this.search(e.target.value);
    }

    onInputChangeFunc = (event, newInputValue, index) => {
        if (!event) return;
        if (event.nativeEvent.type != 'blur') {
            var clone = mobx.toJS(pushNotificationStores.selectedSegments);
            clone[index].selectedValue.title = newInputValue;
            pushNotificationStores.setSelectedSegmentsWorker(clone);
        }
    }

    onChangeFunc = (e, obj, index) => {
        if (e && obj) {
            var clone = mobx.toJS(pushNotificationStores.selectedSegments);
            clone[index].segmentId = obj.id;
            clone[index].selectedValue = {
                id: obj.id,
                title: obj.title
            }
            pushNotificationStores.setSelectedSegmentsWorker(clone);
        }
    }

    renderSpecificType = () => {
        return (
            <div>
                <b>
                    Gửi tới toàn bộ Users trên hệ thống
                </b>
                <Switch
                    checked={pushNotificationStores.sendAll == 1 ? true : false}
                    onChange={(e, checked) => {
                        pushNotificationStores.sendAll = checked ? 1 : 2;
                    }}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                {
                    (pushNotificationStores.sendAll == 2)
                    &&
                    <Autocomplete
                        multiple
                        id="size-small-standard-multi"
                        size="small"
                        options={mobx.toJS(pushNotificationStores.searchResults)}
                        loading={this.state.loading}
                        getOptionLabel={option => option.fullname}
                        style={{ minWidth: '400px', maxWidth: '1400px' }}
                        onChange={(e, arr) => {
                            pushNotificationStores.selectedArray = arr;
                        }}
                        defaultValue={mobx.toJS(pushNotificationStores.selectedArray)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Tìm (Tên, email, số điện thoại)"
                                variant="outlined"
                                onChange={this.handleChange}
                                style={{ minWidth: '400px', maxWidth: '1400px' }}
                            />
                        )}
                    />

                }
            </div>
        )
    }

    renderSegmentedTextInputType = (segment, index) => {
        const input1 = segment.selectedValue.title || '';
        return (
            <div key={segment.segmentId} style={{ marginTop: '20px' }} className="row">
                <div className="col-md-4">
                    <Autocomplete
                        id="ac-text-input-type"
                        options={this.state.segments}
                        getOptionLabel={option => option.title}
                        style={{ width: '100%' }}
                        onChange={(e, obj) => this.onChangeFunc(e, obj, index)}
                        onInputChange={(e, newInputValue) => this.onInputChangeFunc(e, newInputValue, index)}
                        inputValue={input1}
                        renderInput={params => <TextField style={{ width: '100%' }}
                            {...params} label="Chọn..." variant="outlined" />
                        }
                    /></div>
                <div className="col-md-4">
                    <TextField style={{ width: '100%' }}
                        label="Điều kiện" variant="outlined"
                        onChange={(e) => {
                            const val = e.target.value || '';
                            var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                            clone[index].value = val;
                            pushNotificationStores.setSelectedSegmentsWorker(clone);
                        }}
                        value={segment.value || ''}
                    />
                </div>
                {
                    (pushNotificationStores.selectedSegments.length == 1 || index == pushNotificationStores.selectedSegments.length - 1)
                        ?
                        <div className="col-md-4"><button type="button" onClick={
                            () => {
                                var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                var maxNumber = clone[clone.length - 1].id + 1;
                                clone.push({
                                    id: maxNumber,
                                    segmentId: 1,
                                    value: '',
                                    displayValue: '',
                                    selectedValue: {
                                        id: 1,
                                        title: '',
                                    }
                                })
                                pushNotificationStores.setSelectedSegmentsWorker(clone);
                            }

                        } className="btn btn-info">AND</button>
                        </div>
                        :
                        <div className="col-md-4"><button type="button" onClick={
                            () => {
                                var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                clone.splice(index, 1)
                                pushNotificationStores.setSelectedSegmentsWorker(clone);
                            }

                        } className="btn btn-danger">DELETE</button>
                        </div>
                }

            </div>
        )
    }

    renderSegmentedComboBoxType = (segment, index, comboboxOptions, onChange, onInputChange, inputValue) => {
        const input1 = mobx.toJS(pushNotificationStores.selectedSegments)[index].selectedValue.title || '';
        return (
            <div key={segment.segmentId} style={{ marginTop: '20px' }} className="row">
                <div className="col-md-4">
                    <Autocomplete
                        id="ac-combobox-type1"
                        options={this.state.segments}
                        getOptionLabel={option => option.title}
                        style={{ width: '100%' }}
                        onChange={(e, obj) => this.onChangeFunc(e, obj, index)}
                        onInputChange={(e, newInputValue) => this.onInputChangeFunc(e, newInputValue, index)}
                        inputValue={input1}
                        renderInput={params => {
                            return (
                                <TextField style={{ width: '100%' }}
                                    {...params} label="Chọn..." variant="outlined" />
                            )
                        }}
                    /></div>
                <div className="col-md-4">
                    <Autocomplete
                        id="ac-combobox-type2"
                        options={comboboxOptions}
                        getOptionLabel={option => option.title}
                        style={{ width: '100%' }}
                        onChange={onChange}
                        onInputChange={onInputChange}
                        inputValue={`${inputValue || ''}`}
                        renderOption={params => {
                            if (comboboxOptions[index].image && comboboxOptions[index].image.length > 0) {
                                return (
                                    <div className="row">
                                        <div className="col-md-3">
                                            {
                                                <img src={params.image} style={{ width: '40px', height: '40px' }} />
                                            }
                                        </div>
                                        <div className="col-md-8 offset-1">
                                            <div>{params.title}</div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>{params.title}</div>
                                        </div>
                                    </div>
                                )
                            }

                        }}
                        renderInput={params => {
                            return (
                                <TextField style={{ width: '100%' }}
                                    {...params} label="Chọn..." variant="outlined" />
                            )
                        }}
                    />
                </div>
                {
                    (pushNotificationStores.selectedSegments.length == 1 || index == pushNotificationStores.selectedSegments.length - 1)
                        ?
                        <div className="col-md-4"><button type="button" onClick={
                            () => {
                                var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                var maxNumber = clone[clone.length - 1].id + 1;
                                clone.push({
                                    id: maxNumber,
                                    segmentId: 1,
                                    value: '',
                                    displayValue: '',
                                    selectedValue: {
                                        id: 1,
                                        title: 'Đã đăng bán X sản phẩm',
                                    }
                                })
                                pushNotificationStores.setSelectedSegmentsWorker(clone);
                            }

                        } className="btn btn-info">AND</button>
                        </div>
                        :
                        <div className="col-md-4"><button type="button" onClick={
                            () => {
                                var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                clone.splice(index, 1);
                                pushNotificationStores.setSelectedSegmentsWorker(clone);
                            }

                        } className="btn btn-danger">DELETE</button>
                        </div>
                }

            </div>
        )
    }

    renderSegmentedType = () => {
        const { selectedSegments } = pushNotificationStores;
        return (
            <div>
                <b>
                    Target user if...
                </b>
                <br />
                <br />

                {
                    mobx.toJS(selectedSegments || []).map((segment, index) => {
                        if (segment.segmentId == 0 ||
                            segment.segmentId == 1 ||
                            segment.segmentId == 2 ||
                            segment.segmentId == 3 ||
                            segment.segmentId == 8
                        ) {
                            return this.renderSegmentedTextInputType(segment, index);
                        } else if (
                            segment.segmentId == 4 ||
                            segment.segmentId == 9                            
                        ) {
                            return this.renderSegmentedComboBoxType(segment, index, mobx.toJS(pushNotificationStores.categories),
                                (event, arr) => {
                                    if (event && arr) {
                                        var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                        clone[index].value = arr.id;
                                        pushNotificationStores.setSelectedSegmentsWorker(clone);
                                    }
                                }
                                ,
                                (event, newInputValue) => {
                                    //On input change
                                    if (!event) return;
                                    var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                    clone[index].displayValue = newInputValue;
                                    pushNotificationStores.setSelectedSegmentsWorker(clone);
                                },
                                segment.displayValue
                            );
                        } else if (
                            segment.segmentId == 5
                        ) {
                            return this.renderSegmentedComboBoxType(segment, index, mobx.toJS(pushNotificationStores.areaTexts),
                                (event, arr) => {
                                    if (event && arr) {
                                        var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                        clone[index].value = arr.title || '';
                                        pushNotificationStores.setSelectedSegmentsWorker(clone);
                                    }
                                }
                                ,
                                (event, newInputValue) => {

                                },
                                segment.value
                            );
                        } else if (
                            segment.segmentId == 6
                        ) {
                            return this.renderSegmentedComboBoxType(segment, index, mobx.toJS(pushNotificationStores.idVerifies),
                                (event, arr) => {
                                    if (event && arr) {
                                        var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                        clone[index].value = arr.id;
                                        pushNotificationStores.setSelectedSegmentsWorker(clone);
                                    }
                                }
                                ,
                                (event, newInputValue) => {
                                    if (!event) return;
                                    var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                    clone[index].displayValue = newInputValue;
                                    pushNotificationStores.setSelectedSegmentsWorker(clone);
                                },
                                segment.displayValue
                            );
                        } else if (
                            segment.segmentId == 7
                        ) {
                            return this.renderSegmentedComboBoxType(segment, index, mobx.toJS(pushNotificationStores.phoneNumberVerifies),
                                (event, arr) => {
                                    if (event && arr) {
                                        var clone = mobx.toJS(pushNotificationStores.selectedSegments);
                                        clone[index].value = arr.id;
                                        pushNotificationStores.setSelectedSegmentsWorker(clone);
                                    }
                                }
                                ,
                                (event, newInputValue) => {

                                },
                                segment.value
                            );
                        }
                    })
                }
            </div>
        )
    }

    render() {
        return (
            <div>
                <button type="button" onClick={() => {
                    pushNotificationStores.type = SPECIFIC_TYPE
                }} className={pushNotificationStores.type == SPECIFIC_TYPE ? "btn btn-primary" : "btn btn-secondary"}>Specific</button>
                <button type="button" onClick={() => {
                    pushNotificationStores.type = SEGMENTED_TYPE
                }}
                    className={pushNotificationStores.type == SEGMENTED_TYPE ? "btn btn-primary" : "btn btn-secondary"}>Segment</button>
                <hr />
                <br />
                {
                    pushNotificationStores.type == SPECIFIC_TYPE
                        ?
                        this.renderSpecificType()
                        :
                        this.renderSegmentedType()
                }
                <br />
                <hr />
                <b>
                    Hẹn giờ gửi Notification
                </b>
                <Switch
                    disabled={pushNotificationStores.scheduledSwitchStatus}
                    checked={pushNotificationStores.setSchedule == 1 ? true : false}
                    onChange={(e, checked) => {
                        pushNotificationStores.setSchedule = checked ? 1 : 2;
                    }}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <br />
                {
                    pushNotificationStores.setSchedule == 1
                    &&
                    <DateTimePicker
                        format="dd/MMM/yyyy HH:mm"
                        onChange={this.onChangeDate}
                        minDate={new Date()}
                        value={pushNotificationStores.scheduleDate || ''}
                    />

                }
                <br />
                {
                    pushNotificationStores.setSchedule == 1 
                    &&
                    <b>
                        Gửi hàng ngày (vào cùng giờ cho tới hôm đó):
                    </b>
                }
                {
                    pushNotificationStores.setSchedule == 1 
                    &&
                    <Switch
                        disabled={pushNotificationStores.setSchedule != 1}
                        checked={pushNotificationStores.scheduleType == 2 ? true : false}
                        onChange={(e, checked) => {
                            pushNotificationStores.scheduleType = checked ? 2 : 1;
                        }}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />

                }


            </div>
        );
    }
}
export default observer(Step2);