import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { productReportStore } from '../stores/ProductReportStore';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import Dropdown from '../../components/Dropdown';
import moment from 'moment'
import {
    Link
} from "react-router-dom";

const PRODUCT_REPORT_STATUS = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    DONE: 'DONE'
}

const PRODUCT_REPORT_STATUS_LIST = [
    {
        id: PRODUCT_REPORT_STATUS.NEW,
        name: 'Chưa xử lý'
    },
    {
        id: PRODUCT_REPORT_STATUS.PROCESSING,
        name: 'Đang xử lý'
    },
    {
        id: PRODUCT_REPORT_STATUS.DONE,
        name: 'Đã xử lý'
    }
]
class ReportListTable extends Component {
    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: productReportStore.totalFeedbackCount,
            page: productReportStore.feedbackCurrentPage,
        };
        return paginationOption;
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img alt='' className='img' style={{ width: 80, height: 80 }} src={cell} />
        </div>
    }

    lockProduct = (cell, row) => {
        return <div className="td-actions text-center">
            {
                !row.statusFeedback ?
                    <span className="badge badge-pill badge-danger">Chưa trả lời</span>
                    :
                    <span className="badge badge-pill badge-success">Đã trả lời</span>
            }
        </div>
    }
    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('hh:mm A')}</p>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                    </div>
                }

            </div>
        );
    }
    detailFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <Link target="_blank" to={'/Portal/Products?pId=' + row.productId}> <button class="btn btn-primary" onClick={() => {
                }}>Chi tiết</button></Link>
            </div>
        );
    }

    detailFormatter = (cell, row) => {
        return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
            <Link className="btn btn-primary btn-simple" target="_blank" to={'/Portal/Products?pId=' + row.productId}>
                <i className="material-icons">info</i>
            </Link>
        </div>
    }
    fullnameFormarter = (cell, row) => {
        return <div className='text-center'>
            <span>
                <a href={`#${row.toUserId}`} onClick={() => this.props.onShowUserInfo(row.toUserId)}>{row.toUserFullname}</a>
            </span>
            <div className="td-actions text-center">
                <button onClick={this.onFeedbackIconPress(row.toUserId)} style={{ marginTop: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                    <i className="material-icons">feedback</i>
                </button>
            </div>
        </div>
    }
    onFeedbackIconPress = (userIdToFeedback) => () => {
        productReportStore.changeFeedbackModalStatusWorker(true);
        this.props.onFeedbackIconPress(userIdToFeedback);
    }

    fullnameFormarter2 = (cell, row) => {
        return <div className='text-center'>
            <span>
                <a href={`#${row.fromUserId}`} onClick={() => this.props.onShowUserInfo(row.fromUserId)}>{row.fromUserFullName}</a>
            </span>
            <div className="td-actions text-center">
                <button onClick={this.onFeedbackIconPress(row.fromUserId)} style={{ marginTop: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                    <i className="material-icons">feedback</i>
                </button>
            </div>
        </div>
    }

    onChangeStatus = (row) => (item) => {
        if (row.status == item.id) return;
        productReportStore.updateProductReportStatusWorker(row.id, item.id);
    }

    statusFormater = (cell, row) => {
        const buttonClassName = row.status == PRODUCT_REPORT_STATUS.DONE ? 'btn-success' : row.status == PRODUCT_REPORT_STATUS.PROCESSING ? 'btn-info' : 'btn-danger'
        return <div className="td-actions text-center">
            <Dropdown
                buttonClassName={buttonClassName}
                onItemClicked={this.onChangeStatus(row)}
                idSelected={row.status}
                datas={PRODUCT_REPORT_STATUS_LIST}
            />
        </div>
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'reportTypeName',
                text: 'Loại',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 100,
                    textAlign: 'left'
                }
            },
            {
                dataField: 'toUserFullname',
                text: 'Tên chủ sở hữu sản phẩm',
                headerFormatter: this.headerFormatter,
                formatter: this.fullnameFormarter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'message',
                text: 'Tin nhắn',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'fromUserFullName',
                text: 'Tên người báo cáo',
                headerFormatter: this.headerFormatter,
                formatter: this.fullnameFormarter2,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'productName',
                text: 'Tên sản phẩm',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'description',
                text: 'Mô tả sản phẩm',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'regularPrice',
                text: 'Giá gốc',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'price',
                text: 'Giá bán',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'thumbImage',
                text: 'Hình ảnh sản phẩm',
                headerFormatter: this.headerFormatter,
                formatter: this.thumbFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'createdAt',
                text: 'Thời gian',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'status',
                text: 'Trạng thái',
                formatter: this.statusFormater,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'productId',
                text: 'Chi tiết ',
                formatter: this.detailFormatter,
                headerStyle: {
                    width: 80,
                    textAlign: 'center'
                }
            },
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 60,
        textAlign: 'left'
    })

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        productReportStore.setPage(page);
        productReportStore.getData();
    }

    render() {
        const reports = toJS(productReportStore).reportlist;
        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={reports}
                columns={this.columnsOption()}
                loading={productReportStore.loading}
            />
        );
    }
}

export default observer(ReportListTable);