import { requestApi } from "../axios";
const prefix = 'product-report';

export function getProductReportList(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function updateProductReportStatusApi(id, status) {
    return requestApi(`${prefix}/update-product-report-status`, 'PUT', { id, status });
}

export function sendFeedbackProductReportApi(content, userIdToSend, metadata) {
    return requestApi(`${prefix}/send-feedback-product-report`, 'POST', {
        content, userIdToSend, metadata
    });
}