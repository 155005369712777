import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ReviewProductPage from './components/ReviewProduct';
import BiddingList from './components/BiddingList'
import { reviewProductStores } from './stores/ReviewProductStores';
import { biddingListStores } from './stores/BiddingListStores';
import { observer } from 'mobx-react';
import './css/styles.css'
import ProductList from './components/ProductList';
import { Global } from '../../GlobalClass';
import { ROUTER } from '../../common/route';
import { history } from '../../history';
import { productListStores } from './stores/ProductListStores';
import { AcceptButton, InformationButton } from '../components/Button/Button';
import CreateNewProductFromChoTotModal from './components/CreateNewProductFromChoTotModal/CreateNewProductFromChoTotModal';
import PushSale from './components/PushSale';

class ProductTabsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultActiveKeyTab: 'product-list',
            showModal: false,
            showPushSale: false
        }
        biddingListStores.getBiddingCount();
    };

    onSelectedTab = (key) => {
        this.setState({ defaultActiveKeyTab: key });
        if (key === 'review-product') Global.push(`${ROUTER.PRODUCT.REVIEW}`);
        else Global.push(`${ROUTER.PRODUCT.INDEX}`);
    }

    setActiveTab = (key) => {
        this.onSelectedTab(key);
    }

    pushSaleHandler = () => {
        this.setState({showPushSale: !this.state.showPushSale})
    }

    closeModal = () => this.setState({showModal: false});

    openModal = () => this.setState({showModal: true});

    render() {
        const { defaultActiveKeyTab } = this.state;
        const productListTitle = `Danh sách sản phẩm (${productListStores.totalProductListCount})`;
        const reviewProductTitle = `Mặt hàng chưa xem (${reviewProductStores.reviewProductTotalCount})`;
        const bidingTitle = `Danh sách đấu giá (${biddingListStores.biddingCount})`;
        return (
            <div style={{position: 'relative'}} >
            {
                this.state.showPushSale ? <PushSale /> :
                <Tabs
                    onSelect={this.onSelectedTab}
                    activeKey={defaultActiveKeyTab}
                    // defaultActiveKey={defaultActiveKeyTab}
                    transition={false}
                    id="product-tab-aladin">
                    <Tab eventKey="product-list" title={productListTitle}>
                        <ProductList {...this.props} setActiveTab={this.setActiveTab} />
                    </Tab>
                    <Tab eventKey="review-product" title={reviewProductTitle}>
                        <ReviewProductPage {...this.props} />
                    </Tab>
                    <Tab eventKey="bidding-list" title={bidingTitle}>
                        <BiddingList {...this.props} />
                    </Tab>
                </Tabs>
            }
            <div style={{position: 'absolute', right: 0, top: 0, display: 'flex', flexDirection: 'row'}}>
                <AcceptButton 
                    containerStyle={{ height: 'fit-content' }} 
                    onClick={this.pushSaleHandler} 
                    title={this.state.showPushSale ? 'Quản lí sản phẩm' : 'Sản phẩm kích bán'}
                />
                <AcceptButton 
                    containerStyle={{ height: 'fit-content' }} 
                    onClick={this.openModal} 
                    title='Tạo sản phẩm từ Chợ Tốt'
                />
            </div>
            <CreateNewProductFromChoTotModal
                closeModal={this.closeModal}
                isShowing={this.state.showModal}
            />
            </div>
        );
    }

    componentDidMount() {
        reviewProductStores.getRejectTypeListAction();
        if (history.location.pathname == ROUTER.PRODUCT.REVIEW) {
            this.setState({ defaultActiveKeyTab: 'review-product' });
        } else {
            this.setState({ defaultActiveKeyTab: 'product-list' });
        }
    }

}

export default observer(ProductTabsComponent);