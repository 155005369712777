import { requestApi } from "../axios";

export function createEvent(data) {
  return requestApi(`/new/createEvent`, 'POST', data);
}

export function editEvent(data) {
  return requestApi(`/new/updateEvent`, 'POST', data);
}

export function loadEvents() {
  return requestApi(`/new/loadEvents`, 'GET')
}
