import { extendObservable } from 'mobx';
import {
    getCommonStatisticApi,
    getDailyUserChart,
    getWeeklyUserChart,
    getMonthlyUserChart,
    getYearlyUserChart,
    getUserTypeChart,
    getTotalProductByCategoryChart,
    getDailyMakeAnOfferChart,

    getDailyTotalMoneySold,
    getDailyTotalSale,
    getDailyTotalSold
} from '../../../api/dashboard/dashboard';
import _ from 'lodash';

const initialState = {
    commonStatistic: {
        totalUser: 0,
        totalProduct: 0,
        totalProductSold: 0,
        totalNewRegisterUser: 0,
        totalVerifiedUser: 0,
        totalSellers: 0,
        dailyUserChart: {},
        weeklyUserChart: {},
        monthlyUserChart: {},
        yearlyUserChart: {},
        userTypeChart: {},
        totalProductByCategoryChart: {},
        dailyAskChart: {},
        dailyMakeAnOfferChart: {},
        dailyChatMessagesChart: {},
        dailyTotalMoneySoldChart: {},
        dailyTotalSaleChart: {},
        dailyTotalSoldChart: {},
    }
}
class DashboardStores {
    constructor() {
        const state = { ...initialState };
        extendObservable(this, state);
    }

    getStatisticData = async () => {
        const result = await getCommonStatisticApi();
        this.commonStatistic = result.data || initialState.commonStatistic;
    }

    getDailyUserChart = async () => {
        const result = await getDailyUserChart();
        const dataArray = result.dataArray || [];
        var xaxisCategories = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisCategories.push(element.name);
        }
        var xaxisData = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisData.push(element.totalUsers);
        }
        const dailyUserChart = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: xaxisCategories
                },
                fill: {
                    colors: ['#2ecc71']
                }
            },
            series: [
                {
                    name: "Registerd user",
                    data: xaxisData
                }
            ]
        }
        this.dailyUserChart = dailyUserChart;
    }

    getDailyTotalSale = async () => {

    }

    getWeeklyUserChart = async () => {
        const result = await getWeeklyUserChart();
        const dataArray = result.dataArray || [];

        var xaxisCategories = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisCategories.push(element.name);
        }
        var xaxisData = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisData.push(element.totalUsers);
        }
        const dailyUserChart = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: xaxisCategories
                },
                fill: {
                    colors: ['#3498db']
                }
            },
            series: [
                {
                    name: "Registerd user",
                    data: xaxisData
                }
            ]
        }
        this.weeklyUserChart = dailyUserChart;
    }

    getMonthlyUserChart = async () => {
        const result = await getMonthlyUserChart();
        const dataArray = result.dataArray || [];

        var xaxisCategories = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisCategories.push(element.name);
        }
        var xaxisData = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisData.push(element.totalUsers);
        }
        const dailyUserChart = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: xaxisCategories
                },
                fill: {
                    colors: ['#9b59b6']
                }
            },
            series: [
                {
                    name: "Registerd user",
                    data: xaxisData
                }
            ]
        }
        this.monthlyUserChart = dailyUserChart;
    }

    yearlyUserChart = async () => {
        const result = await getYearlyUserChart();
        const dataArray = result.dataArray || [];

        var xaxisCategories = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisCategories.push(element.name);
        }
        var xaxisData = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisData.push(element.totalUsers);
        }
        const dailyUserChart = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: xaxisCategories
                },
                fill: {
                    colors: ['#34495e']
                }
            },
            series: [
                {
                    name: "Registerd user",
                    data: xaxisData
                }
            ]
        }
        this.yearlyUserChart = dailyUserChart;
    }

    getTotalProductByCategoryChart = async () => {
        const result = await getTotalProductByCategoryChart();
        const dataArray = result.dataArray || [];
        var xaxisCategories = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisCategories.push(element.name);

        }
        var xaxisData = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisData.push(_.toNumber(element.count));
        }
        // const dailyUserChart = {
        //     options: {
        //         chart: {
        //             id: "basic-bar"
        //         },
        //         xaxis: {
        //             categories: xaxisCategories
        //         },
        //         // fill: {
        //         //     colors: ['#34495e']
        //         // },
        //         dataLabels: {
        //             enabled: true,
        //         }                  
        //     },
        //     series: [
        //         {
        //             name: "Số lượng",
        //             data: xaxisData
        //         }
        //     ]
        // }
        // console.log(dailyUserChart);


        const dailyUserChart = {
            options: {
                labels: xaxisCategories,
            },
            series: xaxisData
        }
        console.log(dailyUserChart);
        this.totalProductByCategoryChart = dailyUserChart;
    }

    getUserTypeChart = async () => {
        const result = await getUserTypeChart();
        const data = result.data || {};

        const dailyUserChart = {
            options: {
                labels: ['Facebook', 'Google', 'Email', 'Apple', 'Zalo']
            },
            series: [
                data.facebook ? _.toNumber(data.facebook) : 0,
                data.facebook ? _.toNumber(data.google) : 0,
                data.facebook ? _.toNumber(data.email) : 0,
                data.facebook ? _.toNumber(data.apple) : 0,
                data.facebook ? _.toNumber(data.zalo) : 0
            ]
        }
        this.userTypeChart = dailyUserChart;
    }

}
export const dashboardStores = new DashboardStores();