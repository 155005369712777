import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { AcceptButton, CancelButton } from '../../components/Button';
import { no_image } from 'src/common/images';
import { COLOR } from 'src/common/constant';
import { Global } from 'src/GlobalClass';
import PayloadForm from './PayloadForm';
import { uploadImageNoResize, AWS_IMAGE_BASE_URL, AWS_IMAGE_FOLDERS } from 'src/api/axios';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import promotionPopupStore from '../stores/PromotionPopupStore';

const ActionTypes = [
  { id: 1, name: 'Đăng bán 1 sản phẩm mới', action: 'POST_SCREEN' },
  { id: 2, name: 'Mở browser với link có sẵn', action: 'OPEN_URL' },
  { id: 3, name: 'Mở 1 topic trong Forum', action: 'FORUM_TOPIC' },
  { id: 4, name: 'Like fanpage Aladin', action: 'LIKE_ALADIN_FANPAGE' },
  { id: 5, name: 'Mở trang cá nhân', action: 'MY_PROFILE' },
  { id: 6, name: 'Tìm kiếm từ khoá', action: 'SEARCH_SCREEN' },
  { id: 7, name: 'Mã mời bạn bè', action: 'MY_PROFILE_INVITE_FRIEND' },
  { id: 8, name: 'Tìm kiếm người bán quanh bạn', action: 'MY_PROFILE_FIND_SELLER_NEAR_ME' },
  { id: 9, name: 'Đánh giá ứng dụng Aladin', action: 'ALADIN_RATE' },
  { id: 10, name: 'Nhận lượt đẩy tin', action: 'RECEIVE_BOOST' },
  { id: 11, name: 'Kết quả sự kiện Wish Product', action: 'WISH_RESULT' },
  { id: 12, name: 'Xem thông tin chi tiết Wallet sự kiện Wish Product', action: 'WISH_INFO' },
  { id: 13, name: 'Giới thiệu 1 sản phẩm đặc biệt', action: 'PRODUCT_DETAIL' },
  { id: 14, name: 'Mở danh sách kích bán', action: 'BUYING_REQUEST_LIST' },
  { id: 15, name: 'Mở danh sách sản phẩm của 1 user (merchant)', action: 'PUBLIC_PROFILE_PRODUCTS' },
  { id: 16, name: 'Mở browser bên trong app', action: 'OPEN_URL_INAPP' },
  { id: 17, name: 'Tạo Wish Product', action: 'WISH_INIT'},
  { id: 18, name: 'Di chuyển vào 1 tab trong Xóm', action: 'FORUM_TAB'},
  { id: 19, name: 'Mở danh sách đấu giá', action: 'BIDDING_LIST_SCREEN'},
  { id: 20, name: 'Mở sound leaderboard', action: 'SOUND_LEADERBOARD'},
  { id: 21, name: 'Tạo thiệp mừng', action: 'CREATE_GREETING_CARD'},
  { id: 22, name: 'Mời vào locale tag', action: 'LOCALE_TAG_INVITE'},
]

const EditPromotionPopupModal = ({
  isShow,
  promotionPopup,
}) => {
  const inputFileRef = useRef(null);
  const [promotionPopupButtonAction, setPromotionButtonAction] = useState(promotionPopup && promotionPopup.PromotionButton && promotionPopup.PromotionButton.payload.action || undefined);
  const [promotionPopupButtonTitle, setPromotionPopupButtonTitle] = useState(promotionPopup && promotionPopup.PromotionButton && promotionPopup.PromotionButton.title || undefined);
  const [promotionPopupButtonTitleColor, setPromotionPopupButtonTitleColor] = useState(promotionPopup && promotionPopup.PromotionButton && promotionPopup.PromotionButton.color || undefined);
  const [promotionPopupButtonBgColor, setPromotionPopupButtonBgColor] = useState(promotionPopup && promotionPopup.PromotionButton && promotionPopup.PromotionButton.bgColor || undefined);
  const [buttonPayload, setButtonPayload] = useState(promotionPopup && promotionPopup.PromotionButton && promotionPopup.PromotionButton.payload || undefined);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [imageUrl, setImageUrl] = useState(promotionPopup ? promotionPopup.background : undefined);
  const [promotionPopupName, setPromotionPopupName] = useState(promotionPopup ? promotionPopup.name : undefined);
  const [promotionBackgroundColor, setPromotionBackgroundColor] = useState(promotionPopup ? promotionPopup.bgColor : undefined);
  const [startDate, setStartDate] = useState(promotionPopup ? promotionPopup.startDate : undefined);
  const [endDate, setEndDate] = useState(promotionPopup ? promotionPopup.endDate : undefined);
  const [errLinkMessage, setErrLinkMessage] = useState();

  useEffect(() => {
    if (promotionPopup) {
      if (promotionPopup.PromotionButton) {
        setButtonPayload(promotionPopup.PromotionButton.payload);
        setPromotionButtonAction(promotionPopup.PromotionButton.payload && promotionPopup.PromotionButton.payload.action || undefined);
        setPromotionPopupButtonTitle(promotionPopup.PromotionButton.title);
        setPromotionPopupButtonTitleColor(promotionPopup.PromotionButton.color);
        setPromotionPopupButtonBgColor(promotionPopup.PromotionButton.bgColor);
      }
      setImageUrl(promotionPopup.background);
      setPromotionPopupName(promotionPopup.name);
      setPromotionBackgroundColor(promotionPopup.bgColor);
      setStartDate(promotionPopup.startDate);
      setEndDate(promotionPopup.endDate);
      return () => { resetState() };
    }
  }, [promotionPopup])

  useEffect(() => {
    if (!!buttonPayload && !!promotionPopupButtonTitle && !!promotionPopupButtonAction && !!imageUrl && !!startDate && !!endDate) {
      setDisabledSubmit(false);
      return;
    }
    if (!disabledSubmit) setDisabledSubmit(true);
  }, [buttonPayload, promotionPopupButtonTitle, promotionPopupButtonAction, imageUrl, startDate, endDate])

  const onPickImage = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  }

  const onPickFileSuccess = async (e) => {
    let file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) return Global.error({ message: 'Vui lòng chọn file định dạng ảnh !' });
    const formData = new FormData();
    formData.append("file", file);
    uploadImage(formData);
  }

  const uploadImage = async (form) => {
    try {
      const result = await uploadImageNoResize(form);
      if (result.data) {
        setImageUrl(result.data.imageName ? AWS_IMAGE_BASE_URL + AWS_IMAGE_FOLDERS.md + '/' + result.data.imageName : result.data.uploadedUrl);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  const handleSubmit = async () => {
    const { updatePromotionPopup, createPromotionPopup } = promotionPopupStore;
    if (promotionPopupButtonAction === 'OPEN_URL') {
      if (!validURL(buttonPayload.url)) {
        setErrLinkMessage('Link không hợp lệ');
        return;
      }
    }
    if (errLinkMessage) setErrLinkMessage(undefined);
    try {
      if (promotionPopup && promotionPopup.id) {
        await updatePromotionPopup(
          promotionPopup.id,
          {
            name: promotionPopupName,
            bgColor: promotionBackgroundColor,
            background: imageUrl,
            buttonTitle: promotionPopupButtonTitle,
            buttonTitleColor: promotionPopupButtonTitleColor,
            buttonBgColor: promotionPopupButtonBgColor,
            buttonPayload,
            startDate,
            endDate,
          })
      }
      else {
        await createPromotionPopup({
          name: promotionPopupName,
          bgColor: promotionBackgroundColor,
          background: imageUrl,
          buttonTitle: promotionPopupButtonTitle,
          buttonTitleColor: promotionPopupButtonTitleColor,
          buttonBgColor: promotionPopupButtonBgColor,
          buttonPayload,
          startDate,
          endDate,
        })
      }
    }
    catch (err) {
      Global.error({ messgae: 'Có lỗi xảy ra. Vui lòng thử lại' })
    }
  }

  const handleCancel = () => {
    onHideModal();
  }

  const resetState = () => {
    setPromotionPopupButtonTitle(undefined)
    setPromotionButtonAction(undefined)
    setButtonPayload(undefined)
    setStartDate(undefined)
    setEndDate(undefined)
    setImageUrl(undefined)
  }

  const onHideModal = () => {
    const { getPromotionPopupList, clearCurrentPromotionPopup, setShowModal } = promotionPopupStore;
    setShowModal(false);
    getPromotionPopupList();
    clearCurrentPromotionPopup();
  }


  const renderPayloadForm = () => {
    if (!!!buttonPayload) setButtonPayload({ action: promotionPopupButtonAction })
    switch (promotionPopupButtonAction) {
      case 'LIKE_ALADIN_FANPAGE':
      case 'POST_SCREEN':
      case 'MY_PROFILE':
      case 'MY_PROFILE_INVITE_FRIEND':
      case 'MY_PROFILE_FIND_SELLER_NEAR_ME':
      case 'ALADIN_RATE':
      case 'RECEIVE_BOOST':
      case 'WISH_RESULT':
      case 'WISH_INFO':
      // case 'WISH_INIT':
      case 'BUYING_REQUEST_LIST':
      case 'BIDDING_LIST_SCREEN':
      case 'SOUND_LEADERBOARD':
      case 'CREATE_GREETING_CARD':
      case 'LOCALE_TAG_INVITE':
        return null;
      case 'OPEN_URL':
      case 'FORUM_TOPIC':
      case 'FORUM_TAB':
      case 'PRODUCT_DETAIL':
      case 'SEARCH_SCREEN':
      case 'PUBLIC_PROFILE_PRODUCTS':
      case 'OPEN_URL_INAPP':
        return <PayloadForm promotionPopupButtonAction={promotionPopupButtonAction} payload={buttonPayload} setPayload={setButtonPayload} errorMsg={errLinkMessage} />
      default: return null;
    }
  }

  return <Modal
    show={isShow}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={onHideModal}
  >
    <Modal.Header closeButton >
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        Chỉnh sửa Promotion Popup
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 16 }}>
        <img alt='' style={{ height: 300, maxWidth: '30vw', objectFit: 'contain', marginBottom: 6, marginTop: 16, border: `solid 0.5px ${COLOR.tertiary_light}` }}
          src={imageUrl || no_image} />
        <div className='td-actions text-center' style={{ marginLeft: 6 }}>
          <button onClick={onPickImage} type="button" rel="tooltip" className="btn btn-warning btn-simple">
            <i className="material-icons">edit</i>
          </button>
        </div>
      </div>
      <br />
      <div style={{ height: 16 }} />

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Tên Promotion Popup</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setPromotionPopupName(e.target.value)} value={promotionPopupName} />
      </div>
      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Tên button</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setPromotionPopupButtonTitle(e.target.value)} value={promotionPopupButtonTitle} />
      </div>
      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Màu nền (Ex:#333333)</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setPromotionBackgroundColor(e.target.value)} value={promotionBackgroundColor} />
      </div>
      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Màu button title (Ex:#333333)</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setPromotionPopupButtonTitleColor(e.target.value)} value={promotionPopupButtonTitleColor} />
      </div>
      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Màu nền button (Ex:#333333)</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setPromotionPopupButtonBgColor(e.target.value)} value={promotionPopupButtonBgColor} />
      </div>
      <label
        style={{ marginRight: 16, width: '15%' }}
      >Chọn sự hành động:</label>
      <select name="event" value={promotionPopupButtonAction}
        onChange={e => {
          setButtonPayload(undefined);
          setPromotionButtonAction(e.target.value)
        }}
        style={{ padding: 5, width: '40%' }}
      >
        <option disabled value selected > -- Chọn một sự kiện -- </option>
        {
          ActionTypes.map(e => <option value={e.action} key={e.action} >{e.name}</option>)
        }
      </select>
      {renderPayloadForm()}
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }} >
        <div style={{ flex: 1 }} >
          <label style={{ marginRight: 16 }} >Ngày bắt đầu</label>
          <DatePicker
            showTimeSelect
            value={startDate ? moment(startDate).format("DD/MM/YYYY hh:mm A") : ''}
            adjustDateOnChange={false}
            className="my-date-picker"
            selected={startDate && Date.parse(startDate) || new Date()}
            startDate={startDate || new Date()}
            endDate={endDate || new Date()}
            dateFormat='dd/MM/yyyy'
            minDate={startDate && moment(startDate).isBefore() && startDate || new Date()}
            onChange={(date) => {
              if (!!date)
                setStartDate(date)
            }}
          />
        </div>
        <div>
          <label style={{ marginRight: 16 }} >Ngày kết thúc</label>
          <DatePicker
            showTimeSelect
            value={endDate ? moment(endDate).format("DD/MM/YYYY hh:mm A") : ''}
            adjustDateOnChange={false}
            className="my-date-picker"
            selected={endDate && Date.parse(endDate) || new Date()}
            startDate={startDate || new Date()}
            endDate={endDate || new Date()}
            dateFormat='dd/MM/yyyy'
            minDate={startDate || new Date()}
            onChange={(date) => {
              if (!!date)
                setEndDate(date)
            }}
          />
        </div>
      </div>

      <input hidden accept="image/*" onChange={onPickFileSuccess} ref={inputFileRef} type='file' id='img-banner' />
    </Modal.Body>
    <Modal.Footer>
      <CancelButton onClick={handleCancel} title='Huỷ' />
      <AcceptButton disabled={disabledSubmit} onClick={handleSubmit} title={promotionPopup ? 'Thay đổi' : 'Tạo mới'} />
    </Modal.Footer>
  </Modal>
}

export default EditPromotionPopupModal;