import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import { AcceptButton, CancelButton } from '../../../components/Button/Button';
import Loader from 'react-loader-spinner';
import { COLOR } from 'src/common/constant';
import { observer } from 'mobx-react';
// import { Carousel } from 'react-responsive-carousel';
import { InputGroup, FormControl, ListGroup } from 'react-bootstrap';
import RadioGroup from '../../../components/RadioGroup';
import { reviewVerifyIdStores } from '../../stores/ReviewVerifyIdStores';
import { Global } from '../../../../GlobalClass';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import moment from 'moment';

class ReviewVerifyIdModal extends Component {
    initialState = {
        reason: '',
        rejectTypeSelected: 0,
        showReasonInput: false,
        txtId: ''
    }
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.confirmAlert = false;

    };

    componentDidMount() {
        reviewVerifyIdStores.getVerifyIdListHistories();
    }

    onReasonTextChange = (e) => {
        const txt = e.target.value;
        if (txt.length > 254) return;
        this.setState({ reason: txt })
    }

    onHide = () => {
        this.props.onHide();
        this.setState(this.initialState)
    }

    onRejectVerifyId = () => {
        const { reason, rejectTypeSelected, txtId } = this.state;
        if (!reason) return Global.warning({ message: 'Vui lòng nhập lý do.' })
        this.confirmAlert = window.confirm('Xác nhận lại hành động.');
        if (this.confirmAlert) {
            const { reviewVerifyIdItemDetail, onRejectVerifyIdRequest } = reviewVerifyIdStores;
            const params = {
                userId: reviewVerifyIdItemDetail.id,
                rejectTypeId: rejectTypeSelected,
                rejectContent: reason,
                paperId: txtId
            };
            onRejectVerifyIdRequest(params);
            this.onHide();
        }
    }

    onApproveVerifyId = () => {
        const { reviewVerifyIdItemDetail, onApproveVerifyIdRequest } = reviewVerifyIdStores;
        const params = {
            userId: reviewVerifyIdItemDetail.id,
            paperId: this.state.txtId
        };
        onApproveVerifyIdRequest(params);
        this.onHide();
    }

    onReasonItemChange = ({ item, index }) => {
        if (index === reviewVerifyIdStores.verifyIdRejectTypeList.length - 1) {
            this.setState({ reason: '', showReasonInput: true, rejectTypeSelected: item.id });
        } else {
            this.setState({ rejectTypeSelected: item.id, reason: item.name, showReasonInput: false });
        }
    }

    onChangeText = (e) => {
        const txtId = e.target.value;
        this.setState({ txtId })
    }

    componentDidUpdate = async (prevProps, prevState) => {
        const { isShow, verifyUserId } = this.props;
        if (prevProps.isShow !== isShow && isShow) {
            await reviewVerifyIdStores.getVerifyIdItemDetail(verifyUserId);
            if (reviewVerifyIdStores.reviewVerifyIdItemDetail.paperId) this.setState({ txtId: reviewVerifyIdStores.reviewVerifyIdItemDetail.paperId });
        }
    };

    render() {
        const imgPrefix = 'https://aladin-today-bucket.s3.ap-southeast-1.amazonaws.com/ex-lg/';
        const { isShow } = this.props;
        const { reviewVerifyIdItemDetail, firstLoadingReviewVerifyIdDetail, verifyIdRejectTypeList, verifyIdHistoryList } = reviewVerifyIdStores;
        const { reason, rejectTypeSelected, showReasonInput, txtId } = this.state;
        return (
            <Modal
                show={isShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                animation
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                        {reviewVerifyIdItemDetail.fullname} - Cần xác minh loại giấy tờ: {reviewVerifyIdItemDetail.verifyDocumentType}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                    <Tabs
                        mountOnEnter
                        defaultActiveKey={"1"}
                        transition={false}
                        id="user-tab-aladin">
                        <Tab eventKey={"1"} title={"Thông tin"}>
                            {
                                firstLoadingReviewVerifyIdDetail ?
                                    <Loader
                                        type="TailSpin"
                                        color={COLOR.primary}
                                        height={30}
                                        width={30}
                                    />
                                    :
                                    <div style={{ marginTop: 16 }}>
                                        <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 16, alignItems: 'center' }}>
                                            <i className='material-icons'>description</i>
                                            <b style={{ marginLeft: 8 }}>Số giấy tờ: </b>
                                            <span style={{ flex: 1, paddingRight: 32 }}>
                                                <input
                                                    style={{ marginLeft: 16 }}
                                                    onChange={this.onChangeText}
                                                    type="text"
                                                    value={txtId}
                                                    className="form-control"
                                                    maxLength={16}
                                                    placeholder="Nhập vào số giấy tờ" />
                                            </span>
                                        </div>
                                        {
                                            reviewVerifyIdItemDetail.imgId &&
                                            <img style={{ height: 300, maxWidth: '30vw', objectFit: 'contain', marginBottom: 6, border: `solid 0.5px ${COLOR.tertiary_light}` }}
                                                src={reviewVerifyIdItemDetail.imgId || ''} />
                                        }
                                        {
                                            reviewVerifyIdItemDetail.verifySelfie &&
                                            <img style={{ height: 300, maxWidth: '30vw', objectFit: 'contain', marginBottom: 6, border: `solid 0.5px ${COLOR.tertiary_light}` }}
                                                src={imgPrefix + reviewVerifyIdItemDetail.verifySelfie || ''} />
                                        }
                                        {
                                            reviewVerifyIdItemDetail.verifyDocumentIdFront &&
                                            <img style={{ height: 300, maxWidth: '30vw', objectFit: 'contain', marginBottom: 6, border: `solid 0.5px ${COLOR.tertiary_light}` }}
                                                src={imgPrefix + reviewVerifyIdItemDetail.verifyDocumentIdFront || ''} />
                                        }
                                        {
                                            reviewVerifyIdItemDetail.verifyDocumentIdBack &&
                                            <img style={{ height: 300, maxWidth: '30vw', objectFit: 'contain', marginBottom: 6, border: `solid 0.5px ${COLOR.tertiary_light}` }}
                                                src={imgPrefix + reviewVerifyIdItemDetail.verifyDocumentIdBack || ''} />
                                        }

                                        <div style={{ flexDirection: 'row' }}>
                                            <div className="row">
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text style={{ alignItems: 'flex-start', justifyContent: 'left', minWidth: 150 }}>Lý do từ chối: </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <div className="col">
                                                        <RadioGroup
                                                            inputKey='review-verify-id-modal'
                                                            checkedId={rejectTypeSelected}
                                                            onItemClicked={this.onReasonItemChange}
                                                            datas={verifyIdRejectTypeList} />
                                                        {
                                                            showReasonInput &&
                                                            <FormControl isInvalid={true} isValid={false} value={reason} onChange={this.onReasonTextChange} style={{ minHeight: 80, borderBottom: 'solid 1px #fe805c' }} placeholder="Nhập lý do" plaintext as="textarea" />
                                                        }
                                                    </div>
                                                </InputGroup>
                                            </div>

                                        </div>
                                    </div>
                            }
                        </Tab>
                        <Tab eventKey={"2"} title={"Tiến Trình"}>
                            <br />
                            <ListGroup>
                                {
                                    verifyIdHistoryList.map((element) => {
                                        if (element.status === "REJECTED") {
                                            return (
                                                <ListGroup.Item variant="danger">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <b>{element.desc}</b>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {moment(element.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                                                        </div>
                                                    </div>

                                                </ListGroup.Item>
                                            );
                                        } else {
                                            return (
                                                <ListGroup.Item variant="primary">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <b>{element.desc}</b>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {moment(element.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                                                        </div>
                                                    </div>

                                                </ListGroup.Item>
                                            );
                                        }
                                    }
                                    )
                                }
                            </ListGroup>
                        </Tab>
                    </Tabs>


                </Modal.Body>

                {
                    reviewVerifyIdItemDetail.id &&
                    <Modal.Footer>
                        {/* <button className="btn btn-round">Close</button>
                             */}
                        <CancelButton onClick={this.onRejectVerifyId} title='Từ chối' />
                        <AcceptButton onClick={this.onApproveVerifyId} title='Phê duyệt' />
                    </Modal.Footer>
                }

            </Modal>
        );
    }
}

export default observer(ReviewVerifyIdModal)