import { requestApi } from "../axios";
const prefix = 'manage-advertising';

export function getAdvertisingListApi(offset) {
    return requestApi(`${prefix}/list?limit=10&offset=${offset}`, 'GET');
}

export function getAdvertisingTotalCountApi() {
    return requestApi(`${prefix}/total-count-advertising`, 'GET');
}

export function updateAdvertisingStatusApi(id, status) {
    const request = {
        id,
        status
    }
    return requestApi(`${prefix}/update-advertising-status`, 'POST', request);
}

export function getCategories(url) {
    return requestApi(`${prefix}/` + url, 'GET');
}
export function createOrUpdateAds(data) {
    return requestApi(`${prefix}/create-or-update-ads`, 'POST', data);
}

export function deleteAds(id) {
    return requestApi(`${prefix}/delete-ads?id=${id}`, 'GET');
}