import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { reportStore } from '../stores/ReportStore';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { user_avatar_default } from '../../../common/images';
import moment from 'moment';
import Dropdown from '../../components/Dropdown';

const REPORT_STATUS = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    DONE: 'DONE'
}

const REPORT_STATUS_LIST = [
    {
        id: REPORT_STATUS.NEW,
        name: 'Chưa xử lý'
    },
    {
        id: REPORT_STATUS.PROCESSING,
        name: 'Đang xử lý'
    },
    {
        id: REPORT_STATUS.DONE,
        name: 'Đã xử lý'
    }
]

class ReportListTable extends Component {
    onChangeStatus = (row) => (item) => {
        if (row.status == item.id) return;
        reportStore.updateStatusWorker(row.id, item.id);
    }

    onChangeNote = (row) => () => {
        reportStore.changeReportModalStatusWorker(true);
        this.props.onChangeNotePress(row.note || '', row.id);
    }

    onSendFeedback = (userIdToFeedback) => () => {
        reportStore.changeReportModalStatusWorker(true);
        this.props.onFeedbackIconPress(userIdToFeedback);
    }

    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: reportStore.totalReportCount,
            page: reportStore.reportCurrentPage,
        };
        return paginationOption;
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img alt='' className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar || user_avatar_default} />
        </div>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('hh:mm A')}</p>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                    </div>
                }

            </div>
        );
    }

    statusFormater = (cell, row) => {
        const buttonClassName = row.status == REPORT_STATUS.DONE ? 'btn-success' : row.status == REPORT_STATUS.PROCESSING ? 'btn-info' : 'btn-danger'
        return <div className="td-actions text-center">
            <Dropdown
                buttonClassName={buttonClassName}
                onItemClicked={this.onChangeStatus(row)}
                idSelected={row.status}
                datas={REPORT_STATUS_LIST}
            />
        </div>
    }


    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'reportTypeName',
                text: 'Loại',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'message',
                text: 'Tin nhắn',
                headerFormatter: this.headerFormatter,
                formatter: this.messageFormarter,
                headerStyle: {
                    width: 100
                }
            },
            {
                dataField: 'fromUserFullName',
                text: 'Người báo cáo',
                headerFormatter: this.headerFormatter,
                formatter: this.fullnameFormarter2,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'reportedUserName',
                text: 'Người bị báo cáo',
                headerFormatter: this.headerFormatter,
                formatter: this.fullnameFormarter1,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'createdAt',
                text: 'Thời gian',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'status',
                text: 'Trạng thái',
                formatter: this.statusFormater,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'note',
                text: 'Ghi chú',
                headerFormatter: this.headerFormatter,
                formatter: this.noteFormarter,
                headerStyle: {
                    width: 100,
                    textAlign: 'center'
                }
            },
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 60,
        textAlign: 'left'
    })

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => this.props.onItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">send</i>
            </button>
        </div >
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    fullnameFormarter1 = (cell, row) => {
        return <div className='text-center'>
            <span>
                <a href={`#${row.reportedUserId}`} onClick={() => this.props.onShowUserInfo(row.reportedUserId)}>{row.reportedUserName}</a>
            </span>
            <div className="td-actions text-center">
                <button onClick={this.onSendFeedback(row.reportedUserId)} style={{ marginTop: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                    <i className="material-icons">feedback</i>
                </button>
            </div>
        </div>
    }

    noteFormarter = (cell, row) => {
        return <div className="td-actions text-center">
            {!!row.note && <p style={{ maxHeight: 100, overflow: 'scroll' }}>{row.note}</p>}
            <button onClick={this.onChangeNote(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-warning btn-simple">
                <i className="material-icons">edit</i>
            </button>
        </div>
    }

    fullnameFormarter2 = (cell, row) => {
        return <div className='text-center'>
            <span>
                <a href={`#${row.fromUserId}`} onClick={() => this.props.onShowUserInfo(row.fromUserId)}>{row.fromUserFullName}</a>
            </span>
            <div className="td-actions text-center">
                <button onClick={this.onSendFeedback(row.fromUserId)} style={{ marginTop: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                    <i className="material-icons">feedback</i>
                </button>
            </div>
        </div>
    }

    messageFormarter = (cell, row) => {
        return <div style={{ maxHeight: 120, overflow: 'scroll' }}><p>{row.message}</p></div>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        reportStore.setPage(page);
        reportStore.getData();
    }

    render() {
        const reports = toJS(reportStore).reportlist;
        return (
            <div className='report-table'>
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    onTableChange={this.onTableChange}
                    datas={reports}
                    columns={this.columnsOption()}
                    loading={reportStore.loadingFeedbackList}
                />
            </div>
        );
    }
}

export default observer(ReportListTable);