import React, { Component } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { pushNotificationStores, SPECIFIC_TYPE } from '../stores/PushNotificationStores';
import BootstrapTable from 'react-bootstrap-table-next';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';
import moment from 'moment';

const columns = [{
    dataField: 'id',
    text: 'Id'
}, {
    dataField: 'name',
    text: 'Full name'
}, {
    dataField: 'phoneNumber',
    text: 'Phone number'
}, {
    dataField: 'email',
    text: 'Email'
}
];

class Step3 extends Component {

    render() {
        return (
            <div>
                <Form.Label>Tiêu đề push notification</Form.Label>
                <p>{pushNotificationStores.pushNotificationTitle}</p>
                <Form.Label>Nội dung push notìication</Form.Label>
                <p>{pushNotificationStores.pushNotificationContent}</p>
                {
                    pushNotificationStores.setSchedule == 1
                    &&
                    <div>
                        <Form.Label>Schedule At</Form.Label>
                        <p>{moment(mobx.toJS(pushNotificationStores.scheduleDate)).format('DD/MMM/YYYY HH:mm')}</p>
                    </div>
                }
                {
                    pushNotificationStores.type === SPECIFIC_TYPE
                        ?
                        <div>
                            <Form.Label>Danh sách users</Form.Label>
                            <BootstrapTable keyField='id' data={mobx.toJS(pushNotificationStores.selectedArray)} columns={columns} />
                        </div>
                        :
                        <div>
                            <Form.Label>Có <b> {pushNotificationStores.countUserMatcheSegmented} Users</b>  phù hợp điều kiện</Form.Label>

                        </div>

                }

            </div>
        );
    }
}

export default observer(Step3);