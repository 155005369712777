import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { reviewCoalitionStores } from '../stores/ReviewCoalitionStores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { user_avatar_default } from '../../../common/images';
import moment from 'moment'

class CoalitionReviewTable extends Component {


    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: reviewCoalitionStores.totalCount,
            page: reviewCoalitionStores.currentPage,
        };
        return paginationOption;
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img alt='' className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar || user_avatar_default} />
        </div>
    }

    imageFormatter = (cell, row) => {
        return (<div onClick={() => {
            reviewCoalitionStores.showImageModal = true;
        }} key={row.id} style={{ cursor: 'pointer' }} >
            <img alt='' className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.coalitionAvatar} />
        </div>)
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('hh:mm A')}</p>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                    </div>
                }

            </div>
        );
    }

    statusFormater = (cell, row) => {
        return <div className="td-actions text-center">
            {
                !row.statusFeedback ?
                    <span className="badge badge-pill badge-danger">Chưa trả lời</span>
                    :
                    <span className="badge badge-pill badge-success">Đã trả lời</span>
            }
        </div>
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'coalitionAvatar',
                text: 'Ảnh',
                headerFormatter: this.headerFormatter,
                formatter: this.imageFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'coalitionName',
                text: 'Tên LM',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 100,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'coalitionAbout',
                text: 'Mô tả',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 100,
                    textAlign: 'center'
                }
            },

            {
                dataField: 'locationName',
                text: 'Địa điểm',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            }
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
        textAlign: 'center'
    })

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={
                () => {
                    this.onItemClicked(row);
                }
            } style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">edit</i>
            </button>
        </div >
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    onItemClicked(row) {
        console.log('-----');
        console.log(row);
        console.log('-----');
        reviewCoalitionStores.showModal(row);
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        reviewCoalitionStores.setPage(page);
        reviewCoalitionStores.loadData();
    }

    render() {
        const data = toJS(reviewCoalitionStores).data;
        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={data}
                columns={this.columnsOption()}
                loading={reviewCoalitionStores.loading}
            />
        );
    }
}

export default observer(CoalitionReviewTable);