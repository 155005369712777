import { extendObservable } from 'mobx';
import { getLocalStorage, LOCAL_STORAGE_TYPE, clearLocalStorage } from '../common/localStorage';
import { Global } from '../GlobalClass';
class AppStores {
    constructor() {
        extendObservable(this, {
            appToken: null,
            userInfor: {},
            routerTitle: '',
            sideBarItems: []
        });
    }

    setToken = () => this.appToken = getLocalStorage(LOCAL_STORAGE_TYPE.USER_TOKEN);

    setUserInfo = (data) => this.userInfor = data || {};
    setRouterTitle = (str) => this.routerTitle = str;

    setSideBars = (datas) => this.sideBarItems = datas || [];

    logout = () => {
        this.appToken = null;
        this.userInfor = {};
        clearLocalStorage();
        Global.replaceRefresh('/Login');
    }

}
export const appStores = new AppStores();