import React, { useState } from 'react';
import CollectionProductTable from './CollectionProductTable';
import { specificCollectionStore } from '../store/SpecificCollectionStore';
import { useObserver } from "mobx-react";
import { toJS } from 'mobx';

const CollectionSearchProduct = () => {
  const [txtSearch, setTxtSearch] = useState('');

  const onChangeTextDebounce = async () => {
    specificCollectionStore.getTotalProductListCount();
    specificCollectionStore.setProductListPage(1);
    specificCollectionStore.getProductListStore();
  }

  const onChangeText = async (e) => {
    specificCollectionStore.setSearchText(e.target.value);
    onChangeTextDebounce();
}

  return useObserver(() => { 
    const { txtSearchProduct } = toJS(specificCollectionStore);
    return <>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className='material-icons'>search</i>
          </span>
        </div>
        <input
          style={{ marginBottom: 12 }}
          onChange={onChangeText}
          type="text"
          value={txtSearchProduct}
          className="form-control"
          placeholder="Tìm kiếm theo tên, email hoặc số điện thoại..." />
      </div>
      <CollectionProductTable isSearch={true} />
    </>
  })
}

export default CollectionSearchProduct;