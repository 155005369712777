import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { get, toJS } from 'mobx';
import moment from 'moment';
import _ from 'lodash'
import '../../css/styles.css';
import { orderListStores } from '../../stores/OrderListStores';
import PaginationTable from '../../../components/PaginationTable';
import { user_avatar_default } from '../../../../common/images';
import { formatCurrency } from 'src/helpers/CF';
import { Modal } from 'react-bootstrap';
import { CancelButton } from 'src/pages/components/Button';
import { formatter } from '../../../../helpers/formatter';

class OrderListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultNote: '',
            isShowResultNote: false,
        }
        this.onChangeTextDebounce = _.debounce(this.onChangeTextDebounce.bind(this), 600);
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={cell || user_avatar_default} />
        </div>
    }

    priceFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span>{formatCurrency(cell)}</span>
        );
    }

    statusFormatter = (cell, row) => {
        if (cell) {
            return `${cell.name} - ${cell.status}`;
        }
        return 'NONE';
    }

    onButtonResultClicked = (row) => {
        this.setState({isShowResultNote: true, resultNote: row.currentState && row.currentState.note || 'N/a'});
    }

    onCloseCancelNoteModal = () => {
        this.setState({isShowResultNote: false, resultNote: ''})
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }

    resultFormatter = (cell, row) => {
        if (cell === 'FAILURE') {
            return (<span className='resultNote' onClick={e => this.onButtonResultClicked(row)}>{cell}</span>);
        }
        return cell;
    }

    paymentMethodFormatter = (cell) => <><span>{cell?.type??'PAY_ALL'}</span><br/><span>{cell?.name??'Trả 100%'}</span></>

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'invoice.productId',
                text: 'Mã sản phẩm',
                formatter: formatter.productIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'invoice.productThumb',
                text: 'Hình ảnh',
                formatter: this.thumbFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.productName',
                text: 'Tên sản phẩm',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault,
            },
            {
                dataField: 'invoice.price',
                text: 'Giá (VNĐ)',
                headerFormatter: this.headerFormatter,
                formatter: this.priceFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.sellerId',
                text: 'Id người bán',
                formatter: formatter.userIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.buyerId',
                text: 'Id người mua',
                formatter: formatter.userIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.payByMethod',
                text: 'Phương thức thanh toán',
                formatter: this.paymentMethodFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentState.name',
                text: 'Tiến trình',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentState.status',
                text: 'Trạng thái',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentState.result',
                text: 'Kết quả',
                formatter: this.resultFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
        ];
    }

    fullnameFormarter = (cell, row) => {
        return <a href={`#${row.userId}`} onClick={() => this.props.onShowUserInfo(row.userId)}>{row.fullname}</a>
    }


    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        orderListStores.setOrderTablePage(page);
        this.getOrderList(toJS(orderListStores).txtSearchProduct);
    }

    getTotalSize = async () => {
        await orderListStores.getTotalOrderCount(orderListStores.productFilterSelected);
    }

    getOrderList = () => {
        orderListStores.getOrderListStore();
    }

    paginationOption = () => (
        {
            custom: true,
            hideSizePerPage: true,
            totalSize: toJS(orderListStores).totalOrderListCount,
            page: orderListStores.orderTableCurrentPage,
        }
    )

    async onChangeTextDebounce() {
        await this.getTotalSize();
        orderListStores.setOrderTablePage(1);
        this.getOrderList();
    }

    onChangeText = async (e) => {
        orderListStores.onSearchOrderChange(e.target.value);
        this.onChangeTextDebounce();
    }


    render() {
        const { orderList, getOrderListLoading, txtSearchOrder } = toJS(orderListStores);
        const { isShowResultNote, resultNote } = this.state;
        return (
            <div className='product-list'>
                <Modal show={isShowResultNote} onHide={this.onCloseCancelNoteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Lý do</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {resultNote}
                    </Modal.Body>
                    <Modal.Footer>
                        <CancelButton title='ĐÓNG' onClick={this.onCloseCancelNoteModal} />
                    </Modal.Footer>
                </Modal>
                {/* TODO: Phuc handle it after getting feedback from admin
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className='material-icons'>search</i>
                        </span>
                    </div>
                    <input
                        style={{ marginBottom: 12 }}
                        onChange={this.onChangeText}
                        type="text"
                        value={txtSearchOrder}
                        className="form-control"
                        placeholder="Tìm kiếm theo tên sản phẩm..." />
                </div> */}
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    expandRow = {{
                        renderer: row=> {'a'}
                    }}
                    onTableChange={this.onTableChange}
                    datas={orderList}
                    columns={this.columnsOption()}
                    loading={getOrderListLoading}
                    // tableRowEvents={
                    //     {
                    //         onClick: (e, row, rowIndex) => {
                    //             this.props.onProductItemClicked(row)
                    //         }
                    //     }
                    // }
                />
            </div>
        )
    }

    componentDidMount = async () => {
        await this.getTotalSize();
        this.getOrderList();
    };


}

export default observer(OrderListTable);