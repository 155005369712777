import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PaginationTable from '../../components/PaginationTable';
import moment from 'moment';
import EditEventCoverModal from './components/EditEventCoverModal';
import { AcceptButton } from '../../components/Button';
import { Global } from 'src/GlobalClass';
import { loadEvents } from 'src/api/events/events';
import { getProductCategories } from 'src/api/pushNotification/pushNotification';
import _ from 'lodash';

const EventCover = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [categories, setCategories] = useState([]);

  const hideModal = () => setIsShowModal(false);

  const fetchEvents = async () => {
    try {
      const result = await loadEvents();
      if (result.responseCode.toString().startsWith('2')) {
        setEvents(result.dataArray);
      }
      else Global.error({message: 'Có lỗi xảy ra. Vui lòng thử lại.'})
    }
    catch (err) {
      Global.error({message: 'Có lỗi xảy ra. Vui lòng thử lại.'})
    }
  }

  const fecthCategories = async () => {
    try {
      const result = await getProductCategories();
      if (result.responseCode.toString().startsWith('2')) {
        console.log(result);
        setCategories(result.dataArray);
      }
      else Global.error({message: 'Lấy thông tin category bị lỗi'})
    }
    catch (err) {
      Global.error({message: 'Lấy thông tin category bị lỗi'})
    }
  }

  useEffect(() => {fetchEvents(); fecthCategories()}, []);

  const paginationOption = useMemo(() => {
    const paginationOption = {
      custom: true,
      hideSizePerPage: true,
      totalSize: 0,
      page: 0,
    };
    return paginationOption;
  }, []);

  const headerStyleDefault = useCallback(() => ({
    width: 100,
  }), []);

  const headerFormatter = useCallback((column, colIndex) => {
    return <h5><strong>{column.text}</strong></h5>
  }, []);

  const actionButtons = useCallback((cell, row) => {
    return <div className="td-actions text-center">
      {/* <button onClick={() => console.log('')} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-danger">
        <i className="material-icons">delete</i>
      </button> */}
      <button onClick={() => {
        setSelectedEvent(row);
        setIsShowModal(true);        
      }} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
        <i className="material-icons">edit</i>
      </button>
    </div>
  }, []);

  const thumbFormatter = useCallback((cell, row) => {
    return <div key={row.id} className="card-avatar">
      <img className='img' style={{ width: 160, height: 80, objectFit: 'cover', borderRadius: 20 }} src={row.cover} />
    </div>
  }, []);

  const descFormatter = useCallback((cell, row) => {
    return <div>
      
    </div>
  }, [])

  const dateFormatter = useCallback((cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span>{moment(cell).format('DD/MM/YYYY')}</span>
      </div>
    );
  }, []);

  const setLabelForCategory = (cateId) => {
    const cat = _.find(categories, {id: cateId});
    if (cat) return cat.categoryName;
    else return 'Newsfeed';
  }

  const cateFormarter = (cell, row) => {
    return <div key={row.id} >
      <span>{setLabelForCategory(cell)}</span>
    </div>
  }

  const columnsOption = () => {
    return [
      {
        dataField: 'id',
        text: '#',
        headerFormatter: headerFormatter,
        headerStyle: {
          width: 60
        }
      },
      {
        dataField: 'cover',
        text: 'Image',
        formatter: thumbFormatter,
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'name',
        text: 'Tên sự kiện',
        headerStyle: headerStyleDefault,
        headerFormatter: headerFormatter,
      },
      {
        dataField: 'cateId',
        text: 'Hiển thị ở',
        headerStyle: headerStyleDefault,
        formatter: cateFormarter,
        headerFormatter: headerFormatter,
      },
      {
        dataField: 'startDate',
        text: 'Ngày bắt đầu',
        headerFormatter: headerFormatter,
        formatter: dateFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'endDate',
        text: 'Ngày kết thúc',
        headerFormatter: headerFormatter,
        formatter: dateFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'action',
        text: 'Hành động',
        formatter: actionButtons,
        headerFormatter: headerFormatter,
        headerStyle: { width: 80 }
      }
    ];
  }

  return <>
    <div className='row justify-content-end' style={{ margin: 0, flex: 1, paddingRight: 16 }}>
      <AcceptButton containerStyle={{ height: 'fit-content' }} onClick={() => setIsShowModal(true)} title='Tạo mới' />
    </div>
    <PaginationTable
      paginationOptions={paginationOption}
      // onTableChange={onTableChange}
      datas={events}
      columns={columnsOption()}
      loading={false}
    />
    <EditEventCoverModal 
      isShow={isShowModal}
      onHide={hideModal}
      event={selectedEvent}
      reload={fetchEvents}
      reset={() => setSelectedEvent(undefined)}
      categories={categories}
    />
  </>
}

export default EventCover;