import React, { useContext, useState } from 'react';
import AddedModsContext from '../context/addedModsContext';
import { getUserListApi, getTotalUserCountApi } from '../../../api/user/user-list';
import { pageableOffset } from '../../../helpers/CF';
import ModListTable from './ModListTable';
import { Global } from '../../../GlobalClass';
import { removeMod, addMod } from '../../../api/mods/mods';

const NewModListTable = () => {
  const {setAddedMods, setRefreshAddedMods} = useContext(AddedModsContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [totalUserListCount, setTotalUserListCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchUserList = async (search) => {
    try {
      getTotalUserCount(search || '');
      const offset = pageableOffset(currentPage);
      const result = await getUserListApi(offset, search || '');
      if (result.responseCode.toString().startsWith('2')) {
        setUserList(result.dataArray || [])
        if (search && search.length > 0) {
          setTotalUserListCount(result.data.count);
        }
      } else {
        Global.error({ message: result.responseText || '' });
      }
      setLoading(false);
    } 
    catch (error) {
      Global.error({ message: error.message || '' });
      setLoading(false);
    }
  }

  const getTotalUserCount = async (search) => {
    const result = await getTotalUserCountApi(search || '');
    if (result.responseCode.toString().startsWith('2')) {
      setTotalUserListCount(parseInt(result.data.total))
    } else {
      Global.error({ message: result.responseText || '' });
    }
  }

  const setCurrentPageHandler = (currentPage) => setCurrentPage(currentPage);

  const addModHandler = async (id) => {
    try {
      const result = await addMod(id);
      if (result.responseCode.toString().startsWith('2')) {
        setRefreshAddedMods(true);
        Global.success({ message: result.responseText || '' })
      } else {
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (err) {
      Global.error({ message: err.message || '' });
    }
  }

  const removeModHandler = async (id) => {
    try {
      const result = await removeMod(id);
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({ message: result.responseText || '' })
        setRefreshAddedMods(true);
      } else {
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (err) {
      Global.error({ message: err.message || '' });
    }
  }

  return <ModListTable 
    fetchModList={fetchUserList}
    modList={userList}
    loadFor='USER_LIST'
    setCurrentPage={setCurrentPageHandler}
    totalSize={totalUserListCount}
    currentPage={currentPage}
    addMod={addModHandler}
    removeMod={removeModHandler}
  />
}

export default NewModListTable;