import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormTypes } from '../../../../common/constant';
import InputTextNotificationForm from './InputTextNotificationForm'
import { pushNotificationStores } from '../../stores/PushNotificationStores';

class AdvertisingForm extends Component {
    constructor(props) {
        super(props);
    };

    render() {
        const { payloadSchema } = pushNotificationStores.notificationTypeSelected;
        return (
            <div>
                {
                    Object.keys(payloadSchema || {}).map((key, index) => (
                        <div key={index}>
                            {this.switchToRenderView(key, payloadSchema[key])}
                        </div>
                    ))
                }
            </div>
        );
    }

    switchToRenderView = (key, item) => {
        switch (item.type) {
            // case FormTypes.COMBOBOX:
            //     return this.renderCombobox(key, item);
            case FormTypes.TEXT_INPUT:
                return this.renderTextInput(key, item);
            default:
                return;
        }
    }

    // renderCombobox = (key, item) => {
    //     return (
    //         <ComboboxADSForm
    //             itemKey={key}
    //             item={item} />
    //     )
    // }
    renderTextInput = (key, item) => {
        return (
            <InputTextNotificationForm
                itemKey={key}
                item={item} />
        )
    }
}


export default observer(AdvertisingForm);