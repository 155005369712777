import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { toJS } from 'mobx';
import { reviewProductStores } from '../../stores/ReviewProductStores';
import moment from 'moment';
import { formatCurrency } from '../../../../helpers/CF';
import PaginationTable from '../../../components/PaginationTable';

class ProductReviewTable extends Component {

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="img-container">
            <img alt='' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.thumbImage} />
        </div>
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => this.props.onItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">edit</i>
            </button>
            <button onClick={(event) => {
                reviewProductStores.onApproveProduct(row.id)
                event.stopPropagation();
            }} type="button" rel="tooltip" className="btn btn-success btn-simple">
                <i className="material-icons">check</i>
            </button>
        </div >
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('HH:mm')}</span><br />
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }
    priceFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span>{formatCurrency(cell)}</span>
        );
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
        textAlign: 'center'
    })

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'productName',
                text: 'Tên',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'price',
                text: 'Giá (VNĐ)',
                headerFormatter: this.headerFormatter,
                formatter: this.priceFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'description',
                text: 'Mô tả',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 150
                }
            },
            {
                dataField: 'fullname',
                text: 'Người bán',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 120
                }
            },
            {
                dataField: 'areaText',
                text: 'Địa chỉ',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 200
                }
            },
            {
                dataField: 'conditionDesc',
                text: 'Tình trạng',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'categoryName',
                text: 'Phân loại',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'createdAt',
                text: 'Thời gian đăng',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'updatedAt',
                text: 'Thời gian cập nhật',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'thumbImage',
                text: 'Hình ảnh',
                formatter: this.thumbFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            }
        ];
    }

    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: reviewProductStores.reviewProductTotalCount,
            page: reviewProductStores.currentReviewProductPage,
        };
        return paginationOption;
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        reviewProductStores.setReviewProductPage(page);
        reviewProductStores.getReviewProductsAction();
    }


    render() {
        const products = toJS(reviewProductStores).reviewProducts;
        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={products}
                columns={this.columnsOption()}
                loading={reviewProductStores.loadingReviewProducts}
                tableRowEvents={
                    {
                        onClick: (e, row, rowIndex) => {
                            this.props.onItemClicked(row)
                        }
                    }
                }
            />
        );
    }

    componentDidMount = () => {
    };


}

export default observer(ProductReviewTable);