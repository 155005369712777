import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import moment from 'moment';

const FeedbackHistoryItem = ({
    feedbackItem
}) => {
    const replied = !!feedbackItem.responseFeedbackContent;
    return (
        <ListGroup.Item className='list-group-feedbackItem' style={{backgroundColor: 'white'}} >
            <blockquote className="blockquote">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <small
                        className='mb-0'>
                        {feedbackItem.feedbackContent}
                    </small>
                    <footer style={{ marginLeft: 8 }} className="blockquote-footer">
                        <cite title="Source Title">
                            <small>{moment(feedbackItem.createdAt).format('HH:mm A DD-MM-YYYY')}</small>
                        </cite>
                    </footer>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
                    {replied && <i className="material-icons">reply</i>}
                    <small
                        className='mb-0'>
                        <i style={{ color: !replied ? 'red' : undefined, marginLeft: replied ? 8 : 0 }}>{feedbackItem.responseFeedbackContent || 'Chưa phản hồi'}</i>
                    </small>
                </div>
            </blockquote>
        </ListGroup.Item>
    )
}

export default FeedbackHistoryItem;
