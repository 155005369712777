import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { introImagesStores } from '../stores/IntroImagesStores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { user_avatar_default } from '../../../common/images';
import moment from 'moment'
import _ from 'lodash';
import Switch from "react-switch";


class IntroImagesTable extends Component {
    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            page: introImagesStores.currentPage,
        };
        return paginationOption;
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'url',
                text: 'Ảnh',
                formatter: this.imageFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 100,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'orderIndex',
                text: 'Thứ tự hiển thị',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 70,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'fullname',
                text: 'Người tạo',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 170
                }
            },
            {
                dataField: 'createdAt',
                text: 'Ngày tạo',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: {
                    width: 100
                }
            },
            {
                dataField: 'active',
                text: 'Active',
                headerFormatter: this.headerFormatter,
                formatter: this.activeFormatter,
                headerStyle: {
                    width: 50
                }
            },
            {
                dataField: '',
                text: 'Action',
                headerFormatter: this.headerFormatter,
                formatter: this.actionButtons,
                headerStyle: {
                    width: 80
                }
            },
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
        textAlign: 'center'
    })

    imageFormatter = (cell, row) => {
        return <div className="td-actions text-center">
            <img src={cell} width={50} height={50} />
        </div >
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => introImagesStores.onItemClicked(row)} style={{ marginRight: 6 }}
                type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">send</i>
            </button>
        </div >
    }

    dateFormatter = (cell, row) => {
        return <div className="td-actions text-center">
            {moment(cell).format('DD/MM/YYYY hh:mm:ss')}
        </div >
    }

    activeFormatter = (cell, row) => {
        return (
            <div className="td-actions text-center">
                <Switch
                    onChange={(checked) => {
                        introImagesStores.updateActive(checked, row.id);
                    }}
                    checked={!!cell} />

            </div>
        )
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        introImagesStores.setPage(page);
        introImagesStores.getData();
    }

    render() {
        const data = toJS(introImagesStores).data;
        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={data}
                columns={this.columnsOption()}
                loading={introImagesStores.loading}
            />
        );
    }
}

export default observer(IntroImagesTable);