import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { threadListStores } from '../stores/ThreadListStores';
import { AcceptButton } from '../../components/Button/Button';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { usePrevious } from 'src/helpers/hooksApi'
import Loader from 'react-loader-spinner';
import { COLOR } from 'src/common/constant';
import { FormControl } from 'react-bootstrap';
import Dropdown from 'src/pages/components/Dropdown/Dropdown';
import { Global } from 'src/GlobalClass';
import { uploadImageNoResize, AWS_IMAGE_FOLDERS, AWS_IMAGE_BASE_URL } from 'src/api/axios';

const InputField = ({
    title,
    value,
    onChangeText,
    placeholder,
    style,
    maxLength,
    multiLine,
    textStyle
}) => {
    return (
        <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 16, alignItems: 'center', ...(style || {}) }}>
            <b style={{ marginLeft: 16, minWidth: 80 }}>{title}</b>
            <span style={{ flex: 1, paddingRight: 32 }}>
                {
                    multiLine ?
                        <FormControl isInvalid={true} isValid={false}
                            value={value || ''}
                            onChange={onChangeText}
                            style={{ borderBottom: 'solid 1px #fe805c', marginLeft: 16, minHeight: 40 }}
                            placeholder={placeholder}
                            plaintext
                            maxLength={maxLength}
                            as="textarea" />
                        :
                        <input
                            style={{ marginLeft: 16, ...(textStyle || {}) }}
                            onChange={onChangeText}
                            type="text"
                            value={value || ''}
                            className="form-control"
                            maxLength={maxLength}
                            multiple
                            placeholder={placeholder} />
                }
            </span>
        </div>
    )
}


const ThreadDetailModal = ({
    category,
    pinned,
    searchKeyword
}) => {
    const {
        threadDetailInfo,
        threadDetailModalStatus,
        setThreadDetailModalStatus,
        setThreadDetailInfo,
        getThreadDetailLoading,
        threadCategories,
        createThreadWorker,
        setThreadTablePage,
        getThreadList,
        rightButtonModalLoading,
        updateThreadWorker
    } = toJS(threadListStores);
    const [title, setTitle] = useState(threadDetailInfo.title || '');
    const [description, setDescription] = useState(threadDetailInfo.description || '');
    const [categoryId, setCategoryId] = useState(threadDetailInfo.categoryId || 0);
    const [uploadedImages, setUploadedImages] = useState(threadDetailInfo.imageNames || []);
    const prevThreadDetailInfo = usePrevious(threadDetailInfo);
    const prevGetThreadDetailLoading = usePrevious(getThreadDetailLoading);
    const inputFileRef = useRef(null);
    useEffect(() => {
        if (
            threadDetailInfo &&
            threadDetailInfo.id &&
            (
                (prevGetThreadDetailLoading != getThreadDetailLoading && getThreadDetailLoading) ||
                ((!prevThreadDetailInfo || !prevThreadDetailInfo.id) && prevThreadDetailInfo != threadDetailInfo)
            )
        ) {
            setTitle(threadDetailInfo.title);
            setDescription(threadDetailInfo.description);
            setCategoryId(threadDetailInfo.categoryId);
            setUploadedImages(threadDetailInfo.imageNames);
        }
    }, [threadDetailInfo, getThreadDetailLoading, prevThreadDetailInfo, prevGetThreadDetailLoading]);
    
    const resetFormData = useCallback(() => {
        setTitle('');
        setDescription('');
        setCategoryId(0);
    }, [setTitle, setDescription, setCategoryId]);

    const onHide = useCallback(() => {
        setThreadDetailInfo();
        setThreadDetailModalStatus(false);
        setTimeout(() => {
            resetFormData();
        }, 500);
    }, []);

    const onPickImage = () => {
        if (inputFileRef.current) inputFileRef.current.click();
    }

    const removeImage = useCallback((image) => {
        setUploadedImages(imgs => {
            return imgs.filter(i => i !== image)
        });
    }, [])

    const uploadImage = async (form) => {
        const result = await uploadImageNoResize(form);
        if (result.data && result.data.imageName) {
            const newUpalodedImages = [...uploadedImages];
            newUpalodedImages.push(result.data.imageName )
            setUploadedImages(newUpalodedImages);
        }
    }

    const onPickFileSuccess = async (e) => {
        let file = e.target.files[0];
        if (!file) return;
        if (!file.type.includes('image')) return Global.error({ message: 'Vui lòng chọn file định dạng ảnh !' });
        const formData = new FormData();
        formData.append("file", file);
        uploadImage(formData);
    }

    

    // const onCloseModal = useCallback(() => setThreadDetailModalStatus(false), []);

    const onRightButtonClick = useCallback(async () => {
        if (threadDetailInfo && threadDetailInfo.id) { // update
            if (!title || !description | !categoryId) return Global.warning({ message: 'Vui lòng nhập đủ thông tin.' });
            await updateThreadWorker({ title, description, categoryId, forumId: threadDetailInfo.id, imageNames: uploadedImages });
            setThreadDetailInfo();
            resetFormData();
            getThreadList(category, searchKeyword, pinned);
        } else { // create
            if (!title || !description | !categoryId) return Global.warning({ message: 'Vui lòng nhập đủ thông tin.' });
            await createThreadWorker({ title, description, categoryId, imageNames: uploadedImages });
            setThreadTablePage(1);
            getThreadList(category, searchKeyword, pinned);
            resetFormData();
        }
        setThreadDetailModalStatus(false);
    }, [threadDetailInfo, title, description, categoryId]);

    const onChangeTitle = useCallback((e) => setTitle(e.target.value), []);
    const onChangeDesc = useCallback((e) => setDescription(e.target.value), []);

    const onItemActivityClicked = useCallback((item) => {
        setCategoryId(item.id)
    }, []);

    const threadCategoriesFilter = useMemo(() => {
        return threadCategories.filter(o => !!o.id);
    }, [threadCategories]);

    const rightButtonTitle = useMemo(() => {
        if (threadDetailInfo && threadDetailInfo.id) return 'Cập nhật';
        return 'Tạo mới';
    }, [threadDetailInfo]);


    return (
        <Modal
            className='thread-detail-modal'
            show={threadDetailModalStatus}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            animation
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                    {threadDetailInfo.title || 'Tạo mới bài viết'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                <div style={{ paddingTop: 12 }}>
                    {
                        !!getThreadDetailLoading ?
                            <Loader
                                type="TailSpin"
                                color={COLOR.primary}
                                height={30}
                                width={30}
                            />
                            :
                            <div>
                                <InputField
                                    textStyle={{ fontWeight: 'bold' }}
                                    title='Tiêu đề'
                                    value={title}
                                    onChangeText={onChangeTitle}
                                    placeholder='Nhập tiêu đề'
                                    maxLength={100}
                                />
                                <InputField
                                    multiLine
                                    title='Mô tả'
                                    value={description}
                                    onChangeText={onChangeDesc}
                                    placeholder='Nhập mô tả'
                                    maxLength={16384}
                                />

                                <div className="row text-center">
                                    <b style={{ marginLeft: 24, minWidth: 80, textAlign: 'center' }}>Hình ảnh: </b>
                                    <div className="row" style={{marginLeft: 12}} >
                                        {uploadedImages && uploadedImages.length > 0 && uploadedImages.map((image) => {
                                            const imageUrl = AWS_IMAGE_BASE_URL + AWS_IMAGE_FOLDERS.lg + '/' + image;
                                            return <div style={{position: 'relative', cursor: 'pointer'}} >
                                                <img alt='' src={imageUrl} style={{width: 60, height: 60, marginRight: 5}}  
                                                    onClick={() => 
                                                        window.open(imageUrl, '_blank')
                                                    }
                                                />
                                                <div style={{
                                                    position: 'absolute', 
                                                    right: 5, 
                                                    top: 5, 
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                                                    cursor: 'not-allowed',
                                                    width: 15,
                                                    height: 15,
                                                    borderRadius: 7.5,
                                                    padding: 0
                                                }}
                                                onClick={() => { removeImage(image) }}
                                                >
                                                    <i className="material-icons" style={{fontSize: 10, position: 'absolute', right: 2.5, top: 2.5, color: 'white'}}>clear</i>
                                                </div>
                                            </div>
                                        })}
                                        <div style={{display: 'table', cursor: 'copy'}} onClick={onPickImage} >
                                            <div style={{
                                                width: 60, 
                                                height: 60, 
                                                backgroundColor: 'rgba(0, 0, 0, 0.09)', 
                                                justifyContent: 'center',
                                                display: 'table-cell',
                                                verticalAlign: 'middle'
                                            }} ><i className="material-icons" style={{cursor: 'copy'}} >add</i></div>
                                        </div>
                                    </div> 
                                </div>

                                <div className="row text-center">
                                    <b style={{ marginLeft: 24, minWidth: 80, textAlign: 'center' }}>Phân loại: </b>
                                    <Dropdown
                                        containerStyle={{ marginLeft: 12, }}
                                        buttonStyle={{ padding: '6px 20px' }}
                                        onItemClicked={onItemActivityClicked}
                                        idSelected={categoryId}
                                        datas={threadCategoriesFilter}
                                    />
                                </div>

                                <input hidden accept="image/*" 
                                onChange={onPickFileSuccess} 
                                ref={inputFileRef} type='file' id='img-banner' />
                            </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <CancelButton onClick={onCloseModal} title='Đóng' /> */}
                <AcceptButton disabled={rightButtonModalLoading} onClick={onRightButtonClick} title={rightButtonTitle} />
            </Modal.Footer>
        </Modal>
    );
}

export default observer(ThreadDetailModal);
