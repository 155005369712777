import axios from 'axios';
import { LOCAL_STORAGE_TYPE } from '../common/localStorage';
import { Global } from '../GlobalClass';
const { REACT_APP_BASE_URL, REACT_APP_API_TIMEOUT } = process.env;

// https://api.prod.aladin.today/admin

const axiosInstance = axios.create({
    baseURL: REACT_APP_BASE_URL,
    timeout: REACT_APP_API_TIMEOUT,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': "*"
    },
});

const axiosInstanceUpload = axios.create({
    baseURL: REACT_APP_BASE_URL,
    // baseURL: 'https://dev.aladin.today/admin/',
    timeout: REACT_APP_API_TIMEOUT,
    headers: {
        'Access-Control-Allow-Origin': "*"
    },
});

// images
export const AWS_IMAGE_FOLDERS = {
    ex_sm: 'ex-sm',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    ex_lg: 'ex-lg'
};

export const AWS_IMAGE_BASE_URL = 'https://aladin-today-bucket.s3.ap-southeast-1.amazonaws.com/';

const handlingErrors = (error) => {
    let message;
    let status;
    let code;
    let data;
    try {
        if (error.response) {
            if (error.response.data && typeof (error.response.data) === 'object') {
                message = error.response.data.responseText || error.response.data.name || error.response.statusText;
                status = error.response.status;
                code = error.response.data.responseCode;
                data = error.response.data.data;
            } else {
                message = error.response.statusText
                status = error.response.status;
                code = error.code;
            }
        } else {
            if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                message = error.message;
                code = error.code;
                status = 600;
                if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
                    status = 601;
                    message = 'Lỗi internet, vui lòng kiểm tra kết nối';
                }
            }
            else {
                // Something happened in setting up the request that triggered an Error
                message = error.message;
                code = error.code;
                status = 1001;
            }
        }
    } catch (e) {
        status = 1000;
        code = 'UNKNOWN';
        message = e.message;
    }
    return { status, message, code, data };
}

export function setTokenAxios(token) {
    axiosInstance.defaults.headers.common.Authorization = token;
    axiosInstanceUpload.defaults.headers.common.Authorization = token;
}
// method: 'GET' | 'POST' | 'PUT' | 'DELETE'
export function requestApi(url, method, data, headers) {
    const token = localStorage.getItem(LOCAL_STORAGE_TYPE.USER_TOKEN);
    const authHeader = token ? { Authorization: token } : {};
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.request({
                data,
                headers: {
                    ...authHeader,
                    // ...axiosInstance.defaults.headers.common,
                    ...headers
                },
                method,
                url
            });
            if (response.data.responseCode.toString().startsWith('2')) {
                resolve(response.data);
            } else {
                const error = handlingErrors(new Error(response.data.responseText));
                reject(error);
            }
        } catch (e) {
            const error = handlingErrors(e);
            Global.error({
                message: error.message,
            });
            reject(error);
        }
    });
}


export const uploadImage = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstanceUpload.request({
                data: formData,
                headers: {
                    'content-type': 'multipart/form-data',
                },
                method: 'POST',
                url: 'image-upload'
            });
            if (response.data.responseCode.toString().startsWith('2')) {
                resolve(response.data);
            } else {
                const error = handlingErrors(new Error(response.data.responseText));
                reject(error);
            }
        } catch (e) {
            const error = handlingErrors(e);
            Global.error({
                message: error.message,
            });
            reject(error);
        }
    })
}


export const uploadImageNoResize = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.request({
                data: formData,
                headers: {
                    'content-type': 'multipart/form-data'
                },
                method: 'POST',
                url: 'image-upload/multiple-size'
            });
            if (response.data.responseCode.toString().startsWith('2')) {
                resolve(response.data);
            } else {
                const error = handlingErrors(new Error(response.data.responseText));
                reject(error);
            }
        } catch (e) {
            const error = handlingErrors(e);
            Global.error({
                message: error.message,
            });
            reject(error);
        }
    })
}