import { requestApi } from "../axiosCoalition";
const prefix = 'coalition-review';

export function getCoalitionReview(offset) {
    return requestApi(`${prefix}/review-coalition?limit=10&offset=${offset}`, 'GET');
}

export function getCoalitionReviewById(id) {
    return requestApi(`${prefix}/review-coalition-by-id?id=${id}`, 'GET');
}

export function getCoalitionTotalCount() {
    return requestApi(`${prefix}/count-review-coalition`, 'GET');
}

export function rejectCoalition(id) {
    return requestApi(`${prefix}/reject-coalition`, 'POST', { id: id });
}

export function approveCoalition(id) {
    return requestApi(`${prefix}/approve-coalition`, 'POST', { id: id });
}