import React, { Component } from 'react';
import { manageAdvertisingStores } from '../../stores/ManageAdvertisingStores';
import AdvertisingListTable from './AdvertisingListTable';
import { observer } from 'mobx-react';

class AdvertisingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <AdvertisingListTable
                        getTotalSize={this.getTotalSize}
                        getAdvertisingList={this.getAdvertisingList} />
                </div>

            </div>
        );
    }


    getAdvertisingList = () => {
        manageAdvertisingStores.getAdvertisingListStore();
    }


    getTotalSize = async () => {
        await manageAdvertisingStores.getTotalAdvertisingCount();
    }

    componentDidMount = async () => {
        await this.getTotalSize();
        this.getAdvertisingList();
    };

}


export default observer(AdvertisingList);