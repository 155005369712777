import { requestApi } from "../axios";
const prefix = 'forum';

export function getThreadListApi({ offset, categoryId, pinned, keyword }) {
    let api = `${prefix}/get-forums?limit=10&offset=${offset}`;
    if (categoryId) api += `&categoryId=${categoryId}`;
    if (pinned) api += `&pinned=${pinned}`;
    if (keyword) api += `&keyword=${keyword}`;
    return requestApi(api, 'GET');
}

export function getTotalThreadCountApi(categoryId, keyword, pinned) {
    let api = `${prefix}/get-forums-count?`;
    if (categoryId) api += `categoryId=${categoryId}`;
    if (pinned) {
        if (!categoryId) api += `&pinned=${pinned}`;
        else api += `pinned=${pinned}`;
    }
    if (keyword) {
        if (!categoryId && !pinned) api += `keyword=${keyword}`;
        else api += `&keyword=${keyword}`
    }
    return requestApi(api, 'GET');
}

export function updateThreadApi(data) {
    return requestApi(`${prefix}/update-forum`, 'PUT', data);
}

export function createThreadApi({ title, description, categoryId, imageNames }) {
    return requestApi(`${prefix}/create-forum`, 'POST', { title, description, categoryId, imageNames });
}

export function getThreadDetailApi(forumId) {
    return requestApi(`${prefix}/get-forum-detail?forumId=${forumId}`, 'GET');
}

export function deleteThreadApi(forumId) {
    return requestApi(`${prefix}/delete-forum`, 'DELETE', { forumId });
}

export function getThreadCategoriesApi() {
    return requestApi(`${prefix}/get-forum-categories`, 'GET');
}
