import React, { Component } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { forgotPasswordPhoneNumberStores } from '../stores/ForgotPasswordPhoneNumberStores';
import { observer } from 'mobx-react';

class ForgotPasswordModal extends Component {

    constructor(props) {
        super(props);
        this.onChanged = this.onChanged.bind(this);
    };

    onChanged(input) {
        forgotPasswordPhoneNumberStores.note = input.target.value;
    }

    render() {
        const { stores, ...rest } = this.props;

        return (
            <Modal {...rest} bsSize="large" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">Xử lý quên mật khẩu sổ điện thoại</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formBasicEmail">
                        {
                            (forgotPasswordPhoneNumberStores.password && forgotPasswordPhoneNumberStores.password.length > 0)
                                ?
                                <Alert variant='success'>
                                    Mật khẩu của khách hàng là: <b>{forgotPasswordPhoneNumberStores.password}</b>
                                </Alert>
                                :
                                <Alert variant='dark'>
                                    Bạn chưa tạo mật khẩu cho khách hàng
                            </Alert>
                        }
                        <div style={{ marginTop: 59 }}>
                            <Form.Label>Ghi chú</Form.Label>
                            <Form.Control onChange={this.onChanged} type="text" placeholder="Nhập ghi chú của bạn" />
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="success" onClick={forgotPasswordPhoneNumberStores.onBtnGeneratePassword}>Tạo mật khẩu mới cho khách hàng</Button>
                    <Button bsStyle="success" onClick={forgotPasswordPhoneNumberStores.onBtnSaveClicked}>Lưu</Button>

                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default observer(ForgotPasswordModal);