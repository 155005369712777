import { extendObservable } from 'mobx';
import { getListBiddingApi, getBiddingCountApi } from '../../../api/product/bidding';
import { pageableOffset } from '../../../helpers/CF';
import { Global } from '../../../GlobalClass';

export const radioFilterBiddingListData = [
  {
      id: 'all',
      name: 'Tất cả'
  },
  {
      id: 'running',
      name: 'Đang đấu giá'
  },
  {
    id: 'succeeded',
    name: 'Thành công'
  },
  {
    id: 'failed',
    name: 'Thất bại'
  }
]

class BiddingListStores {
  constructor() {
    extendObservable(this, {
      biddingList: [],
      biddingCount: 0,
      isLoadingGetList: false,
      biddingTableCurrentPage: 1,
      txtSearchProduct: '',
      isShowModalResetBidding: false,
      isShowModalUpdateTypeBidding: false,
      isShowModalUpdateAuctionTypeBidding: false,
      isShowModalUpdatePriceBuyNowBidding: false,
      biddingSelected: {},
      biddingFilterSelected: radioFilterBiddingListData[0].id
    })
  }

  onSearchProductChange = (txt) => this.txtSearchProduct = txt || '';

  setBiddingFilterSelected = (id) => this.biddingFilterSelected = id;

  setBiddingSelected = bidding => this.biddingSelected = bidding;

  setShowModalResetBidding = value => this.isShowModalResetBidding = value;

  setShowModalUpdateTypeBidding = value => this.isShowModalUpdateTypeBidding = value;

  setShowModalUpdateAuctionTypeBidding = value => this.isShowModalUpdateAuctionTypeBidding = value;

  setShowModalUpdatePriceBuyNowBidding = value => this.isShowModalUpdatePriceBuyNowBidding = value;

  setBiddingTableCurrentPage = page => {
    this.biddingTableCurrentPage = page;
  }
  
  getListBidding = async () => {
    try {
      this.isLoadingGetList = true;
      const offset = pageableOffset(this.biddingTableCurrentPage);
      const result = await getListBiddingApi(this.txtSearchProduct || '', this.biddingFilterSelected, 10, offset);
      if (result.responseCode.toString().startsWith('2')) {
        this.biddingList = result.dataArray || [];
      } else {
        Global.error({ message: result.responseText || '' });
      }
      this.isLoadingGetList = false;
    } catch (error) {
      Global.error({ message: error.message || '' });
      this.isLoadingGetList = false;
    }
  }

  getBiddingCount = async () => {
    try {
      this.isLoadingGetList = true;
      const result = await getBiddingCountApi(this.txtSearchProduct || '', this.biddingFilterSelected);
      if (result.responseCode.toString().startsWith('2')) {
        this.biddingCount = result.data && result.data.count || 0;
      } else {
        Global.error({ message: result.responseText || '' });
      }
      this.isLoadingGetList = false;
    } catch (error) {
      Global.error({ message: error.message || '' });
      this.isLoadingGetList = false;
    }
  }
}

export const biddingListStores = new BiddingListStores()