import { Modal } from 'react-bootstrap';

import React from 'react';
import { CancelButton, AcceptButton } from '../Button/Button';

const ConfirmModal = (props) => {
    const { isShow, title, message, onCancel, onAccepts } = props;
    return (
        <Modal
            style={{ zIndex: 1000000 }}
            show={isShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            animation
            onHide={onCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span>{message}</span>
            </Modal.Body>

            <Modal.Footer>
                {/* <button className="btn btn-round">Close</button>
                             */}
                <CancelButton onClick={onCancel} title='Hủy bỏ' />
                <AcceptButton onClick={onAccepts} title='Chấp nhận' />
            </Modal.Footer>
        </Modal>
    )
};

export default ConfirmModal;
