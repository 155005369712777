import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

export default class Step1_ImageUpload_2 extends Component {

    constructor(props){
        super(props);
        this.onDrop = this.onDrop.bind(this);
    };

    onDrop(acceptedFiles, rejectedFiles) {
        this.props.pushNotificationStores.onDrop(acceptedFiles, rejectedFiles);
    }

    render() {
        let pushNotificationStores = this.props.pushNotificationStores;
        return (
            <div>  <Dropzone
                accept=".jpg,.png"
                multiple={false}
                onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div id="box-shadow">
                                {
                                    pushNotificationStores.uploadedUrl != '' ?
                                        <img src={pushNotificationStores.uploadedUrl} id="upload-image" />
                                        :
                                        <div>
                                            {!pushNotificationStores.uploadingImage ? "Upload ảnh" : ""}
                                        </div>
                                }

                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>
                <br /> <hr />
            </div>
        );
    }
}
