import React, { useRef, useState } from 'react';
import { COLOR } from '../../../common/constant';
import { Global } from '../../../GlobalClass';
import { Switch } from '@material-ui/core';
import { no_image } from 'src/common/images';
import { specificCollectionStore } from '../store/SpecificCollectionStore';
import { useObserver } from "mobx-react";
import { toJS } from 'mobx';
import moment from "moment";
import DatePicker from 'react-datepicker';

const CollectionInfo = ({isNew}) => {
  const inputFileRef = useRef(null);
  // const [collectionName, setCollectionName] = useState(collection ? collection.advertisingName : '');
  // const [createdDate, setCreatedDate] = useState(collection ? collection.createdAt : '');
  // const [endDate, setEndDate] = useState(collection ? collection.endDate : '');
  const UI_TYPES = [
    {
      key: '1:1',
      value: 'Vuông 1x1',
    },
    {
      key: '2:2',
      value: 'Vuông 2x2 (Hết hỗ trợ)',
    },
    {
      key: '1:2',
      value: 'Ngang 1x2',
    },
    {
      key: '2:1',
      value: 'Dọc 2x1 (Hết hỗ trợ)',
    }
  ];
  
  const onPickImage = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  }

  const onPickFileSuccess = async (e) => {
    let file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) return Global.error({ message: 'Vui lòng chọn file định dạng ảnh !' });
    const formData = new FormData();
    formData.append("file", file);
    specificCollectionStore.uploadImage(formData);
  }

  return useObserver(() => {
    const { collection } = toJS(specificCollectionStore);
    const today = moment(new Date()).format('YYYY-MM-DD');

    return <div className='row'>
    <div className='row table col-md-6' style={{ alignItems: 'center' }}>
      <img alt='' style={{ height: 300, maxWidth: '30vw', objectFit: 'contain', marginBottom: 6, marginTop: 16, border: `solid 0.5px ${COLOR.tertiary_light}` }}
          src={collection && collection.thumbImage ? collection.thumbImage : no_image} />
      <div className='td-actions text-center' style={{ marginLeft: 6 }}>
        <button onClick={onPickImage} type="button" rel="tooltip" className="btn btn-warning btn-simple">
          <i className="material-icons">edit</i>
        </button>
      </div>
    </div>

    <div className='col-md-6'>
      <h4 className="card-title">Thông tin chi tiết</h4>

      <div className='row'>
        <div className="col-md-6" >
          <div className="form-group bmd-form-group">
            <label className="bmd-label-floating">Tên Collection</label>
              <input 
                value={collection && collection.advertisingName ? collection.advertisingName : ''} 
                type="text" 
                className="form-control" 
                onChange={(e) => {
                  specificCollectionStore.changeAdvertisingName(e.target.value);
                }} 
              />
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }} className='row'>
            <h6 className="card-category text-gray" style={{ margin: 'auto' }}>Trạng thái: </h6>
            <Switch 
              checked={isNew ? false : collection && collection.deletedAt ? false : true} 
              disabled={isNew} 
              onChange={(e, checked) => {
                if (collection && collection.id) specificCollectionStore.updateCollectionStatus(!checked);
              }}
            />
        </div>
      </div>

      <div className="row">
        <div className="col-md-5" style={{ padding: 0, marginTop: 20 }}>
          <div className="form-group bmd-form-group">
            <label className="bmd-label-floating">Ngày tạo</label>
            <input 
              disabled 
              value={collection && collection.createdAt ? moment(collection.createdAt).format("DD/MM/YYYY") : ''} 
              type="text" 
              className="form-control" 
            />
          </div>
        </div>
        <div className="col-md-2" />
        <div className="col-md-5" style={{ padding: 0, marginTop: 20 }}>
          <div className="form-group bmd-form-group">
            {collection && collection.endDate && <label style={{fontSize: 12, marginTOp: 0, marginBottom: 0}} >Ngày kết thúc</label>}
            <DatePicker
              value={collection && collection.endDate ? moment(collection.endDate).format("DD/MM/YYYY") : ''}
              adjustDateOnChange={false}
              className="my-date-picker"
              selected={new Date()}
              dateFormat='dd/MM/yyyy'
              minDate={new Date()}
              onChange={(date) => {
                if (!!date)
                  specificCollectionStore.changeEndDate(date)
              }} 
            />
          </div>
        </div>
      </div>
      <div className="" style={{margin: 0}} >
        <label className="bmd-label-floating" style={{marginRight: 0, width: '25%'}}>Cách hiển thị: </label>
        <select name="event" value={collection && collection.layoutFormat ? collection.layoutFormat : '1:1'}
          onChange={e => {
            specificCollectionStore.changeLayoutFormat(e.target.value)
          }}
          style={{ padding: 5, width: '40%' }}
        >
            {UI_TYPES.map(c => <option value={c.key} key={c.key} >{c.value}</option>)}
        </select>
      </div>
      <div>
        <h4 className="card-title">Mô tả</h4>
        <textarea 
          rows="5" 
          cols="60" 
          style={{ borderRadius: 10, padding: 10, borderColor: 'rgba(0,0,0,0.1)' }}
          value={collection && collection.description ? collection.description : ''}
          onChange={(e) => {
            specificCollectionStore.changeDescription(e.target.value);
          }}
        />
      </div>
      {/* input file */}
      <input hidden accept="image/*" onChange={onPickFileSuccess} ref={inputFileRef} type='file' id='img-banner' />
    </div>
  
  </div>
  })
}

export default CollectionInfo;