import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { toJS } from 'mobx';
import moment from 'moment';
import { formatCurrency } from '../../../../helpers/CF';
import { reviewVerifyPhoneStores } from '../../stores/ReviewVerifyPhoneStores';
import PaginationTable from '../../../components/PaginationTable';
import { user_avatar_default } from 'src/common/images';


class ReviewVerifyPhoneTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    avatarFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar || user_avatar_default} />
        </div>
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => this.props.onRejectItemClick(row)} type="button" rel="tooltip" className="btn btn-danger btn-simple">
                <i className="material-icons">cancel</i>
            </button>
            <button style={{ marginLeft: 6 }} onClick={(event) => {
                this.props.onApproveRequest(row.id);
                event.stopPropagation();
            }} type="button" rel="tooltip" className="btn btn-success btn-simple">
                <i className="material-icons">check</i>
            </button>
        </div >
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('HH:mm')}</span><br />
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }
    priceFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span>{formatCurrency(cell)}</span>
        );
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: toJS(reviewVerifyPhoneStores).totalReviewVerifyPhoneSize,
            page: toJS(reviewVerifyPhoneStores).currentReviewVerifyPhonePage,
        };
        return paginationOption;
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'avatar',
                text: 'Ảnh đại diện',
                formatter: this.avatarFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'fullname',
                text: 'Tên',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'phoneNumber',
                text: 'Số điện thoại',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'updatedAt',
                text: 'Ngày',
                formatter: this.dateFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            }
        ];
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        reviewVerifyPhoneStores.setReviewVerifyPhonePage(page);
        reviewVerifyPhoneStores.getReviewVerifyPhoneList();
    }

    async onChangeTextDebounce() {
        const { txtSearch } = this.state;
        await this.props.getTotalSize(txtSearch.trim());
        reviewVerifyPhoneStores.setReviewVerifyPhonePage(1);
        this.props.getUserList(txtSearch.trim());
    }

    onChangeText = async (e) => {
        await this.setState({
            txtSearch: e.target.value
        })
        this.onChangeTextDebounce();
    }

    render() {
        const reviewList = toJS(reviewVerifyPhoneStores.reviewVerifyPhoneList);

        return (
            <div className='user-list'>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className='material-icons'>search</i>
                        </span>
                    </div>
                    <input
                        style={{ marginBottom: 12 }}
                        onChange={this.onChangeText}
                        type="text"
                        value={this.state.txtSearch}
                        className="form-control"
                        placeholder="Tìm kiếm theo tên hoặc số điện thoại..." />
                </div>
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    onTableChange={this.onTableChange}
                    datas={reviewList}
                    columns={this.columnsOption()}
                    loading={reviewVerifyPhoneStores.getVerifyPhoneListLoading}
                />
            </div>

        );
    }
}

export default observer(ReviewVerifyPhoneTable);