import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { reviewCoalitionStores } from '../stores/ReviewCoalitionStores';
import { observer } from 'mobx-react';

class CoalitionReviewModal extends Component {

    render() {
        const { stores, ...rest } = this.props;

        return (
            <Modal {...rest} bsSize="large" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">Phê duyệt liên minh</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-group">
                        <label for="txtCoalitionId" className="control-label col-xs-4">id</label>
                        <div className="col-xs-8">
                            <div className="input-group">
                                <input value={reviewCoalitionStores.coalitionModalItem.id} readonly id="txtCoalitionId" name="txtCoalitionId" type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="txtCoalitionName" className="control-label col-xs-4">Tên Liên minh</label>
                        <div className="col-xs-8">
                            <input value={reviewCoalitionStores.coalitionModalItem.coalitionName} readonly id="txtCoalitionName" name="txtCoalitionName" className="form-control" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="txtAbout" className="control-label col-xs-4">About</label>
                        <div className="col-xs-8">
                            <input value={reviewCoalitionStores.coalitionModalItem.coalitionAbout} readonly id="txtAbout" name="txtAbout" type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="txtCoalitionType" className="control-label col-xs-4">Coalition Type</label>
                        <div className="col-xs-8">
                            <input value={reviewCoalitionStores.coalitionModalItem.coalitionTypeName} readonly id="txtCoalitionType" name="txtCoalitionType" type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="txtAdminRule" className="control-label col-xs-4">Admin Rule</label>
                        <div className="col-xs-8">
                            <input value={reviewCoalitionStores.coalitionModalItem.coalitionRule} readonly id="txtAdminRule" name="txtAdminRule" type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="txtLocationName" className="control-label col-xs-4">Location Name</label>
                        <div className="col-xs-8">
                            <input value={reviewCoalitionStores.coalitionModalItem.locationName} readonly id="txtLocationName" name="txtLocationName" type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="txtRangeOfActivity" className="control-label col-xs-4">Bán kính (km)</label>
                        <div className="col-xs-8">
                            <input value={reviewCoalitionStores.coalitionModalItem.rangeOfActivity + " km"} readonly id="txtRangeOfActivity" name="txtRangeOfActivity" type="text" className="form-control" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => { reviewCoalitionStores.reject() }}>Từ chối</Button>
                    <Button variant="success" onClick={() => { reviewCoalitionStores.approve() }}>Phê duyệt</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default observer(CoalitionReviewModal);