import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { toJS } from 'mobx';
import moment from 'moment';
import { formatCurrency } from '../../../../helpers/CF';
import { reviewVerifyIdStores } from '../../stores/ReviewVerifyIdStores';
import PaginationTable from '../../../components/PaginationTable';

class ReviewVerifyIdTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    avatarFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar} />
        </div>
    }
    imgIdFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.imgId} />
        </div>
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => this.props.onItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">edit</i>
            </button>
            <button onClick={(event) => {
                reviewVerifyIdStores.onApproveVerifyIdRequest(row.id)
                event.stopPropagation();
            }} type="button" rel="tooltip" className="btn btn-success btn-simple">
                <i className="material-icons">check</i>
            </button>
        </div >
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('HH:mm')}</span><br />
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }
    priceFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span>{formatCurrency(cell)}</span>
        );
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: toJS(reviewVerifyIdStores).totalReviewVerifyIdSize,
            page: toJS(reviewVerifyIdStores).currentReviewVerifyIdPage,
        };
        return paginationOption;
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'avatar',
                text: 'Ảnh đại diện',
                formatter: this.avatarFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'fullname',
                text: 'Tên',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'imgId',
                text: 'Ảnh xác minh',
                formatter: this.imgIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            }
        ];
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        reviewVerifyIdStores.setReviewVerifyIdPage(page);
        reviewVerifyIdStores.getReviewVerifyIdList();
    }


    render() {
        const reviewList = toJS(reviewVerifyIdStores.reviewVerifyIdList);

        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={reviewList}
                columns={this.columnsOption()}
                loading={reviewVerifyIdStores.getVerifyIdListLoading}
            />
        );
    }
}

export default observer(ReviewVerifyIdTable);