import { extendObservable } from 'mobx';
import { getVerifyPhoneListCountApi, getVerifyPhoneRequestListApi, approveRequestVerifyPhoneApi, getVerifyPhoneRejectTypeListApi, rejectRequestVerifyPhoneApi } from '../../../api/user/verify-phone-review';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';
class ReviewVerifyPhoneStores {
    constructor() {
        extendObservable(this, {
            reviewVerifyPhoneList: [],
            totalReviewVerifyPhoneSize: 0,
            currentReviewVerifyPhonePage: 1,
            // 
            reviewVerifyPhoneItemDetail: {},
            verifyPhoneRejectTypeList: [],
            verifyPhoneRejectTypeListLoading: [],
            getVerifyPhoneListLoading: false
        });
    }

    setReviewVerifyPhonePage = (page) => this.currentReviewVerifyPhonePage = page;

    getVerifyPhoneRequestCount = async (search) => {
        try {
            const result = await getVerifyPhoneListCountApi(search);
            this.totalReviewVerifyPhoneSize = parseInt(result.data.total || '0');
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    getReviewVerifyPhoneList = async (search) => {
        try {
            this.getVerifyPhoneListLoading = true;
            const offset = pageableOffset(this.currentReviewVerifyPhonePage);
            const result = await getVerifyPhoneRequestListApi(offset, search);
            this.reviewVerifyPhoneList = result.dataArray;
            this.getVerifyPhoneListLoading = false;
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    onApproveVerifyPhoneRequest = async (userId) => {
        try {
            const result = await approveRequestVerifyPhoneApi(userId);
            this.getReviewVerifyPhoneList(this.currentReviewVerifyPhonePage);
            this.totalReviewVerifyPhoneSize = this.totalReviewVerifyPhoneSize - 1;
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    onRejectVerifyPhoneRequest = async (payload) => {
        try {
            const result = await rejectRequestVerifyPhoneApi(payload);
            this.getReviewVerifyPhoneList(this.currentReviewVerifyPhonePage);
            this.totalReviewVerifyPhoneSize = this.totalReviewVerifyPhoneSize - 1;
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    getVerifyPhoneRejectTypeListAction = async () => {
        try {
            this.verifyPhoneRejectTypeListLoading = true;
            const result = await getVerifyPhoneRejectTypeListApi();
            this.verifyPhoneRejectTypeList = result.dataArray || [];
            this.verifyPhoneRejectTypeListLoading = false;
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

}
export const reviewVerifyPhoneStores = new ReviewVerifyPhoneStores();