import { extendObservable, toJS } from 'mobx';
import { getCommonStatisticApi } from '../../../api/dashboard/dashboard';
import { getBannersApi, getBannerTotalCountApi, updateBannerApi, createBannerApi } from '../../../api/advertising/banner';
import { pageableOffset } from '../../../helpers/CF';
import { Global } from '../../../GlobalClass';
import { uploadImageNoResize } from '../../../api/axios';
class AdvertisingStores {
    constructor() {
        extendObservable(this, {
            bannerList: [],
            bannerTotalCount: 0,
            loadingBannerList: false,
            bannerTableCurrentPage: 1,
            isShowBannerDetailModal: false,
            bannerDetail: { status: true }, // { id, title, image, url, status }
            uploadingBannerImage: false
        });
    }

    getBannerListAction = async () => {
        this.loadingBannerList = true;
        const offset = pageableOffset(this.bannerTableCurrentPage);
        const result = await getBannersApi(offset);
        if (result.responseCode.toString().startsWith('2')) {
            this.bannerList = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.loadingBannerList = false;
    }

    getBannerTotalCountAction = async () => {
        const result = await getBannerTotalCountApi();
        if (result.responseCode.toString().startsWith('2')) {
            this.bannerTotalCount = parseInt(result.data.total);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    setBannerTablePage = (page) => this.bannerTableCurrentPage = page;

    onBannerDetailModalChange = (status) => this.isShowBannerDetailModal = status;

    setDataBannerDetail = (data) => this.bannerDetail = data || { status: true };

    uploadBannerAction = async (form) => {
        this.uploadingBannerImage = true;
        const result = await uploadImageNoResize(form);
        if (result.data) {
            this.bannerDetail = { ...this.bannerDetail, image: result.data.uploadedUrl };
        }
        this.uploadingBannerImage = false;
    }

    updateBannerAction = async () => {
        const { id, image, status, title, url } = toJS(this.bannerDetail);
        this.onBannerDetailModalChange(false);
        const result = await updateBannerApi({ id, image, status, title, url });
        if (result.responseCode.toString().startsWith('2')) {
            this.getBannerListAction();
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    createBannerAction = async () => {
        const { image, status, title, url } = toJS(this.bannerDetail);
        this.onBannerDetailModalChange(false);
        const result = await createBannerApi({ image, status, title, url });
        if (result.responseCode.toString().startsWith('2')) {
            this.getBannerListAction();
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

}
export const advertisingStores = new AdvertisingStores();