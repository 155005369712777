import React, { Component } from 'react';
import AdvertisingBannerTable from './components/AdvertisingBannerTable';
import AdvertisingBannerDetailModal from './components/AdvertisingBannerDetailModal';
import { advertisingStores } from './stores/AdvertisingStores';

export default class AdvertisingBanner extends Component {
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <AdvertisingBannerTable />
                </div>
                <AdvertisingBannerDetailModal />
            </div>
        );
    }

    componentDidMount = async () => {
        await advertisingStores.getBannerTotalCountAction();
        advertisingStores.getBannerListAction();
    };

}
