import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { Card } from 'react-bootstrap';
import { dashboardStores } from '../Dashboard/stores/DashboardStores';
import { observer } from 'mobx-react';
import {
    getDailyTotalMoneySold,
} from '../../api/dashboard/dashboard';
class ChartMoneySold extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: []
                }
            },
            series: [
                {
                    name: "series-1",
                    data: []
                }
            ]
        };
    }


    componentDidMount = async () => {
        const result = await getDailyTotalMoneySold();
        const dataArray = result.dataArray || [];
        var xaxisCategories = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisCategories.push(element.name);
        }
        var xaxisData = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            xaxisData.push(element.totalMoneySold);
        }
        const dailyTotalMoneySoldChart = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: xaxisCategories
                },
                fill: {
                    colors: ['#2ecc71']
                }
            },
            series: [
                {
                    name: "Daily total money sold ",
                    data: xaxisData
                }
            ]
        }
        this.setState({
            options: dailyTotalMoneySoldChart.options,
            series: dailyTotalMoneySoldChart.series
        })
    };

    render() {
        return (
            <Card style={{ width: '100%' }}>
                <Card.Header as="h5">Biểu đồ</Card.Header>
                <Card.Body>
                    <Card.Title>Số lượng "Total money sold" hằng ngày</Card.Title>
                    <Card.Text>
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            width='100%'

                        />
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}

export default observer(ChartMoneySold);