import React, { Component } from 'react';
// import NormalText from '../../components/Form/NormalText';
import { observer } from 'mobx-react';
import ReactQuill from 'react-quill'; // ES6
import Dropzone from 'react-dropzone';
import { articleStores } from '../stores/ArticleStores';
import Loader from 'react-loader-spinner'
import { Modal, Button, Form, Alert } from 'react-bootstrap';

// import Config from '../../commons/Config';
class InsetModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tags: [
                { id: 1, name: "Apples" },
                { id: 2, name: "Pears" }
            ],
            suggestions: [
                { id: 3, name: "Bananas" },
                { id: 4, name: "Mangos" },
                { id: 5, name: "Lemons" },
                { id: 6, name: "Apricots" }
            ]
        }
        this.quillRef = null;
        this.handleChange = this.handleChange.bind(this)
        this.onBtnSaveClicked = this.onBtnSaveClicked.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onContentImagePressed = this.onContentImagePressed.bind(this);
    }



    modules = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image', 'video'],
                ['clean']
            ],
            handlers: {
                'image': (image, callback) => {

                    this.fileSelector.click();
                }
            }
        }
    };

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    //Action xay ra khi click chon xong anh tai QuillJS
    onContentImagePressed(e) {
        articleStores.uploadDetailImg(e.target.files[0], (uploadedFileUrl) => {
            var range = this.quillRef.getEditor().getSelection();
            this.quillRef.getEditor().insertEmbed(range.index, 'image', uploadedFileUrl, "user");
        });
    }

    onBtnSaveClicked(e) {
        articleStores.onSave();
    }

    onDrop(acceptedFiles, rejectedFiles) {
        articleStores.onDrop(acceptedFiles, rejectedFiles);
    }

    handleChange(content, delta, source, editor) {
        articleStores.content = content;
    }

    componentDidMount() {
        articleStores.loadData();
    }

    render() {
        const { stores, ...rest } = this.props;

        return (
            <Modal {...rest} size="lg" bsSize="lg" aria-labelledby="contained-modal-title-lg">
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-lg">Thêm mới tin tức</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <input type="file" onChange={this.onContentImagePressed} ref={(el) => { this.fileSelector = el }} style={{ display: 'none' }} accept="image/*" />

                    <div className="form-group">
                        <label>Ảnh tiêu đề</label>
                        <br />
                        <br />
                        <Dropzone
                            accept=".jpg,.png"
                            multiple={false}
                            onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div id="box-shadow">
                                            {
                                                articleStores.uploadedImageUrl != '' ?
                                                    <img src={articleStores.uploadedImageUrl} id="upload-image" />
                                                    :
                                                    <div>
                                                        {!articleStores.uploadedImageUrl ? "Click here or Drag & Drop to upload image" : ""}
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <div style={{ position: 'absolute', top: 150, left: 80 }}>
                            {
                                articleStores.uploadingImage
                                &&
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={30}
                                    width={30}
                                />
                            }
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '30px' }}>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="bmd-label-floating">Tiêu dề</label>
                                <input value={articleStores.title} onChange={(e) => {
                                    articleStores.title = e.target.value;
                                }} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '30px' }}>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="bmd-label-floating">Tiêu dề phụ</label>
                                <input value={articleStores.subTitle} onChange={(e) => {
                                    articleStores.subTitle = e.target.value;
                                }} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="row" style={{ marginTop: '30px' }}>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="bmd-label-floating">Tags</label>

                            </div>
                        </div>
                    </div> */}
                    <Form.Label style={{ marginTop: '30px' }}>Loại bài viết</Form.Label>
                    <Form.Control
                        value={articleStores.articleGroup}
                        onChange={(e) => {
                            articleStores.articleGroup = e.target.value;

                        }}
                        as='select'>
                        <option
                            value={0}>Chọn loại bài viết</option>
                        {
                            articleStores.articleGroups.map((articleGroup, index) =>
                                <option key={index}
                                    value={articleGroup.id}>{articleGroup.groupName}</option>
                            )


                        }
                    </Form.Control>
                    <div className="form-group">
                        <label>Nội dung</label>
                        {
                            articleStores.uploadingDetailImage
                            &&
                            <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={30}
                                width={30}
                            />
                        }
                        <ReactQuill
                            ref={(el) => { this.quillRef = el; }}
                            value={this.props.stores.content}
                            onChange={this.handleChange}
                            modules={this.modules}
                            formats={this.formats}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="success" onClick={this.onBtnSaveClicked}>{this.props.stores.editId == -1 ? 'Thêm mới' : 'Sửa'}</Button>
                    <Button onClick={() => { this.props.stores.editId = -1; this.props.onHide(); }}>Đóng</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default observer(InsetModal);