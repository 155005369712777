import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { get, toJS } from 'mobx';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import _ from 'lodash'
import '../../css/styles.css';
import { deliveryListStores } from '../../stores/DeliveryListStores';
import PaginationTable from '../../../components/PaginationTable';
import { user_avatar_default } from '../../../../common/images';

import { formatCurrency } from 'src/helpers/CF';
import { Modal } from 'react-bootstrap';
import { AcceptButton, CancelButton } from 'src/pages/components/Button';
import { COLOR } from 'src/common/constant';
import { FormControl } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { formatter } from '../../../../helpers/formatter';


class DeliveryListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowConfirmPickUp: false,
            isShowConfirmDeliver: false,
            isShowConfirmCancel: false,
            isShowResultNote: false,
            order: null,
            cancelNote: '',
            resultNote: '',
        }
        this.onChangeTextDebounce = _.debounce(this.onChangeTextDebounce.bind(this), 600);
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={cell || user_avatar_default} />
        </div>
    }

    priceFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span>{formatCurrency(cell)}</span>
        );
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center" style={{ justifyContent: 'center' }} disabled={row.currentState.status === "DONE"} >
            <button onClick={() => this.setState({ isShowConfirmPickUp: true, order: row })} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple" disabled={row.currentState.status !== "TODO"}>
                <i className="material-icons">delivery_dining</i>
            </button>
            <button onClick={() => this.setState({ isShowConfirmDeliver: true, order: row })} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-success btn-simple" disabled={row.currentState.status !== "DOING"}>
                <i className="material-icons">hail</i>
            </button>
            <button
                type="button" rel="tooltip" className="btn btn-danger btn-simple"
                onClick={() => this.setState({ isShowConfirmCancel: true, order: row })}
            >
                <i className="material-icons">clear</i>
            </button>
        </div>
    }

    onConfirmPickUp = async () => {
        await this.props.onPickupProduct(this.state.order);
        this.getDeliveryList();
        this.getTotalSize();
        this.setState({ isShowConfirmPickUp: false, order: null });
    }

    onConfirmDeliver = async () => {
        await this.props.onDeliverProduct(this.state.order);
        this.getDeliveryList();
        this.getTotalSize();
        this.setState({ isShowConfirmDeliver: false, order: null });
    }

    onConfirmCancel = async e => {
        e.preventDefault();
        const { order, cancelNote } = this.state;
        await this.props.onCancelOrder(order, cancelNote);
        this.getDeliveryList();
        this.getTotalSize();
        this.setState({ isShowConfirmCancel: false, order: null });
    }

    onCloseConfirmPickUpModal = () => {
        this.setState({ isShowConfirmPickUp: false, order: null });
    }

    onCloseConfirmDeliveryModal = () => {
        this.setState({ isShowConfirmDeliver: false, order: null });
    }

    onCloseConfirmCancelModal = () => {
        this.setState({ isShowConfirmCancel: false, order: null });
    }

    onButtonResultClicked = (row) => {
        this.setState({isShowResultNote: true, resultNote: row.currentState && row.currentState.note || 'N/a'});
    }

    onCloseCancelNoteModal = () => {
        this.setState({isShowResultNote: false, resultNote: ''})
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }

    resultFormatter = (cell, row) => {
        if (cell === 'FAILURE') {
            return (<span className='resultNote' onClick={e => this.onButtonResultClicked(row)}>{cell}</span>);
        }
        return cell;
    }

    paymentMethodFormatter = (cell) => <><span>{cell?.type??'PAY_ALL'}</span><br/><span>{cell?.name??'Trả 100%'}</span></>

    codAmountFormatter = (cell) => formatCurrency(cell?.codAmount??0)

    codFeeFormatter = (cell) => formatCurrency(cell?.shippingFee?.price??0)

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'invoice.productId',
                text: 'Mã sản phẩm',
                formatter: formatter.productIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'invoice.productThumb',
                text: 'Hình ảnh',
                formatter: this.thumbFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.productName',
                text: 'Tên sản phẩm',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault,
            },
            {
                dataField: 'invoice.price',
                text: 'Giá (VNĐ)',
                headerFormatter: this.headerFormatter,
                formatter: this.priceFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.sellerId',
                text: 'Id người bán',
                formatter: formatter.userIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.buyerId',
                text: 'Id người mua',
                formatter: formatter.userIdFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.payByMethod',
                text: 'Phương thức thanh toán',
                formatter: this.paymentMethodFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.payByMethod',
                text: 'Số tiền thu hộ (COD)',
                formatter: this.codAmountFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'invoice.payByMethod',
                text: 'Phí thu hộ',
                formatter: this.codFeeFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentState.name',
                text: 'Tiến trình',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentState.status',
                text: 'Trạng thái',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'currentState.result',
                text: 'Kết quả',
                formatter: this.resultFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            }
        ];
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        deliveryListStores.setDeliveryTablePage(page);
        this.getDeliveryList(toJS(deliveryListStores).txtSearchProduct);
    }

    getTotalSize = async () => {
        await deliveryListStores.getTotalDeliveryCount(deliveryListStores.productFilterSelected);
    }

    getDeliveryList = () => {
        deliveryListStores.getDeliveryListStore();
    }

    paginationOption = () => ({
        custom: true,
        hideSizePerPage: true,
        totalSize: toJS(deliveryListStores).totalDeliveryListCount,
        page: deliveryListStores.deliveryTableCurrentPages,
    })

    async onChangeTextDebounce() {
        await this.getTotalSize();
        deliveryListStores.setDeliveryTablePage(1);
        this.getDeliveryList();
    }

    onChangeText = async (e) => {
        deliveryListStores.onSearchDeliveryChange(e.target.value);
        this.onChangeTextDebounce();
    }

    onCancelNoteChanged = (e) => this.setState({ cancelNote: e.target.value });



    render() {
        const {
            deliveryList,
            getDeliveryListLoading,
            txtSearchDelivery,
            isLoadingConfirmPickup,
            isLoadingConfirmDeliver,
            isLoadingConfirmCancel,
        } = toJS(deliveryListStores);
        const { isShowConfirmPickUp, isShowConfirmDeliver, isShowConfirmCancel, cancelNote, isShowResultNote, resultNote } = this.state;
        return (
            <div>
                <Modal show={isShowResultNote} onHide={this.onCloseCancelNoteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Lý do</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {resultNote}
                    </Modal.Body>
                    <Modal.Footer>
                        <CancelButton title='ĐÓNG' onClick={this.onCloseCancelNoteModal} />
                    </Modal.Footer>
                </Modal>
                <Modal show={isShowConfirmPickUp} onHide={this.onCloseConfirmPickUpModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bạn đã nhận hàng từ người bán?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        {isLoadingConfirmPickup ?
                            <Loader
                                style={{ marginRight: '12px', marginTop: '8px' }}
                                type="TailSpin"
                                color={COLOR.primary}
                                height={30}
                                width={30}
                            />
                            : <div>
                                <CancelButton title='HỦY BỎ' onClick={this.onCloseConfirmPickUpModal} />
                                <AcceptButton title='XÁC NHẬN' onClick={this.onConfirmPickUp} />
                            </div>
                        }
                    </Modal.Footer>
                </Modal>
                <Modal show={isShowConfirmDeliver} onHide={this.onCloseConfirmDeliveryModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bạn đã giao hàng đến người mua?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        {isLoadingConfirmDeliver ?
                            <Loader
                                style={{ marginRight: '12px', marginTop: '8px' }}
                                type="TailSpin"
                                color={COLOR.primary}
                                height={30}
                                width={30}
                            />
                            : <div>
                                <CancelButton title='HỦY BỎ' onClick={this.onCloseConfirmDeliveryModal} />
                                <AcceptButton title='XÁC NHẬN' onClick={this.onConfirmDeliver} />
                            </div>
                        }
                    </Modal.Footer>
                </Modal>
                <Modal show={isShowConfirmCancel} onHide={this.onCloseConfirmCancelModal}>
                    <Form onSubmit={this.onConfirmCancel}>
                        <Modal.Header closeButton>
                            <Modal.Title>Bạn muốn hủy giao đơn hàng này?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormControl
                                required
                                value={cancelNote}
                                onChange={this.onCancelNoteChanged}
                                style={{ minHeight: 100, borderBottom: 'solid 1px #fe805c' }} placeholder="Ghi chú lý do" plaintext as="textarea" />
                        </Modal.Body>
                        <Modal.Footer>
                            {isLoadingConfirmCancel ?
                                <Loader
                                    style={{ marginRight: '12px', marginTop: '8px' }}
                                    type="TailSpin"
                                    color={COLOR.primary}
                                    height={30}
                                    width={30}
                                />
                                : <div>
                                    <CancelButton title='HỦY BỎ' onClick={this.onCloseConfirmCancelModal} />
                                    <AcceptButton title='ĐỒNG Ý' type='submit' />
                                </div>
                            }
                        </Modal.Footer>
                    </Form>
                </Modal>
                <div className='product-list'>
                    {/* TODO: Phuc handle it after getting feedback from admin
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className='material-icons'>search</i>
                            </span>
                        </div>
                        <input
                            style={{ marginBottom: 12 }}
                            onChange={this.onChangeText}
                            type="text"
                            value={txtSearchDelivery}
                            className="form-control"
                            placeholder="Tìm kiếm theo tên sản phẩm..." />
                    </div> */}
                    <PaginationTable
                        paginationOptions={this.paginationOption()}
                        onClick
                        onTableChange={this.onTableChange}
                        datas={deliveryList}
                        columns={this.columnsOption()}
                        loading={getDeliveryListLoading}
                    // tableRowEvents={
                    //     {
                    //         onClick: (e, row, rowIndex) => {
                    //             this.props.onProductItemClicked(row)
                    //         }
                    //     }
                    // }
                    />
                </div>
            </div>
        )
    }

    componentDidMount = async () => {
        await this.getTotalSize();
        this.getDeliveryList();
    };


}

export default observer(DeliveryListTable);