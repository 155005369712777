import { extendObservable } from 'mobx';
import { getVerifyIdListHistoriesApi, getVerifyIdListCountApi, getVerifyIdRequestListApi, getVerifyIdRequestDetailApi, approveRequestVerifyIdApi, getVerifyIdRejectTypeListApi, rejectRequestVerifyIdApi } from '../../../api/user/verify-id-review';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';
class ReviewVerifyIdStores {
    constructor() {
        extendObservable(this, {
            reviewVerifyIdList: [],
            totalReviewVerifyIdSize: 0,
            currentReviewVerifyIdPage: 1,
            // 
            reviewVerifyIdItemDetail: {},
            firstLoadingReviewVerifyIdDetail: false,
            verifyIdRejectTypeList: [],
            getVerifyIdListLoading: false,
            //
            verifyIdHistoryList: [],
        });
    }

    setReviewVerifyIdPage = (page) => this.currentReviewVerifyIdPage = page;

    getVerifyIdRequestCount = async () => {
        const result = await getVerifyIdListCountApi();
        if (result.responseCode.toString().startsWith('2')) {
            this.totalReviewVerifyIdSize = parseInt(result.data.total || '0');
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    getReviewVerifyIdList = async () => {
        this.getVerifyIdListLoading = true;
        const offset = pageableOffset(this.currentReviewVerifyIdPage);
        const result = await getVerifyIdRequestListApi(offset);
        if (result.responseCode.toString().startsWith('2')) {
            this.reviewVerifyIdList = result.dataArray;
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.getVerifyIdListLoading = false;
    }

    getVerifyIdItemDetail = async (userId) => {
        this.firstLoadingReviewVerifyIdDetail = true;
        const result = await getVerifyIdRequestDetailApi(userId);
        this.firstLoadingReviewVerifyIdDetail = false;
        if (result.responseCode.toString().startsWith('2')) {
            this.reviewVerifyIdItemDetail = result.data;
            this.getVerifyIdListHistories(userId);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    getVerifyIdListHistories = async (userId) => {
        if (userId) {
            const result = await getVerifyIdListHistoriesApi(userId);
            if (result.responseCode.toString().startsWith('2')) {
                this.verifyIdHistoryList = result.dataArray;
            } else {
                Global.error({ message: result.responseText || '' });
            }
        }
    }


    onApproveVerifyIdRequest = async (params) => {
        const result = await approveRequestVerifyIdApi(params);
        if (result.responseCode.toString().startsWith('2')) {
            this.getReviewVerifyIdList(this.currentReviewVerifyIdPage);
            this.totalReviewVerifyIdSize = this.totalReviewVerifyIdSize - 1;
        }
    }

    onRejectVerifyIdRequest = async (params) => {
        const result = await rejectRequestVerifyIdApi(params);
        if (result.responseCode.toString().startsWith('2')) {
            this.getReviewVerifyIdList(this.currentReviewVerifyIdPage);
            this.totalReviewVerifyIdSize = this.totalReviewVerifyIdSize - 1;
        }
    }

    getVerifyIdRejectTypeListAction = async () => {
        const result = await getVerifyIdRejectTypeListApi();
        if (result.responseCode.toString().startsWith('2')) {
            this.verifyIdRejectTypeList = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    setTotalReviewVerifyIdCount = (count) => this.totalReviewVerifyIdSize = count;

    setVerifyIdRequestItemDetail = (data) => this.reviewVerifyIdItemDetail = data || {};
}
export const reviewVerifyIdStores = new ReviewVerifyIdStores();