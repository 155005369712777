import React, { useState, useEffect } from 'react';
import { Global } from 'src/GlobalClass';
import moment from 'moment';
import PaginationTable from 'src/pages/components/PaginationTable';
import { user_avatar_default } from 'src/common/images';
import _ from 'lodash';
import { specificCollectionStore } from '../store/SpecificCollectionStore';
import { useObserver } from "mobx-react";
import { toJS } from 'mobx';

const CollectionProductTable = ({isSearch = false}) => {
  useEffect(() => {
    if (!isSearch) specificCollectionStore.getAddedProductList()
    else {
      specificCollectionStore.getTotalProductListCount();
      specificCollectionStore.getProductListStore();
    }
  }, [])

  const addProductToCollection = (product) => {
    specificCollectionStore.addProductToCollection(product);
  }

  const deleteProductCollection = product => {
    const confirmAlert = window.confirm(`Bạn có chắc muốn xoá product ${product.productName}?`);
    if (confirmAlert) {
      specificCollectionStore.deleteProductFromCollection(product)
    }
  }

  const thumbFormatter = (cell, row) => {
    return <div key={row.id} className="card-avatar">
      <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.thumbImage || user_avatar_default} />
    </div>
  }

  const statusFormater = (cell, row) => {
    return <b><i>{row.statusName}</i></b>
  }

  const actionButtons = (cell, row) => {
    let buttonIcon = 'add';
    let disabled = false;
    if (isSearch) {
      const { newAddedProducts, addedProducts } = toJS(specificCollectionStore);
      if (newAddedProducts.length > 0) {
        const foundProductInNewList = _.find(newAddedProducts, {id: row.id});
        if (!foundProductInNewList) {
          const foundProductInAddedList = _.find(addedProducts, {id: row.id});
          if (foundProductInAddedList) {
            buttonIcon = 'check_circle_outline';
            disabled = true
          }
        }
        else {
          buttonIcon = 'check_circle_outline';
          disabled = true
        }
      }
    }
    return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
      {isSearch ? <button disabled={disabled} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-success btn-simple" onClick={() => addProductToCollection(row)} >
        <i className="material-icons">{buttonIcon}</i>
      </button> :
      <button style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-danger btn-simple" onClick={() => deleteProductCollection(row)}>
        <i className="material-icons">clear</i>
      </button>}
    </div>
  }

  const headerFormatter = (column, colIndex) => {
    return <h5><strong>{column.text}</strong></h5>
  }

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span>{moment(cell).format('DD/MM/YYYY')}</span>
      </div>
    );
  }

  const expiredDateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span>{moment(cell).format('DD/MM/YYYY')}</span>
      </div>
    );
  }

  const headerStyleDefault = (column, colIndex) => ({
    width: 100,
  })

  const columnsOption = () => {
    return [
      {
        dataField: 'id',
        text: '#',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'thumbImage',
        text: 'hình ảnh',
        formatter: thumbFormatter,
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'productName',
        text: 'Tên sản phẩm',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault,
      },
      {
        dataField: 'price',
        text: 'Giá',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'areaText',
        text: 'Địa chỉ',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'conditionName',
        text: 'Tình trạng',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: "action",
        text: "Hành động",
        formatter: actionButtons,
        headerFormatter: headerFormatter,
        headerStyle: {
          width: 60,
        },
      },
    ];
  }

  const paginationOption = (totalAddedProductListCount, totalProductListCount, productsTableCurrentPage, productListTableCurrentPage) => {
    const paginationOption = {
      custom: true,
      hideSizePerPage: true,
      totalSize: isSearch ? totalProductListCount : totalAddedProductListCount,
      page: isSearch ? productListTableCurrentPage : productsTableCurrentPage,
    };
    return paginationOption;
  }

  const onTableChange = (type, { page, sizePerPage }) => {
    window.scrollTo(0, 0);
    if (!isSearch) {
      specificCollectionStore.setAddedProductsTablePage(page);
      specificCollectionStore.getAddedProductList();
    }
    else {
      specificCollectionStore.setProductListPage(page);
      specificCollectionStore.getProductListStore();
    }
  }

  return useObserver(() => {
    const { 
      addedProducts, 
      getAddedProductListLoading, 
      totalAddedProductListCount, 
      products, 
      getProductListLoading, 
      totalProductListCount,
      productsTableCurrentPage,
      productListTableCurrentPage
    } = toJS(specificCollectionStore);
    return <PaginationTable
      paginationOptions={(() => paginationOption(totalAddedProductListCount, totalProductListCount,productsTableCurrentPage,productListTableCurrentPage))()}
      onTableChange={onTableChange}
      datas={isSearch ? products : addedProducts}
      columns={columnsOption()}
      loading={isSearch ? getProductListLoading : getAddedProductListLoading}
    />
  })
}

export default CollectionProductTable;