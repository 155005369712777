function roundToTwo(num, roundLeft, roundRight) {
  return +(Math.round(num + roundLeft) + roundRight);
}

export function shortenPrice(price) {
  if (!!!price) return;
  if (price <= 0) return '0K';
  if (price < 500) return '~0K';
  if (price <= 999) return '~1K';
  if (price < 999999) {
    const parse = roundToTwo(price / 1000, 'e+0', 'e-0');
    if (price === parse * 1000) return parse + 'K';
    else return '~' + parse + 'K';
  }
  if (price < 999999999) {
    const parse = roundToTwo(price / 1000000, 'e+1', 'e-1');
    if (price === parse * 1000000) return parse + 'Tr';
    else return '~' + parse + 'Tr';
  }
  const parse = roundToTwo(price / 1000000000, 'e+2', 'e-2');
  if (price === parse * 1000000000) return parse + 'Tỷ';
  else return '~' + parse + 'Tỷ';
}