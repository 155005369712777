import React, { Component } from 'react';
import { ROUTER } from '../../common/route';
import { history } from '../../history';
import { observer } from 'mobx-react';
import OrderList from './components/OrderList'
import DeliveryList from './components/DeliveryList'
import RefundList from './components/RefundList'
import { orderListStores } from './stores/OrderListStores';
import { refundListStores } from './stores/RefundListStores';
import { deliveryListStores } from './stores/DeliveryListStores';
import { Tab, Tabs } from 'react-bootstrap';
import { AcceptButton } from '../components/Button/Button';
import UpdateOrderModal from './components/UpdateOrderModal/UpdateOrderModal';


class OrderTabsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultActiveKeyTab: 'order-list',
            showModal: false
        }
    }

    closeModal = () => this.setState({showModal: false});

    openModal = () => this.setState({showModal: true});

    render() {
        const { defaultActiveKeyTab } = this.state;
        const orderListTitle = `Danh sách đơn hàng (${orderListStores.totalOrderListCount || 0})`;
        const deliveryListTitle = `Danh sách đơn cần giao (${deliveryListStores.totalDeliveryListCount || 0})`;
        const refundListTitle = `Danh sách đơn yêu cầu hoàn tiền (${refundListStores.totalRefundListCount || 0})`;
        return (
            <div style={{ position: 'relative' }} >
                <Tabs accessKey={defaultActiveKeyTab}>
                    <Tab eventKey="order-list" title={orderListTitle}>
                        <OrderList {...this.props} />
                    </Tab>
                    <Tab eventKey="delivery-list" title={deliveryListTitle}>
                        <DeliveryList {...this.props} />
                    </Tab>
                    <Tab eventKey="refund-list" title={refundListTitle}>
                        <RefundList {...this.props} />
                    </Tab>
                </Tabs>
                <div style={{position: 'absolute', right: 0, top: 0, display: 'flex', flexDirection: 'row'}}>
                    <AcceptButton 
                        containerStyle={{ height: 'fit-content' }} 
                        onClick={this.openModal} 
                        title='Cập nhật hoá đơn'
                    />
                </div>
                <UpdateOrderModal
                    closeModal={this.closeModal}
                    isShowing={this.state.showModal}
                />
            </div>
        );
    }

    componentDidMount() {
        if (history.location.pathname == ROUTER.ORDER.REVIEW) {
            this.setState({ defaultActiveKeyTab: 'review-order' });
        } else {
            this.setState({ defaultActiveKeyTab: 'order-list' });
        }
    }

}

export default observer(OrderTabsComponent);