import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ProductListTable from './ProductListTable';
import { Global } from '../../../../GlobalClass';
import { ROUTER } from '../../../../common/route';
import { reviewProductStores } from '../../stores/ReviewProductStores';
import ProductReviewModal from '../ReviewProduct/ProductReviewModal';
import { getParamURL } from '../../../../helpers/CF';
import { history } from '../../../../history';
import { userDetailStores } from 'src/pages/UserManagement/stores/UserDetailStores';
import UserDetailModal from 'src/pages/UserManagement/components/UserDetail/UserDetailModal';
import RadioGroup from 'src/pages/components/RadioGroup/RadioGroup';
import { productListStores, radioFilterProductListData } from '../../stores/ProductListStores';
import ConvertToBiddingModal from '../ConvertToBiddingModal/ConvertToBiddingModal';

class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productDetailModal: false,
            productId: null,
            convertToBiddingModal: false,
            convertedBiddingProduct: undefined
        }
    };

    openConvertToBiddingModal = (product) => {
        this.setState({convertedBiddingProduct: product, convertToBiddingModal: true})
    }

    hideConvertToBiddingModal = () => {
        this.setState({convertToBiddingModal: false, convertedBiddingProduct: undefined})
    }

    onHideProductModal = () => {
        this.setState({ productDetailModal: false, productId: null });
        Global.push(ROUTER.PRODUCT.INDEX);
    }

    onProductItemClicked = (item) => {
        this.setState({ productId: item.id, productDetailModal: true });
        Global.push(`${ROUTER.PRODUCT.INDEX}?pId=${item.id}`);
    }

    onShowUserInfo = (userId) => {
        if (!userId) return;
        userDetailStores.setUserIdForLoadDetail(userId);
        userDetailStores.onChangeUserDetailModal(true);
    }

    onSelectedFilter = async ({ item }) => {
        const filter = item.id;
        const { setProductTablePage, setProductFilterSelected, getTotalProductCount, getProductListStore } = productListStores;
        setProductFilterSelected(filter);
        setProductTablePage(1);
        await getTotalProductCount(productListStores.productFilterSelected); // không khai báo trước productFilterSelected ở trên nếu k sẽ lấy giá trị cũ (giá trị lúc khai báo)
        getProductListStore();
    }

    render() {
        const { productDetailModal, convertToBiddingModal, convertedBiddingProduct } = this.state;
        const { productFilterSelected } = productListStores;
        return (
            <div className="card">
                <div id='product-list' className='v-radio'>
                    <RadioGroup
                        inputKey='product-list'
                        containerStyle={{ marginRight: 32 }}
                        checkedId={productFilterSelected}
                        onItemClicked={this.onSelectedFilter}
                        datas={radioFilterProductListData}
                    />
                </div>
                <div className="card-body">
                    <ProductListTable
                        onShowUserInfo={this.onShowUserInfo}
                        onProductItemClicked={this.onProductItemClicked}
                        openConvertToBiddingModal={this.openConvertToBiddingModal}
                        setActiveTab={this.props.setActiveTab}
                    />
                </div>

                <ProductReviewModal isShow={productDetailModal} onHide={this.onHideProductModal} />
                <ConvertToBiddingModal 
                    isShow={convertToBiddingModal} 
                    onHide={this.hideConvertToBiddingModal}
                    convertedBiddingProduct={convertedBiddingProduct}
                />
                <UserDetailModal />
            </div>
        );
    }

    componentDidMount = async () => {
        const { getRejectTypeListAction, productRejectTypeList } = reviewProductStores;
        if (!productRejectTypeList.length) await getRejectTypeListAction();
        if (history.location.pathname == ROUTER.PRODUCT.INDEX) {
            const productId = getParamURL(this.props, 'pId');
            if (!!productId)
                this.setState({ productId, productDetailModal: true });
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { productDetailModal } = this.state;
        if (prevState.productDetailModal !== productDetailModal) {
            const {
                getReviewProductDetailAction,
                getProductHistoryInReviewProductAction,
                setSupportConversationsPage,
                setSupportConversationList
            } = reviewProductStores;
            if (productDetailModal) {
                getReviewProductDetailAction(this.state.productId);
                getProductHistoryInReviewProductAction(this.state.productId);
            } else {
                setSupportConversationsPage(1);
                setSupportConversationList([]);
            }
        }
    };
}

export default observer(ProductList);