import { requestApi } from "../axios";
const prefix = 'user-profile';

export function getCollectionsApi(offset) {
  return requestApi(`/manage-advertising/getPickAdsList?limit=10&offset=${offset}`, 'GET');
}

export function getTotalSizeApi() {
  return requestApi(`/manage-advertising/total-count-advertising?type=2`, 'GET');
}

export function getAddedProductListApi(adId, offset) {
  return requestApi(`/manage-advertising/getPickAdsProducts?limit=10&adId=${adId}&offset=${offset}`, 'GET')
}

export function getProductListApi(offset, search, userId, filter, adId) {
  if (adId) return requestApi(`product/list-product-for-pick-ads?limit=10&offset=${offset}&filter=${filter}&adId=${adId}`, 'POST', { search, userId });
  else return requestApi(`product/list-product-for-pick-ads?limit=10&offset=${offset}&filter=${filter}`, 'POST', { search, userId });
}

export function getTotalProductCountApi(search, userId, filter, adId) {
  if (adId) return requestApi(`product/total-product-count-for-pick-ads?filter=${filter}&adId=${adId}`, 'POST', { search, userId });
  else return requestApi(`product/total-product-count-for-pick-ads?filter=${filter}`, 'POST', { search, userId });
}

export function createCollectionApi(payload) {
  return requestApi(`/manage-advertising/createPickAds`, 'POST', payload);
}

export function updateCollectionApi(payload) {
  return requestApi(`/manage-advertising/updatePickAds`, 'POST', payload);
}

export function deleteCollectionApi(adId) {
  return requestApi(`/manage-advertising/deletePickAds`, 'POST', { adId });
}

export function addProductsToCollectionApi(payload) {
  return requestApi(`/manage-advertising/addProductToPickAds`, 'POST', payload)
}

export function deleteProductFromCollectionApi(payload) {
  return requestApi(`/manage-advertising/deleteProductOfPickAds`, 'POST', payload)
}
