import { observer } from 'mobx-react';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { AcceptButton, CancelButton } from 'src/pages/components/Button';
import { reportStore, REPORT_MODAL_TYPE } from '../stores/ReportStore';

const ReportContentModal = forwardRef((props, ref) => {
    const [content, setContent] = useState('');
    const [reportId, setReportId] = useState(null);
    const [forumId, setForumId] = useState('');
    const [userIdToSendFeedback, setUserId] = useState(null);
    const { isShowReportContentModal, reportModalType, reportModalPostContentLoading } = reportStore;
    useImperativeHandle(ref, () => ({
        setContent: (txt) => setContent(txt),
        setReportId: (id) => setReportId(id),
        settUserIdToSendFeedback: (id) => setUserId(id),
        setForumId: (id) => setForumId(id),
    }));
    const onHide = useCallback(() => {
        setContent('');
        setReportId(null);
        setUserId(null);
        setForumId('');
        reportStore.changeReportModalStatusWorker(false);
        reportStore.changeReportModalLoadingWorker(false);
    }, []);
    const onAcceptChange = useCallback(async () => {
        if (!content) return;
        let status;
        if (reportModalType === REPORT_MODAL_TYPE.REPORT_NOTE) status = await reportStore.updateNoteWorker(reportId, content);
        else status = await reportStore.sendReportFeedbackWorker(content, userIdToSendFeedback, { forumId });
        if (status) onHide();
    }, [reportId, content, userIdToSendFeedback, forumId]);
    const onChangeText = useCallback((e) => setContent(e.target.value), []);
    const onChangeForumId = useCallback((e) => setForumId(e.target.value), []);
    const modalTitle = reportModalType === REPORT_MODAL_TYPE.REPORT_NOTE ? 'Cập nhật ghi chú' : 'Gửi phản hồi';
    return (
        <Modal
            show={isShowReportContentModal}
            size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            animation
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                <FormControl isInvalid={true} isValid={false}
                    value={content}
                    onChange={onChangeText}
                    style={{ minHeight: 100, borderBottom: 'solid 1px #fe805c' }} placeholder="Nhập nội dung" plaintext as="textarea" />
            </Modal.Body>

            <Modal.Body >
                <FormControl isInvalid={true} isValid={false}
                    value={forumId}
                    onChange={onChangeForumId}
                    style={{ borderBottom: 'solid 1px #fe805c' }} placeholder="Forum id" plaintext/>
            </Modal.Body>

            <Modal.Footer>
                <CancelButton onClick={onHide} title='Hủy bỏ' />
                <AcceptButton disabled={reportModalPostContentLoading} onClick={onAcceptChange} title='Đồng ý' />
            </Modal.Footer>

        </Modal>
    )
});

export default observer(ReportContentModal);
