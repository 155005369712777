import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ForgotPasswordPhoneNumberTable from './components/ForgotPasswordPhoneNumberTable';
import ForgotPasswordModal from './components/ForgotPasswordModal';
import { forgotPasswordPhoneNumberStores } from './stores/ForgotPasswordPhoneNumberStores';
import { Global } from '../../GlobalClass';

class ForgotPasswordPhoneNumber extends Component {
    constructor(props) {
        super(props);
    };


    render() {
        return (
            <div className="card">
                <ForgotPasswordModal onHide={() => {
                    forgotPasswordPhoneNumberStores.showModal = false
                }} show={forgotPasswordPhoneNumberStores.showModal} />
                <div className="card-body">
                    <ForgotPasswordPhoneNumberTable
                    />
                </div>
            </div>
        );
    }

    componentDidMount = async () => {
        forgotPasswordPhoneNumberStores.getData();
    };

}

export default observer(ForgotPasswordPhoneNumber);