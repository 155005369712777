import { extendObservable } from 'mobx';
import { getUserListApi, getTotalUserCountApi } from '../../../api/user/user-list';
import { resetPasswordApi } from '../../../api/user/user-account';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';
class UserListStores {
    constructor() {
        extendObservable(this, {
            userList: [],
            getUserListLoading: true,
            totalUserListCount: 0,
            userTableCurrentPage: 1,
            isShowChangePassModal: false,
            userIdForChangePass: 0
        });
    }


    getUserListStore = async (search) => {
        try {
            this.getUserListLoading = true;
            const offset = pageableOffset(this.userTableCurrentPage);
            const result = await getUserListApi(offset, search || '');
            if (result.responseCode.toString().startsWith('2')) {
                this.userList = result.dataArray || [];
                if (search && search.length > 0) {
                    this.totalUserListCount = result.data.count;
                }
            } else {
                Global.error({ message: result.responseText || '' });
            }
            this.getUserListLoading = false;
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.getUserListLoading = false;
        }
    }
    setUserTablePage = (page) => this.userTableCurrentPage = page;

    getTotalUserCount = async (search) => {
        const result = await getTotalUserCountApi(search || '');
        if (result.responseCode.toString().startsWith('2')) {
            this.totalUserListCount = parseInt(result.data.total);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    onChangePassModalStatusChange = (status) => this.isShowChangePassModal = status;
    setUserIdPasswordChange = (id) => this.userIdForChangePass = id;


    changePasswordStore = async (password) => {
        if (!this.userIdForChangePass) return;
        const result = await resetPasswordApi(this.userIdForChangePass, password);
        if (!result.responseCode.toString().startsWith('2')) {
            Global.error({ message: result.responseText || '' });
        }
    }

    updateStatusBlockUserStore = (userId, status) => {
        const user = this.userList.find(o => o.id == userId);
        if (!!user) user.deletedAt = status ? new Date() : null;
    }

}
export const userListStores = new UserListStores();