import React, { Component } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { pushNotificationStores } from '../stores/PushNotificationStores';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import PushNotificationFormData from '../components/PushNotificationFormData';
import Step1_ImageUpload_2 from './Step1_ImageUpload_2';
import moment from 'moment';
import { uploadImage } from 'src/api/axios';
import axios from 'axios';
class Step1 extends Component {
    constructor(props) {
        super(props);
        this.state = { croppedImage: '', showUploadPopup: true };
        this.onCropImage = this.onCropImage.bind(this);
    }

    onTypeChange = (e) => pushNotificationStores.onNotificationTypeSelected(e.target.value);

    async onCropImage(croppedImage) {
        this.setState({
            croppedImage: croppedImage,
            showUploadPopup: false
        });

        fetch(croppedImage, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(async function (buffer) {
                    var blodData = new Blob([buffer]);
                    const data = new FormData();
                    data.append('file', blodData, moment().format('yyyymmddhhmmss') + '_notification.jpg');
                    const result = await uploadImage(data);
                });
            })
            .catch(err => {
                console.log(err);
            });

        // axios({
        //     method: "get",
        //     url: croppedImage, // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
        //     responseType: "blob",
        // }).then(function (response) {
        //     var reader = new FileReader();
        //     reader.readAsDataURL(response.data);
        //     reader.onloadend = async function () {
        //         var base64data = reader.result;
        //         const data = new FormData();
        //         data.append('file', base64data, moment().format('yyyymmddhhmmss') + '_notification.jpg');
        //         const result = await uploadImageNoResize(data);
        //         console.log(result);
        //     };
        // });



    }

    render() {
        let {
            pushNotificationTitle,
            pushNotificationContent,
            notificationTypeSelected,
            notificationTypes
        } = pushNotificationStores;
        return (
            <div>
                <Form.Label>Upload ảnh Notification</Form.Label>
                <Step1_ImageUpload_2 pushNotificationStores={pushNotificationStores} />
                <Form.Label>Tiêu đề push notification</Form.Label>
                <Form.Control
                    onChange={(e) => {
                        pushNotificationStores.pushNotificationTitle = e.target.value;
                    }}
                    value={pushNotificationTitle}
                    type="text"
                    placeholder="Nhập tiêu đề push notification"
                />

                <Form.Label style={{ marginTop: '30px' }}>Nội dung push notification</Form.Label>
                <Form.Control
                    onChange={(e) => {
                        pushNotificationStores.pushNotificationContent = e.target.value;
                    }}
                    value={pushNotificationContent}
                    type="text"
                    placeholder="Nhập nội dung push notification"

                />

                <Form.Label style={{ marginTop: '30px' }}>Loại thông báo</Form.Label>
                <Form.Control
                    value={notificationTypeSelected.id}
                    onChange={this.onTypeChange}
                    as='select'>
                    <option
                        value={0}>Chọn loại thông báo</option>
                    {
                        toJS(notificationTypes).map((element, index) => {
                            return (
                                <option key={index}
                                    value={element.id}>{element.description}</option>
                            )
                        })
                    }
                </Form.Control>

                <PushNotificationFormData />
            </div>
        );
    }
}


export default observer(Step1);