import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { toJS } from 'mobx';
import moment from 'moment';
import _ from 'lodash'
import { userListStores } from '../../stores/UserListStores';
import PaginationTable from '../../../components/PaginationTable';
import { userDetailStores } from '../../stores/UserDetailStores';
import { user_avatar_default } from '../../../../common/images';

class UserListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            txtSearch: ''
        }
        this.onChangeTextDebounce = _.debounce(this.onChangeTextDebounce.bind(this), 600);
    };

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar || user_avatar_default} />
        </div>
    }

    statusFormater = (cell, row) => {
        return <div className="td-actions text-center">
            {
                !!row.deletedAt ?
                    <span className="badge badge-pill badge-danger">Đã khóa</span>
                    :
                    <span className="badge badge-pill badge-success">Hoạt động</span>
            }
        </div>
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
            <button onClick={() => this.onUserItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">edit</i>
            </button>
            <button onClick={this.onShowChangePassModal(row.id)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-warning btn-simple">
                <i className="material-icons">cached</i>
            </button>
        </div>
    }

    onUserItemClicked = (user) => {
        userDetailStores.setUserIdForLoadDetail(user.id);
        userDetailStores.onChangeUserDetailModal(true);
    }

    onShowChangePassModal = (userId) => () => {
        if (!userId) return;
        userListStores.setUserIdPasswordChange(userId);
        userListStores.onChangePassModalStatusChange(true);
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'avatar',
                text: 'Avatar',
                formatter: this.thumbFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'fullname',
                text: 'Tên',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'email',
                text: 'Email',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'createdAt',
                text: 'Ngày tham gia',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'deletedAt',
                text: 'Trạng thái',
                formatter: this.statusFormater,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            }
        ];
    }


    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        userListStores.setUserTablePage(page);
        this.props.getUserList(this.state.txtSearch);
    }

    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: toJS(userListStores).totalUserListCount,
            page: userListStores.userTableCurrentPage,
        };
        return paginationOption;
    }

    async onChangeTextDebounce() {
        const { txtSearch } = this.state;
        await this.props.getTotalSize(txtSearch.trim());
        userListStores.setUserTablePage(1);
        this.props.getUserList(txtSearch.trim());
    }

    onChangeText = async (e) => {
        await this.setState({
            txtSearch: e.target.value
        })
        this.onChangeTextDebounce();
    }


    render() {
        const { userList, getUserListLoading } = toJS(userListStores);
        return (
            <div className='user-list'>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className='material-icons'>search</i>
                        </span>
                    </div>
                    <input
                        style={{ marginBottom: 12 }}
                        onChange={this.onChangeText}
                        type="text"
                        value={this.state.txtSearch}
                        className="form-control"
                        placeholder="Tìm kiếm theo tên, email hoặc số điện thoại..." />
                </div>
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    onTableChange={this.onTableChange}
                    datas={userList}
                    columns={this.columnsOption()}
                    loading={getUserListLoading}
                />
            </div>
        )
    }

    componentDidMount = () => {
    };


}

export default observer(UserListTable);