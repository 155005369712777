import { requestApi } from "../axios";
import { stringify } from 'querystring';

const prefix = '/product'

export function convertToBiddingApi({
  productId,
  floorPrice,
  startPrice,
  stepPrice,
  endDate,
}) {
  return requestApi(`${prefix}/convert-to-bidding`, 'POST', { productId, floorPrice, startPrice, stepPrice, endDate });
}

export function resetBidding({
  productId,
  floorPrice,
  startPrice,
  stepPrice,
  endDate,
}) {
  return requestApi(`${prefix}/reset-bidding`, 'POST', { productId, floorPrice, startPrice, stepPrice, endDate });
}

export function getListBiddingApi(search, filter, limit, offset) {
  return requestApi(`${prefix}/list-bidding?${stringify({ search, filter, limit, offset })}`, 'GET')
}

export function getBiddingCountApi(search, filter) {
  return requestApi(`${prefix}/bidding-count?${stringify({search, filter})}`, 'GET')
}

export function updateTypeBidding({
  productId,
  type
}) {
  return requestApi(`${prefix}/type-bidding`, 'POST', { productId, type });
}

export function updateAuctionTypeBidding({
  productId,
  auctionType
}) {
  return requestApi(`${prefix}/auction-type-bidding`, 'POST', { productId, auctionType });
}

export function updatePriceBuyNowBidding({
  productId,
  buynowPrice,
}) {
  return requestApi(`${prefix}/buy-now-price-bidding`, 'POST', { productId, buynowPrice });
}