import React, { useState, useEffect, useContext } from 'react';
import PaginationTable from '../../components/PaginationTable';
import _ from 'lodash';
import moment from 'moment';
import { user_avatar_default } from '../../../common/images';
import AddedModsContext from '../context/addedModsContext';

const ModListTable = ({
  loadFor,
  fetchModList,
  modList,
  removeMod,
  addMod,
  setCurrentPage,
  currentPage,
  totalSize
}) => {
  const [searchKey, setSearchKey] = useState(undefined);
  const {addedMods} = useContext(AddedModsContext);

  useEffect(() => {
    if(fetchModList) fetchModList();
  }, [])

  const headerFormatter = (column, colIndex) => {
    return <h5><strong>{column.text}</strong></h5>
  }

  const thumbFormatter = (cell, row) => {
    return <div key={row.id} className="card-avatar">
      <img className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar || user_avatar_default} />
    </div>
  }

  const headerStyleDefault = (column, colIndex) => ({
    width: 100,
  })

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
          <span>{moment(cell).format('DD/MM/YYYY')}</span>
      </div>
    );
  }

  const statusFormater = (cell, row) => {
    return <div className="td-actions text-center">
      {
        !!row.deletedAt ?
          <span className="badge badge-pill badge-danger">Đã khóa</span>
          :
          <span className="badge badge-pill badge-success">Hoạt động</span>
      }
    </div>
  }

  const actionButtons = (cell, row) => {
    let isAddedMods;
    if (loadFor === 'CURRENT_MODS') isAddedMods = _.find(addedMods, {userId: row.userId});
    else isAddedMods = _.find(addedMods, {userId: row.id});
    if (!isAddedMods) {
      return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
        <button 
          style={{ marginRight: 6 }} 
          type="button" rel="tooltip" 
          className="btn btn-success btn-simple"
          onClick={() => addMod(row.id)}
        >
          <i className="material-icons">add</i>
        </button>
      </div>
    }
    else return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
      <button 
        style={{ marginRight: 6 }} 
        type="button" rel="tooltip" 
        className="btn btn-danger btn-simple"
        onClick={() => {
          if (loadFor === 'CURRENT_MODS') removeMod(row.userId);
          else removeMod(row.id)
        }}
      >
        <i className="material-icons">clear</i>
      </button>
    </div>
  }

  const paginationOption = () => {
    const paginationOption = {
      custom: true,
      hideSizePerPage: true,
      totalSize: totalSize || 0,
      page: currentPage || 1,
    };
    return paginationOption;
  }

  const columnsOption = () => {
    return [
      {
        dataField: loadFor === 'USER_LIST' ? 'id' : 'userId',
        text: '#',
        headerFormatter: headerFormatter,
        headerStyle: {
          width: 60
        }
      },
      {
        dataField: 'avatar',
        text: 'Avatar',
        formatter: thumbFormatter,
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'fullname',
        text: 'Tên',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'createdAt',
        text: 'Ngày tham gia',
        headerFormatter: headerFormatter,
        formatter: dateFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'deletedAt',
        text: 'Trạng thái',
        formatter: statusFormater,
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'action',
        text: 'Hành động',
        formatter: actionButtons,
        headerFormatter: headerFormatter,
        headerStyle: {
            width: 60
        }
      }
    ];
  }

  const fetchModListWithKeyword = (text) => {
    fetchModList(text);
  }

  const debounceOnChangeText = _.debounce(fetchModListWithKeyword, 300); 

  const onChangeText = (e) => {
    setSearchKey(e.target.value);
    debounceOnChangeText(e.target.value.trim());
  }

  const onTableChange = (type, { page, sizePerPage }) => {
    window.scrollTo(0, 0);
    if (setCurrentPage) setCurrentPage(page);
    fetchModList(searchKey);
  }

  return <div>
    {loadFor !== 'CURRENT_MODS' ? <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className='material-icons'>search</i>
        </span>
      </div>
      <input
        style={{ marginBottom: 12 }}
        onChange={onChangeText}
        type="text"
        value={searchKey}
        className="form-control"
        placeholder="Tìm kiếm theo tên, email hoặc số điện thoại..." 
      />
    </div> : null}
    <PaginationTable
      paginationOptions={paginationOption()}
      onTableChange={onTableChange}
      datas={modList}
      columns={columnsOption()}
      loading={false}
    />
  </div>
}

export default ModListTable;