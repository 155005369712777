import { extendObservable } from 'mobx';
import { loginApi } from '../../../api/auth';
import { setLocalStorage, LOCAL_STORAGE_TYPE, getLocalStorage, delLocalStorage } from '../../../common/localStorage';
import { Global } from '../../../GlobalClass';
import { setTokenAxios } from '../../../api/axios';
class LoginStores {
    constructor() {
        extendObservable(this, {
            username: '',
            password: ''
        });
    }

    doLoginAction = async () => {
        try {
            const dataObject = {
                username: this.username,
                password: this.password,
            }
            const response = await loginApi(dataObject);
            if (response.responseCode.toString().startsWith('2')) {
                const pathToRedirect = getLocalStorage(LOCAL_STORAGE_TYPE.PATH_TO_REDIRECT);
                if (!!pathToRedirect) {
                    delLocalStorage(LOCAL_STORAGE_TYPE.PATH_TO_REDIRECT);
                    window.location.replace(pathToRedirect);
                } else Global.replaceRefresh('/SplashPage');
                setLocalStorage(LOCAL_STORAGE_TYPE.USER_TOKEN, response.data.token);
                setTokenAxios(response.data.token)
            } else Global.error({ message: response.responseText })
        } catch (error) {
            Global.error({ message: error.message })
        }
    }

    onChangeUsername = (str) => this.username = str;
    onChangePassword = (str) => this.password = str;
}
export const loginStore = new LoginStores();