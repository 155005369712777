import React, { useState, useContext, useEffect } from 'react';
import ModListTable from './ModListTable';
import { getCurrentMods, removeMod, addMod } from '../../../api/mods/mods';
import { Global } from '../../../GlobalClass';
import AddedModsContext from '../context/addedModsContext';

const CurrentModListTable = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalMods, setTotalMods] = useState(0);
  const {addedMods, setAddedMods, setRefreshAddedMods, refreshAddedMods} = useContext(AddedModsContext);

  useEffect(() => {
    if (refreshAddedMods) setRefreshAddedMods(false);
    else {
      fetchModList();
    }
  }, [refreshAddedMods])

  const fetchModList = async (text) => {
    try {
      const result = await getCurrentMods(0);
      if (result.responseCode.toString().startsWith('2')) {
        setAddedMods(result.dataArray);
      } else {
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (err) {
      Global.error({ message: err.message || '' });
    }
  }

  const removeModHandler = async (id) => {
    try {
      const result = await removeMod(id);
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({ message: result.responseText || '' })
      } else {
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (err) {
      Global.error({ message: err.message || '' });
    }
  }

  return <ModListTable 
    fetchModList={fetchModList}
    modList={addedMods}
    loadFor='CURRENT_MODS'
    removeMod={removeModHandler}
    currentPage={currentPage}
    totalSize={totalMods}
  />
}

export default CurrentModListTable;