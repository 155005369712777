import React, { Component } from 'react';
import { Global } from '../../GlobalClass';
import { handleAuthMiddleware } from '../../helpers/CF';
import { appStores } from '../../stores/AppStores';

export default class SplashPage extends Component {
    constructor(props) {
        super(props);
    };
    render() {
        return (
            <div className="page-header clear-filter">
                <div className="page-header-image" style={{
                    backgroundImage: "url('/./assets/img/cover.jpg')",
                    backgroundSize: 'cover'
                }}></div>
                <div className="content-center">
                    <span color='white'>Splash Page</span>
                </div>
            </div>
        );
    }

    checkAuth = async () => {
        const isAuth = handleAuthMiddleware();
        if (isAuth) {
            if (appStores.userInfor.group == 'administrator')
                Global.replaceRefresh('/Portal');
            else Global.replaceRefresh('/Portal/Products');
        } else {
            appStores.logout();
        }
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.checkAuth();
        }, 1000);
    };

}
