import React, { Component } from 'react';
import ProductReviewTable from './ReviewVerifyIdTable';
import ReviewVerifyIdModal from './ReviewVerifyIdModal';
import { Global } from '../../../../GlobalClass';
import { reviewVerifyIdStores } from '../../stores/ReviewVerifyIdStores';
import { observer } from 'mobx-react';
import { ROUTER } from '../../../../common/route';
import { getParamURL } from '../../../../helpers/CF';


class ReviewVerifyIdPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowVerifyIdDetailModal: false,
            verifyUserId: null
        }

    };

    onHideReviewProductModal = () => {
        this.setState({ isShowVerifyIdDetailModal: false, verifyUserId: null });
        Global.push(ROUTER.USER.VERIFY_ID);
        reviewVerifyIdStores.setVerifyIdRequestItemDetail({});
    }

    onItemClicked = (item) => {
        this.setState({ verifyUserId: item.id, isShowVerifyIdDetailModal: true });
        Global.push(`${ROUTER.USER.VERIFY_ID}?id=${item.id}`)
    }

    render() {
        const { isShowVerifyIdDetailModal, verifyUserId } = this.state;
        return (
            <div className="card">
                <div className="card-body">
                    <ProductReviewTable
                        onItemClicked={this.onItemClicked} />
                </div>

                <ReviewVerifyIdModal
                    verifyUserId={verifyUserId}
                    isShow={isShowVerifyIdDetailModal}
                    onHide={this.onHideReviewProductModal} />
            </div>
        );
    }

    getReviewProductList = async () => {
        reviewVerifyIdStores.getReviewVerifyIdList();
    }

    componentDidMount = async () => {
        await reviewVerifyIdStores.getVerifyIdRequestCount();
        await reviewVerifyIdStores.getVerifyIdRejectTypeListAction();
        this.getReviewProductList();
        const verifyUserId = getParamURL(this.props, 'id');
        if (!!verifyUserId)
            this.setState({ verifyUserId, isShowVerifyIdDetailModal: true });
    };

    // componentDidUpdate = (prevProps, prevState) => {
    //     const { isShowVerifyIdDetailModal } = this.state;
    //     if (prevState.isShowVerifyIdDetailModal !== isShowVerifyIdDetailModal && isShowVerifyIdDetailModal) {
    //         reviewVerifyIdStores.getVerifyIdItemDetail(this.state.verifyUserId);
    //     }
    // };
}

export default observer(ReviewVerifyIdPage);