import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { AcceptButton, CancelButton } from '../../components/Button/Button';
import { observer } from 'mobx-react';
import { feedbackStores } from '../stores/FeedbackStores';
import { Global } from '../../../GlobalClass';
import { FormControl } from 'react-bootstrap';
import * as mobx from 'mobx';
import { user_avatar_default } from '../../../common/images';
class FeedbackResponseModal extends Component {
    initialState = {
        txt: ''
    }
    constructor(props) {
        super(props);
        this.state = this.initialState;
    };

    onHide = () => {
        feedbackStores.onChangeFeedbackResponseModal(false);
        feedbackStores.setIdFeedbackToResponse(0);
        this.setState(this.initialState);
    }


    onChangeText = (e) => this.setState({ txt: e.target.value })

    onAcceptChange = () => {
        const { txt } = this.state;
        const { responseFeedbackAction, onChangeFeedbackResponseModal, setIdFeedbackToResponse } = feedbackStores;
        if (!txt) return Global.error({ message: 'Vui lòng nhập phản hồi.' });
        responseFeedbackAction(txt);
        onChangeFeedbackResponseModal(false);
        setIdFeedbackToResponse(0);
        this.onHide();
    }


    render() {
        const { txt } = this.state;
        return (
            <Modal
                show={feedbackStores.isShowFeedbackResponseModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                animation
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                        {'Phản hồi Feedback'}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                    <p>  <img alt='' className='img' style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 25 }} src={feedbackStores.feedbackCurentItem.avatar || user_avatar_default} />
                        <b><h3>{feedbackStores.feedbackCurentItem.fullname}</h3></b></p>
                    <p>{feedbackStores.feedbackCurentItem.feedbackContent}</p>
                    <img src={feedbackStores.feedbackCurentItem.image} style={{
                        maxHeight: '150px'
                    }} />
                    <br />
                    <hr />
                    <FormControl isInvalid={true} isValid={false}
                        value={txt}
                        onChange={this.onChangeText}
                        style={{ minHeight: 100, borderBottom: 'solid 1px #fe805c' }} placeholder="Nhập trả lời của bạn" plaintext as="textarea" />
                </Modal.Body>

                <Modal.Footer>
                    <CancelButton onClick={this.onHide} title='Hủy bỏ' />
                    <AcceptButton onClick={this.onAcceptChange} title='Đồng ý' />
                </Modal.Footer>

            </Modal>
        );
    }
}


export default observer(FeedbackResponseModal);