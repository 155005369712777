import { requestApi } from "../axios";
const prefix = 'report';

export function getReportList(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}
export function getReportListTotalCount() {
    return requestApi(`${prefix}/total-count`, 'GET');
}

export function updateReportStatusApi(id, status) {
    return requestApi(`${prefix}/update-status`, 'PUT', { id, status });
}
export function updateNoteApi(id, note) {
    return requestApi(`${prefix}/update-note`, 'PUT', { id, note });
}

export function getLocationsOfProduct() {
    return requestApi(`${prefix}/get-locations`, 'GET');
}

export function getLocationReport(fromDate, toDate, areaText) {
    return requestApi(`${prefix}/get-locations-report`, 'POST', {
        fromDate: fromDate,
        toDate: toDate,
        areaText: areaText
    });
}

export function sendReportFeedbackApi(content, userIdToSend, metadata) {
    return requestApi(`${prefix}/send-feedback-report`, 'POST', {
        content, userIdToSend, metadata
    });
}