import React, { useCallback, useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { AcceptButton } from 'src/pages/components/Button/Button';
import { reviewProductStores } from '../../stores/ReviewProductStores';
import { usePrevious } from 'src/helpers/hooksApi';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import RadioGroup from '../../../components/RadioGroup';

const ProductReject = () => {
    const [text, setText] = useState('');
    const [suggestReasonId, setSuggestReasonId] = useState(null);
    const rejectReasonList = [
        {id: 1, name: 'Quả Khế chỉ dành cho cá nhân đăng bán đồ cũ, không dành cho shop, mong bạn thông cảm.'},
        {id: 2, name: 'Bạn vui lòng không để thông tin cá nhân (bao gồm địa chỉ, số điện thoại, link website, trang mạng xã hội) trên hình ảnh và bài đăng.'}
    ];
    const {
        productDetailInfo,
        // getSupportConversationTotalCountWorker,
        // getSupportConversationListWorker,
        // setSupportConversationsPage,
        sendSupportMessageLoading,
        onRejectProduct
    } = toJS(reviewProductStores);
    // const prevProductDetailInfo = usePrevious(productDetailInfo);
    const prevSendSupportMessageLoading = usePrevious(sendSupportMessageLoading);

    // useEffect(() => {
    //     if (!prevProductDetailInfo & productDetailInfo || (prevProductDetailInfo && productDetailInfo && prevProductDetailInfo.id != productDetailInfo.id && productDetailInfo.id)) {
    //         // load support conversation
    //         getSupportConversationTotalCountWorker();
    //         getSupportConversationListWorker();
    //     }
    // }, [productDetailInfo, prevProductDetailInfo]);

    useEffect(() => {
        if (prevSendSupportMessageLoading != sendSupportMessageLoading && prevSendSupportMessageLoading && !sendSupportMessageLoading) {
            setText('');
            // setSupportConversationsPage(1);
            // getSupportConversationTotalCountWorker();
            // getSupportConversationListWorker();
        }
    }, [sendSupportMessageLoading, prevSendSupportMessageLoading]);

    const onReject = useCallback(() => { 
        onRejectProduct(productDetailInfo.id, 5, text); 
        setText('');
        setSuggestReasonId(null);
    }, [text, productDetailInfo, onRejectProduct]);

    const onChangeText = useCallback((e) => setText(e.target.value), []);

    const onSuggestReasonItemChange = ({ item, index }) => {
        setSuggestReasonId(item.id);
        setText(item.name);
    }

    return (
        <div className='product-reject'>
            <div className='input-view'>
                <FormControl isInvalid={true} isValid={false}
                    value={text || ''}
                    onChange={onChangeText}
                    style={{ borderBottom: 'solid 1px #fe805c', minHeight: 80 }}
                    placeholder={'Nhập lý do từ chối'}
                    plaintext
                    maxLength={1024}
                    as="textarea" />
                <AcceptButton onClick={onReject} disabled={sendSupportMessageLoading} containerStyle={{ height: 'fit-content' }} title='Từ chối' />
            </div>
            <br/>
            <h4><b>Chọn 1 lý do gợi ý sẵn:</b></h4>
            <br/>
            <div className="col">
                <RadioGroup
                    inputKey='suggest-reject-reason'
                    checkedId={suggestReasonId}
                    onItemClicked={onSuggestReasonItemChange}
                    datas={rejectReasonList} />
            </div>
        </div>
    )
}

export default observer(ProductReject);
