import React, { Component } from 'react';
import { articleStores } from './stores/ArticleStores';
import InsertModal from './components/InsertModal';
import Table from './components/Table';
import { Button } from 'react-bootstrap'

import { observer } from 'mobx-react';

class Article extends Component {
    constructor(props) {
        super(props);
        this.onCloseInsertModal = this.onCloseInsertModal.bind(this);

    };
    componentDidMount() {
        articleStores.loadData()
    }

    onCloseInsertModal() {
        articleStores.showInsertModal = false;
    }
    render() {
        return (
            <div className="card">
                <div className="card-header card-header-primary">
                    <h4 className="card-title">Quản lý tin tức</h4>
                </div>

                <div className="card-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 offset-md-9" style={{
                                textAlign: 'end'
                            }}>
                                <Button onClick={() => {
                                    articleStores.showInsertModal = true
                                }} variant="primary">Thêm mới</Button>
                            </div>
                        </div>
                        <Table stores={articleStores} />
                    </div>
                </div>

                <InsertModal stores={articleStores} show={articleStores.showInsertModal} onHide={this.onCloseInsertModal} />
            </div>
        );
    }

    componentDidMount = async () => {
    };

}
export default observer(Article)