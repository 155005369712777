import { historyRefresh } from './history';

import toastr from 'toastr';
toastr.options.toastClass = 'toastr';
toastr.options.newestOnTop = false;
toastr.options.preventDuplicates = true;
toastr.options.tapToDismiss = true;
toastr.options.showDuration = 500;
toastr.options.hideDuration = 500;
toastr.options.timeOut = 3000;

class GlobalClass {

  setHistory(history) {
    this.history = history;
  }
  error = ({ message, title, params }) => toastr && toastr.error(message, title, params)
  info = ({ message, title, params }) => toastr && toastr.info(message, title, params)
  success = ({ message, title, params }) => toastr && toastr.success(message, title, params)
  warning = ({ message, title, params }) => toastr && toastr.warning(message, title, params)
  clear = () => toastr && toastr.clear()


  push = (route, params) => this.history && this.history.push(route, params);
  pushRefresh = (route, params) => historyRefresh && historyRefresh.push(route, params);

  replace = (route) => this.history && this.history.replace(route);
  replaceRefresh = (route) => historyRefresh && historyRefresh.replace(route);

  currentUrl = () => window.location.href || document.URL;

}

export let Global = new GlobalClass()