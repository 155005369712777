
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { advertisingStores } from '../stores/AdvertisingStores';
import { AcceptButton, CancelButton } from '../../components/Button/Button';
import { no_image } from '../../../common/images';
import { COLOR } from '../../../common/constant';
import { Switch } from '@material-ui/core';
import { Global } from '../../../GlobalClass';

class AdvertisingBannerDetailModal extends Component {
    constructor(props) {
        super(props);
    };
    onHide = () => {
        const { onBannerDetailModalChange, setDataBannerDetail } = toJS(advertisingStores);
        onBannerDetailModalChange(false);
        setTimeout(() => {
            setDataBannerDetail();
        }, 300);
    }

    onAcceptChange = async () => {
        const { bannerDetail, updateBannerAction, createBannerAction } = toJS(advertisingStores);
        const { id } = bannerDetail;
        const isEdit = !!id;
        if (isEdit) {
            updateBannerAction();
        } else {
            createBannerAction();
        }
    }

    onChangeStatus = (e, checked) => {
        advertisingStores.setDataBannerDetail({ ...toJS(advertisingStores).bannerDetail, status: checked })
    }

    onTitleChange = (e) => {
        const title = e.target.value;
        advertisingStores.setDataBannerDetail({ ...toJS(advertisingStores).bannerDetail, title });
    }

    onUrlChange = (e) => {
        const url = e.target.value;
        advertisingStores.setDataBannerDetail({ ...toJS(advertisingStores).bannerDetail, url });
    }

    onPickImage = () => this.inputFile.click();

    onPickFileSuccess = async (e) => {
        let file = e.target.files[0];
        if (!file) return;
        if (!file.type.includes('image')) return Global.error({ message: 'Vui lòng chọn file định dạng ảnh !' });
        const formData = new FormData();
        formData.append("file", file);
        advertisingStores.uploadBannerAction(formData);
    }



    render() {
        const { isShowBannerDetailModal, bannerDetail, uploadingBannerImage } = toJS(advertisingStores);
        const { id, title, image, url, status } = bannerDetail;
        const isEdit = !!id;
        return (
            <Modal
                show={isShowBannerDetailModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                animation
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                        {isEdit ? 'Chỉnh sửa' : 'Tạo mới'}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                    <div className='row table' style={{ alignItems: 'center' }}>
                        <img alt='' style={{ height: 300, maxWidth: '30vw', objectFit: 'contain', marginBottom: 6, marginTop: 16, border: `solid 0.5px ${COLOR.tertiary_light}` }}
                            src={image || no_image} />
                        <div className='td-actions text-center' style={{ marginLeft: 6 }}>
                            <button onClick={this.onPickImage} type="button" rel="tooltip" className="btn btn-warning btn-simple">
                                <i className="material-icons">edit</i>
                            </button>
                        </div>
                    </div>

                    <h4 className="card-title">Thông tin chi tiết</h4>

                    <div className='row'>
                        <div className="col-md-6" >
                            <div className="form-group bmd-form-group">
                                <label className="bmd-label-floating">Tiêu đề</label>
                                <input onChange={this.onTitleChange} value={title || ''} type="text" className="form-control" />
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }} className='row'>
                            <h6 className="card-category text-gray" style={{ margin: 'auto' }}>Trạng thái: </h6>
                            <Switch onChange={this.onChangeStatus} checked={!!status} />
                        </div>
                    </div>


                    <div className="col-md-12" style={{ padding: 0, marginTop: 20 }}>
                        <div className="form-group bmd-form-group">
                            <label className="bmd-label-floating">Đường dẫn</label>
                            <input onChange={this.onUrlChange} value={url || ''} type="text" className="form-control" />
                        </div>
                    </div>
                    {/* input file */}
                    <input hidden accept="image/*" onChange={this.onPickFileSuccess} ref={ref => this.inputFile = ref} type='file' id='img-banner' />
                </Modal.Body>

                <Modal.Footer>
                    {
                        uploadingBannerImage &&
                        <Loader
                            style={{ marginRight: '12px', marginTop: '8px' }}
                            type="TailSpin"
                            color={COLOR.primary}
                            height={30}
                            width={30}
                        />
                    }
                    <CancelButton onClick={this.onHide} title='Hủy bỏ' />
                    <AcceptButton disabled={uploadingBannerImage} onClick={this.onAcceptChange} title='Cập nhật' />
                </Modal.Footer>

            </Modal>
        );
    }
}

export default observer(AdvertisingBannerDetailModal);