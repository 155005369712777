import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import need_buy from '@img/need_buy.png';
import { useForm } from "react-hook-form";
import { shortenPrice } from '@helpers/shortenPrice';
import { uploadImageNoResize, AWS_IMAGE_BASE_URL, AWS_IMAGE_FOLDERS } from '@api/axios';
import { getFullImagePath } from '@helpers/getFullImagePath';
import { Global } from 'src/GlobalClass';
import { createNewPushSaleProduct, editPushSaleProduct } from '@api/pushSale/pushSale';
import _ from 'lodash';
import './styles.css';

const MAX_LENGTH = 99;

const PushSaleEditorModal = ({
  isShowing,
  closeModal,
  categories,
  selectedProduct,
  resetSelectedProduct,
  categoryImages,
  reloadData
}) => {
  const { register, handleSubmit, getValues, errors, watch, reset, setValue } = useForm();
  const [description, setDescription] = useState('');
  const [imageText, setImageText] = useState('');
  const [price, setPrice] = useState('');
  const [imageName, setImageName] = useState();
  const [cateImage, setCateImage] = useState();

  const inputFileRef = useRef(null);

  useEffect(() => {
    if (selectedProduct) {
      setValue('defaultTitle', selectedProduct.defaultTitle || '');
      setValue('imageText', selectedProduct.imageText || '');
      setValue('description', selectedProduct.description || '');
      setValue('cateId', selectedProduct.defaultCateId || '');
      setValue('price', selectedProduct.price);
      setDescription(selectedProduct.description);
      setImageText(selectedProduct.imageText);
      setPrice(selectedProduct.price);
      setImageName(selectedProduct.imageName);
      const selectedCate = categoryImages && _.find(categoryImages, { cateId: selectedProduct.defaultCateId });
      if (selectedCate) setCateImage(selectedCate.imageName);
    }
    else {
      categoryImages.length > 0 && setCateImage(categoryImages[0].imageName);
    }
  }, [selectedProduct, categoryImages])

  const handleCloseModal = () => {
    resetState();
    resetSelectedProduct();
    closeModal();
  }

  const resetState = () => {
    reset();
    setDescription('');
    setImageName('');
    setPrice('');
    setImageText('');
    categoryImages.length > 0 && setCateImage(categoryImages[0].imageName);
  }

  const onPickImage = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  }

  const onPickFileSuccess = async (e) => {
    let file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) return Global.error({ message: 'Vui lòng chọn file định dạng ảnh !' });
    const formData = new FormData();
    formData.append("file", file);
    uploadImage(formData);
  }

  const uploadImage = async (form) => {
    try {
      const result = await uploadImageNoResize(form);
      if (result.data) {
        setImageName(result.data.imageName);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const deleteUserPickedImage = () => setImageName(undefined);

  const onSubmit = async (data) => {
    const payloadSubmit = { ...data, imageName: imageName, cateId: parseInt(data.cateId) }
    try {
      if (selectedProduct) {
        const editPayloadSubmit = { ...payloadSubmit, id: selectedProduct.id }
        const result = await editPushSaleProduct(editPayloadSubmit);
        if (result.responseCode.toString().startsWith('2')) {
          reloadData();
          Global.success({ message: 'Chỉnh sửa sản phẩm thành công' });
          handleCloseModal();
        }
        else Global.error({ messgae: 'Có lỗi xảy ra. Vui lòng thử lại' })
      }
      else {
        const result = await createNewPushSaleProduct(payloadSubmit);
        if (result.responseCode.toString().startsWith('2')) {
          reloadData();
          Global.success({ message: 'Tạo sản phẩm thành công' });
          handleCloseModal();
        }
        else Global.error({ messgae: 'Có lỗi xảy ra. Vui lòng thử lại' })
      }
    }
    catch (err) {
      Global.error({ messgae: 'Có lỗi xảy ra. Vui lòng thử lại' })
    }
  };

  return <div>
    <Modal
      className='push-sale-editor-modal'
      show={isShowing}
      size='xl'
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      animation
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton onHide={handleCloseModal} >
        <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
          {'Sản phẩm kích bán'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fe805c', padding: 20, alignItems: 'center' }} >
            <div style={{ width: 183 }} >
              <div style={{
                position: 'relative', backgroundImage: `url(${getFullImagePath(imageName || cateImage)})`,
                width: 183, height: 229, borderRadius: 16,
                backgroundSize: 'contain',
                display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 22
              }} >
                <img
                  style={{ position: 'absolute', top: -2, left: -2 }}
                  src={need_buy}
                />
                <p
                  style={{ fontSize: 16, fontWeight: 900, fontFamily: 'Montserrat', color: '#c5d9ff', fontStyle: 'italic', fontStretch: 'ultra-expanded', wordBreak: 'break-all' }}
                >
                  {!imageName ? imageText : ''}
                </p>
              </div>
              <p
                style={{ fontSize: 12, marginBottom: 0, fontWeight: 600, color: 'white', lineHeight: 1.5, fontFamily: 'Montserrat', marginTop: 8, wordBreak: 'break-all' }}
              >
                {description}
              </p>
              <Row>
                <Col xs={4} style={{ display: 'flex', alignItems: 'center' }} >
                  <p
                    style={{ marginBottom: 0, fontSize: 16, fontFamily: 'Montserrat', fontWeight: 'bold', color: 'white' }}
                  >{shortenPrice(price)}</p>
                </Col>
                <Col xs={8} style={{ justifyContent: 'flex-end', display: 'flex' }} >
                  <button
                    style={{ borderRadius: 20, color: 'white', backgroundColor: '#333333', fontSize: 12, fontWeight: 600, border: 'none', padding: '8px 20px', fontFamily: 'Montserrat' }}
                  >Bán ngay</button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <form onSubmit={handleSubmit(onSubmit)} >
              <label className='label'>Loại mặt hàng</label>
              <select
                className='inputView'
                name="cateId"
                ref={register({ required: true })}
                onChange={e => {
                  const selectedCate = categoryImages.length > 0 && _.find(categoryImages, cate => cate.cateId == e.target.value);
                  if (selectedCate) setCateImage(selectedCate.imageName);
                  if (selectedProduct.imageName && !!_.find(categoryImages, { imageName: imageName })) setImageName(undefined);
                }}
              >
                {categories && categories.map((cat, index) => <option
                  value={cat.id}
                  key={cat.id.toString()}
                >{cat.categoryName}</option>)}
              </select>
              {errors.cateId && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
              <div className='td-actions text-center'>
                <button onClick={onPickImage} type="button" rel="tooltip" className="btn btn-warning btn-simple">
                  <i className="material-icons">add_circle</i>
                </button>
                <button onClick={deleteUserPickedImage} type="button" rel="tooltip" className="btn btn-danger btn-simple">
                  <i className="material-icons">delete</i>
                </button>
              </div>
              <label className='label'>Tên sản phẩm mặc định</label>
              <input className='inputView' name="defaultTitle" ref={register({ required: true })} />
              {errors.defaultTitle && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
              <label className='label'>Chữ trên ảnh</label>
              <input
                className='inputView'
                name="imageText"
                ref={register}
                onChange={e => setImageText(e.target.value)}
              />
              <label className='label'>Giá</label>
              <input
                className='inputView'
                name="price"
                type='number'
                ref={register({ required: true })}
                onChange={e => setPrice(e.target.value)}
              />
              {errors.price && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
              <label className='label'>Mô tả</label>
              <textarea className='inputView' name="description" ref={register} rows={5} onChange={e => setDescription(e.target.value)} maxLength={MAX_LENGTH} />
              {errors.price && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
              <input className='inputView' type="submit" value={selectedProduct ? 'Thay đổi' : 'Tạo mới'} />
            </form>
            <input className='inputView' hidden accept="image/*" onChange={onPickFileSuccess} ref={inputFileRef} type='file' id='img-banner' />
          </Col>
        </Row>
      </Modal.Body>

    </Modal>
  </div>
}

export default PushSaleEditorModal;