import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { toJS } from 'mobx';
import moment from 'moment';
import _ from 'lodash'
import { manageAdvertisingStores } from '../../stores/ManageAdvertisingStores';
import PaginationTable from '../../../components/PaginationTable';
import { user_avatar_default } from '../../../../common/images';
import { Switch } from '@material-ui/core';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';



class AdvertisingListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, resize: 'both', height: 80, objectFit: 'cover' }} src={row.thumbImage || user_avatar_default} />
        </div>
    }


    activeFormatter = (cell, row) => {
        return (
            <div className="td-actions text-left">
                <Switch
                    onChange={(e, checked) => {
                        manageAdvertisingStores.updateAdvertisingdStatusStore(row.id, !checked);
                    }}
                    checked={!row.deletedAt} />
            </div>
        )
    }
    expireFormatter = (cell, row) => {
        return <div className="td-actions text-left">
            {
                moment(row.endDate) <= moment() ?
                    <span className="badge badge-pill badge-danger">Hết hạn</span>
                    :
                    <span className="badge badge-pill badge-success">Hoạt động</span>
            }
        </div>
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
            <button onClick={this.onShowEditModal(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">edit</i>
            </button>
            <button onClick={this.onShowDeleteConfirmModal(row.id)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-danger btn-simple">
                <i className="material-icons">delete</i>
            </button>
        </div>
    }


    onShowEditModal = (item) => () => {
        if (!item || !item.id) return;
        const dataItem = {
            id: item.id,
            objectTree: item.payload || {},
            adsName: item.advertisingName,
            adTypeId: item.type,
            endDate: moment(item.endDate).format('DD/MM/YYYY'),
            uploadedUrl: item.thumbImage,
            deletedAt: item.deletedAt
        }
        manageAdvertisingStores.onSelectItemToEdit(dataItem);
    }
    onShowDeleteConfirmModal = (id) => () => {
        if (!id) return;
        manageAdvertisingStores.onChooseItemAdsToDelete(id);
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        let style = {};
        if (moment(row.endDate) <= moment()) {
            style = {
                fontWeight: 'bold',
                color: 'red',
                textDecoration: 'underline',
                textDecorationColor: 'red'
            }
        }
        return (
            <div>
                <span style={style}>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'thumbImage',
                text: 'Hình ảnh',
                formatter: this.thumbFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'advertisingName',
                text: 'Tên quảng cáo',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'createdAt',
                text: 'Ngày tạo',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'endDate',
                text: 'Ngày kết thúc',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'deletedAt',
                text: 'Trạng thái',
                formatter: this.activeFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            }
        ];
    }


    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        manageAdvertisingStores.setAdvertisingPage(page);
        this.props.getAdvertisingList();
    }

    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: toJS(manageAdvertisingStores).totalAdvertisingListCount,
            page: manageAdvertisingStores.advertisingListCurrentPage,
        };
        return paginationOption;
    }


    render() {
        const { advertisingList, getAdvertisingListLoading } = toJS(manageAdvertisingStores);
        return (
            <div className='advertising-list'>
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    onTableChange={this.onTableChange}
                    datas={advertisingList}
                    columns={this.columnsOption()}
                    loading={getAdvertisingListLoading}
                />

                <ConfirmModal
                    title='Xác nhận'
                    message='Bạn có chắc chắn muốn xóa?'
                    isShow={!!manageAdvertisingStores.idAdsToDelete}
                    onCancel={() => manageAdvertisingStores.onChooseItemAdsToDelete(0)}
                    onAccepts={manageAdvertisingStores.onDeleteADS}
                />
            </div>
        )
    }

    componentDidMount = () => {
    };


}

export default observer(AdvertisingListTable);