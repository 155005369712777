import React, { Component } from 'react';
import { observer } from 'mobx-react';
// import {
//     Link
// } from "react-router-dom";
// import { ROUTER } from '../../common/route';
// import { toJS } from 'mobx';
// import * as mobx from 'mobx';
// import { Button } from 'react-bootstrap';


class Stats extends Component {
    constructor(props) {
        super(props);
    };


    render() {

        return (
            <div className='content'>
                <a href="https://api.uat.aladin.today/public/remoteLog/getLocaleStats" target="_blank">01. Thông số của các địa phương</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getQKUserNumber" target="_blank">02. Số lượng thành viên Quả Khế</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getQKProductNumber" target="_blank">03. Số mặt hàng được bán từ thành viên Quả Khế</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getQKAskNumber" target="_blank">04. Số lượt hỏi mua từ thành viên Quả Khế</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getGroupNumber" target="_blank">05. Số hội đang hoạt động trên các địa phương</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getEventTetStats" target="_blank">06. Thống kê các chỉ số của event Tết</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getEventTopEnergy" target="_blank">07. Top 100 hoạt động event</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getA1Stats" target="_blank">08. Thống kê chỉ số A1</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getLocaleA1Stats" target="_blank">09. Thống kê các chỉ số A1 của địa phương</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getTopInviteFriend" target="_blank">10. Top 100 mời bạn</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getEventDBNLVStats" target="_blank">11. Thống kê các chỉ số của event Bán Ngay Nhận Lộc Vàng</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getEventXNLStats" target="_blank">12. Thống kê các chỉ số của event Xoay Nhận Lộc</a>
                <br/>
                <a href="https://api.uat.aladin.today/public/remoteLog/getLastOTPs" target="_blank">13. Các OTPs cuối cùng</a>
                <br/>
            </div>
        );
    }

}

export default observer(Stats)