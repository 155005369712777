import React, { Component } from 'react';
import { Modal, Form } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import './insertmodal.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { observer } from 'mobx-react';
import { manageAdvertisingStores } from '../stores/ManageAdvertisingStores';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { AcceptButton, CancelButton } from '../../components/Button/Button';
import * as mobx from 'mobx';
import AdvertisingForm from './AdvertisingForm';
import { Global } from '../../../GlobalClass';
import moment from 'moment';
import { Switch } from '@material-ui/core';

class InsertModal extends Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
    };

    onDrop(acceptedFiles, rejectedFiles) {
        manageAdvertisingStores.onDrop(acceptedFiles, rejectedFiles);
    }

    componentDidMount() {
        manageAdvertisingStores.getTypeOfAds();
    }

    onAdTypeChange = (e) => {
        const { adTypes, adsObjectSelected } = manageAdvertisingStores;
        const adKey = e.target.value;
        if (adKey == 0) {
            manageAdvertisingStores.adsObjectSelected = {
                ...mobx.toJS(adsObjectSelected),
                objectTree: {},
                adTypeId: 0
            }
            return;
        }
        const itemAds = mobx.toJS(adTypes).find(o => o.value == adKey);
        if (!itemAds) return;
        manageAdvertisingStores.adsObjectSelected = {
            ...mobx.toJS(adsObjectSelected),
            objectTree: itemAds.payloadSchema,
            adTypeId: itemAds.value
        }
    }

    onHide = () => {
        manageAdvertisingStores.onHideStore();
    }

    onSave = async () => {
        const { adsObjectSelected } = manageAdvertisingStores;
        if (!adsObjectSelected.uploadedUrl) return Global.error({ message: `Vui lòng upload ảnh quảng cáo` });
        if (!adsObjectSelected.adsName) return Global.error({ message: `Vui lòng nhập tên quảng cáo` });
        if (!adsObjectSelected.endDate) return Global.error({ message: `Vui lòng nhập ngày kết thúc` });
        if (!adsObjectSelected.adTypeId) return Global.error({ message: `Vui lòng chọn loại quảng cáo` });
        for (const key in adsObjectSelected.objectTree) {
            const itemValue = adsObjectSelected.objectTree[key];
            if (itemValue.require && !adsObjectSelected.objectTree[key]) {
                Global.error({ message: `Vui lòng nhập ${itemValue.title}` })
                return;
            }
        }
        await manageAdvertisingStores.onBtnSavePressed();
        this.onHide();
    }

    render() {
        const { ...rest } = this.props;
        const {
            adsObjectSelected,
            uploadingImage,
            adTypes,
            savingData
        } = manageAdvertisingStores;

        return (
            <Modal
                onHide={this.onHide}
                {...rest} size='lg' aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">
                        Quảng cáo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <Dropzone
                                    accept=".jpg,.png"
                                    multiple={false}
                                    onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <div id="box-shadow">
                                                    {
                                                        adsObjectSelected.uploadedUrl != '' ?
                                                            <img src={adsObjectSelected.uploadedUrl} id="upload-image" />
                                                            :
                                                            <div>
                                                                {!uploadingImage ? "Click here or Drag & Drop to upload image" : ""}
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                <div style={{ position: 'absolute', top: 90, left: 100 }}>
                                    {
                                        uploadingImage
                                        &&
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={30}
                                            width={30}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{ marginTop: '30px' }}>
                        <div className="col-md-12">
                            <Form>
                                <Form.Label>Tên quảng cáo</Form.Label>
                                <Form.Control onChange={e => adsObjectSelected.adsName = e.target.value} value={adsObjectSelected.adsName} type="text" placeholder="Nhập tên quảng cáo" />
                                <Form.Label style={{ marginTop: 24 }}>Loại quảng cáo</Form.Label>
                                <Form.Control
                                    value={adsObjectSelected.adTypeId}
                                    onChange={this.onAdTypeChange} as='select'>
                                    <option
                                        value={0}>Chọn quảng cáo</option>
                                    {
                                        mobx.toJS(adTypes).map((element, index) => {
                                            return (
                                                <option key={index}
                                                    value={element.value}>{element.label}</option>
                                            )
                                        })
                                    }
                                </Form.Control>
                                <Form.Group controlId="form">
                                    <div style={{ flexDirection: 'row' }}>
                                        <Form.Label style={{ marginRight: 12 }}>Ngày kết thúc</Form.Label>

                                        <DatePicker
                                            value={adsObjectSelected.endDate}
                                            adjustDateOnChange={false}
                                            className="my-date-picker"
                                            selected={new Date()}
                                            dateFormat='dd/MM/yyyy'
                                            onBlur={() => {
                                                if (!moment(adsObjectSelected.endDate, 'DD/MM/YYYY').isValid() || adsObjectSelected.endDate.length != 10)
                                                    adsObjectSelected.endDate = moment().format('DD/MM/YYYY');
                                            }}
                                            onChange={async (date) => {
                                                if (!!date)
                                                    adsObjectSelected.endDate = moment(date).format('DD/MM/YYYY');
                                            }} />

                                        <span style={{ marginLeft: 16 }}>
                                            <Switch
                                                onChange={(e, checked) => adsObjectSelected.deletedAt = !checked ? new Date() : null}
                                                checked={!adsObjectSelected.deletedAt}
                                            />
                                        </span>
                                    </div>
                                </Form.Group>

                                <AdvertisingForm objectTree={adsObjectSelected.objectTree} />
                            </Form>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <AcceptButton disabled={savingData} onClick={this.onSave} title='Lưu' />
                    <CancelButton onClick={this.onHide} title='Hủy bỏ' />
                </Modal.Footer>
            </Modal>
        );
    }
}

export default observer(InsertModal);
