import { requestApi } from "../axios";
const prefix = 'user-profile';

export function getVerifyPhoneListCountApi(search) {
    return requestApi(`${prefix}/non-verifying-phone-count`, 'POST', { search: search });
}
export function getVerifyPhoneRejectTypeListApi() {
    return requestApi(`${prefix}/verify-phone-reject-type-list`, 'GET');
}
export function getVerifyPhoneRequestListApi(offset, search) {
    const url = `${prefix}/non-verifying-phone-list`
    return requestApi(url, 'POST', { limit: 10, offset: offset || 0, search: search });
}
export function approveRequestVerifyPhoneApi(userId) {
    const url = `${prefix}/approve-phone`
    return requestApi(url, 'POST', { userId });
}
export function rejectRequestVerifyPhoneApi(payload) {
    const url = `${prefix}/reject-phone`
    return requestApi(url, 'POST', payload);
}