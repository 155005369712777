import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'react-bootstrap';
import { manageAdvertisingStores } from '../../stores/ManageAdvertisingStores';
import { toJS } from 'mobx';

class ComboboxADSForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: []
        }
    };

    onChange = (e) => {
        const key = e.target.value;
        if (key == 0)
            delete manageAdvertisingStores.adsObjectSelected.objectTree[this.props.itemKey].key;
        else manageAdvertisingStores.adsObjectSelected.objectTree[this.props.itemKey].key = key || null;
    }

    render() {
        const { datas } = this.state;
        const { item, itemKey } = this.props;
        const { adsObjectSelected } = manageAdvertisingStores;
        const comboboxData = !!datas.length ? datas : (item.items || []);
        return (
            <div style={{ marginTop: 30 }}>
                <Form.Label>{item.title}</Form.Label>
                <Form.Control
                    value={adsObjectSelected.objectTree[this.props.itemKey].key || 0}
                    onChange={this.onChange} as='select'>
                    <option
                        value={0}>Chọn</option>
                    {
                        toJS(comboboxData).map((element, index) => {
                            return (
                                <option
                                    key={index}
                                    value={element.key}>{element.value}</option>
                            )
                        })
                    }
                </Form.Control>
            </div>
        );
    }


    componentDidMount = async () => {
        const { item } = this.props;
        const { url } = item;
        if (!url) return;
        const datas = await manageAdvertisingStores.getComboboxData(item.url);
        this.setState({ datas })
    };


}

export default observer(ComboboxADSForm)