import { extendObservable, toJS } from 'mobx';
import { pageableOffset } from '../../../helpers/CF';
import { getFeedbackListApi, getFeedbackTotalCountApi, responseFeedbackApi, updateFeedbackStatusApi } from '../../../api/feedback/feedback';
import { Global } from '../../../GlobalClass';
class FeedbackStores {
    constructor() {
        extendObservable(this, {
            feedbackList: [],
            loadingFeedbackList: false,
            totalFeedbackCount: 0,
            feedbackCurrentPage: 1,
            isShowFeedbackResponseModal: false,
            idFeedbackToResponse: 0,
            feedbackCurentItem: {}
        });
    }

    getFeedbackTotalCountAction = async () => {
        const result = await getFeedbackTotalCountApi();
        if (result.responseCode.toString().startsWith('2')) {
            this.totalFeedbackCount = parseInt(result.data.total);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    getFeedbackListAction = async () => {
        this.loadingFeedbackList = true;
        const offset = pageableOffset(this.feedbackCurrentPage);
        const result = await getFeedbackListApi(offset);
        if (result.responseCode.toString().startsWith('2')) {
            this.feedbackList = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.loadingFeedbackList = false;
    }

    updateStatusWorker = async (id, status) => {
        this.loadingFeedbackList = true;
        try {
            const result = await updateFeedbackStatusApi(id, status);
            if (result.responseCode.toString().startsWith('2')) {
                const index = toJS(this.feedbackList).findIndex(o => o.id == id);
                if (index > -1) {
                    this.feedbackList[index].status = status;
                }
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.loadingFeedbackList = false;
    }

    onChangeFeedbackResponseModal = (status) => this.isShowFeedbackResponseModal = status;

    setIdFeedbackToResponse = (item) => {
        this.idFeedbackToResponse = item.id;
        this.feedbackCurentItem = item;
    }

    setFeedbackPage = (page) => this.feedbackCurrentPage = page;

    responseFeedbackAction = async (message) => {
        const result = await responseFeedbackApi(this.idFeedbackToResponse, message);
        if (result.responseCode.toString().startsWith('2')) this.getFeedbackListAction();
        else Global.error({ message: result.responseText || '' });
    }
}
export const feedbackStores = new FeedbackStores();