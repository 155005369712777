import { extendObservable } from 'mobx';
import { getOrderListApi, getTotalOrderCountApi } from '../../../api/order/order';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';

class OrderListStores {
    constructor() {
        extendObservable(this, {
            orderList: [],
            getOrderListLoading: false,
            totalOrderListCount: 0,
            orderTableCurrentPage: 1,
            isShowOrderDetailModal: false,
            txtSearchOrder: '',
            // orderFilterSelected: radioFilterOrderListData[0].id  
        });
    }
    onSearchOrderChange = (txt) => this.txtSearchOrder = txt || '';

    setOrderFilterSelected = (id) => this.orderFilterSelected = id;

    getCurrentState = order => order.progress.find(state => state.name === order.progressStep) || {name: 'N/a', status: 'N/a', result: 'N/a'};

    getOrderListStore = async () => {
        try {
            this.getOrderListLoading = true;
            const offset = pageableOffset(this.orderTableCurrentPage);
            const result = await getOrderListApi(offset, this.txtSearchProduct || '');
            if (result.responseCode.toString().startsWith('2')) {
                this.orderList = (result.dataArray || []).map(order => ({...order, currentState: this.getCurrentState(order)}));
            } else {
                Global.error({ message: result.responseText || '' });
            }
            this.getOrderListLoading = false;
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.getOrderListLoading = false;
        }
    }
    setOrderTablePage = (page) => this.orderTableCurrentPage = page;

    getTotalOrderCount = async (filter) => {
        const result = await getTotalOrderCountApi(this.txtSearchOrder || '', null, filter);
        if (result.responseCode.toString().startsWith('2')) {
            this.totalOrderListCount = parseInt(result.data.count);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

}
export const orderListStores = new OrderListStores();