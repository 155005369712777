import { extendObservable } from 'mobx';
import { getLocationsOfProduct, getLocationReport } from '../../../api/report/report';
import moment from 'moment';
import * as mobx from 'mobx';

class ChartReportUserByLocationStores {
    constructor() {
        extendObservable(this, {
            fromDate: moment(new Date()).subtract(1, 'month').toDate(),
            toDate: new Date(),
            locations: [],
            selectedLocation: '',
            inputValue: '',
            userInLocation: [],
            userRegistered: [],
            productSales: [],
            productMarkAsSold: [],
        });
    }

    getLocations = async () => {
        const locations = await getLocationsOfProduct();
        var tempLocations = [];
        var i = 1;
        for (const location of locations.dataArray) {
            tempLocations.push({
                id: i,
                title: location.areaText,
            });
            i++;
        }
        this.locations = tempLocations;
    }

    getReport = async () => {
        const mFromDate = moment(mobx.toJS(this.fromDate)).format('YYYY-MM-DD');
        const mToDate = moment(mobx.toJS(this.toDate)).format('YYYY-MM-DD');

        const data = await getLocationReport(mFromDate, mToDate, this.inputValue);
        this.userInLocation = data.data.userInLocation;
        this.userRegistered = data.data.userRegistered;
        this.productSales = data.data.productSales;
        this.productMarkAsSold = data.data.productMarkAsSold;
    }
}
export const chartReportUserByLocationStores = new ChartReportUserByLocationStores();