import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AcceptButton } from '../../../components/Button/Button';
import { Global } from '../../../../GlobalClass';
import { cloneProductChototApi } from '../../../../api/cloneProductChotot/cloneProductChotot';

const CreateNewProductFromChoTotoModal = ({
  closeModal,
  isShowing
}) => {
  const [productId, setProductId] = useState('')

  const cloneProduct = async () => {
    try {
      const result = await cloneProductChototApi(productId);
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({messgae: 'Tạo thành công'});
        setProductId('');
        closeModal();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (err) {
      Global.error({message: 'Tạo thất bại'})
    }
  }

  return <Modal 
    show={isShowing}
    size='xl'
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={() => {}}
  >
    <Modal.Header closeButton onHide={closeModal} >
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        {'Tạo sản phẩm mới từ Chợ Tốt'}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body >
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}} >
        <input style={{
          fontSize: '18px', padding: '5px', width: '50%', marginRight: '16px'}} 
          placeholder='Nhập id sản phẩm trên Chợ Tốt' 
          value={productId}
          onChange={e => setProductId(e.target.value)}
        />
        <AcceptButton 
          containerStyle={{ height: 'fit-content' }} 
          onClick={cloneProduct} 
          title='Tạo sản phẩm'
        />
      </div>
    </Modal.Body>

  </Modal>
}

export default CreateNewProductFromChoTotoModal;