import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { AcceptButton, CancelButton } from '../../../components/Button/Button';
import { observer } from 'mobx-react';
import { userListStores } from '../../stores/UserListStores';
import { Global } from '../../../../GlobalClass';

class ChangePasswordModal extends Component {
    initialState = {
        txtPassword: '',
        txtRetypePassword: '',
    }
    constructor(props) {
        super(props);
        this.state = this.initialState;
    };

    onHide = () => {
        userListStores.onChangePassModalStatusChange(false);
        userListStores.setUserIdPasswordChange(0);
        this.setState(this.initialState);
    }


    onChangeText = (type, e) => {
        if (type === 'first') {
            this.setState({ txtPassword: e.target.value })
        } else {
            this.setState({ txtRetypePassword: e.target.value })
        }
    }

    onAcceptChange = () => {
        const { txtPassword, txtRetypePassword } = this.state;
        if (txtPassword != txtRetypePassword) return Global.error('Xác nhận mật khẩu không khớp.');
        userListStores.changePasswordStore(txtPassword);
        this.onHide();
    }


    render() {
        const { txtPassword, txtRetypePassword } = this.state;
        return (
            <Modal
                show={userListStores.isShowChangePassModal}
                size='sm'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                animation
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                        {'Đặt lại mật khẩu'}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                    <div>
                        {/* <img
                            style={{ width: 400, height: 300, objectFit: 'cover', marginBottom: 6 }}
                            src={reviewVerifyIdItemDetail.imgId || ''} /> */}
                        <div style={{ flexDirection: 'row' }}>
                            <input
                                style={{ marginBottom: 12 }}
                                onChange={e => this.onChangeText('first', e)}
                                type="text"
                                value={txtPassword}
                                className="form-control"
                                placeholder="Nhập mât khẩu mới" />
                            <input
                                style={{ marginBottom: 12 }}
                                onChange={e => this.onChangeText('second', e)}
                                type="text"
                                value={txtRetypePassword}
                                className="form-control"
                                placeholder="Xác nhận lại mật khẩu mới" />

                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <CancelButton onClick={this.onHide} title='Hủy bỏ' />
                    <AcceptButton onClick={this.onAcceptChange} title='Đồng ý' />
                </Modal.Footer>

            </Modal>
        );
    }
}


export default observer(ChangePasswordModal);