import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { articleStores } from '../stores/ArticleStores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { user_avatar_default } from '../../../common/images';
import moment from 'moment'
import _ from 'lodash';
import Switch from "react-switch";


class Table extends Component {
  paginationOption = () => {
    const paginationOption = {
      custom: true,
      hideSizePerPage: true,
      page: articleStores.currentPage,
    };
    return paginationOption;
  }

  columnsOption = () => {
    return [
      {
        dataField: 'id',
        text: '#',
        headerFormatter: this.headerFormatter,
        headerStyle: {
          width: 40
        }
      },
      {
        dataField: 'thumbImage',
        text: 'Ảnh',
        formatter: this.imageFormatter,
        headerFormatter: this.headerFormatter,
        headerStyle: {
          width: 100,
          textAlign: 'center'
        }
      },
      {
        dataField: 'title',
        text: 'Title',
        headerFormatter: this.headerFormatter,
        headerStyle: {
          width: 70,
          textAlign: 'center'
        }
      },
      {
        dataField: 'subTitle',
        text: 'subTitle',
        headerFormatter: this.headerFormatter,
        headerStyle: {
          width: 170
        }
      },
      {
        dataField: 'createdAt',
        text: 'Ngày tạo',
        headerFormatter: this.headerFormatter,
        formatter: this.dateFormatter,
        headerStyle: {
          width: 100
        }
      },
      {
        dataField: '',
        text: 'Edit',
        headerFormatter: this.headerFormatter,
        formatter: this.actionButtons,
        headerStyle: {
          width: 80
        }
      },
      {
        dataField: '',
        text: 'Delete',
        headerFormatter: this.headerFormatter,
        formatter: this.deleteButtons,
        headerStyle: {
          width: 80
        }
      },
    ];
  }

  headerStyleDefault = (column, colIndex) => ({
    width: 100,
    textAlign: 'center'
  })

  imageFormatter = (cell, row) => {
    return <div className="td-actions text-center">
      <img src={cell} width={50} height={50} />
    </div >
  }

  actionButtons = (cell, row) => {
    return <div className="td-actions text-center">
      <button onClick={() => articleStores.onEditClicked(row)} style={{ marginRight: 6 }}
        type="button" rel="tooltip" className="btn btn-info btn-simple">
        <i className="material-icons">create</i>
      </button>
    </div >
  }

  deleteButtons = (cell, row) => {
    return <div className="td-actions text-center">
      <button onClick={() => {
        var r = window.confirm('Are you sure you want to delete? ');
        if (r) {
          articleStores.onDeleteClicked(row)
        }
      }

      } style={{ marginRight: 6 }}
        type="button" rel="tooltip" className="btn btn-info btn-simple">
        <i className="material-icons">delete</i>
      </button>
    </div >
  }

  dateFormatter = (cell, row) => {
    return <div className="td-actions text-center">
      {moment(cell).format('DD/MM/YYYY hh:mm:ss')}
    </div >
  }

  headerFormatter = (column, colIndex) => {
    return <h5><strong>{column.text}</strong></h5>
  }

  onTableChange = (type, { page, sizePerPage }) => {
    window.scrollTo(0, 0);
    articleStores.currentPage = page;
    articleStores.loadData();
  }

  render() {
    const data = toJS(articleStores).datas;
    return (
      <PaginationTable
        paginationOptions={this.paginationOption()}
        onTableChange={this.onTableChange}
        datas={data}
        columns={this.columnsOption()}
        loading={articleStores.loading}
      />
    );
  }
}

export default observer(Table);