import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { AcceptButton } from '../../../components/Button';
import { no_image } from 'src/common/images';
import { COLOR } from 'src/common/constant';
import { Global } from 'src/GlobalClass';
import PayloadForm from './PayloadForm';
import { uploadImageNoResize, AWS_IMAGE_BASE_URL, AWS_IMAGE_FOLDERS } from 'src/api/axios';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { createEvent, editEvent } from 'src/api/events/events';

const EventTypes = [
  {id: 1, name: 'Đăng bán 1 sản phẩm mới', action: 'POST_SCREEN'},
  {id: 2, name: 'Mở browser với link có sẵn', action: 'OPEN_URL'},
  {id: 3, name: 'Mở 1 topic trong Forum', action: 'FORUM_TOPIC'},
  {id: 4, name: 'Like fanpage Aladin', action: 'LIKE_ALADIN_FANPAGE'},
  {id: 5, name: 'Mở trang cá nhân', action: 'MY_PROFILE'},
  {id: 6, name: 'Tìm kiếm từ khoá', action: 'SEARCH_SCREEN'},
  {id: 7, name: 'Mã mời bạn bè', action: 'MY_PROFILE_INVITE_FRIEND'},
  {id: 8, name: 'Tìm kiếm người bán quanh bạn', action: 'MY_PROFILE_FIND_SELLER_NEAR_ME'},
  {id: 9, name: 'Đánh giá ứng dụng Aladin', action: 'ALADIN_RATE'},
  {id: 10, name: 'Nhận lượt đẩy tin', action: 'RECEIVE_BOOST'},
  {id: 11, name: 'Kết quả sự kiện Wish Product', action: 'WISH_RESULT' },
  {id: 12, name: 'Xem thông tin chi tiết Wallet sự kiện Wish Product', action: 'WISH_INFO'},
  {id: 13, name: 'Giới thiệu 1 sản phẩm đặc biệt', action: 'PRODUCT_DETAIL'},
  {id: 14, name: 'Mở danh sách kích bán', action: 'BUYING_REQUEST_LIST'},
  {id: 15, name: 'Mở danh sách sản phẩm của 1 user (merchant)', action: 'PUBLIC_PROFILE_PRODUCTS'},
  {id: 16, name: 'Mở browser bên trong app', action: 'OPEN_URL_INAPP'},
  {id: 17, name: 'Mở danh sách đấu giá', action: 'BIDDING_LIST_SCREEN'},
  {id: 18, name: 'Mở sound leaderboard', action: 'SOUND_LEADERBOARD'},
  {id: 19, name: 'Tạo thiệp mừng', action: 'CREATE_GREETING_CARD'},
  {id: 20, name: 'Mời vào locale tag', action: 'LOCALE_TAG_INVITE'},
]

const EditEventCoverModal = ({
  isShow,
  onHide,
  event, 
  reload,
  reset,
  categories
}) => {
  const inputFileRef = useRef(null);
  const [eventType, setEventType] = useState(event ? event.payload.action : undefined);
  const [payload, setPayload] = useState(event ? event.payload : undefined);
  const [disabledSubmit, setDisabledSubmit]= useState(true);
  const [imageUrl, setImageUrl] = useState(event ? event.cover : undefined);
  const [eventName, setEventName] = useState(event ? event.name : '');
  const [startDate, setStartDate] = useState(event ? event.startDate : undefined);
  const [endDate, setEndDate] = useState(event ? event.endDate : undefined);
  const [errLinkMessage, setErrLinkMessage] = useState();
  const [cateId, setCateId] = useState(event ? event.cateId : undefined);

  useEffect(() => {
    if (event) {
      setEventType(event.payload.action);
      setPayload(event.payload);
      setImageUrl(event.cover);
      setEventName(event.name);
      setStartDate(event.startDate);
      setEndDate(event.endDate);
      setCateId(event.cateId);
    }
  }, [event])

  useEffect(() => {
    if (!!payload && !!eventType && !!imageUrl && !!eventName && !!startDate && !!endDate) {
      setDisabledSubmit(false);
      return;
    }
    if (!disabledSubmit) setDisabledSubmit(true);
  }, [payload, eventType, imageUrl, eventName, startDate, endDate])
  
  const onPickImage = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  }

  const onPickFileSuccess = async (e) => {
    let file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) return Global.error({ message: 'Vui lòng chọn file định dạng ảnh !' });
    const formData = new FormData();
    formData.append("file", file);
    uploadImage(formData);
  }

  const uploadImage = async (form) => {
    try {
      const result = await uploadImageNoResize(form);
      if (result.data) {
        setImageUrl(result.data.imageName ? AWS_IMAGE_BASE_URL + AWS_IMAGE_FOLDERS.lg + '/' + result.data.imageName : result.data.uploadedUrl);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  const handleSubmit = async () => {
    if (eventType === 'OPEN_URL') {
      if (!validURL(payload.url)) {
        setErrLinkMessage('Link không hợp lệ');
        return;
      }
    }
    if (errLinkMessage) setErrLinkMessage(undefined);
    try {
      let result;
      if (event) {
        result = await editEvent({
          id: event.id,
          name: eventName,
          cover: imageUrl,
          payload,
          startDate,
          endDate,
          cateId: cateId === '-1' ? null : cateId
        })
      }
      else {
        result = await createEvent({
          name: eventName,
          cover: imageUrl,
          payload,
          startDate,
          endDate,
          cateId: cateId === '-1' ? null : cateId
        })
      }
      if (result.responseCode.toString().startsWith('2')) {
        resetState();
        onHide();
        reload();
        reset();
        Global.success({message: 'Tạo sự kiện thành công'})
      }
      else Global.error({messgae: 'Có lỗi xảy ra. Vui lòng thử lại'})
    }
    catch (err) {
      Global.error({messgae: 'Có lỗi xảy ra. Vui lòng thử lại'})
    }
  }

  const resetState = () => {
    setEventName('')
    setEventType(undefined)
    setPayload(undefined)
    setStartDate(undefined)
    setEndDate(undefined)
    setImageUrl(undefined)
    setCateId(-1);
  }


  const renderPayloadForm = () => {
    if (!!!payload) setPayload({action: eventType})
    switch (eventType) {
      case 'LIKE_ALADIN_FANPAGE':
      case 'POST_SCREEN':
      case 'MY_PROFILE':
      case 'MY_PROFILE_INVITE_FRIEND':
      case 'MY_PROFILE_FIND_SELLER_NEAR_ME':
      case 'ALADIN_RATE':
      case 'RECEIVE_BOOST':
      case 'WISH_RESULT':
      case 'WISH_INFO':
      case 'BUYING_REQUEST_LIST':
      case 'BIDDING_LIST_SCREEN':
      case 'SOUND_LEADERBOARD':
      case 'CREATE_GREETING_CARD':
      case 'LOCALE_TAG_INVITE':
        return null;
      case 'OPEN_URL':
      case 'FORUM_TOPIC':
      case 'PRODUCT_DETAIL':
      case 'SEARCH_SCREEN':
      case 'PUBLIC_PROFILE_PRODUCTS':
      case 'OPEN_URL_INAPP':
        return <PayloadForm eventType={eventType} payload={payload} setPayload={setPayload} errorMsg={errLinkMessage} />
      default: return null;
    }
  }

  return <Modal
    show={isShow}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={() => {
      resetState();
      reset();
      onHide();
    }}
  >
    <Modal.Header closeButton >
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        Chỉnh sửa Event Cover
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 16 }}>
        <img alt='' style={{ height: 300, maxWidth: '30vw', objectFit: 'contain', marginBottom: 6, marginTop: 16, border: `solid 0.5px ${COLOR.tertiary_light}` }}
            src={imageUrl || no_image} />
        <div className='td-actions text-center' style={{ marginLeft: 6 }}>
          <button onClick={onPickImage} type="button" rel="tooltip" className="btn btn-warning btn-simple">
            <i className="material-icons">edit</i>
          </button>
        </div>
      </div>
      <div style={{marginTop: 16, marginBottom: 16}} >
        <label style={{marginRight: 16, width: '15%'}} >Tên sự kiện: </label>
        <input type='text' style={{width: '40%', padding: 5}} onChange={e => setEventName(e.target.value)} value={eventName} />
      </div>

      <label
        style={{marginRight: 16, width: '15%'}}
      >Nơi hiển thị</label>
      <select name="event" value={cateId} 
        onChange={e => {
          setCateId(e.target.value)
        }}
        style={{padding: 5, width: '40%'}}
      >
        <option disabled value={-1} selected > -- Nếu để trống mặc định ở Newsfeed -- </option>
        <option value={-1} key='0000' >Newsfeed</option>
        {
          categories.map(c => <option value={c.id} key={c.id.toString()} >{c.categoryName}</option>)
        }
      </select>

      <br />
      <div style={{height: 16}} />

      <label
        style={{marginRight: 16, width: '15%'}}
      >Chọn sự kiện:</label>
      <select name="event" value={eventType} 
        onChange={e => {
          setPayload(undefined);
          setEventType(e.target.value)
        }}
        style={{padding: 5, width: '40%'}}
      >
        <option disabled value selected > -- Chọn một sự kiện -- </option>
        {
          EventTypes.map(e => <option value={e.action} key={e.action} >{e.name}</option>)
        }
      </select>
      {renderPayloadForm()}
      <div style={{display: 'flex', flexDirection: 'row', marginTop: 16}} >
        <div style={{flex: 1}} >
          <label style={{marginRight: 16}} >Ngày bắt đầu</label>
          <DatePicker
            value={startDate ? moment(startDate).format("DD/MM/YYYY") : ''}
            adjustDateOnChange={false}
            className="my-date-picker"
            selected={new Date()}
            dateFormat='dd/MM/yyyy'
            minDate={new Date()}
            onChange={(date) => {
              if (!!date)
                setStartDate(date)
            }} 
          />
        </div>
        <div>
          <label style={{marginRight: 16}} >Ngày kết thúc</label>
          <DatePicker
            value={endDate ? moment(endDate).format("DD/MM/YYYY") : ''}
            adjustDateOnChange={false}
            className="my-date-picker"
            selected={new Date()}
            dateFormat='dd/MM/yyyy'
            minDate={startDate ? startDate : new Date()}
            onChange={(date) => {
              if (!!date)
                setEndDate(date)
            }} 
          />
        </div>
      </div>
      
      <input hidden accept="image/*" onChange={onPickFileSuccess} ref={inputFileRef} type='file' id='img-banner' />
    </Modal.Body>
    <Modal.Footer>
      <AcceptButton  disabled={disabledSubmit} onClick={handleSubmit} title={event ? 'Thay đổi' : 'Tạo mới'} />
    </Modal.Footer>
  </Modal>
}

export default EditEventCoverModal;