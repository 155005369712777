import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { toJS } from 'mobx';
import moment from 'moment';
import _ from 'lodash'
import { productListStores } from '../../stores/ProductListStores';
import { biddingListStores  } from '../../stores/BiddingListStores';
import PaginationTable from '../../../components/PaginationTable';
import { user_avatar_default } from '../../../../common/images';

class ProductListTable extends Component {
    constructor(props) {
        super(props);
        this.onChangeTextDebounce = _.debounce(this.onChangeTextDebounce.bind(this), 600);
    };

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.thumbImage || user_avatar_default} />
        </div>
    }

    statusFormater = (cell, row) => {
        return <b style={{ color: !!row.deletedAt ? 'red' : 'black' }}><i>{row.statusName}</i></b>
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
            <button onClick={() => this.props.onProductItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">edit</i>
            </button>
            <button 
                type="button" rel="tooltip" className="btn btn-success btn-simple"
                onClick={() => {
                    if (row.isBidding) {
                        const { onSearchProductChange, getBiddingCount, getListBidding } = biddingListStores;
                        onSearchProductChange(row.id);
                        getBiddingCount();
                        getListBidding();
                        this.props.setActiveTab('bidding-list');
                    } else {
                        this.props.openConvertToBiddingModal(row)
                    }
                }}
            >
                <i className="material-icons">gavel</i>
            </button>
        </div>
    }

    onShowChangePassModal = (userId) => () => {
        if (!userId) return;
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }

    expiredDateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'thumbImage',
                text: 'hình ảnh',
                formatter: this.thumbFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'productName',
                text: 'Tên sản phẩm',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault,
            },
            {
                dataField: 'fullname',
                text: 'Người bán',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault,
                formatter: this.fullnameFormarter
            },
            {
                dataField: 'description',
                text: 'Mô tả',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'areaText',
                text: 'Địa chỉ',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'createdAt',
                text: 'Ngày đăng bán',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'expiredAt',
                text: 'Ngày hết hạn',
                headerFormatter: this.headerFormatter,
                formatter: this.expiredDateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'statusName',
                text: 'Trạng thái',
                formatter: this.statusFormater,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            }
        ];
    }

    fullnameFormarter = (cell, row) => {
        return <a href={`#${row.userId}`} onClick={() => this.props.onShowUserInfo(row.userId)}>{row.fullname}</a>
    }


    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        productListStores.setProductTablePage(page);
        this.getProductList(toJS(productListStores).txtSearchProduct);
    }

    getTotalSize = async () => {
        await productListStores.getTotalProductCount(productListStores.productFilterSelected);
    }

    getProductList = () => {
        productListStores.getProductListStore();
    }

    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: toJS(productListStores).totalProductListCount,
            page: productListStores.productTableCurrentPage,
        };
        return paginationOption;
    }

    async onChangeTextDebounce() {
        await this.getTotalSize();
        productListStores.setProductTablePage(1);
        this.getProductList();
    }

    onChangeText = async (e) => {
        productListStores.onSearchProductChange(e.target.value);
        this.onChangeTextDebounce();
    }


    render() {
        const { productList, getProductListLoading, txtSearchProduct } = toJS(productListStores);
        return (
            <div className='product-list'>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className='material-icons'>search</i>
                        </span>
                    </div>
                    <input
                        style={{ marginBottom: 12 }}
                        onChange={this.onChangeText}
                        type="text"
                        value={txtSearchProduct}
                        className="form-control"
                        placeholder="Tìm kiếm theo tên sản phẩm..." />
                </div>
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    onTableChange={this.onTableChange}
                    datas={productList}
                    columns={this.columnsOption()}
                    loading={getProductListLoading}
                    // tableRowEvents={
                    //     {
                    //         onClick: (e, row, rowIndex) => {
                    //             this.props.onProductItemClicked(row)
                    //         }
                    //     }
                    // }
                />
            </div>
        )
    }

    componentDidMount = async () => {
        await this.getTotalSize();
        this.getProductList();
    };


}

export default observer(ProductListTable);