import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import ThreadListTable from './components/ThreadListTable'
import { threadListStores } from './stores/ThreadListStores';
import ThreadDetailModal from './components/ThreadDetailModal';
import RadioGroup from 'src/pages/components/RadioGroup/RadioGroup';
import { toJS } from 'mobx';
import Loader from 'react-loader-spinner';
import { COLOR } from 'src/common/constant';
import { AcceptButton, InformationButton } from '../components/Button/Button';
import ModListPage from './components/ModListPage';
import _ from 'lodash';

const ThreadList = (props) => {
    const {
        getThreadList,
        categorySelected,
        threadCategories,
        setCategorySelected,
        getThreadCategories,
        setThreadTablePage,
        getThreadCategoriesLoading,
        setThreadDetailModalStatus
    } = toJS(threadListStores);

    const [showingPage, setShowingPage] = useState('THREAD_LIST');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [pinned, setPinned] = useState(false);

    const onChangeText = (e) => {
        setSearchKeyword(e.target.value);
        onChangeTextDebounce(e.target.value, categorySelected.id, pinned);
    }

    const fetchNewDataWhenTextChange = (text, categoryId, pinned) => {
        getThreadList(categoryId, text, pinned);
    }

    const onChangeTextDebounce = useCallback(_.debounce(
        (text, categoryId, pinned) => {
            setThreadTablePage(1);
            fetchNewDataWhenTextChange(text, categoryId, pinned);
        }
    , 500), []);

    useEffect(() => {
        getThreadCategories();
        getThreadList();
    }, []);

    const changePage = () => {
        if (showingPage === 'THREAD_LIST') setShowingPage('MOD_LIST')
        else setShowingPage('THREAD_LIST')
    }

    const onSelectedFilter = dto => {
        setCategorySelected(dto.item);
        if (dto.item.name === 'Pinned') {
            setPinned(true);
            return;
        }
        if (pinned) setPinned(false);
        setThreadTablePage(1);
        getThreadList(dto.item.id, searchKeyword);
    }

    useEffect(() => {
        if (pinned) {
            getThreadList(undefined, searchKeyword, pinned)
        }
    }, [pinned])

    const onAddNewThread = useCallback(() => setThreadDetailModalStatus(true), []);

    return (
        <div className="card">
            <div className="card-body">
                <div className='row'>
                    {showingPage === 'THREAD_LIST' ? <div id='product-list' className='v-radio'>
                        {
                            getThreadCategoriesLoading ?
                                <Loader
                                    type="TailSpin"
                                    color={COLOR.primary}
                                    height={30}
                                    width={30}
                                />
                                :
                                <RadioGroup
                                    inputKey='thread-list'
                                    containerStyle={{ marginRight: 32 }}
                                    checkedId={categorySelected.id}
                                    onItemClicked={onSelectedFilter}
                                    datas={threadCategories}
                                />
                        }
                    </div> : <div />}
                    <div className='row justify-content-end' style={{ margin: 0, flex: 1, paddingRight: 16 }}>
                        <InformationButton 
                            containerStyle={{ height: 'fit-content' }} 
                            onClick={changePage} 
                            title={showingPage === 'THREAD_LIST' ? 'Danh sách quản lí forum' : 'Danh sách bài đăng forum'}
                        />
                        {showingPage === 'THREAD_LIST' && <AcceptButton containerStyle={{ height: 'fit-content' }} onClick={onAddNewThread} title='Tạo mới' />}
                    </div>
                </div>
                {showingPage === 'THREAD_LIST' && <>
                    <input
                        style={{ marginBottom: 12, width: '80%', borderRadius: 16, padding: 8 }}
                        onChange={onChangeText}
                        type="text"
                        value={searchKeyword}
                        placeholder="Tìm kiếm theo tiêu đề, mô tả" 
                    />
                    <ThreadListTable 
                        category={categorySelected.id} 
                        pinned={pinned}
                        searchKeyword={searchKeyword}
                    />
                </>}
                {showingPage === 'MOD_LIST' ? <ModListPage /> : null}
            </div>
            <ThreadDetailModal categoryId={categorySelected.id} pinned={pinned} searchKeyword={searchKeyword} />
        </div>
    )
}

export default observer(ThreadList);
