import React, { useRef, useCallback, useState } from 'react';
import ReactQuill from 'react-quill';
import Loader from 'react-loader-spinner'
import { COLOR } from 'src/common/constant';
import { sendEmailStores } from '../stores/SendEmailStores';
import { Form } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

const modules = (imageHandler) => {
    return (
        {
            toolbar: {
                container: [
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image', 'video'],
                    ['clean']
                ],
                handlers: {
                    'image': imageHandler
                }
            }
        }
    )
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

const SendEmailStep1 = ({
}) => {
    const quillRef = useRef(null);
    const fileSelector = useRef(null);

    const onChangeEmailContent = useCallback((content) => {
        sendEmailStores.emailContentChange(content.trim());
    }, []);

    const onContentImagePressed = async (e) => {
        if (!e.target || !e.target.value) return;
        const { files } = e.target;
        const file = files[0];
        e.target.value = '';
        const uploadedUrl = await sendEmailStores.uploadImageInEmailService(file);
        if (!uploadedUrl || !quillRef || !quillRef.current) return;
        const range = quillRef.current.getEditor().getSelection();
        if (range) quillRef.current.getEditor().insertEmbed(range.index, 'image', uploadedUrl, 'send_email_user');
    }

    const imageHandler = useCallback((image, callback) => {
        if (fileSelector && fileSelector.current) fileSelector.current.click();
    }, []);

    return (
        <div style={{ marginBottom: 40 }}>
            <input
                name='input-image'
                disabled={toJS(sendEmailStores).uploadingImageInEmail}
                type='file'
                onChange={onContentImagePressed}
                ref={fileSelector} style={{ display: 'none' }}
                accept="image/*" />

            <span>
                <Loader
                    visible={toJS(sendEmailStores).uploadingImageInEmail}
                    type='Puff'
                    color={COLOR.primary}
                    height={30}
                    width={30}
                />
            </span>

            <Form.Control
                style={{ marginBottom: 16 }}
                onChange={(e) => sendEmailStores.emailTitleChange(e.target.value)}
                value={sendEmailStores.emailTitle}
                type="text"
                placeholder="Nhập tiêu đề email"
            />
            <ReactQuill
                theme='snow'
                placeholder='Nội dung email'
                ref={quillRef}
                value={sendEmailStores.emailContent}
                onChange={onChangeEmailContent}
                modules={modules(imageHandler)}
                formats={formats}
            />
        </div>
    )
}

export default observer(SendEmailStep1);
