import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationBasic = ({ total, onPageChanged, active }) => {
    let items = [];
    for (let number = 1; number <= total; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active}>
                {number}
            </Pagination.Item>,
        );
    }
    return (
        <div>
            <br />
            <Pagination onClick={onPageChanged}>{items}</Pagination>
        </div>
    )
}

export default PaginationBasic;