import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { observer } from 'mobx-react';
import { FEEDBACK_OF_USER_LIMIT_PAGEABLE, userDetailStores } from '../../stores/UserDetailStores';
import { COLOR, pageableDefault } from 'src/common/constant';
import moment from 'moment';
import Dropdown from '../../../components/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup'
import { user_avatar_default } from 'src/common/images';
import { blockUserApi, unBlockUserApi } from 'src/api/user/user-account';
import { Global } from 'src/GlobalClass';
import { userListStores } from '../../stores/UserListStores';
import { AcceptButton, CancelButton } from '../../../components/Button/Button';
import { getBooleanListVerifications } from 'src/helpers/CF';
import Icon from 'src/resources/svg';
import { toJS } from 'mobx';
import StarRatings from 'react-star-ratings';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProductListOfUser from '../ProductListOfUser'
import { s3UrlPrefix } from '../../../../common/constant';
import _ from 'lodash';
import FeedbackHistoryItem from './FeedbackHistoryItem';
import ReportHistoryItem from './ReportHistoryItem';
import PaginationBasic from 'src/pages/components/PaginationBasic';

class UserDetailModal extends Component {
    initialState = {
        activityDatas: [
            {
                id: 1,
                name: 'Lịch sử phản hồi'
            },
            {
                id: 2,
                name: 'Lịch sử báo cáo'
            },
        ],
        activitySelectedId: 0,
        defaultActiveKeyTab: 'info-activity-tab',
        feedbackHistoryCurrentPage: 1,
        txtId: ''
    }
    constructor(props) {
        super(props);
        this.state = this.initialState;
    };

    onHide = () => {
        const { onChangeUserDetailModal, setUserDetailData, setReportOfUserPageable, clearUserProductListData } = userDetailStores;
        onChangeUserDetailModal(false);
        setReportOfUserPageable(pageableDefault);
        clearUserProductListData();
        setTimeout(() => {
            this.setState(this.initialState);
            setUserDetailData();
        }, 300);
    }

    loadDetailData = async () => {
        await userDetailStores.onLoadUserDetail();
        this.setState({ txtId: userDetailStores.itemUserDetail.paperId || '' })
    }

    onItemActivityClicked = (item) => {
        if (item.id === this.state.activitySelectedId) return;
        const {
            setFeedbacksOfUserData,
            onLoadFeedbacksOfUser,
            setReportsOfUserData,
            onLoadReportsOfUser,
            onLoadFeedbacksOfUserCount,
            setReportOfUserPageable
        } = userDetailStores;
        this.setState({ activitySelectedId: item.id });
        if (item.id == 1) {
            onLoadFeedbacksOfUserCount();
            setFeedbacksOfUserData([]);
            onLoadFeedbacksOfUser(1);
        } else {
            setReportOfUserPageable(pageableDefault);
            setReportsOfUserData([]);
            onLoadReportsOfUser();
        }
    }

    onBlockUserClicked = (status) => async () => {
        // true: block this user, false: unblock this user
        const { itemUserDetail } = userDetailStores;
        if (!itemUserDetail.id) return Global.error({ message: 'Lỗi xác thực người dùng' });
        userDetailStores.setUserDetailData({
            ...itemUserDetail,
            deletedAt: status ? new Date() : null
        })
        userListStores.updateStatusBlockUserStore(itemUserDetail.id, status);
        if (status) blockUserApi(itemUserDetail.id);
        else unBlockUserApi(itemUserDetail.id);
    }

    renderVerificationsItem = (itemUserDetail) => {
        const verifications = getBooleanListVerifications(itemUserDetail);
        return verifications.map((item, index) => {
            return (
                <div key={index}
                    style={{
                        marginLeft: 8,
                        marginRight: 8,
                    }}>
                    <Icon name={item.img} width={26} height={26} color={item.status === true ? COLOR.primary : COLOR.tertiary} />
                </div>
            )
        })
    }
    renderBadgeItem = (itemUserDetail) => {
        const { badges } = toJS(itemUserDetail);
        return (badges || []).map((item, index) => {
            return (
                <div key={index}
                    style={{
                        marginLeft: 8,
                        marginRight: 8
                    }}>
                    <div>
                        <span style={{
                            paddingLeft: 4,
                            paddingRight: 4,
                        }} className="card-description">
                            <b>({item.count})</b>
                        </span>
                        <img src={item.imgSelected} style={{ width: 32, height: 32, objectFit: 'contain' }} />
                    </div>
                    <p>{item.name}</p>
                </div>
            )
        })
    }


    renderProfileView = () => {
        const { itemUserDetail } = toJS(userDetailStores);
        return (
            <div className="card card-profile">
                <div className="card-avatar">
                    <img style={{ width: 120, height: 120, borderRadius: 60, objectFit: 'cover' }} className="img" src={itemUserDetail.avatar || user_avatar_default} />
                </div>
                <div className="card-body">
                    <b><h4 className="card-title">{itemUserDetail.fullname || 'Người dùng Aladin'}</h4></b>

                    <StarRatings
                        starRatedColor={COLOR.yellow}
                        rating={parseInt(itemUserDetail.rating) || 0}
                        starDimension="24px"
                        starSpacing="6px"
                    />

                    <h6 className="card-category text-gray">
                        {'Tham gia ngày: ' + moment(itemUserDetail.createdAt).format('DD-MM-YYYY')}
                    </h6>

                    {
                        !!itemUserDetail.id &&
                        <p className="card-description">
                            <b>Mã người dùng: </b>{itemUserDetail.id || ''}
                        </p>
                    }
                    {
                        !!itemUserDetail.email &&
                        <p className="card-description">
                            <b>Email: </b>{itemUserDetail.email || ''}
                        </p>
                    }
                    {
                        !!itemUserDetail.phoneNumber &&
                        <p className="card-description">
                            <b>Số điện thoại: </b>{itemUserDetail.phoneNumber || ''}
                        </p>
                    }
                    {
                        !!itemUserDetail.areaText &&
                        <p className="card-description">
                            <b>Địa chỉ: </b>{itemUserDetail.areaText || ''}
                        </p>
                    }
                    {
                        !!itemUserDetail.dob &&
                        <p className="card-description">
                            <b>DOB: </b>{itemUserDetail.dob ? moment(itemUserDetail.dob).format('DD/MM/YYYY') : ''}
                        </p>
                    }
                    {
                        !!itemUserDetail.gender &&
                        <p className="card-description">
                            <b>Giới tính: </b>{itemUserDetail.gender || ''}
                        </p>
                    }

                    <div className="row">
                        <div className="col-md-6">
                            <p className="card-description">
                                <b>{itemUserDetail.numberOfFollowers || '0'}</b>  Người theo dõi
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p className="card-description">
                                <b>{itemUserDetail.numberOfFollowing || '0'}</b> Đang theo dõi
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <p className="card-description">
                                <b>{itemUserDetail.productSelling || '0'}</b> Sản phẩm đang bán
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p className="card-description">
                                <b>{itemUserDetail.productSold || '0'} </b>  Sản phẩm đã bán
                            </p>
                        </div>
                    </div>

                    <p className="card-description">
                        <b>{itemUserDetail.conversationTotalCount || '0'} </b>  Cuộc trò chuyện
                    </p>

                    <div className="row justify-content-center">
                        {this.renderVerificationsItem(itemUserDetail)}
                    </div>

                    <div className="row justify-content-center" style={{ marginTop: 6, marginBottom: 8 }}>
                        {this.renderBadgeItem(itemUserDetail)}
                    </div>

                    {
                        !!itemUserDetail.intro &&
                        <div style={{ marginTop: 12, marginBottom: 12 }}>
                            {itemUserDetail.intro}
                        </div>
                    }

                    {
                        !itemUserDetail.deletedAt ?
                            // <CancelButton onClick={this.onBlockUserClicked(true)} round title={'Khóa tài khoản'} />
                            <button onClick={this.onBlockUserClicked(true)} href="#" className="btn btn-danger btn-round">Khóa tài khoản</button>
                            :
                            // <AcceptButton onClick={this.onBlockUserClicked(false)} round title={'Mở khóa tài khoản'} />
                            <button onClick={this.onBlockUserClicked(false)} href="#" className="btn btn-success btn-round">Mở khóa tài khoản</button>
                    }


                </div>
            </div>
        )
    }

    onLoadMoreReports = () => userDetailStores.onLoadReportsOfUser(true);

    renderFeedbackHistory = () => {
        const { feedbacksOfUser, loadingUserDetailHistory, feedbacksOfUserCount } = userDetailStores;
        const totalPage = Math.ceil(feedbacksOfUserCount / FEEDBACK_OF_USER_LIMIT_PAGEABLE);
        if (!feedbacksOfUser.length && !loadingUserDetailHistory)
            return (
                <cite style={{ marginLeft: 32 }} title="Source Title">
                    Không có dữ liệu
                </cite>
            )
        return (
            <div style={{ paddingLeft: 16 }}>
                {
                    loadingUserDetailHistory &&
                    <Loader
                        style={{ marginTop: '12px' }}
                        type="TailSpin"
                        color={COLOR.primary}
                        height={30}
                        width={30}
                    />
                }
                {
                    feedbacksOfUser.map((item, index) => {
                        return (
                            <ListGroup key={index} variant='flush'>
                                <FeedbackHistoryItem feedbackItem={item} />
                            </ListGroup>
                        )
                    })
                }
                {!!totalPage &&
                    <PaginationBasic active={this.state.feedbackHistoryCurrentPage} onPageChanged={this.onPageChanged} total={totalPage} />}
            </div>
        )
    }

    onPageChanged = (e) => {
        const numString = e.target.text;
        if (numString) {
            this.setState({ feedbackHistoryCurrentPage: Number(numString) });
            userDetailStores.onLoadFeedbacksOfUser(Number(numString));
        }
    }

    onPaperIdChange = (e) => this.setState({ txtId: e.target.value || '' })

    renderReportHistory = () => {
        const { reportsOfUser, loadingUserDetailHistory, pageableReportsOfUser } = userDetailStores;
        // if (loadingUserDetailHistory)
        //     return (
        //         <Loader
        //             type="TailSpin"
        //             color={COLOR.primary}
        //             height={30}
        //             width={30}
        //         />
        //     )
        if (!reportsOfUser.length && !loadingUserDetailHistory)
            return (
                <cite style={{ marginLeft: 32 }} title="Source Title">
                    Không có dữ liệu
                </cite>
            )
        return (
            <div style={{ paddingLeft: 16 }}>
                {
                    reportsOfUser.map((item, index) => {
                        return (
                            <ListGroup key={index} variant="flush">
                                <ReportHistoryItem reportItem={item} />
                            </ListGroup>
                        )
                    })
                }

                {
                    loadingUserDetailHistory &&
                    <Loader
                        style={{ marginTop: '12px' }}
                        type="TailSpin"
                        color={COLOR.primary}
                        height={30}
                        width={30}
                    />
                }

                {
                    (pageableReportsOfUser.last == false && !loadingUserDetailHistory) &&
                    <div style={{ marginTop: 12 }}>
                        <a onClick={this.onLoadMoreReports} href='#'>Load More...</a>
                    </div>
                }
            </div>
        )
    }

    onUpdatePaperId = () => userDetailStores.updatePaperId(this.state.txtId);

    renderIdInfor = () => {
        const { itemUserDetail, paperUpdating } = userDetailStores;
        return (
            <div>
                <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 16, alignItems: 'center', marginLeft: 16 }}>
                    <i className='material-icons'>description</i>
                    <b style={{ marginLeft: 8 }}>Số giấy tờ: </b>
                    <span style={{ flex: 1, paddingRight: 32 }}>
                        <input
                            style={{ marginLeft: 16 }}
                            onChange={this.onPaperIdChange}
                            type="text"
                            value={this.state.txtId}
                            className="form-control"
                            maxLength={16}
                            placeholder="Nhập vào số giấy tờ" />
                    </span>
                    <AcceptButton disabled={paperUpdating} onClick={this.onUpdatePaperId} title='Cập nhật' />
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <b>Verify Selfie</b>
                            <br />
                            {
                                !!itemUserDetail.verifySelfie &&
                                <img style={{ maxWidth: '300px' }} src={_.startsWith(itemUserDetail.verifySelfie, 'http') ? itemUserDetail.verifySelfie : s3UrlPrefix.IMAGE_URL_PREFIX_LG + itemUserDetail.verifySelfie} />
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <b>Mặt trước CMND / CCCD</b>
                            <br />
                            {
                                !!itemUserDetail.verifyDocumentIdFront &&
                                <img style={{ maxWidth: '300px' }} src={_.startsWith(itemUserDetail.verifyDocumentIdFront, 'http') ? itemUserDetail.verifySelfie : s3UrlPrefix.IMAGE_URL_PREFIX_LG + itemUserDetail.verifyDocumentIdFront} />
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <b>Mặt sau CMND / CCCD</b>
                            <br />
                            {
                                !!itemUserDetail.verifyDocumentIdBack &&
                                <img style={{ maxWidth: '300px' }} src={_.startsWith(itemUserDetail.verifyDocumentIdBack, 'http') ? itemUserDetail.verifySelfie : s3UrlPrefix.IMAGE_URL_PREFIX_LG + itemUserDetail.verifyDocumentIdBack} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderInfoActivityView = () => {
        const { activityDatas, activitySelectedId } = this.state;
        return (
            <div className="row">
                <div className="col-md-7">
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'baseline' }}>
                        <b style={{ marginLeft: 32, marginTop: 12 }}>Chọn hành động: </b>
                        <Dropdown
                            containerStyle={{ marginLeft: 12, }}
                            buttonStyle={{ padding: '6px 20px' }}
                            onItemClicked={this.onItemActivityClicked}
                            idSelected={activitySelectedId}
                            datas={activityDatas}
                        />
                    </div>

                    {
                        activitySelectedId == 1 ?
                            this.renderFeedbackHistory()
                            :
                            activitySelectedId == 2 ?
                                this.renderReportHistory() : null
                    }

                </div>
                <div className="col-md-5" style={{ paddingTop: 30 }}>
                    {this.renderProfileView()}
                </div>
            </div>
        )
    }

    onSelectedTab = (key) => {
        this.setState({ defaultActiveKeyTab: key });
    }

    render() {
        const { loadingDetailData, totalUserProductListCount } = userDetailStores;
        const { defaultActiveKeyTab } = this.state;
        const productListTitle = 'Danh sách sản phẩm';
        return (
            <Modal
                className='user-detail-modal'
                onShow={this.loadDetailData}
                show={userDetailStores.isShowUserDetailModal}
                size='xl'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                animation
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                        {'Thông tin người dùng'}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll', paddingRight: 20 }}>
                    {
                        loadingDetailData ?
                            <Loader
                                type="TailSpin"
                                color={COLOR.primary}
                                height={30}
                                width={30}
                            />
                            :
                            <Tabs
                                onSelect={this.onSelectedTab}
                                activeKey={defaultActiveKeyTab}
                                // defaultActiveKey={defaultActiveKeyTab}
                                transition={false}
                                id="user-detail-modal-tab">
                                <Tab eventKey="info-activity-tab" title='Thông tin và hoạt động'>
                                    {this.renderInfoActivityView()}
                                </Tab>
                                <Tab eventKey="product-list-tab" title={`${productListTitle} (${totalUserProductListCount || 0})`}>
                                    <ProductListOfUser />
                                </Tab>
                                <Tab eventKey="id-tab" title="Thông tin giấy tờ tùy thân">
                                    {this.renderIdInfor()}
                                </Tab>
                            </Tabs>

                    }


                </Modal.Body>

                <Modal.Footer>
                    <CancelButton onClick={this.onHide} title='Đóng' />
                </Modal.Footer>

            </Modal>
        );
    }
}

export default observer(UserDetailModal)