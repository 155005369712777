import { requestApi } from "../axios";
const prefix = 'advertising';

export function getBannersApi(offset) {
    return requestApi(`${prefix}/banners?limit=10&offset=${offset}`, 'GET');
}

export function getBannerTotalCountApi() {
    return requestApi(`${prefix}/total-count-banner`, 'GET');
}
export function createBannerApi(data) {
    return requestApi(`${prefix}/create-banner`, 'POST', data);
}
export function updateBannerApi(data) {
    return requestApi(`${prefix}/update-banner`, 'POST', data);
}