import { requestApi } from "../axios";
const prefix = 'product';

export function getProductHistoryApi(offset, productId) {
    const dto = {
        productId,
        limit: 10,
        offset
    }
    return requestApi(`${prefix}/product-history`, 'POST', dto);
}

export function getProductListApi(offset, search, userId, filter) {
    return requestApi(`${prefix}/list-product?limit=10&offset=${offset}&filter=${filter}`, 'POST', { search, userId });
}

export function getTotalProductCountApi(search, userId, filter) {
    return requestApi(`${prefix}/total-product-count?filter=${filter}`, 'POST', { search, userId });
}

