import { extendObservable, toJS } from 'mobx';
import { getReviewProductsCountApi, getReviewProductsApi, getReviewProductDetailApi, approveProductApi, reportProductApi, rejectProductApi, getRejectTypeListApi, sendSupportMessageApi } from '../../../api/product/product-review';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';
import { getProductHistoryApi } from '../../../api/product/product';
import { getSupportConversationCount, getSupportConversationList } from '../../../api/product/product-review';
import { pageableDefault } from '../../../common/constant';
import { history } from '../../../history';
import { ROUTER } from '../../../common/route';
import { productListStores } from './ProductListStores';
export const radioFilterProductReviewData = [
    {
        id: 'all',
        name: 'Tất cả'
    },
    {
        id: 'new_post',
        name: 'Bài đăng mới'
    },
    {
        id: 'update_post',
        name: 'Bài đăng cập nhật thông tin'
    },
    {
        id: 'need_to_review',
        name: 'Bài đăng tự động phê duyệt (cần review)'
    },
    {
        id: 'first_post',
        name: 'Người bán đăng bài lần đầu'
    },
]

class ReviewProductStores {
    constructor() {
        extendObservable(this, {
            isShowReviewProduct: false,
            reviewProducts: [],
            reviewProductTotalCount: 0,
            loadingReviewProducts: false,
            currentReviewProductPage: 1,
            // 
            productDetailInfo: {},
            firstLoadingReviewProductDetail: false,
            productRejectTypeList: [],
            productHistoryList: [],
            loadingProductHistory: false,
            pageableProductHistory: pageableDefault,
            filterSelected: radioFilterProductReviewData[0].id,

            // support conversation
            supportConversations: [],
            supportConversationsPage: 1,
            supportConversationsTotalCount: 0,
            getSupportConversationLoading: false,
            sendSupportMessageLoading: false,

            //marked as shop
            isMarked: false,
            isShop: false,
        });
    }

    setReviewProductPage = (page) => this.currentReviewProductPage = page;
    setFilterSelected = (id) => this.filterSelected = id;

    setIsShowReviewProduct = (isShow) => this.isShowReviewProduct = isShow;

    getReviewProductsCountAction = async () => {
        try {
            const result = await getReviewProductsCountApi(this.filterSelected);
            if (result.responseCode.toString().startsWith('2')) {
                this.reviewProductTotalCount = parseInt(result.data.total);
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    getReviewProductsAction = async () => {
        try {
            this.loadingReviewProducts = true;
            const offset = pageableOffset(this.currentReviewProductPage);
            const result = await getReviewProductsApi(offset, this.filterSelected);
            if (result.responseCode.toString().startsWith('2')) {
                this.reviewProducts = result.dataArray;
            } else {
                Global.error({ message: result.responseText || '' });
            }
            this.loadingReviewProducts = false;
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }
    getReviewProductDetailAction = async (productId) => {
        try {
            this.firstLoadingReviewProductDetail = true;
            const result = await getReviewProductDetailApi(productId);
            this.firstLoadingReviewProductDetail = false;
            if (result.responseCode.toString().startsWith('2')) {

                this.productDetailInfo = result.data || {};
                this.isMarked = result.data.isMarked;
                this.isShop = result.data.isShop;
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    onApproveProduct = async (productId) => {
        const result = await approveProductApi(productId, this.isShop);
        if (result.responseCode.toString().startsWith('2')) {
            this.getReviewProductsAction();
            if (this.reviewProductTotalCount > 0) this.reviewProductTotalCount -= 1;
        }
    }

    onReportProduct = async (productId, rejectType, reason) => {
        try {
            const result = await reportProductApi(productId, rejectType, reason);
            if (result.responseCode.toString().startsWith('2')) {
                if (history.location.pathname == ROUTER.PRODUCT.REVIEW) {
                    this.getReviewProductsAction();
                    this.reviewProductTotalCount -= 1;
                } else {
                    productListStores.totalProductListCount -= 1;
                }
                productListStores.getProductListStore();
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    onRejectProduct = async (productId, rejectType, reason) => {
        try {
            const result = await rejectProductApi(productId, rejectType, reason);
            if (result.responseCode.toString().startsWith('2')) {
                if (history.location.pathname == ROUTER.PRODUCT.REVIEW) {
                    this.getReviewProductsAction();
                    this.reviewProductTotalCount -= 1;
                } else {
                    productListStores.totalProductListCount -= 1;
                }
                productListStores.getProductListStore();
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    getRejectTypeListAction = async () => {
        try {
            const result = await getRejectTypeListApi();
            if (result.responseCode.toString().startsWith('2')) {
                this.productRejectTypeList = result.dataArray || [];
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    setProductHistoryPageable = (data) => {
        this.pageableProductHistory = data;
    }

    getProductHistoryInReviewProductAction = async (productId) => {
        try {
            this.loadingProductHistory = true;
            const result = await getProductHistoryApi(this.pageableProductHistory.offset, productId);
            if (result.responseCode.toString().startsWith('2')) {
                this.setProductHistoryList(result.dataArray || []);
                let newPageable = {
                    offset: this.pageableProductHistory.offset + result.dataArray.length,
                    last: result.pageable.last
                }
                this.setProductHistoryPageable(newPageable);
            } else {
                Global.error({ message: result.responseText || '' });
            }
            this.loadingProductHistory = false;
        } catch (error) {
            this.loadingProductHistory = false;
            Global.error({ message: error.message || '' });
        }
    }

    getSupportConversationListWorker = async () => {
        try {
            if (!this.productDetailInfo || !this.productDetailInfo.id) return;
            this.getSupportConversationLoading = true;
            const offset = pageableOffset(this.supportConversationsPage);
            const result = await getSupportConversationList({ productId: this.productDetailInfo.id, offset });
            this.getSupportConversationLoading = false;
            if (result.responseCode.toString().startsWith('2')) {
                this.setSupportConversationList(result.dataArray);
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            this.getSupportConversationLoading = false;
            Global.error({ message: error.message || '' });
        }
    }

    getSupportConversationTotalCountWorker = async () => {
        try {
            if (!this.productDetailInfo || !this.productDetailInfo.id) return;
            const result = await getSupportConversationCount({ productId: this.productDetailInfo.id });
            if (result.responseCode.toString().startsWith('2')) {
                this.supportConversationsTotalCount = parseInt(result.data.count || '0');
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    sendSupportMessageWorker = async (message) => {
        try {
            if (!this.productDetailInfo || !this.productDetailInfo.id) return;
            this.sendSupportMessageLoading = true;
            const result = await sendSupportMessageApi({ productId: this.productDetailInfo.id, message });
            this.sendSupportMessageLoading = false;
            if (result.responseCode.toString().startsWith('2')) {
                Global.success({ message: 'Gửi tin nhắn thành công' });
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            this.sendSupportMessageLoading = false;
            Global.error({ message: error.message || '' });
        }
    }

    setSupportConversationList = (list) => this.supportConversations = list || [];

    setProductHistoryList = (datas) => this.productHistoryList = datas;

    setProductDetailInfo = (data) => this.productDetailInfo = data || {};

    setSupportConversationsPage = (page) => this.supportConversationsPage = page;


}
export const reviewProductStores = new ReviewProductStores();