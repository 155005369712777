import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { toJS } from 'mobx';
import moment from 'moment';
import _ from 'lodash'
import { userDetailStores } from '../../stores/UserDetailStores';
import PaginationTable from '../../../components/PaginationTable';
import { user_avatar_default } from '../../../../common/images';
import { ROUTER } from 'src/common/route';

class ProductListOfUserTable extends Component {
    constructor(props) {
        super(props);
    };

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.thumbImage || user_avatar_default} />
        </div>
    }

    statusFormater = (cell, row) => {
        return <b><i>{row.statusName}</i></b>
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
            <button onClick={() => this.props.onProductItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">edit</i>
            </button>
        </div>
    }

    onShowChangePassModal = (userId) => () => {
        if (!userId) return;
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    linkFormatter = (cell, row) => {
        return <a href={ROUTER.PRODUCT.REVIEW + `?id=${cell}`} target='_blank' >{cell}</a>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }

    expiredDateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('DD/MM/YYYY')}</span>
            </div>
        );
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
    })

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                formatter: this.linkFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'thumbImage',
                text: 'hình ảnh',
                formatter: this.thumbFormatter,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'productName',
                text: 'Tên sản phẩm',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault,
            },
            {
                dataField: 'description',
                text: 'Mô tả',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'areaText',
                text: 'Địa chỉ',
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'createdAt',
                text: 'Ngày đăng bán',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'expiredAt',
                text: 'Ngày hết hạn',
                headerFormatter: this.headerFormatter,
                formatter: this.expiredDateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'statusName',
                text: 'Trạng thái',
                formatter: this.statusFormater,
                headerFormatter: this.headerFormatter,
                headerStyle: this.headerStyleDefault
            }
        ];
    }


    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        userDetailStores.setProductTablePage(page);
        this.getProductList();
    }

    getTotalSize = async () => {
        await userDetailStores.getTotalProductCount();
    }

    getProductList = () => {
        userDetailStores.getUserProductListStore();
    }

    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: toJS(userDetailStores).totalUserProductListCount,
            page: toJS(userDetailStores).userProductListTableCurrentPage,
        };
        return paginationOption;
    }

    render() {
        const { userProductList, getUserProductListLoading } = userDetailStores;
        return (
            <div className='product-list'>
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    onTableChange={this.onTableChange}
                    datas={userProductList}
                    columns={this.columnsOption()}
                    loading={getUserProductListLoading}
                />
            </div>
        )
    }

    componentDidMount = async () => {
        await this.getTotalSize();
        this.getProductList();
    };

}

export default observer(ProductListOfUserTable);