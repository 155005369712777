import { extendObservable } from 'mobx';
class RouterStores {
    constructor() {
        extendObservable(this, {
            routerTitle: '',
            routerIndex: 0
        });
    }

    setRouteTitle = (title) => {
        if (title !== this.routerTitle) this.routerTitle = title;
    }
    setRouteIndex = (index) => {
        if (index !== this.routerIndex) this.routerIndex = index;
    }

}
export const routerStores = new RouterStores();