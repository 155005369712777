import React from 'react';
import { Form } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { pushNotificationStores } from '../../stores/PushNotificationStores';

const InputTextNotificationForm = (props) => {
    const {
        itemKey,
        item
    } = props;

    const { payloadData } = pushNotificationStores;

    const onTextChange = (e) => {
        payloadData[itemKey] = e.target.value;
    }

    let value = payloadData[itemKey] || '';

    return (
        <div style={{ marginTop: 30 }}>
            <Form.Label>{item.title}</Form.Label>
            <Form.Control
                // onBlur={onBlur}
                type={item.inputType || 'text'}
                value={value}
                onChange={onTextChange}
                placeholder="Nhập..." />
        </div>
    )
}


export default observer(InputTextNotificationForm);