import { requestApi } from "../axios";
const prefix = 'intro-image';

export function getData(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function update(field, value, id) {
    return requestApi(`${prefix}/update`, 'PUT', { field, value, id });
}

export function insert(url, orderIndex) {
    return requestApi(`${prefix}/insert`, 'POST', { url, orderIndex });
}

export function updateRow(id, url, orderIndex) {
    return requestApi(`${prefix}/update-row`, 'PUT', { id, url, orderIndex });
}
