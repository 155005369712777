import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { user_avatar_default } from 'src/common/images';
import PaginationTable from '../../components/PaginationTable';
import promotionPopupStore from '../stores/PromotionPopupStore';
import EditPromotionPopupModal from './EditPromotionPopupModal';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { COLOR } from 'src/common/constant';
import Loader from 'react-loader-spinner';
import { CancelButton, AcceptButton } from 'src/pages/components/Button';
import { toJS } from 'mobx';

class PromotionPopupTable extends Component {
  constructor() {
    super();
    this.state= {
      isShowConfirmEndPromotionPopup: false
    }
  }
  headerFormatter = (column) => {
    return <h5><strong>{column.text}</strong></h5>
  }

  formatterImage = (cell, row) => {
    return <div key={row.id} className="card-avatar">
      <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={cell || user_avatar_default} />
    </div>
  }

  dateTimeFormatter = (cell) => {
    return (
      <div>
        {
          cell &&
          <div>
            <p>{moment(cell).format('DD/MM/YYYY')}</p>
            <p>{moment(cell).format('hh:mm A')}</p>
          </div>
        }

      </div>
    );
  }

  onEditClick = async promotionPopupId => {
    const {getPromotionPopupDetails, setShowModal} = promotionPopupStore;
    await getPromotionPopupDetails(promotionPopupId);
    setShowModal(true);
  }

  onEndClick = async promotionPopupId => {
    const {getPromotionPopupDetails} = promotionPopupStore;
    await getPromotionPopupDetails(promotionPopupId);
    this.setState({
      isShowConfirmEndPromotionPopup: true,
    })
  }

  actionButtons = (cell, row) => {
    return <div className="td-actions text-center">
      <button onClick={e => this.onEditClick(row.id)} style={{ marginRight: 6 }} type="button" className="btn btn-info btn-simple">
        <i className="material-icons">edit</i>
      </button>
      <button disabled={moment(row.endDate).isBefore()} onClick={e => this.onEndClick(row.id)} style={{ marginRight: 6 }} type="button" className="btn btn-danger btn-simple">
        <i className="material-icons">clear</i>
      </button>
    </div >
  }

  paginationOption = () => (
    {
        custom: true,
        hideSizePerPage: true,
        totalSize: promotionPopupStore.promotionPopupCount,
        page: promotionPopupStore.tableCurrentPage,
    }
  )

  onTableChange = (type, { page, sizePerPage }) => {
    window.scrollTo(0, 0);
    promotionPopupStore.setTableCurrentPage(page);
    promotionPopupStore.getPromotionPopupList();
  }

  columnsOption = [
    {
      dataField: 'id',
      text: '#',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 40
      }
    },
    {
      dataField: 'name',
      text: 'Tên',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 100,
      }
    },
    {
      dataField: 'background',
      text: 'Hình ảnh',
      headerFormatter: this.headerFormatter,
      formatter: this.formatterImage,
      headerStyle: {
        width: 80
      }
    },
    {
      dataField: 'startDate',
      text: 'Ngày bắt đầu',
      headerFormatter: this.headerFormatter,
      formatter: this.dateTimeFormatter,
      headerStyle: {
        width: 100
      }
    },
    {
      dataField: 'endDate',
      text: 'Ngày kết thúc',
      headerFormatter: this.headerFormatter,
      formatter: this.dateTimeFormatter,
      headerStyle: {
        width: 100
      }
    },
    {
      dataField: 'action',
      text: 'Hành động',
      formatter: this.actionButtons,
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 80,
        textAlign: 'center'
      }
    },
  ]

  onHideConfirmEndPromotionPopup = () => {
    this.setState({isShowConfirmEndPromotionPopup: false})
  }

  onConfirmEndPromotionPopup = async () => {
    const { currentPromotionPopup, updatePromotionPopup } = promotionPopupStore;
    if (currentPromotionPopup && currentPromotionPopup.id) {
      const {name, background, startDate, PromotionButton: {payload, title}} = currentPromotionPopup;
      await updatePromotionPopup(
        currentPromotionPopup.id,
        {
          name,
          background,
          buttonTitle: title,
          buttonPayload: payload,
          startDate,
          endDate: new Date(),
        }
      )
    }
    this.setState({
      isShowConfirmEndPromotionPopup: false,
    })
  }


  render() {
    const { promotionPopupList, isLoadingGetPromotionPopupList, currentPromotionPopup, isShowModal, isLoadingUpdate } = promotionPopupStore;
    const { isShowConfirmEndPromotionPopup } = this.state;
    return (
      <div>
        <Modal show={isShowConfirmEndPromotionPopup} onHide={this.onHideConfirmEndPromotionPopup}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Bạn muốn kết thúc promotion popup <b>{currentPromotionPopup && currentPromotionPopup.name || ''}</b>?</span></Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            {isLoadingUpdate ?
              <Loader
                style={{ marginRight: '12px', marginTop: '8px' }}
                type="TailSpin"
                color={COLOR.primary}
                height={30}
                width={30}
              />
              : <div>
                <CancelButton title='HỦY BỎ' onClick={this.onHideConfirmEndPromotionPopup} />
                <AcceptButton title='XÁC NHẬN' onClick={this.onConfirmEndPromotionPopup} />
              </div>
            }
          </Modal.Footer>
        </Modal>
        <PaginationTable
          paginationOptions={this.paginationOption()}
          onTableChange={this.onTableChange}
          datas={promotionPopupList}
          columns={this.columnsOption}
          loading={isLoadingGetPromotionPopupList}
        />
        <EditPromotionPopupModal
          isShow={isShowModal}
          onHide={this.onHideModal}
          promotionPopup={currentPromotionPopup}
        />
      </div>
    )
  }
}

export default observer(PromotionPopupTable);