import { extendObservable, toJS } from 'mobx';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';
import { uploadImage } from '../../../api/axios';
import { getAdvertisingListApi, getAdvertisingTotalCountApi, updateAdvertisingStatusApi, getCategories, createOrUpdateAds, deleteAds } from '../../../api/advertising/manage-advertising';
import moment from 'moment';
class ManageAdvertisingStores {
    constructor() {
        extendObservable(this, {
            advertisingList: [],
            getAdvertisingListLoading: true,
            totalAdvertisingListCount: 0,
            idAdsToDelete: 0,
            advertisingListCurrentPage: 1,
            showModal: false,
            //Modal fields
            uploadingImage: false,
            adTypes: [],
            adsObjectSelected: {
                id: 0,
                objectTree: {},
                adsName: '',
                adTypeId: 0,
                endDate: moment().format('DD/MM/YYYY'),
                uploadedUrl: ''
            },
            isEdit: false,
            savingData: false
        });
    }


    getAdvertisingListStore = async () => {
        this.getAdvertisingListLoading = true
        const offset = pageableOffset(this.advertisingListCurrentPage);
        const result = await getAdvertisingListApi(offset);
        if (result.responseCode.toString().startsWith('2')) {
            this.advertisingList = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.getAdvertisingListLoading = false;
    }

    getTotalAdvertisingCount = async () => {
        const result = await getAdvertisingTotalCountApi();
        if (result.responseCode.toString().startsWith('2')) {
            this.totalAdvertisingListCount = parseInt(result.data.total);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    setAdvertisingPage = (page) => this.advertisingListCurrentPage = page;
    onChooseItemAdsToDelete = (id) => this.idAdsToDelete = id;


    updateAdvertisingdStatusStore = async (id, checked) => {
        if (!id) return;
        const itemToChange = this.advertisingList.find(o => o.id == id);
        itemToChange.deletedAt = checked ? new Date() : null;
        const result = await updateAdvertisingStatusApi(id, checked);
        if (!result.responseCode.toString().startsWith('2')) {
            itemToChange.deletedAt = !checked ? new Date() : null;
            Global.error({ message: result.responseText || '' });
        }
    }

    onDrop = async (acceptedFiles, rejectedFiles) => {
        this.uploadingImage = true;
        this.adsObjectSelected.uploadedUrl = '';
        if (acceptedFiles.length < 0) {
            return
        }
        let data = new FormData();
        let file = acceptedFiles[0];
        data.append('file', file, file.name);

        try {
            const result = await uploadImage(data);
            this.adsObjectSelected.uploadedUrl = result.data.uploadedUrl;
            this.uploadingImage = false;
        } catch (error) {
            this.uploadingImage = false;
        }
    }

    onBtnSavePressed = async () => {
        this.savingData = true;
        const adsObjectSelected = toJS(this.adsObjectSelected);
        let dataSave = {
            adsName: adsObjectSelected.adsName,
            imageUrl: adsObjectSelected.uploadedUrl,
            type: adsObjectSelected.adTypeId,
            payload: adsObjectSelected.objectTree,
            endDate: moment(adsObjectSelected.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            deletedAt: adsObjectSelected.deletedAt
        }
        if (this.isEdit) dataSave.id = adsObjectSelected.id;
        const res = await createOrUpdateAds(dataSave);
        if (res.responseCode.toString().startsWith('2')) {
            this.getAdvertisingListStore();
            this.onHideStore();
        } else {
            Global.error({ message: res.responseText || '' });
        }
        this.savingData = false;
    }

    onHideStore = () => {
        this.uploadingImage = false;
        this.savingData = false;
        this.showModal = false;
        this.adsObjectSelected = {
            id: 0,
            objectTree: {},
            adsName: '',
            adTypeId: 0,
            endDate: moment().format('DD/MM/YYYY'),
            uploadedUrl: ''
        };
        this.isEdit = false;
    }

    onDeleteADS = async () => {
        const res = await deleteAds(this.idAdsToDelete);
        if (res.responseCode.toString().startsWith('2')) {
            this.onChooseItemAdsToDelete(0);
            this.getAdvertisingListStore();
        } else {
            Global.error({ message: res.responseText || '' });
        }
    }

    showModalInsert = () => {
        this.showModal = true;
    }

    getTypeOfAds = async () => {
        const categories = await getCategories('type-of-ads');
        this.adTypes = categories.dataArray;
    }
    getComboboxData = async (url) => {
        const response = await getCategories(url);
        if (response.responseCode.toString().startsWith('2')) return response.dataArray;
        return [];
    }

    onSelectItemToEdit = (data) => {
        this.adsObjectSelected = data;
        this.showModalInsert();
        this.isEdit = true;
    }

}
export const manageAdvertisingStores = new ManageAdvertisingStores();