import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { Card } from 'react-bootstrap';

class UserChartDaily extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: []
                }
            },
            series: [
                {
                    name: "series-1",
                    data: []
                }
            ]
        };
    }

    componentWillReceiveProps(props) {
        if (!!props.chartData)
            this.setState({
                options: props.chartData.options,
                series: props.chartData.series
            })
    }

    render() {
        return (
            <Card style={{ width: '100%' }}>
                <Card.Header as="h5">Biểu đồ</Card.Header>
                <Card.Body>
                    <Card.Title>Số lượng User đăng ký hằng ngày</Card.Title>
                    <Card.Text>
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            width='100%'

                        />
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}

export default UserChartDaily;