export const ROUTER = {
    INDEX: '/Portal',
    STATS: {
        INDEX: '/Portal/Stats',
    },
    PRODUCT: {
        INDEX: '/Portal/Products',
        REVIEW: '/Portal/Products/Review'
    },
    ORDER: {
        INDEX: '/Portal/Orders',
        REVIEW: '/Portal/Orders/Review'
    },
    USER: {
        INDEX: '/Portal/Users',
        VERIFY_ID: '/Portal/Users/Verify-Id',
        VERIFY_PHONE: '/Portal/Users/Verify-Phone',
        DETAIL: '/Portal/Users/Detail',
    },
    CHART: {
        INDEX: '/Portal/Chart'
    },
    FEEDBACK: {
        INDEX: '/Portal/Feedbacks'
    },
    REPORT: {
        INDEX: '/Portal/Report'
    },
    PRODUCT_REPORT: {
        INDEX: '/Portal/Product-Report'
    },
    ADVERTISING: {
        INDEX: '/Portal/Advertising'
    },
    FORGOT_PASSWORD_PHONE_NUMBER: {
        INDEX: '/Portal/Forgot-password-phonenumber'
    },
    INTRO_IMAGES: {
        INDEX: '/Portal/Intro-Images'
    },
    PUSH_NOTIFICATION: {
        INDEX: '/Portal/Push-Notification'
    },
    MANAGE_ADVERTISING: {
        INDEX: '/Portal/Manage-Advertising'
    },
    MANAGE_ARTICLE: {
        INDEX: '/Portal/Manage-Article'
    },
    CHART_ASK: {
        INDEX: '/Portal/Chart/Ask'
    },
    CHART_MAKE_AN_OFFER: {
        INDEX: '/Portal/Chart/Make-an-offer'
    },
    CHART_CHAT_MESSAGE: {
        INDEX: '/Portal/Chart/Chat-message'
    },
    CHART_MONEY_SOLD: {
        INDEX: '/Portal/Chart/money-sold'
    },
    CHART_SELLING_PRODUCTS: {
        INDEX: '/Portal/Chart/selling-products'
    },
    CHART_PRODUCT_SOLD: {
        INDEX: '/Portal/Chart/product-solds'
    },
    CHART_PRODUCT_SELL_THREE_TYPES: {
        INDEX: '/Portal/Chart/product-sell-three-types'
    },
    CHART_REPORT_USER_BY_LOCATION: {
        INDEX: '/Portal/Chart/report-user-by-location'
    },
    COALITION: {
        INDEX: '/Portal/Coalition'
    },
    SEND_EMAIL: {
        INDEX: '/Portal/send-mail'
    },
    COLLECTION: {
        INDEX: '/Portal/collection'
    },
    FORUM: {
        INDEX: '/Portal/forum-manager'
    },
    EVENTS: {
        INDEX: '/Portal/Events'
    },
    PROMOTION_POPUP: {
        INDEX: '/Portal/promotion-popup'
    }
}



export const USER_TAB_KEY = {
    user_list: 'user-list',
    review_verify_id: 'review-verify-id',
    review_verify_phone: 'review_verify_phone',
}