import { requestApi } from "../axios";
const prefix = 'article';

export function postArticle(data) {
    return requestApi(`${prefix}/post`, 'POST', data);
}

export function editArticle(data) {
    return requestApi(`${prefix}/edit`, 'POST', data);
}

export function deleteArticle(data) {
    return requestApi(`${prefix}/delete`, 'POST', data);
}

export function getArticleGroups(data) {
    return requestApi(`${prefix}/article-group`, 'GET');
}

export function getArticles(limit, offset) {
    return requestApi(`${prefix}/articles?limit=${limit}&offset=${offset}`, 'GET');
}