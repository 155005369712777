import { extendObservable } from 'mobx';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';
import { getCollectionsApi, getTotalSizeApi, deleteCollectionApi } from 'src/api/collection/collection';
import { updateAdvertisingStatusApi } from 'src/api/advertising/manage-advertising';

class CollectionStore {
  constructor() {
    extendObservable(this, {
      collectionList: [],
      getCollectionListStatus: true,
      totalCollectionListCount: 0,
      collectionTableCurrentPage: 1,
      editCollectionModal: false,
      newCollectionModal: false
    })
  }

  getCollectionList = async () => {
    try {
      this.getCollectionListStatus = true;
      const offset = pageableOffset(this.collectionTableCurrentPage);
      const result = await getCollectionsApi(offset);
      if (result.responseCode.toString().startsWith('2')) {
        this.collectionList = result.dataArray || [];
      } else {
        Global.error({ message: result.responseText || '' });
      }
      this.getCollectionListStatus = false;
    }
    catch (err) {
      Global.error({ message: err.message || '' });
      this.getCollectionListStatus = false;
    }
  }

  getTotalSize = async () => {
    try {
      const result = await getTotalSizeApi();
      if (result.responseCode.toString().startsWith('2')) {
        this.totalCollectionListCount = result.data.total;
      } else {
        Global.error({ message: result.responseText || '' });
      }
    }
    catch (err) {
      Global.error({ message: err.message || '' });
    }
  }

  removeCollection = async (collection) => {
    try {
      const result = await deleteCollectionApi(collection.id);
      if (result.responseCode.toString().startsWith('2')) {
        Global.success({ message: `Collection ${collection.advertisingName} đã được xoá thành công.` })
        this.getCollectionList();
      } else {
        Global.error({ message: result.responseText || '' });
      }

    }
    catch (err) {
      Global.error({ message: err.message || '' });
    }
  }

  setCollectionTablePageHandler = (page) => this.collectionTableCurrentPage = page;

  updateCollectionStatus = async (id, checked) => {
    if (id === undefined || id === null) return;
    const itemToChange = this.collectionList.find(o => o.id == id);
    itemToChange.deletedAt = checked ? new Date() : null;
    const result = await updateAdvertisingStatusApi(id, checked);
    if (!result.responseCode.toString().startsWith('2')) {
      itemToChange.deletedAt = !checked ? new Date() : null;
      Global.error({ message: result.responseText || '' });
    }
  }
}

export const collectionListStores = new CollectionStore();

