import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { chartReportUserByLocationStores } from './ChartReportUserByLocationStores';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import * as mobx from 'mobx';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';

class ChartReportUserByLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultActiveKeyTab: 'user-by-location'
        }
    }


    componentDidMount = async () => {
        chartReportUserByLocationStores.getLocations();
    };


    onSelectedTab = (key) => {
        this.setState({ defaultActiveKeyTab: key });

    }


    onSelectedTab = (key) => {
        this.setState({ defaultActiveKeyTab: key });

    }

    render() {
        return (
            <Card style={{ width: '100%' }}>
                <Card.Header as="h5">Chọn ...</Card.Header>
                <Card.Body>
                    <div className="row">
                        <div className="col-md-3">
                            <span>From Date &nbsp;&nbsp;&nbsp;</span>
                            <DatePicker
                                value={chartReportUserByLocationStores.fromDate}
                                adjustDateOnChange={false}
                                className="my-date-picker"
                                selected={chartReportUserByLocationStores.fromDate}
                                dateFormat='dd/MM/yyyy'
                                onBlur={() => {

                                }}
                                onChange={async (date) => {
                                    chartReportUserByLocationStores.fromDate = date;
                                }} />

                        </div>
                        <div className="col-md-3">
                            <span>To Date&nbsp;&nbsp;&nbsp;</span>
                            <DatePicker
                                value={chartReportUserByLocationStores.toDate}
                                adjustDateOnChange={false}
                                className="my-date-picker"
                                selected={chartReportUserByLocationStores.toDate}
                                dateFormat='dd/MM/yyyy'
                                onBlur={() => {

                                }}
                                onChange={async (date) => {
                                    chartReportUserByLocationStores.toDate = date;
                                }} />
                        </div>

                        {/* <div className="col-md-4">
                            <Autocomplete
                                id="ac-type"
                                options={mobx.toJS(chartReportUserByLocationStores.locations)}
                                getOptionLabel={option => option.title}
                                style={{ width: '100%' }}
                                onChange={(e, arr) => {

                                    if (arr) {
                                        console.log('Auto complete');
                                        console.log(arr);
                                        chartReportUserByLocationStores.inputValue = arr.title;
                                    }
                                }}
                                onInputChange={(event, newInputValue) => {
                                    chartReportUserByLocationStores.inputValue = newInputValue;
                                }}
                                inputValue={chartReportUserByLocationStores.inputValue}
                                renderInput={params => <TextField style={{ width: '100%' }}
                                    {...params} label="Choose location" variant="outlined" />
                                }
                            />
                        </div> */}
                        <div className="col-md-1">
                            <button className="btn btn-success" onClick={() => {
                                chartReportUserByLocationStores.getReport();
                            }}>Generate Report</button>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-12">
                            <Tabs
                                onSelect={this.onSelectedTab}
                                activeKey={this.state.defaultActiveKeyTab}
                                transition={false}
                                id="push-notification-tab-aladin">
                                <Tab eventKey="user-by-location" title={"User by location (" + _.size(mobx.toJS(chartReportUserByLocationStores.userInLocation)) + ")"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <BootstrapTable
                                                wrapperClasses="table-responsive"
                                                keyField='areaText'
                                                noDataIndication="Không có dữ liệu"
                                                striped
                                                hover
                                                bordered={false}
                                                data={chartReportUserByLocationStores.userInLocation}
                                                columns={[
                                                    {
                                                        dataField: 'areaText',
                                                        text: 'Area text'
                                                    },
                                                    {
                                                        dataField: 'totalUser',
                                                        text: 'Total User'
                                                    }
                                                ]} />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="product-sold" title={"Product Sold (" + _.size(mobx.toJS(chartReportUserByLocationStores.productSales)) + ")"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <BootstrapTable
                                                wrapperClasses="table-responsive"
                                                keyField='date'
                                                noDataIndication="Không có dữ liệu"
                                                striped
                                                hover
                                                bordered={false}
                                                data={chartReportUserByLocationStores.productSales}
                                                columns={[
                                                    {
                                                        dataField: 'areaText',
                                                        text: 'Area text'
                                                    },
                                                    {
                                                        dataField: 'totalProductSale',
                                                        text: 'Total product sold'
                                                    }
                                                ]} />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="product-mark-as-sold" title={"Product mark as sold (" + _.size(mobx.toJS(chartReportUserByLocationStores.productMarkAsSold)) + ")"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <BootstrapTable
                                                wrapperClasses="table-responsive"
                                                keyField='date'
                                                noDataIndication="Không có dữ liệu"
                                                striped
                                                hover
                                                bordered={false}
                                                data={chartReportUserByLocationStores.productMarkAsSold}
                                                columns={[
                                                    {
                                                        dataField: 'areaText',
                                                        text: 'Area text'
                                                    },
                                                    {
                                                        dataField: 'totalProductSale',
                                                        text: 'Product mark as sold'
                                                    }
                                                ]} />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="user-registered" title={"User registered (" + _.size(mobx.toJS(chartReportUserByLocationStores.userRegistered)) + ")"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <BootstrapTable
                                                wrapperClasses="table-responsive"
                                                keyField='date'
                                                noDataIndication="Không có dữ liệu"
                                                striped
                                                hover
                                                bordered={false}
                                                data={chartReportUserByLocationStores.userRegistered}
                                                columns={[
                                                    {
                                                        dataField: 'areaText',
                                                        text: 'Area text'
                                                    },
                                                    {
                                                        dataField: 'totalUser',
                                                        text: 'User registered'
                                                    }
                                                ]} />
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        );
    }
}

export default observer(ChartReportUserByLocation);