import { requestApi } from "../axios";
const prefix = 'forgot-password';

export function getData(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function saveNote(id, note) {
    return requestApi(`${prefix}/save-note`, 'POST', { id: id, note: note });
}

export function generatePassword(id) {
    return requestApi(`${prefix}/generate-password`, 'POST', { id: id });
}
