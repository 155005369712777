import React, { useState, useContext } from 'react';
import CurrentModListTable from './CurrentModListTable';
import NewModListTable from './NewModListTable';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AddedModsContext from '../context/addedModsContext';

const ModListPage = () => {
  const [selectedTab, setSelectedTab] = useState('forum-current-mods');
  const onSelectedTab = (key) => setSelectedTab(key);
  const [addedMods, setAddedMods] = useState([]);
  const [refreshAddedMods, setRefreshAddedMods] = useState(false);

  return <AddedModsContext.Provider value={{addedMods, setAddedMods, refreshAddedMods, setRefreshAddedMods}} >
    <Tabs
      onSelect={onSelectedTab}
      activeKey={selectedTab}
      transition={false}
      id="user-detail-modal-tab">
      <Tab eventKey="forum-current-mods" title='Danh sách quản lí hiện tại'>
        <CurrentModListTable />
      </Tab>
      <Tab eventKey="forum-new-mods" title='Thêm quản lí mới' >
        <NewModListTable />
      </Tab>
    </Tabs>
  </AddedModsContext.Provider>
}

export default ModListPage;