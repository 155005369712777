import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ReviewVerifyIdPage from './components/ReviewVerifyId';
import ReviewVerifyPhone from './components/ReviewVerifyPhone';
import UserList from './components/UserList';
import { USER_TAB_KEY, ROUTER } from '../../common/route';
import { observer } from 'mobx-react'
import { reviewVerifyIdStores } from './stores/ReviewVerifyIdStores';
import { userListStores } from './stores/UserListStores';
import { history } from 'src/history';
import { reviewVerifyPhoneStores } from './stores/ReviewVerifyPhoneStores';


class UserTabsComponent extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            defaultActiveKeyTab: USER_TAB_KEY.user_list
        }
        if (history.location.pathname === ROUTER.USER.VERIFY_ID) {
            this.initialState.defaultActiveKeyTab = USER_TAB_KEY.review_verify_id;
        } else if (history.location.pathname === ROUTER.USER.VERIFY_PHONE) {
            this.initialState.defaultActiveKeyTab = USER_TAB_KEY.review_verify_phone;
        }
        this.state = this.initialState;
    };
    render() {
        const { defaultActiveKeyTab } = this.state;
        const userListTitle = `Danh sách người dùng (${userListStores.totalUserListCount || 0})`;
        const verifyIdListTitle = `Phê duyệt xác thực ID (${reviewVerifyIdStores.totalReviewVerifyIdSize || 0})`;
        const verifyPhoneListTitle = `Hỗ trợ xác thực số điện thoại (${reviewVerifyPhoneStores.totalReviewVerifyPhoneSize || 0})`;
        return (
            <Tabs
                mountOnEnter
                defaultActiveKey={defaultActiveKeyTab}
                transition={false}
                id="user-tab-aladin">
                <Tab eventKey={USER_TAB_KEY.user_list} title={userListTitle}>
                    <UserList {...this.props} />
                </Tab>
                <Tab eventKey={USER_TAB_KEY.review_verify_id} title={verifyIdListTitle}>
                    <ReviewVerifyIdPage {...this.props} />
                </Tab>
                <Tab eventKey={USER_TAB_KEY.review_verify_phone} title={verifyPhoneListTitle}>
                    <ReviewVerifyPhone {...this.props} />
                </Tab>
            </Tabs>
        );
    }

    componentDidMount = () => {
    };

}

export default observer(UserTabsComponent);