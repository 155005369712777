import React, { useEffect } from "react";
import PaginationTable from "../../components/PaginationTable";
import moment from "moment";
import { useObserver } from "mobx-react";
import { collectionListStores } from "../store/CollectionStore";
import { toJS } from 'mobx';
import { user_avatar_default } from 'src/common/images';
import { specificCollectionStore } from '../store/SpecificCollectionStore';
import { Switch } from '@material-ui/core';

const CollectionTable = ({editCollectionHandler}) => {
  useEffect(() => {
    collectionListStores.getCollectionList();
    collectionListStores.getTotalSize();
  }, [])

  const editClickHandler = (row) => {
    specificCollectionStore.collection = row;
    if (editCollectionHandler) editCollectionHandler();
  }

  const onTableChange = (type, { page, sizePerPage }) => {
    window.scrollTo(0, 0);
    collectionListStores.setCollectionTablePageHandler(page);
    collectionListStores.getCollectionList();
  }

  const headerFormatter = (column) => {
    return (
      <h5>
        <strong>{column.text}</strong>
      </h5>
    );
  };
  
  const dateFormatter = (cell) => {
    return (
      <div>
        <span>{moment(cell).format("DD/MM/YYYY")}</span>
      </div>
    );
  };
  
  const statusFormater = (cell, row) => {
    return <div className="td-actions text-center">
        {
          !!row.deletedAt ?
            <span className="badge badge-pill badge-danger">Ngưng hoạt động</span>
            :
            <span className="badge badge-pill badge-success">Hoạt động</span>
        }
    </div>
  }

  const activeFormatter = (cell, row) => {
    return (
      <div className="td-actions text-left">
        <Switch
          onChange={(e, checked) => {
            collectionListStores.updateCollectionStatus(row.id, !checked);
          }}
          checked={!row.deletedAt} />
      </div>
    )
}
  
  const headerStyleDefault = (column, colIndex) => ({
    width: 100,
  })
  
  const paginationOption = (totalCollectionListCount, collectionTableCurrentPage) => {
    return {
      custom: true,
      hideSizePerPage: true,
      totalSize: totalCollectionListCount,
      page: collectionTableCurrentPage
    }
  };

  const removeProductHanlder = (collection) => {
    const confirmAlert = window.confirm(`Bạn có chắc muốn xoá collection ${collection.advertisingName}?`);
    if (confirmAlert) {
      collectionListStores.removeCollection(collection)
    }
  }
  
  const actionButtons = (cell, row) => {
    return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
        <button onClick={() => editClickHandler(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
            <i className="material-icons">edit</i>
        </button>
        <button style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-danger btn-simple" onClick={() => removeProductHanlder(row)} >
            <i className="material-icons">clear</i>
        </button>
    </div>
  }

  const thumbFormatter = (cell, row) => {
    return <div key={row.id} className="card-avatar">
      <img className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.thumbImage || user_avatar_default} />
    </div>
  }
  
  const columns = [
    {
      dataField: "id",
      text: "#",
      headerFormatter: headerFormatter,
      headerStyle: {
        width: 60,
      },
    },
    {
      dataField: "advertisingName",
      text: "Tên Collection",
      headerFormatter: headerFormatter,
      headerStyle: headerStyleDefault
    },
    {
      dataField: "thumbImage",
      text: "Hình ảnh",
      formatter: thumbFormatter,
      headerFormatter: headerFormatter,
      headerStyle: headerStyleDefault
    },
    {
      dataField: "createdAt",
      text: "Ngày tạo",
      headerFormatter: headerFormatter,
      formatter: dateFormatter,
      headerStyle: headerStyleDefault
    },
    {
      dataField: "endDate",
      text: "Ngày kết thúc",
      headerFormatter: headerFormatter,
      formatter: dateFormatter,
      headerStyle: headerStyleDefault
    },
    {
      dataField: "status",
      text: "Trạng thái",
      headerFormatter: headerFormatter,
      formatter: activeFormatter,
      headerStyle: headerStyleDefault
    },
    {
      dataField: "layoutFormat",
      text: "Cách hiển thị",
      headerFormatter: headerFormatter,
      headerStyle: headerStyleDefault
    },
    {
      dataField: "action",
      text: "Hành động",
      formatter: actionButtons,
      headerFormatter: headerFormatter,
      headerStyle: {
        width: 60,
      },
    },
  ];

  return useObserver(() => {
    const { getCollectionListStatus, collectionList, totalCollectionListCount, collectionTableCurrentPage } = toJS(collectionListStores);

    return <div className='collection-list'>
        {/* <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className='material-icons'>search</i>
            </span>
          </div>
          <input
            style={{ marginBottom: 12 }}
            // onChange={this.onChangeText}
            type="text"
            // value={this.state.txtSearch}
            className="form-control"
            placeholder="Tìm kiếm theo tên, email hoặc số điện thoại..." />
        </div> */}
        <PaginationTable
          paginationOptions={(() => paginationOption(totalCollectionListCount, collectionTableCurrentPage))()}
          onTableChange={onTableChange}
          datas={collectionList}
          columns={columns}
          loading={getCollectionListStatus}
        />
      </div>
  });
};

export default CollectionTable;
