import React, { Component } from 'react';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import BootstrapTable from 'react-bootstrap-table-next';

export default class PaginationTable extends Component {

    onTableChange = () => { }

    render() {
        const {
            paginationOptions,
            onTableChange,
            datas,
            keyField,
            columns,
            loading,
            tableRowEvents
        } = this.props;
        const defaultPagination = {
            custom: true,
            hideSizePerPage: true,
            sizePerPage: 10,
        };
        return (
            <PaginationProvider
                pagination={paginationFactory({ ...paginationOptions, ...defaultPagination })}
            >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                            <div>
                                <BootstrapTable
                                    remote
                                    wrapperClasses="table-responsive"
                                    keyField={keyField || 'id'}
                                    data={datas || []}
                                    columns={columns || []}
                                    striped
                                    hover
                                    bordered={false}
                                    loading={loading}
                                    overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(0, 0, 0, 0.15)' }) } })}
                                    noDataIndication="Không có dữ liệu"
                                    {...paginationTableProps}
                                    onTableChange={onTableChange || this.onTableChange}
                                    rowEvents={tableRowEvents}
                                />

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <PaginationListStandalone
                                        {...paginationProps}

                                    />
                                </div>
                            </div>
                        )
                }
            </PaginationProvider>
        );
    }
}
