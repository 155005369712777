import React, { Component } from 'react';
import ReviewVerifyPhoneTable from './ReviewVerifyPhoneTable';
import { Global } from '../../../../GlobalClass';
import { reviewVerifyPhoneStores } from '../../stores/ReviewVerifyPhoneStores';
import { observer } from 'mobx-react';
import { ROUTER } from '../../../../common/route';
import { getParamURL } from '../../../../helpers/CF';
import ReviewVerifyPhoneChooseReasonModal from './ReviewVerifyPhoneChooseReasonModal';


class ReviewVerifyPhone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowVerifyReasonModal: false,
            verifyUserId: null
        }

    };


    onHideReviewVerifyReasonModal = () => {
        this.setState({ isShowVerifyReasonModal: false, verifyUserId: null });
    }

    onRejectItemClick = (item) => {
        this.setState({ isShowVerifyReasonModal: true, verifyUserId: item.id });
    }

    onApproveRequest = (id) => {
        const { verifyUserId } = this.state;
        if (id || verifyUserId) reviewVerifyPhoneStores.onApproveVerifyPhoneRequest(id || verifyUserId);
    }

    onRejectRequest = ({ reason, rejectedId }) => {
        const { verifyUserId } = this.state;
        const payload = {
            userId: verifyUserId,
            rejectTypeId: rejectedId,
            rejectContent: reason
        }
        reviewVerifyPhoneStores.onRejectVerifyPhoneRequest(payload);
    }

    render() {
        const { isShowVerifyReasonModal } = this.state;
        return (
            <div className="card">
                <div className="card-body">
                    <ReviewVerifyPhoneTable
                        getTotalSize={this.getTotalSize}
                        getUserList={this.getUserList}
                        onApproveRequest={this.onApproveRequest}
                        onRejectItemClick={this.onRejectItemClick}
                    />
                </div>
                <ReviewVerifyPhoneChooseReasonModal
                    // onApproveRequest={this.onApproveRequest}
                    onRejectRequest={this.onRejectRequest}
                    onHide={this.onHideReviewVerifyReasonModal}
                    isShow={isShowVerifyReasonModal} />
            </div>
        );
    }

    
    getUserList = (search) => {
        reviewVerifyPhoneStores.getReviewVerifyPhoneList(search);
    }


    getTotalSize = async (search) => {
        await reviewVerifyPhoneStores.getVerifyPhoneRequestCount(search);
    }


    getReviewProductList = async () => {
        reviewVerifyPhoneStores.getReviewVerifyPhoneList();
    }

    componentDidMount = async () => {
        await reviewVerifyPhoneStores.getVerifyPhoneRequestCount();
        await reviewVerifyPhoneStores.getVerifyPhoneRejectTypeListAction();
        this.getReviewProductList();
        // const verifyUserId = getParamURL(this.props, 'id');
        // if (!!verifyUserId)
        //     this.setState({ verifyUserId, isShowVerifyPhoneDetailModal: true });
    };

}

export default observer(ReviewVerifyPhone);