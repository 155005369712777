import React, { Component } from 'react';
import VerticalLinearStepper from './components/VerticalLinearStepper';
import { NOTIFICATION_TAB_KEY, pushNotificationStores } from './stores/PushNotificationStores';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TableScheduledNotification from './components/TableScheduledNotification';
import TableConfigOnOffPushNotification from './components/TableConfigOnOffPushNotification';
import { observer } from 'mobx-react';

class PushNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultActiveKeyTab: NOTIFICATION_TAB_KEY.PUSH_NOTIFICATION
        }
    };

    onSelectedTab = (key) => {
        this.setState({ defaultActiveKeyTab: key });
    }


    onCancelEditClick = () => pushNotificationStores.cancelEditScheduledNotificationWorker();


    render() {
        const isEditNoti = !!pushNotificationStores.notificationIdToEdit;
        const title = isEditNoti ? `Chỉnh sửa thông báo #${pushNotificationStores.notificationIdToEdit}` : 'Gửi thông báo tới người dùng Aladin';
        return (
            <Tabs
                onSelect={this.onSelectedTab}
                activeKey={this.state.defaultActiveKeyTab}
                transition={false}
                id="push-notification-tab-aladin">
                <Tab eventKey={NOTIFICATION_TAB_KEY.PUSH_NOTIFICATION} title="Gửi thông báo (Push notification)">
                    <div className="card">
                        <div className="card-header card-header-primary row align-items-center">
                            <h4 className="card-title"><b>{title}</b></h4>
                            {
                                isEditNoti &&
                                <button
                                    onClick={this.onCancelEditClick}
                                    style={{ marginLeft: 16 }} type="reset" rel="tooltip" className="btn btn-danger btn-sm">Hủy bỏ</button>
                            }
                        </div>
                        <div className="card-body">
                            <VerticalLinearStepper />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey={NOTIFICATION_TAB_KEY.SCHEDULED_PUSH_NOTIFICATION} title="Lịch sử hẹn giờ gửi thông báo">
                    <div className="card">
                        <div className="card-header card-header-primary">
                            <h4 className="card-title"><b>Lịch sử gửi thông báo</b></h4>
                            {/* <p class="card-category">Gửi thông báo tới User Aladin </p> */}
                        </div>
                        <div className="card-body">
                            <TableScheduledNotification
                                onSelectedTab={this.onSelectedTab} />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey={NOTIFICATION_TAB_KEY.CONFIG_PUSH_NOTIFICATION} title="Cấu hình ON/OFF gửi thông báo">
                    <div className="card">
                        <div className="card-header card-header-primary">
                            <h4 className="card-title"><b>Cấu hình ON/OFF gửi thông báo</b></h4>
                            {/* <p class="card-category">Gửi thông báo tới User Aladin </p> */}
                        </div>
                        <div className="card-body">
                            <TableConfigOnOffPushNotification />
                        </div>
                    </div>
                </Tab>
            </Tabs >

        );
    }

    componentDidMount = () => {
        pushNotificationStores.getNotificationTypesStore();
    };

    componentWillUnmount() {
        pushNotificationStores.cancelEditScheduledNotificationWorker();
        pushNotificationStores.setProcessStep(0);
    }


}
export default observer(PushNotification);
