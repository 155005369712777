import { extendObservable, toJS } from 'mobx';
import { getUserDetailApi, getFeedbacksOfUserApi, getReportsOfUserApi, getFeedbacksOfUserCountApi, updatePaperId } from '../../../api/user/user-account';
import { Global } from '../../../GlobalClass';
import { pageableDefault } from '../../../common/constant';
import { pageableOffset } from 'src/helpers/CF';
import { getProductListApi, getTotalProductCountApi } from 'src/api/product/product';
class UserDetailStores {
    constructor() {
        extendObservable(this, {
            isShowUserDetailModal: false,
            loadingDetailData: false,
            itemUserDetail: {},
            userIdForLoadDetail: 0,
            feedbacksOfUser: [],
            feedbacksOfUserCount: 0,
            pageableFeedbacksOfUser: pageableDefault,
            loadingUserDetailHistory: false,
            reportsOfUser: [],
            pageableReportsOfUser: pageableDefault,

            userProductList: [],
            userProductListTableCurrentPage: 1,
            getUserProductListLoading: false,
            totalUserProductListCount: 0,
            paperUpdating: false
        });
    }

    onChangeUserDetailModal = (status) => this.isShowUserDetailModal = status;

    onLoadUserDetail = async () => {
        if (!this.userIdForLoadDetail) return Global.error({ message: 'Dữ liệu không hợp lệ.' });
        this.loadingDetailData = true;
        const result = await getUserDetailApi(this.userIdForLoadDetail);
        this.setUserDetailData(result.data || {});
        this.loadingDetailData = false;
    }

    onLoadFeedbacksOfUser = async (page) => {
        try {
            if (!this.userIdForLoadDetail) return Global.error({ message: 'Dữ liệu không hợp lệ.' });
            this.loadingUserDetailHistory = true;
            const offset = (page * FEEDBACK_OF_USER_LIMIT_PAGEABLE) - FEEDBACK_OF_USER_LIMIT_PAGEABLE;
            const result = await getFeedbacksOfUserApi(this.userIdForLoadDetail, FEEDBACK_OF_USER_LIMIT_PAGEABLE, offset);
            this.setFeedbacksOfUserData(result.dataArray);
            this.loadingUserDetailHistory = false;
        } catch (error) {
            Global.error({ message: error.message });
        }
    }

    onLoadFeedbacksOfUserCount = async () => {
        try {
            if (!this.userIdForLoadDetail) return Global.error({ message: 'Dữ liệu không hợp lệ.' });
            const result = await getFeedbacksOfUserCountApi(this.userIdForLoadDetail);
            this.feedbacksOfUserCount = Number(result.data.total || '0');
        } catch (error) {
            Global.error({ message: error.message });
        }
    }

    setReportOfUserPageable = (data) => {
        this.pageableReportsOfUser = data;
    }

    onLoadReportsOfUser = async (loadMore) => {
        if (!this.userIdForLoadDetail) return Global.error({ message: 'Dữ liệu không hợp lệ.' });
        this.loadingUserDetailHistory = true;
        const result = await getReportsOfUserApi(this.userIdForLoadDetail, this.pageableReportsOfUser.offset);
        let currentArr = toJS(this.reportsOfUser);
        let newArr = (result.dataArray || []);
        if (loadMore) {
            newArr = [...currentArr, ...(result.dataArray || [])]
        }
        this.setReportsOfUserData(newArr);
        let newPageable = {
            offset: this.pageableReportsOfUser.offset + newArr.length,
            last: result.pageable.last
        }
        this.setReportOfUserPageable(newPageable);
        this.loadingUserDetailHistory = false;
    }

    setUserIdForLoadDetail = (id) => this.userIdForLoadDetail = id;

    setUserDetailData = (data) => this.itemUserDetail = data || {};
    setFeedbacksOfUserData = (datas) => this.feedbacksOfUser = datas || [];
    setReportsOfUserData = (datas) => this.reportsOfUser = datas || [];


    getUserProductListStore = async () => {
        this.getUserProductListLoading = true;
        const offset = pageableOffset(this.userProductListTableCurrentPage);
        const result = await getProductListApi(offset, null, this.userIdForLoadDetail);
        if (result.responseCode.toString().startsWith('2')) {
            this.userProductList = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.getUserProductListLoading = false;
    }
    setProductTablePage = (page) => this.userProductListTableCurrentPage = page;

    getTotalProductCount = async () => {
        const result = await getTotalProductCountApi(null, this.userIdForLoadDetail);
        if (result.responseCode.toString().startsWith('2')) {
            this.totalUserProductListCount = parseInt(result.data.total);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    updatePaperId = async (paperId) => {
        try {
            if (!this.itemUserDetail.id || !paperId) return;
            const body = {
                userId: this.itemUserDetail.id,
                paperType: this.itemUserDetail.paperType,
                paperId
            }
            this.paperUpdating = true;
            const result = await updatePaperId(body);
            this.paperUpdating = false;
            if (!result.responseCode.toString().startsWith('2')) {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.paperUpdating = false;
        }
    }

    clearUserProductListData = () => {
        this.totalUserProductListCount = 0;
        this.userProductList = [];
        this.userProductListTableCurrentPage = 1;
    }

}
export const FEEDBACK_OF_USER_LIMIT_PAGEABLE = 6;
export const userDetailStores = new UserDetailStores();