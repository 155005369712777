import { requestApi } from "../axios";
import {stringify} from 'querystring';
const prefix = 'payment';


export function getOrderListApi(offset, search, step, result) {
    return requestApi(`${prefix}/getOrder?${stringify({limit: 10, offset, step, result})}`, 'GET');
}

export function getTotalOrderCountApi(search, step, result) {
    return requestApi(`${prefix}/getOrderCount?${stringify({step, result})}`, 'GET');
}

export function confirmPickUpProductFromSellerApi(orderId) {
    return requestApi(`${prefix}/confirmGetProductFromSeller`, 'POST', {orderId});
}

export function confirmDeliverProductToBuyerApi(orderId) {
    return requestApi(`${prefix}/confirmGiveProductToBuyer`, 'POST', {orderId});
}

export function confirmDeliveryFailureApi(orderId, note) {
    return requestApi(`${prefix}/confirmDeliveryFailure`, 'POST', {orderId, note});
}

export function confirmPayment(partnerTransaction) {
    return requestApi(`${prefix}/confirmPayment`, 'POST', {partnerTransaction});
}