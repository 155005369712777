import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { forgotPasswordPhoneNumberStores } from '../stores/ForgotPasswordPhoneNumberStores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { user_avatar_default } from '../../../common/images';
import moment from 'moment'

class ForgotPasswordPhoneNumberTable extends Component {
    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: forgotPasswordPhoneNumberStores.totalFeedbackCount,
            page: forgotPasswordPhoneNumberStores.feedbackCurrentPage,
        };
        return paginationOption;
    }

    avatarFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar} />
        </div>
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img alt='' className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar || user_avatar_default} />
        </div>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('DD/MM/YYYY hh:mm')}</p>
                    </div>
                }

            </div>
        );
    }
    statusFormater = (cell, row) => {
        return <div className="td-actions text-center">
            {
                !row.statusFeedback ?
                    <span className="badge badge-pill badge-danger">Chưa trả lời</span>
                    :
                    <span className="badge badge-pill badge-success">Đã trả lời</span>
            }
        </div>
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'phoneNumber',
                text: 'Số điện thoại',
                headerFormatter: this.headerFormatter,
                // formatter: this.thumbFormatter,
                headerStyle: {
                    width: 100,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'processedAt',
                text: 'Ngày admin thực hiện',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: {
                    width: 160
                }
            },
            {
                dataField: 'note',
                text: 'Ghi chú của admin',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 170
                }
            },
            {
                dataField: 'avatar',
                text: 'Avatar',
                headerFormatter: this.headerFormatter,
                formatter: this.avatarFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'username',
                text: 'Admin',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'id',
                text: 'Action',
                headerFormatter: this.headerFormatter,
                formatter: this.actionButtons,
                headerStyle: {
                    width: 80
                }
            },
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
        textAlign: 'center'
    })

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => forgotPasswordPhoneNumberStores.onItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">send</i>
            </button>
        </div >
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        forgotPasswordPhoneNumberStores.setPage(page);
        forgotPasswordPhoneNumberStores.getData();
    }

    render() {
        const forgotPasswordPhoneNumberList = toJS(forgotPasswordPhoneNumberStores).forgotPasswordPhoneNumberList;
        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={forgotPasswordPhoneNumberList}
                columns={this.columnsOption()}
                loading={forgotPasswordPhoneNumberStores.loading}
            />
        );
    }
}

export default observer(ForgotPasswordPhoneNumberTable);