import React, { useState, memo } from 'react';

const PayloadForm = ({
  eventType,
  setPayload,
  payload,
  errorMsg
}) => {
  const [textInput, setTextInput] = useState('');

  const renderItem = () => {
    switch (eventType) {
      case 'OPEN_URL':
        return <div>
          <label style={{marginRight: 16, width: '15%'}} >URL: </label>
          <input type='text' style={{width: '40%', padding: 5}} value={payload.url} onChange={e => setPayload({
            ...payload,
            url: e.target.value
          })} />
          {errorMsg && <div style={{display: 'flex', flexDirection: 'row'}} >
            <div style={{width: '25%'}} />
            <p style={{color: 'red'}} >{errorMsg}</p>
          </div>}
        </div>
      case 'FORUM_TOPIC':
        return <div>
          <label style={{marginRight: 16, width: '15%'}} >topicID: </label>
          <input type='text' style={{width: '40%', padding: 5}} onChange={e => setPayload({
            ...payload,
            topicId: e.target.value
          })} 
          value={payload.topicId}
          />
        </div>
      case 'PRODUCT_DETAIL':
        return <div style={{marginTop: 16}} >
            <label style={{marginRight: 16, width: '15%'}} >productId: </label>
            <input type='text' style={{width: '40%', padding: 5}} onChange={e => setPayload({
              ...payload,
              productId: e.target.value
            })} 
            value={payload.productId}
            />
          </div>
      case 'PUBLIC_PROFILE_PRODUCTS':
        return <div style={{marginTop: 16}} >
            <label style={{marginRight: 16, width: '15%'}} >userId: </label>
            <input type='text' style={{width: '40%', padding: 5}} onChange={e => setPayload({
              ...payload,
              userId: e.target.value
            })} 
            value={payload.userId}
            />
          </div>
      case 'SEARCH_SCREEN':
        return <div>
          <div style={{marginTop: 16}} >
            <label style={{marginRight: 16, width: '15%'}} >Từ khoá: </label>
            <input type='text' style={{width: '40%', padding: 5}} onChange={e => setPayload({
              ...payload,
              keyword: e.target.value
            })} 
            value={payload.keyword}
            />
          </div>
          <div style={{marginTop: 16}} >
            <label style={{marginRight: 16, width: '15%'}} >adID: </label>
            <input type='text' style={{width: '40%', padding: 5}} onChange={e => setPayload({
              ...payload,
              adId: e.target.value
            })} 
            value={payload.adId}
            />
          </div>
        </div>
        case 'OPEN_URL_INAPP':
          return <div>
            <label style={{ marginRight: 16, width: '15%' }} >URL: </label>
            <input type='text' style={{ width: '40%', padding: 5 }} value={payload.url} onChange={e => setPayload({
              ...payload,
              url: e.target.value
            })} />
            {errorMsg && <div style={{ display: 'flex', flexDirection: 'row' }} >
              <div style={{ width: '25%' }} />
              <p style={{ color: 'red' }} >{errorMsg}</p>
            </div>}
          </div>
      default:
        return null;
    }
  }

  return <div style={{marginTop: 16}} >
    {renderItem()}
  </div>
}

export default memo(PayloadForm);