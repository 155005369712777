import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import _ from 'lodash';
import { observer } from 'mobx-react'
import { routerStores } from '../stores/RouterStores';
import { ROUTER } from '../../../common/route';
import { appStores } from '../../../stores/AppStores';
import { onSetSideBars } from '../../../helpers/CF';

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    onRouteChanged = () => {
        try {
            const { pathname } = this.props.location;
            const temp = onSetSideBars();
            temp.forEach(item => {
                if (!!item.children && !!item.children.length) {
                    item.children.map(item1 => {
                        if (pathname.toLowerCase().startsWith(item1.to.toLowerCase())) {
                            routerStores.setRouteTitle(item1.title);
                            routerStores.setRouteIndex(item1.id);
                        }
                    })
                } else {
                    if (pathname.toLowerCase().startsWith(item.to.toLowerCase())) {
                        routerStores.setRouteTitle(item.title);
                        routerStores.setRouteIndex(item.id);
                    }
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    renderItem = (props, index) => {
        if (props.children) {
            return (
                <li key={index} className={routerStores.routerIndex == props.id ? "nav-item active" : "nav-item"}>
                    <a data-toggle="collapse" href="#componentsExamples" className="nav-link" aria-expanded="true">
                        <i className="material-icons">{props.icon}</i>
                        <p>
                            {props.title}
                        </p>
                    </a>
                    <div className="collapse in" id="componentsExamples" aria-expanded="true" >
                        <ul className="nav">
                            {
                                props.children.map((child, index) =>
                                    <li className={routerStores.routerIndex == child.id ? "nav-item active" : "nav-item"}
                                        key={`${index}`}
                                        style={{
                                            marginLeft: '30px',
                                        }}>
                                        <Link to={child.to} className="nav-link" >
                                            <p>{child.title}</p>
                                        </Link>
                                    </li>
                                )
                            }

                        </ul>
                    </div>
                </li>
            )
        } else {
            return (
                <li key={index} className={routerStores.routerIndex == props.id ? "nav-item active" : "nav-item"} >
                    <Link to={props.to} className="nav-link">
                        <i className="material-icons">{props.icon}</i>
                        <p>{props.title}</p>
                    </Link>
                </li>
            )
        }

    }

    render() {
        const { sideBarItems } = appStores;
        return (
            <div className="sidebar" data-color="orange" data-background-color="white">
                {/* <!--
            Tip 1: You can change the color of the sidebar using: data-color="purple | azure | green | orange | danger"

            Tip 2: you can also add an image using data-image tag
            --> */}
                <div className="logo">
                    <Link to="/Portal" className="simple-text logo-normal">Admin Portal</Link>
                </div>
                <div className="sidebar-wrapper">
                    <ul className="nav">
                        {
                            sideBarItems.map((item, index) =>
                                this.renderItem(item, index)
                            )
                        }
                    </ul>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.onRouteChanged();
    };


}


export default observer(SideBar);