import { extendObservable } from 'mobx';
import { uploadImageNoResize } from '../../../api/axios';
import { postArticle, getArticleGroups, getArticles, editArticle, deleteArticle } from '../../../api/article/article';

class ArticleStores {
    constructor() {
        extendObservable(this, {
            datas: [],
            showInsertModal: false,
            title: '',
            subTitle: '',
            content: '',
            url: '',
            uploadedImageUrl: '',
            uploadingImage: false,
            editId: -1,
            editObj: {},
            uploadingDetailImage: false,
            articleGroups: [],
            articleGroup: 0,
            currentPage: 1
        });
    }

    resetData() {
        this.datas = [];
        this.showInsertModal = false;
        this.title = '';
        this.subTitle = '';
        this.content = '';
        this.url = '';
        this.uploadedImageUrl = '';
        this.uploadingImage = false;
        this.editId = -1;
        this.editObj = {};
        this.uploadingDetailImage = false;
        this.articleGroups = [];
        this.articleGroup = 0;
    }

    loadData = async () => {
        var limit = 10;
        var offset = 0;
        if (this.currentPage > 1) {
            offset = limit * (this.currentPage - 1)
        }
        const articles = await getArticles(limit, offset);
        this.datas = articles.dataArray;
        this.loadArticleGroups();
    }

    loadArticleGroups = async () => {
        var articleGroups = await getArticleGroups();
        this.articleGroups = articleGroups.dataArray;
    }

    uploadDetailImg = async (blobFile, callback) => {
        this.uploadingDetailImage = true;
        let data = new FormData();
        let file = blobFile;

        data.append('file', file, file.name);

        const result = await uploadImageNoResize(data);
        this.uploadingDetailImage = false;
        callback(result.data.uploadedUrl)
    }

    onDrop = async (acceptedFiles, rejectedFiles) => {
        this.uploadingImage = true;
        this.uploadedImageUrl = '';
        if (acceptedFiles.length < 0) {
            return
        }
        let data = new FormData();
        let file = acceptedFiles[0];
        data.append('file', file, file.name);

        const result = await uploadImageNoResize(data);
        try {
            this.uploadedImageUrl = result.data.uploadedUrl;
            this.uploadingImage = false;
        } catch (error) {
            this.uploadingImage = false;
        }
    }

    onSave = async () => {
        const title = this.title;
        const subTitle = this.subTitle;
        const content = this.content;
        const uploadedImageUrl = this.uploadedImageUrl;
        const groupId = this.articleGroup;

        if (this.editId != -1) {
            await editArticle({
                id: this.editId,
                title: title,
                subTitle: subTitle,
                content: content,
                thumbImage: uploadedImageUrl,
                groupId: groupId
            })

        }
        else {
            await postArticle({
                title: title,
                subTitle: subTitle,
                content: content,
                thumbImage: uploadedImageUrl,
                groupId: groupId
            })

        }

        this.resetData();
    }

    onDeleteClicked = async (row) => {

        await deleteArticle({
            id: row.id
        })
        this.loadData();
    }

    onEditClicked = async (row) => {
        this.showInsertModal = true;
        this.title = row.title;
        this.subTitle = row.subTitle;
        this.content = row.content;
        this.uploadedImageUrl = row.thumbImage
        this.uploadingImage = false;
        this.editId = row.id;
        this.uploadingDetailImage = false;
    }

}
export const articleStores = new ArticleStores;