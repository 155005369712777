import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import CoalitionReviewModal from './components/CoalitionReviewModal';
import CoalitionReviewTable from './components/CoalitionReviewTable';
import { reviewCoalitionStores } from './stores/ReviewCoalitionStores';
import { history } from '../../history';
import { ROUTER } from '../../common/route';
import { getParamURL } from '../../helpers/CF';

class Coalition extends Component {
    constructor(props) {
        super(props);
    };

    onItemClicked(row) {
        reviewCoalitionStores.isShowReviewCoaltionModal = true;
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3 offset-md-9" style={{
                            textAlign: 'end'
                        }}>
                        </div>
                        <CoalitionReviewTable onItemClicked={this.onItemClicked} />
                    </div>
                </div>
                <CoalitionReviewModal
                    show={reviewCoalitionStores.isShowReviewCoaltionModal} onHide={() => {
                        reviewCoalitionStores.isShowReviewCoaltionModal = false
                    }} />
            </div>
        );
    }

    componentDidMount = async () => {
        reviewCoalitionStores.getTotalCount();
        reviewCoalitionStores.loadData();
        console.log(history.location.pathname);
        if (history.location.pathname == '/Portal/Coalition') {
            const id = getParamURL(this.props, 'id');
            if (!!id) {
                console.log('22222');
                reviewCoalitionStores.showModalById(id);
            }

        }
    };

}

export default observer(Coalition);