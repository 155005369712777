import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { pushNotificationStores } from '../stores/PushNotificationStores';
import { Global } from '../../../GlobalClass';
import { COLOR } from '../../../common/constant';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

const muiTheme = createMuiTheme({
    overrides: {
        MuiStepIcon: {
            root: {
                '&$active': {
                    fill: COLOR.primary,
                    '& $text': {
                        fill: 'white',
                    },
                },
            },
            text: {
                fill: 'white',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return ['Nhập thông tin notification', 'Chọn đối tượng cần gửi', 'Review'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return (
                <Step1 />
            )
        case 1:
            return (
                <Step2 />
            )

        case 2:
            return (
                <Step3 />
            )
        default:
            return 'Unknown step';
    }
}

const VerticalLinearStepper = () => {
    const classes = useStyles();
    const [isSuccess, setIsSuccess] = React.useState();
    const steps = getSteps();
    const {
        pushNotificationTitle,
        pushNotificationContent,
        pushNotification,
        notificationTypeSelected,
        payloadData,
        notificationIdToEdit,
        updateScheduledNotificationWorker,
        processStep,
        setProcessStep
    } = pushNotificationStores;

    const handleNext = async () => {
        if (!pushNotificationTitle || !pushNotificationContent || !notificationTypeSelected.id)
            return Global.warning({ message: 'Vui lòng điền đầy đủ thông tin' });
        if (!!notificationTypeSelected.id) {
            const { payloadSchema } = toJS(notificationTypeSelected);
            const keys = Object.keys(payloadSchema || {});
            for (const key of keys) {
                if (payloadSchema[key].require && !payloadData[key])
                    return Global.warning({ message: `Vui lòng nhập vào ${payloadSchema[key].title}` });
            }
        }

        if (processStep === 1) {
            pushNotificationStores.loadSegmentsUser();
        }
        if (processStep === steps.length - 1) {
            let result;
            if (!!notificationIdToEdit)
                result = await updateScheduledNotificationWorker();
            else result = await pushNotification();
            setIsSuccess(result);
            if (!!!result)
                return;
        }
        setProcessStep(processStep + 1);
    };

    const handleBack = () => {
        setProcessStep(processStep - 1);
    };

    const buttonTitle1 = useMemo(() => {
        if (processStep === steps.length - 1) {
            if (!!notificationIdToEdit) return 'Cập nhật';
            return 'Gửi notification';
        }
        return 'Tiếp';
    }, [processStep, steps, notificationIdToEdit]);

    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={muiTheme}>
                <Stepper activeStep={processStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel style={{ color: 'red' }}>{label}</StepLabel>
                            <StepContent>
                                <Typography component={'span'}>{getStepContent(index)}</Typography>
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={processStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Quay lại
                                    </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            {buttonTitle1}
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </MuiThemeProvider>
            {processStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography component={'span'}>{isSuccess ? 'Gửi thành công!' : 'Gửi thất bại, vui lòng thử lại'}</Typography>
                    <Button onClick={pushNotificationStores.reset} className={classes.button}>
                        Reset
                    </Button>
                </Paper>
            )}
        </div>
    );
}

export default observer(VerticalLinearStepper);