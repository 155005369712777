import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { AcceptButton, CancelButton } from 'src/pages/components/Button/Button';
import { COLOR } from '../../../common/constant';
import { Global } from '../../../GlobalClass';
import { Switch } from '@material-ui/core';
import CollectionInfo from './CollectionInfo';
import CollectionProducts from './CollectionProducts';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CollectionSearchProduct from './CollectionSearchProduct';
import CollectionRecentlyAddedProduct from './CollectionRecentlyAddedProduct';
import { observer } from "mobx-react";
import { toJS } from 'mobx';
import { reaction } from 'mobx';
import { specificCollectionStore } from "../store/SpecificCollectionStore";

const CollectionModal = ({show, close, createNew}) => {
  const { collection, newAddedProducts } = toJS(specificCollectionStore);
  const [selectedTab, setSelectedTab] = useState('collection-info-tab');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const diposer = reaction(
      () => specificCollectionStore.collection, 
      collection => {
        if (collection && collection.advertisingName && collection.thumbImage && collection.endDate) setDisabled(false)
        else setDisabled(true);
      }
    );
  }, [])

  const acceptButtonHandler = async () => {
    setDisabled(true);
    try {
      if (createNew) {
        const isSubmitedSuccessfull = await specificCollectionStore.createNewCollection();
        setDisabled(false);
        if (isSubmitedSuccessfull) {
          setSelectedTab('collection-info-tab');
          close();
        }
      }
      else {
        const { collection } = toJS(specificCollectionStore);
        if (new Date() > new Date(collection.endDate)) {
          Global.error({message: 'Ngày kết thúc phải lớn hơn hoặc bằng ngày hôm nay'});
          setDisabled(false);
        }
        else {
          const isSubmitedSuccessfull = await specificCollectionStore.updateCollection();
          setDisabled(false);
          if (isSubmitedSuccessfull) {
            setSelectedTab('collection-info-tab');
            close();
          }
        }
      }
    }
    catch (err) {
      Global.error({message: err.message || ''})
      setDisabled(false);
    }
  }

  const cancelButtonHandler = () => {
    specificCollectionStore.resetStore();
    setSelectedTab('collection-info-tab');
    close();
  }
  
  const onSelectedTab = (key) => setSelectedTab(key)

  return <Modal 
    show={show}
    size='xl'
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={close}
  >
    <Modal.Header closeButton onHide={cancelButtonHandler} >
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        {'Tạo Collection mới'}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll', Width: '50vw' }}>
      <Tabs
        onSelect={onSelectedTab}
        activeKey={selectedTab}
        transition={false}
        id="user-detail-modal-tab">
        <Tab eventKey="collection-info-tab" title='Thông tin và hoạt động'>
          <CollectionInfo isNew={createNew} />
        </Tab>
        {!createNew ? <Tab eventKey="collection-added-products-tab" title='Mặt hàng trong Collection' >
          <CollectionProducts />
        </Tab> : null}
        <Tab eventKey="collection-search-products-tab" title='Thêm mặt hàng vào Collection' >
          <CollectionSearchProduct />
        </Tab>
        <Tab eventKey="collection-recently-added-products-tab" title='Sản phẩm vừa thêm vào Collection' >
          <CollectionRecentlyAddedProduct />
        </Tab>
      </Tabs>

    

  </Modal.Body>

    <Modal.Footer>
      <CancelButton onClick={cancelButtonHandler} title='Hủy bỏ' />
      <AcceptButton disabled={disabled} onClick={acceptButtonHandler} title='Đồng ý' />
  </Modal.Footer>
  </Modal>
}

export default observer(CollectionModal);