import React, { useCallback, useMemo } from 'react';
import PaginationTable from '../../../components/PaginationTable';
import moment from 'moment'
import { reviewProductStores } from '../../stores/ReviewProductStores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';


const ProductSupportConversationTable = () => {
    const {
        productDetailInfo,
        supportConversations,
        setSupportConversationsPage,
        supportConversationsPage,
        supportConversationsTotalCount,
        getSupportConversationListWorker,
        getSupportConversationLoading
    } = toJS(reviewProductStores);

    const paginationOption = useMemo(() => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: supportConversationsTotalCount,
            page: supportConversationsPage,
        };
        return paginationOption;
    }, [supportConversationsPage, supportConversationsTotalCount]);

    const onTableChange = useCallback((type, { page, sizePerPage }) => {
        if (!productDetailInfo || !productDetailInfo.id) return;
        window.scrollTo(0, 0);
        setSupportConversationsPage(page);
        getSupportConversationListWorker(productDetailInfo.id);
    }, []);

    const headerFormatter = useCallback((column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }, []);

    const dateFormatter = useCallback((cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('HH:mm - DD/MM/YYYY')}</span>
            </div>
        );
    }, []);

    const columnsOption = useMemo(() => {
        return [
            {
                dataField: 'message',
                // text: 'Nội dung',
                headerFormatter: headerFormatter
            },
            {
                dataField: 'createdAt',
                // text: 'Ngày gửi',
                headerFormatter: headerFormatter,
                headerStyle: { width: 200 },
                formatter: dateFormatter,
            }
        ]
    }, []);

    return (
        <PaginationTable
            paginationOptions={paginationOption}
            onTableChange={onTableChange}
            datas={supportConversations}
            columns={columnsOption}
            loading={getSupportConversationLoading}
        />
    )
}

export default observer(ProductSupportConversationTable);
