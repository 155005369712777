import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { history } from './history';
const path = require('path');
if (!!process.env.NODE_ENV) {
    require('dotenv').config({
        path: path.join(__dirname, `../.env.${process.env.NODE_ENV}`)
    })
} else {
    require('dotenv').config();
}
ReactDOM.render((
    <BrowserRouter>
        <App history={history} />
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
