import React, { Component } from 'react';
import { advertisingStores } from '../stores/AdvertisingStores';
import PaginationTable from '../../components/PaginationTable';
import { toJS } from 'mobx';
import moment from 'moment';
import { user_avatar_default } from '../../../common/images';
import { observer } from 'mobx-react';
import { AcceptButton } from '../../components/Button/Button';

class AdvertisingBannerTable extends Component {

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        advertisingStores.setBannerTablePage(page);
        advertisingStores.getBannerListAction();
    }

    paginationOption = () => {
        const { bannerTotalCount, bannerTableCurrentPage } = toJS(advertisingStores);
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: bannerTotalCount,
            page: bannerTableCurrentPage,
        };
        return paginationOption;
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    thumbFormatter = (cell, row) => {
        return <div
            style={{ textAlign: 'center' }}
            key={row.id}
            className="card-avatar">
            <img alt='' className='img' style={{ width: 100, height: 100, objectFit: 'contain' }} src={row.image || user_avatar_default} />
        </div>
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={this.onItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">edit</i>
            </button>
        </div >
    }

    onItemClicked = (item) => () => {
        const { onBannerDetailModalChange, setDataBannerDetail } = toJS(advertisingStores);
        onBannerDetailModalChange(true);
        setDataBannerDetail(item);
    }

    statusFormater = (cell, row) => {
        return <div className="td-actions text-center">
            {
                !!row.status ?
                    <span className="badge badge-pill badge-success">Đang hoạt động</span>
                    :
                    <span className="badge badge-pill badge-danger">Đã ẩn</span>
            }
        </div>
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('hh:mm A')}</p>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                    </div>
                }

            </div>
        );
    }

    urlFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a href={cell}>{'link'}</a>
            </div>
        );
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 30
                }
            },
            {
                dataField: 'fullname',
                text: 'Người tạo',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'title',
                text: 'Tiêu đề',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'image',
                text: 'Hình ảnh',
                headerFormatter: this.headerFormatter,
                formatter: this.thumbFormatter,
                headerStyle: {
                    width: 50,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'url',
                text: 'Đường dẫn',
                headerFormatter: this.headerFormatter,
                formatter: this.urlFormatter,
                headerStyle: {
                    width: 35,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'createdAt',
                text: 'Thời gian tạo',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'status',
                text: 'Trạng thái',
                formatter: this.statusFormater,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: this.actionButtons,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 50,
                    textAlign: 'center'
                }
            }
        ];
    }

    onAddBanner = () => {
        advertisingStores.onBannerDetailModalChange(true);
    }

    render() {
        const { bannerList, loadingBannerList } = toJS(advertisingStores);
        return (
            <div>
                <div className='row justify-content-end' style={{ margin: 0 }}>
                    <AcceptButton onClick={this.onAddBanner} title='Tạo mới' />
                </div>
                <PaginationTable
                    paginationOptions={this.paginationOption()}
                    onTableChange={this.onTableChange}
                    datas={bannerList}
                    columns={this.columnsOption()}
                    loading={loadingBannerList}
                />
            </div>
        );
    }
}

export default observer(AdvertisingBannerTable);