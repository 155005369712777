import React, { Component } from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import PortalRoute from './pages/PortalRoute';
import Login from './pages/Login';
import Splash from './pages/Splash';
// import ClientForgotPassword from './pages/ClientForgotPassword';
import { Global } from "./GlobalClass";
import { handleAuthMiddleware } from './helpers/CF';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';


const initialAppState = {
  loading: true
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = initialAppState;
  };

  render() {
    const { loading } = this.state;
    return (
      <Router {...this.props}>
        <div>
          {
            loading ?
              <div style={{ width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', display: 'flex' }}>
                <p>Loading</p>
              </div>
              :
              <Switch>
                <Route exact path="/Splash" component={Splash} />
                <Route path="/Portal" component={PortalRoute} />
                <Route exact path="/Login" component={Login} />
                <Route render={() => <Redirect to="/Splash" />} />
              </Switch>
          }
        </div>
      </Router>
    );
  }


  checkAuth = async () => {
    handleAuthMiddleware();
    this.setState({ loading: false });
  }

  componentDidMount = () => {
    this.checkAuth();
    Global.setHistory(this.props.history);
  }
};



export default App;
