import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import moment from 'moment';
import { configPushNotificationStores } from '../stores/ConfigPushNotificationStores';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';
import Switch from "react-switch";

class TableConfigOnOffPushNotification extends Component {
    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: configPushNotificationStores.total,
            page: configPushNotificationStores.currentPage,
        };
        return paginationOption;
    }



    onOffFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    <Switch
                        onChange={(checked) => {
                            configPushNotificationStores.toggleOnOffPushNotification(row.id, checked ? 'ON' : 'OFF');
                        }}
                        checked={cell == 'ON' ? true : false} />
                }
            </div>
        );
    }

    onOffFormatterInApp = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    <Switch
                        onChange={(checked) => {
                            configPushNotificationStores.toggleOnOffInAppPushNotification(row.id, checked);
                        }}
                        checked={cell} />
                }
            </div>
        );
    }

    onOffFormatterFirebase = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    <Switch
                        onChange={(checked) => {
                            configPushNotificationStores.toggleOnOffFireBasePushNotification(row.id, checked);
                        }}
                        checked={cell} />
                }
            </div>
        );
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'name',
                text: 'Tên',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'description',
                text: 'Mô tả',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 150
                }
            },
            {
                dataField: 'status',
                text: 'ON/OFF Tất cả',
                headerFormatter: this.headerFormatter,
                formatter: this.onOffFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'onOffPushNotification',
                text: 'ON/OFF Push trên Firebase',
                headerFormatter: this.headerFormatter,
                formatter: this.onOffFormatterFirebase,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'onOffInAppNotification',
                text: 'ON/OFF Notification trong app',
                headerFormatter: this.headerFormatter,
                formatter: this.onOffFormatterInApp,
                headerStyle: {
                    width: 80
                }
            },
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
        textAlign: 'center'
    })

    onItemClicked = (row) => {
        this.confirmAlert = window.confirm('Xóa dòng này ?');
        if (this.confirmAlert) {
            configPushNotificationStores.deleteNotificationSchedule(row.id);
        }
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => this.onItemClicked(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-danger btn-simple">
                <i className="material-icons">delete</i>
            </button>
        </div >
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        configPushNotificationStores.setPage(page);
    }

    componentDidMount() {

        configPushNotificationStores.loadConfigPushNotifications();
    }

    render() {

        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={mobx.toJS(configPushNotificationStores.notificatyonTypes)}
                columns={this.columnsOption()}
                loading={configPushNotificationStores.loading}
            />
        );
    }
}

export default observer(TableConfigOnOffPushNotification);