import React, { Component } from 'react';
import { observer } from 'mobx-react';
import IntroImagesTable from './components/IntroImagesTable';
import { introImagesStores } from './stores/IntroImagesStores';
import { Global } from '../../GlobalClass';
import InsertModal from './components/InsertModal';
import { Button } from 'react-bootstrap';

class IntroImages extends Component {
    constructor(props) {
        super(props);
    };


    render() {
        return (
            <div className="card">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 offset-md-9" style={{
                            textAlign: 'end'
                        }}>
                            <Button onClick={introImagesStores.showModalInsert} variant="primary">Thêm mới</Button>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <IntroImagesTable
                    />
                </div>

                <InsertModal
                    onHide={() => {
                        introImagesStores.showModal = false;
                    }} show={introImagesStores.showModal} />
            </div>
        );
    }

    componentDidMount = async () => {
        introImagesStores.getData();
    };

}

export default observer(IntroImages);