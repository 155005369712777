import React from 'react';
import { Form } from 'react-bootstrap';
import { manageAdvertisingStores } from '../../stores/ManageAdvertisingStores';
import { observer } from 'mobx-react';
import numeral from 'numeral';

const InputTextADSForm = (props) => {
    const {
        itemKey,
        item
    } = props;

    const { adsObjectSelected } = manageAdvertisingStores;

    const onTextChange = (e) => {
        if (item.format == 'money' && e.target.value.length >= 16) return;
        adsObjectSelected.objectTree[itemKey].value = e.target.value;
    }
    const onBlur = () => {
        switch (item.format) {
            case 'money':
                adsObjectSelected.objectTree[itemKey].value = Number(numeral(adsObjectSelected.objectTree[itemKey].value).format('0'));
                break;
        }
    }
    let value = adsObjectSelected.objectTree[itemKey] ? (adsObjectSelected.objectTree[itemKey].value || '') : '';

    switch (item.format) {
        case 'money':
            value = numeral(value).format('0,0');
            break;
    }

    return (
        <div style={{ marginTop: 30 }}>
            <Form.Label>{item.title}</Form.Label>
            <Form.Control
                onBlur={onBlur}
                type={item.inputType || 'text'}
                value={value}
                onChange={onTextChange}
                placeholder="Nhập..." />
        </div>
    )
}


export default observer(InputTextADSForm);