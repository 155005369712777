import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { Card } from 'react-bootstrap';
import { dashboardStores } from '../Dashboard/stores/DashboardStores';
import { observer } from 'mobx-react';
import {
    getDailyTotalSaleThreeTypes,
} from '../../api/dashboard/dashboard';
class ChartSellingProductsThreeTypes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
            },
            series: [
            ],
        };
    }


    componentDidMount = async () => {
        const result = await getDailyTotalSaleThreeTypes();
        const dataArray = result.dataArray || [];

        var totalProductSaleByAladin = [];
        var totalProductSaleToNobody = [];
        var totalProductMarkAsSold = [];
        var categories = [];
        for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            categories.push(element.name);
            totalProductSaleByAladin.push(element.totalProductSaleByAladin);
            totalProductSaleToNobody.push(element.totalProductSaleToNobody);
            totalProductMarkAsSold.push(element.totalProductMarkAsSold);
        }
        var seriesData = [
            {
                name: 'Bán qua aladin',
                type: 'column',
                data: totalProductSaleByAladin
            },
            {
                name: "Bán ra ngoài",
                type: 'column',
                data: totalProductSaleToNobody
            },
            {
                name: "Bán nhưng chưa chọn người mua",
                type: 'column',
                data: totalProductMarkAsSold
            },
        ];
        const dailyTotalSaleChart = {
            options: {
                chart: {
                    height: 350,
                    type: "line",
                    stacked: false
                },
                dataLabels: {
                    enabled: true
                },
                colors: ['#99C2A2', '#C5EDAC', '#66C7F4'],
                xaxis: {
                    categories: categories
                }
            },
            series: seriesData,
        }
        console.log(dailyTotalSaleChart.xaxis)
        this.setState({
            options: dailyTotalSaleChart.options,
            series: dailyTotalSaleChart.series,
        })
    };

    render() {
        return (
            <Card style={{ width: '100%' }}>
                <Card.Header as="h5">Biểu đồ</Card.Header>
                <Card.Body>
                    <Card.Title>Số lượng Mặt hàng đã bán theo loại hằng ngày</Card.Title>
                    <Card.Text>
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            xaxis={this.state.xaxis}
                            type="bar"
                            width='100%'

                        />
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}

export default observer(ChartSellingProductsThreeTypes);