export const COLOR = {
    primary: '#fe805c',
    secondary: '#333333',
    greyWeight2: '#898989',
    tertiary: '#8e8e8e',
    greyBackGround: '#f4f4f4',
    tertiary_light: '#efefef',
    tertiary_grey: '#e8e8e8',
    tertiary_white: '#fafafa',
    greenLine: '#0cc264',
    text_normal: '#131415',
    text_light: '#feffff',
    yellow: '#F3B73F',
    green: '#4caf50'
}

export const pageableDefault = {
    offset: 0,
    last: null
}

export const s3UrlPrefix = {
    IMAGE_URL_PREFIX: 'https://aladin-today-bucket.s3.ap-southeast-1.amazonaws.com/',
    IMAGE_URL_PREFIX_LG: 'https://aladin-today-bucket.s3.ap-southeast-1.amazonaws.com/lg/'
}

export const PRODUCT_AUTO_APPROVE_STATUS = {
    AUTO_APPROVE_WAITING_FOR_REVIEW: 'AUTO_APPROVE_WAITING_FOR_REVIEW',
    AUTO_APPROVE_REVIEWED: 'AUTO_APPROVE_REVIEWED',
    AUTO_APPROVE_WAITING_FOR_REVIEW2: 'AUTO_APPROVE_WAITING_FOR_REVIEW2'
}

export const FormTypes = {
    COMBOBOX: 'COMBOBOX',
    TEXT_INPUT: 'TEXT_INPUT'
}