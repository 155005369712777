import { observer } from 'mobx-react';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { AcceptButton, CancelButton } from 'src/pages/components/Button';
import { productReportStore } from '../stores/ProductReportStore';

const ProductReportFeedbackModal = forwardRef((props, ref) => {
    const [content, setContent] = useState('');
    const [forumId, setForumId] = useState('');
    const [userIdToSendFeedback, setUserId] = useState(null);
    const { isShowFeedbackModal, sendFeedbackLoading } = productReportStore;
    useImperativeHandle(ref, () => ({
        setContent: (txt) => setContent(txt),
        settUserIdToSendFeedback: (id) => setUserId(id),
        setForumId: (id) => setForumId(id),
    }));
    const onHide = useCallback(() => {
        setContent('');
        setForumId('');
        setUserId(null);
        productReportStore.changeFeedbackModalStatusWorker(false);
        productReportStore.changeFeedbackModalLoadingWorker(false);
    }, []);
    const onAcceptChange = useCallback(async () => {
        if (!content || !userIdToSendFeedback) return;
        let status = await productReportStore.sendFeedbackProductReportWorker(content, userIdToSendFeedback, { forumId });
        if (status) onHide();
    }, [content, userIdToSendFeedback, forumId]);
    const onChangeText = useCallback((e) => setContent(e.target.value), []);
    const onChangeForumId = useCallback((e) => setForumId(e.target.value), []);
    return (
        <Modal
            show={isShowFeedbackModal}
            size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            animation
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                    {'Gửi phản hồi'}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                <FormControl isInvalid={true} isValid={false}
                    value={content}
                    onChange={onChangeText}
                    style={{ minHeight: 100, borderBottom: 'solid 1px #fe805c' }} placeholder="Nhập nội dung" plaintext as="textarea" />
            </Modal.Body>

            <Modal.Body >
                <FormControl isInvalid={true} isValid={false}
                    value={forumId}
                    onChange={onChangeForumId}
                    style={{ borderBottom: 'solid 1px #fe805c' }} placeholder="Forum id" plaintext/>
            </Modal.Body>

            <Modal.Footer>
                <CancelButton onClick={onHide} title='Hủy bỏ' />
                <AcceptButton disabled={sendFeedbackLoading} onClick={onAcceptChange} title='Đồng ý' />
            </Modal.Footer>

        </Modal>
    )
});

export default observer(ProductReportFeedbackModal);
