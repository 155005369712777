import React, { Component } from 'react';
import SideBar from './components/SideBar';
import NavBar from './components/NavBar';
// import Footer from './components/Footer';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import ProductManagement from '../ProductManagement';
import OrderManagement from '../OrderManagement';
import UserManagement from '../UserManagement';
import UserDetail from '../UserManagement/components/UserDetail';
import FeedbackManagement from '../FeedbackManagement';
import ReportManagement from '../ReportManagement';
import ProductReportManagement from '../ProductReportManagement';
import ForgotPasswordPhoneNumber from '../ForgotPasswordPhoneNumber';
import IntroImages from '../IntroImages';
import PushNotification from '../PushNotification';
import Advertising from '../Advertising';
import Dashboard from '../Dashboard';
import Stats from '../Stats';
import ManageAdvertising from '../ManageAdvertising';
import Coalition from '../Coalition';
import Article from '../Article';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ROUTER } from '../../common/route';
import ChartAsk from '../Charts/ChartAsk';
import ChartMakeAnOffer from '../Charts/ChartMakeAnOffer';
import ChartChatMessage from '../Charts/ChartChatMessage';
import ChartMoneySold from '../Charts/ChartMoneySold';
import ChartSellingProducts from '../Charts/ChartSellingProducts';
import ChartProductSold from '../Charts/ChartProductSold';
import ChartSellingProductsThreeTypes from '../Charts/ChartSellingProductsThreeTypes';
import ChartReportUserByLocation from '../Charts/ChartReportUserByLocation';
import SendEmail from '../SendEmail';
import Collection from '../Collection';
import Forum from '../Forum';
import Events from '../Events';
import PromotionPopup from '../PromotionPopup';

export default class PortalRoutePage extends Component {
    constructor(props) {
        super(props);
    };


    render() {
        return (
            <div className="wrapper">
                <SideBar location={this.props.location} />
                <div className="main-panel">
                    <NavBar />
                    <div className="content" style={{ backgroundColor: 'white' }}>
                        <div className="container-fluid">
                            <Switch>
                                <Route exact path='/Portal' component={Dashboard} />
                                {/* Stats */}
                                <Route exact path={ROUTER.STATS.INDEX} component={Stats} />
                                {/* Product */}
                                <Route exact path={ROUTER.PRODUCT.INDEX} component={ProductManagement} />
                                <Route exact path={ROUTER.PRODUCT.REVIEW} component={ProductManagement} />
                                {/* Order */}
                                <Route exact path={ROUTER.ORDER.INDEX} component={OrderManagement}/>
                                <Route exact path={ROUTER.ORDER.REVIEW} component={OrderManagement}/>
                                {/* Profile */}
                                <Route exact path={ROUTER.USER.INDEX} component={UserManagement} />
                                <Route exact path={ROUTER.USER.VERIFY_ID} component={UserManagement} />
                                <Route exact path={ROUTER.USER.DETAIL} component={UserDetail} />
                                {/* Report */}
                                <Route exact path={ROUTER.REPORT.INDEX} component={ReportManagement} />
                                <Route exact path={ROUTER.PRODUCT_REPORT.INDEX} component={ProductReportManagement} />
                                {/* Feedback */}
                                <Route exact path={ROUTER.FEEDBACK.INDEX} component={FeedbackManagement} />
                                {/* Advertising */}
                                <Route exact path={ROUTER.ADVERTISING.INDEX} component={Advertising} />
                                {/* forgot password phonenumber */}
                                <Route exact path={ROUTER.FORGOT_PASSWORD_PHONE_NUMBER.INDEX} component={ForgotPasswordPhoneNumber} />
                                {/* Intro images */}
                                <Route exact path={ROUTER.INTRO_IMAGES.INDEX} component={IntroImages} />
                                {/* Intro images */}
                                <Route exact path={ROUTER.PUSH_NOTIFICATION.INDEX} component={PushNotification} />
                                {/* Manage advertising */}
                                <Route exact path={ROUTER.MANAGE_ADVERTISING.INDEX} component={ManageAdvertising} />
                                {/* Manage article */}
                                <Route exact path={ROUTER.MANAGE_ARTICLE.INDEX} component={Article} />
                                {/* Charts */}
                                <Route exact path={ROUTER.CHART_ASK.INDEX} component={ChartAsk} />
                                <Route exact path={ROUTER.CHART_MAKE_AN_OFFER.INDEX} component={ChartMakeAnOffer} />
                                <Route exact path={ROUTER.CHART_CHAT_MESSAGE.INDEX} component={ChartChatMessage} />
                                <Route exact path={ROUTER.CHART_MONEY_SOLD.INDEX} component={ChartMoneySold} />
                                <Route exact path={ROUTER.CHART_SELLING_PRODUCTS.INDEX} component={ChartSellingProducts} />
                                <Route exact path={ROUTER.CHART_PRODUCT_SOLD.INDEX} component={ChartProductSold} />
                                <Route exact path={ROUTER.CHART_PRODUCT_SELL_THREE_TYPES.INDEX} component={ChartSellingProductsThreeTypes} />
                                <Route exact path={ROUTER.CHART_REPORT_USER_BY_LOCATION.INDEX} component={ChartReportUserByLocation} />
                                <Route exact path={ROUTER.SEND_EMAIL.INDEX} component={SendEmail} />
                                {/* Coalition */}
                                <Route exact path={ROUTER.COALITION.INDEX} component={Coalition} />
                                {/* Collection */}
                                <Route exact path={ROUTER.COLLECTION.INDEX} component={Collection} />
                                {/* Forum */}
                                <Route exact path={ROUTER.FORUM.INDEX} component={Forum} />
                                {/* Newsfeed */}
                                <Route exact path={ROUTER.EVENTS.INDEX} component={Events} />
                                {/* Promotion popup */}
                                <Route exact path={ROUTER.PROMOTION_POPUP.INDEX} component={PromotionPopup} />
                                {/* NOT FOUND ROUTE */}
                                <Route render={() => <Redirect to='/Portal' />} />
                            </Switch>
                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
        );
    }

    componentDidMount = () => {
    };


}