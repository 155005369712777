import { requestApi } from "../axios";
const prefix = 'user-profile';

export function resetPasswordApi(userId, password) {
    return requestApi(`${prefix}/reset-password`, 'POST', { userId, password });
}

export function getUserDetailApi(userId) {
    return requestApi(`${prefix}/user-detail?id=${userId}`, 'GET');
}

export function getFeedbacksOfUserApi(userId, limit, offset) {
    return requestApi(`${prefix}/feedbacks-of-user?id=${userId}&limit=${limit}&offset=${offset}`, 'GET');
}
export function getFeedbacksOfUserCountApi(userId) {
    return requestApi(`${prefix}/feedbacks-of-user-count?id=${userId}`, 'GET');
}

export function getReportsOfUserApi(userId, offset) {
    return requestApi(`${prefix}/reports-of-user?id=${userId}&limit=10&offset=${offset}`, 'GET');
}

export function blockUserApi(userId) {
    return requestApi(`${prefix}/block-user`, 'POST', { userId });
}

export function unBlockUserApi(userId) {
    return requestApi(`${prefix}/un-block-user`, 'POST', { userId });
}

export function updatePaperId({ userId, paperType, paperId }) {
    return requestApi(`${prefix}/update-paper-id`, 'POST', { userId, paperType, paperId });
}