import { extendObservable } from 'mobx';
import { pageableOffset } from '../../../helpers/CF';
import { getData, saveNote, generatePassword } from '../../../api/forgotPasswordPhoneNumber/forgotPasswordPhoneNumber';
import { Global } from '../../../GlobalClass';
class ForgotPasswordPhoneNumberStores {
    constructor() {
        extendObservable(this, {
            forgotPasswordPhoneNumberList: [],
            loading: false,
            currentPage: 1,
            showModal: false,
            note: '',
            id: 0,
            password: '',
        });
    }


    getData = async () => {
        this.loading = true;
        const offset = pageableOffset(this.currentPage);
        const result = await getData(offset);
        if (result.responseCode.toString().startsWith('2')) {
            this.forgotPasswordPhoneNumberList = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.loading = false;
    }

    onItemClicked(row) {
        this.showModal = true;
        this.id = row.id;
        this.password = row.password;
    }

    onBtnGeneratePassword = async () => {
        let data = await generatePassword(this.id);
        this.password = data.data.password;
    }

    resetData() {
        this.id = 0;
        this.note = '';
    }

    onBtnSaveClicked = async () => {
        await saveNote(this.id, this.note);
        this.showModal = false;
        this.getData();
    }

    // onChangeFeedbackResponseModal = (status) => this.isShowFeedbackResponseModal = status;

    // setIdFeedbackToResponse = (feedbackId) => this.idFeedbackToResponse = feedbackId;

    setPage = (page) => this.currentPage = page;

    // responseFeedbackAction = async (message) => {
    //     const result = await responseFeedbackApi(this.idFeedbackToResponse, message);
    //     if (result.responseCode.toString().startsWith('2')) this.getFeedbackListAction();
    //     else Global.error({ message: result.responseText || '' });
    // }
}
export const forgotPasswordPhoneNumberStores = new ForgotPasswordPhoneNumberStores();

