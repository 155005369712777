import { requestApi } from "../axios";
const prefix = 'user-profile';

export function getVerifyIdListCountApi() {
    return requestApi(`${prefix}/verify-id-count-list`, 'GET');
}
export function getVerifyIdListHistoriesApi(userId) {
    return requestApi(`${prefix}/verify-id-history-list?userId=${userId}`, 'GET');
}
export function getVerifyIdRejectTypeListApi() {
    return requestApi(`${prefix}/verify-id-reject-type-list`, 'GET');
}
export function getVerifyIdRequestListApi(offset) {
    const url = `${prefix}/verify-id-request-list?limit=${10}&offset=${offset || 0}`
    return requestApi(url, 'GET');
}
export function getVerifyIdRequestDetailApi(userId) {
    const url = `${prefix}/verify-id-detail`
    return requestApi(url, 'POST', { userId });
}
export function approveRequestVerifyIdApi({ userId, paperId }) {
    const url = `${prefix}/approve-verify-id`
    return requestApi(url, 'POST', { userId, paperId });
}
export function rejectRequestVerifyIdApi({ userId, rejectTypeId, rejectContent, paperId }) {
    const url = `${prefix}/reject-verify-id`
    return requestApi(url, 'POST', { userId, rejectTypeId, rejectContent, paperId });
}