import React from 'react';


const Dropdown = ({
    datas,
    idSelected,
    onItemClicked,
    buttonStyle,
    containerStyle,
    buttonClassName
}) => {
    const itemSelected = datas.find(o => o.id == idSelected);
    return (
        <div
            className="dropdown"
            style={containerStyle}
        >
            <button className={`btn btn-secondary dropdown-toggle ${buttonClassName || ''}`} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                style={buttonStyle}
            >
                {itemSelected ? itemSelected.name : 'Vui lòng chọn'}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                    (datas || []).map((item, index) => {
                        return (
                            <button style={{ width: '-webkit-fill-available' }} key={index} onClick={() => onItemClicked(item)} className="dropdown-item">{item.name}</button>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Dropdown;