import { requestApi } from "../axios";
const prefix = 'feedback';

export function getFeedbackListApi(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function getFeedbackTotalCountApi() {
    return requestApi(`${prefix}/total-count`, 'GET');
}
export function responseFeedbackApi(feedbackId, message) {
    return requestApi(`${prefix}/response-feedback`, 'POST', { feedbackId, message });
}
export function updateFeedbackStatusApi(id, status) {
    return requestApi(`${prefix}/update-status`, 'PUT', { id, status });
}