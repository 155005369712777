import { extendObservable } from 'mobx';
import { getProductListApi, getTotalProductCountApi } from '../../../api/product/product';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';

export const radioFilterProductListData = [
    {
        id: 'all',
        name: 'Tất cả'
    },
    {
        id: 'no_proof',
        name: 'Sản phẩm không bằng chứng sở hữu'
    }
]
class ProductListStores {
    constructor() {
        extendObservable(this, {
            productList: [],
            getProductListLoading: false,
            totalProductListCount: 0,
            productTableCurrentPage: 1,
            isShowProductDetailModal: false,
            txtSearchProduct: '',
            productFilterSelected: radioFilterProductListData[0].id
        });
    }
    onSearchProductChange = (txt) => this.txtSearchProduct = txt || '';

    setProductFilterSelected = (id) => this.productFilterSelected = id;

    getProductListStore = async () => {
        try {
            this.getProductListLoading = true;
            const offset = pageableOffset(this.productTableCurrentPage);
            const result = await getProductListApi(offset, this.txtSearchProduct || '', null, this.productFilterSelected);
            if (result.responseCode.toString().startsWith('2')) {
                this.productList = result.dataArray || [];
            } else {
                Global.error({ message: result.responseText || '' });
            }
            this.getProductListLoading = false;
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.getProductListLoading = false;
        }
    }
    setProductTablePage = (page) => this.productTableCurrentPage = page;

    getTotalProductCount = async (filter) => {
        const result = await getTotalProductCountApi(this.txtSearchProduct || '', null, filter);
        if (result.responseCode.toString().startsWith('2')) {
            this.totalProductListCount = parseInt(result.data.total);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    onProductDetailModalStatusChange = (status) => this.isShowProductDetailModal = status;

}
export const productListStores = new ProductListStores();