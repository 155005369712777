import { extendObservable } from 'mobx';
import { Global } from '../../../GlobalClass';

import { getPromotionPopupList, getPromotionPopupCount, getPromotionPopupDetails, createPromotionPopup, updatePromotionPopup } from '../../../api/promotionPopup/promotionPopup';
import { pageableOffset } from 'src/helpers/CF';

class PromotionPopupStore {
  constructor() {
    extendObservable(this, {
      isLoadingGetPromotionPopupList: false,
      promotionPopupList: [],
      promotionPopupCount: 0,
      currentPromotionPopup: {},
      tableCurrentPage: 1,
      isShowModal: false,
      txtSearch: ''
    })
  }

  onTextSearchChange = (txt) =>this.txtSearch = txt || '';

  clearCurrentPromotionPopup = () => this.currentPromotionPopup = {};

  setShowModal = isShow => this.isShowModal = isShow;

  setTableCurrentPage = page => {
    this.tableCurrentPage = page;
  }

  getPromotionPopupCount = async () => {
    try {
      this.isLoadingGetPromotionPopupList = true;
      const result = await getPromotionPopupCount(this.txtSearch || '');
      if (result.responseCode.toString().startsWith('2')) {
        this.promotionPopupCount = result.data && result.data.count || 0;
      } else {
        Global.error({ message: result.responseText || '' });
      }
      this.isLoadingGetPromotionPopupList = false;
    } catch (error) {
      Global.error({ message: error.message || '' });
      this.isLoadingGetPromotionPopupList = false;
    }
  }

  getPromotionPopupList = async () => {
    try {
      this.isLoadingGetPromotionPopupList = true;
      const offset = pageableOffset(this.tableCurrentPage);
      const result = await getPromotionPopupList(this.txtSearch || '', 10, offset);
      if (result.responseCode.toString().startsWith('2')) {
        this.promotionPopupList = result.dataArray || [];
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
    this.isLoadingGetPromotionPopupList = false;
  }

  getPromotionPopupDetails = async (promotionPopupId) => {
    try {
      this.isLoadingGetPromotionPopupList = true;
      const result = await getPromotionPopupDetails(promotionPopupId);
      if (result.responseCode.toString().startsWith('2')) {
        this.currentPromotionPopup = result.data || {};
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
    this.isLoadingGetPromotionPopupList = false;
  }

  updatePromotionPopup = async (promotionPopupId, payload) => {
    try {
      const result = await updatePromotionPopup(promotionPopupId, payload);
      if (result.responseCode.toString().startsWith('2')) {
        this.clearCurrentPromotionPopup();
        this.isShowModal = false;
        this.getPromotionPopupList();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
  }

  createPromotionPopup = async (payload) => {
    try {
      const result = await createPromotionPopup(payload);
      if (result.responseCode.toString().startsWith('2')) {
        this.clearCurrentPromotionPopup();
        this.isShowModal = false;
        this.getPromotionPopupList();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
  }
}

export default new PromotionPopupStore();