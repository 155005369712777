import React from 'react';
import CollectionProductTable from './CollectionProductTable';
import { useObserver } from "mobx-react";
import { user_avatar_default } from 'src/common/images';
import moment from 'moment';
import { specificCollectionStore } from '../store/SpecificCollectionStore';
import PaginationTable from 'src/pages/components/PaginationTable';
import { toJS } from 'mobx';

const CollectionRecentlyAddedProduct = () => {
  const thumbFormatter = (cell, row) => {
    return <div key={row.id} className="card-avatar">
      <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.thumbImage || user_avatar_default} />
    </div>
  }

  const statusFormater = (cell, row) => {
    return <b><i>{row.statusName}</i></b>
  }

  const onRemoveProduct = (productId) => {
    specificCollectionStore.removeProductFromNewAddedProducts(productId)
  }

  const actionButtons = (cell, row) => {
    return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
      <button 
        style={{ marginRight: 6 }} 
        type="button" rel="tooltip" 
        className="btn btn-danger btn-simple"
        onClick={() => onRemoveProduct(row.id)}
      >
        <i className="material-icons">clear</i>
      </button>
    </div>
  }

  const headerFormatter = (column, colIndex) => {
    return <h5><strong>{column.text}</strong></h5>
  }

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span>{moment(cell).format('DD/MM/YYYY')}</span>
      </div>
    );
  }

  const columnsOption = () => {
    return [
      {
        dataField: 'id',
        text: '#',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'thumbImage',
        text: 'hình ảnh',
        formatter: thumbFormatter,
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'productName',
        text: 'Tên sản phẩm',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault,
      },
      {
        dataField: 'price',
        text: 'Giá',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'areaText',
        text: 'Địa chỉ',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: 'conditionName',
        text: 'Tình trạng',
        headerFormatter: headerFormatter,
        headerStyle: headerStyleDefault
      },
      {
        dataField: "action",
        text: "Hành động",
        formatter: actionButtons,
        headerFormatter: headerFormatter,
        headerStyle: {
          width: 60,
        },
      },
    ];
  }

  const headerStyleDefault = (column, colIndex) => ({
    width: 100,
  })

  const paginationOption = (totalAddedProductListCount) => {
    const paginationOption = {
      custom: true,
      hideSizePerPage: true,
      totalSize: totalAddedProductListCount,
      page: 0,
    };
    return paginationOption;
  }

  return useObserver(() => {
    const { newAddedProducts, totalNewAddedProductsCount } = toJS(specificCollectionStore);
    return <PaginationTable
      paginationOptions={() => paginationOption(totalNewAddedProductsCount)}
      datas={newAddedProducts}
      columns={columnsOption()}
    />
  })
}

export default CollectionRecentlyAddedProduct;