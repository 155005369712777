import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import { ROUTER } from '../../common/route';
import { dashboardStores } from './stores/DashboardStores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import Icon from '../../resources/svg';
import UserChartDaily from './components/UserChartDaily';
import UserChartWeekly from './components/UserChartWeekly';
import UserChartMonthly from './components/UserChartMonthly';
import UserTypeChart from './components/UserTypeChart';
import TotalMakeAnOfferDaily from './components/TotalMakeAnOfferDaily';
import TotalAskDaily from './components/TotalAskDaily';
import TotalChatMessage from './components/TotalChatMessage';
import TotalSale from './components/TotalSale';
import TotalSold from './components/TotalSold';
import TotalMoneySold from './components/TotalMoneySold';
import TotalProductByCategory from './components/TotalProductByCategory';
import * as mobx from 'mobx';
import { Button } from 'react-bootstrap';
import { generateChartData } from '../../api/dashboard/dashboard';


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.onRefreshData = this.onRefreshData.bind(this);
    };


    componentDidMount = () => {
        dashboardStores.getStatisticData();
        dashboardStores.getDailyUserChart();
        dashboardStores.getWeeklyUserChart();
        dashboardStores.getMonthlyUserChart();
        dashboardStores.getUserTypeChart();
        dashboardStores.getTotalProductByCategoryChart();
    };

    onRefreshData() {
        generateChartData();
        setTimeout(() => {alert('Please reload page')}, 2000)
    }


    renderTotalUserCard = () => {
        const { commonStatistic } = toJS(dashboardStores);
        {/* user total */ }
        return (
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-stats">
                    <div className="card-header card-header-info card-header-icon">
                        <div className="card-icon">
                            <i className="material-icons">person</i>
                        </div>
                        <p className="card-category"><Link to={ROUTER.USER.INDEX}>Số lượng người dùng</Link></p>
                        <h3 className="card-title">
                            {commonStatistic.totalUser || 0}
                        </h3>
                    </div>

                    <div className="card-footer">
                        <div className="stats">
                            <i className="material-icons">face</i> Người dùng mới: {commonStatistic.totalNewRegisterUser || 0}
                        </div>
                        {/* <div className="stats">
                            <i className="material-icons">toc</i>
                            <Link to={ROUTER.USER.INDEX}>Chi tiết</Link>
                        </div> */}
                    </div>

                    <div className="card-footer">
                        <div className="stats">
                            <i className="material-icons">credit_card</i> Yêu cầu xác thực: {commonStatistic.totalVerifiedUser || 0}
                        </div>
                    </div>

                    <div className="card-footer">
                        <div className="stats">
                            <i className="material-icons">accessibility</i> Người bán hàng: {commonStatistic.totalSellers || 0}
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    renderTotalProductCard = () => {
        const { commonStatistic } = toJS(dashboardStores);
        {/* user total */ }
        return (
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-stats">
                    <div className="card-header card-header-success card-header-icon">
                        <div className="card-icon">
                            <i className="material-icons">store</i>
                        </div>
                        <p className="card-category">Số lượng sản phẩm</p>
                        <h3 className="card-title">
                            {commonStatistic.totalProduct || 0}
                        </h3>
                    </div>

                    <div className="card-footer">
                        <div className="stats">
                            <i className="material-icons">label</i> Đã bán: {commonStatistic.totalProductSold || 0}
                        </div>
                    </div>

                    <div className="card-footer">
                        <div className="stats">
                            <i className="material-icons">money</i> Tổng tiền: {commonStatistic.totalMoneyProductSold || '0'} VNĐ
                        </div>
                    </div>


                </div>
            </div>
        )
    }


    render() {

        return (
            <div className='content'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <Button variant="info" onClick={this.onRefreshData}>Refresh data</Button>
                        </div>
                    </div>
                    <div className="row">
                        {this.renderTotalUserCard()}
                        {this.renderTotalProductCard()}
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <UserTypeChart chartData={mobx.toJS(dashboardStores.userTypeChart)} />
                        </div>
                        <div className="col-lg-6"><TotalProductByCategory chartData={mobx.toJS(dashboardStores.totalProductByCategoryChart)} /></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6"><UserChartDaily chartData={mobx.toJS(dashboardStores.dailyUserChart)} /></div>
                        <div className="col-lg-6"><UserChartMonthly chartData={mobx.toJS(dashboardStores.monthlyUserChart)} /></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12"><UserChartWeekly chartData={mobx.toJS(dashboardStores.weeklyUserChart)} /></div>
                    </div>
                </div>
            </div>
        );
    }

}

export default observer(Dashboard)